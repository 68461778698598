import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { TableCellProps } from 'react-virtualized';
import CollectionHeaderBar from 'components/shared/CollectionHeaderBar';
import Dialog, { DialogProps } from 'components/shared/Dialog';
import { AssignableUser, User } from 'models';
import strings from 'common/strings';
import Table from 'components/shared/Table';
import './AssignStepUsersDialog.scss';
import { useOrganizationUsers } from 'api/organizations';
import { Form } from 'react-bootstrap';

const CHECKLIST_COLUMN_WIDTH = 25;

interface AssignStepUsersDialogProps extends DialogProps {
  orgId: string;
  currentlyAssignableUsers?: AssignableUser[];
  onAddUsers: (users: AssignableUser[]) => void;
}

var AssignStepUsersDialog = ({
  orgId,
  currentlyAssignableUsers,
  open,
  onClose,
  title,
  onAddUsers,
}: AssignStepUsersDialogProps) => {
  const [searchText, setSearchText] = useState<string>();
  const orgUsers = useOrganizationUsers(orgId, searchText);
  const [checkedUsers, setCheckedUsers] = useState(
    currentlyAssignableUsers || []
  );

  const handleSearch = (updatedSearchText: string) => {
    setSearchText(updatedSearchText);
    orgUsers.refetch();
  };

  const flattenedUserList = orgUsers?.data?.pages.reduce(
    (acc: User[], pageUsers) => [...acc, ...pageUsers.data],
    []
  ); // TODO we do we need to flatten move to usersList component
  const totalRecords = flattenedUserList?.length; // TODO

  const isChecked = (user: User) =>
    checkedUsers.findIndex(({ id }) => id === user.id) >= 0;

  const handleOnChange = (user: User) => {
    const userId = user.id;
    const index = checkedUsers.findIndex(({ id }) => id === userId);

    if (index > -1) {
      checkedUsers.splice(index, 1);
    } else {
      checkedUsers.push(user);
    }
    setCheckedUsers([...checkedUsers]);
  };

  return (
    <Dialog
      open={open}
      primaryButtonLabel={strings.ADD_USERS}
      maxWidth="lg"
      fullWidth
      onClose={onClose}
      className="AssignStepUsersDialog"
      height={740}
      title={title}
      showCloseButton
      closeElement={
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      }
      onPrimary={() => onAddUsers(checkedUsers)}
    >
      <CollectionHeaderBar
        unit="User"
        count={totalRecords}
        onSearch={handleSearch}
        checklistState={checkedUsers}
        isLoading={orgUsers.isLoading}
      />
      <Table
        columns={[
          {
            heading: '',
            dataKey: '__checked__',
            content: ({ rowData }: TableCellProps) => (
              <Form.Check
                type="checkbox"
                checked={isChecked(rowData)}
                onChange={() => handleOnChange(rowData)}
              />
            ),
            width: CHECKLIST_COLUMN_WIDTH,
          },
          {
            heading: 'Name',
            dataKey: 'name',
            content: ({ rowData }: TableCellProps) =>
              `${rowData.firstName || ''} ${rowData.lastName || ''}`,
            width: 240,
          },
          {
            heading: 'Title',
            dataKey: 'title',
            content: ({ rowData }: TableCellProps) => rowData.jobTitle || '',
            width: 240,
          },
          {
            heading: 'Email',
            dataKey: 'email',
            content: ({ rowData }: TableCellProps) => rowData.email || '',
            flexGrow: 1,
          },
        ]}
        items={flattenedUserList ?? []}
        totalRowCount={totalRecords}
        fetchMoreRows={async () => orgUsers.fetchNextPage()}
        isInfiniteLoading
        showLoadingSkeletons
      />
    </Dialog>
  );
};

export default AssignStepUsersDialog;
