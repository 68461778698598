import { combineReducers } from 'redux';

import organizationUsersReducer from './users';
import organizationPluginsReducer from './plugins';
import organizationOverviewReducer from './overview';

const organizationsReducer = combineReducers({
  users: organizationUsersReducer,
  plugins: organizationPluginsReducer,
  overview: organizationOverviewReducer,
});

export type OrganizationsState = ReturnType<typeof organizationsReducer>;

export default organizationsReducer;
