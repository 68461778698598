import { ThemeProvider } from '@material-ui/styles';
import { defaultQueryFn, queryCacheStrategies } from 'api';
import theme from 'common/theme';
import React from 'react';
import { hot } from 'react-hot-loader';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import store from 'store';
import Authenticate from './Authenticate';
import Header from './Header';
import LoginView from './LoginView';
import OrganizationsView from './OrganizationsView';
import OrganizationsDetailView from './OrganizationsView/OrganizationDetailView';
import SettingsView from './SettingsView';
import UsersView from './UsersView';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      ...(queryCacheStrategies.basic as any),
      retry: false,
    },
  },
});

const App: React.FC = () => (
  <ThemeProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BrowserRouter>
          <div className="full-height flex-rows">
            <div className="flex-grow">
              <Routes>
                <Route path="/login" element={<LoginView />} />
                <Authenticate>
                  <Route path="/" element={<Header />}>
                    <Route path="orgs" element={<OrganizationsView />}>
                      <Route
                        path=":organizationId/*"
                        element={<OrganizationsDetailView />}
                      />
                    </Route>
                    <Route path="users/*" element={<UsersView />} />
                    <Route path="settings/*" element={<SettingsView />} />
                  </Route>
                </Authenticate>
              </Routes>
            </div>
          </div>
        </BrowserRouter>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </ThemeProvider>
);

export default hot(module)(App);
