// Copied 04282021 - https://github.com/carpediemsolutionsllc/car-web-reconvelocity-react/blob/dev/src/api/lib/strategies.ts
import { UseQueryOptions } from 'react-query';

/**
 * @name queryCacheStrategies
 * @descriptions Prescribed cache strategy options for working with react-query
 * in various use cases. When developing additional instances of useAPI/useQuery
 * use one of these configs before implementing any further customization.
 *
 * @remark Which strategy to use should be based on the behavior of data inside
 * your component.
 * @var passive - data is unchanged by the client and/or changes infrequently.
 * @var basic - default settings, use these if you arent sure.
 * @var aggressive - If data updates frequently or needs near-realtime updates.
 *
 * @remark - Be thoughtful! Aggressive settings are powerful but resource
 * hungry - use sparingly. This setting should only be used for requests that
 * power significant UI elements. Avoid using this setting if fetching for
 * each item on iteratable lists. (Note - these should ideally be powered by
 * a parent request that fetches each item in one request.)
 */

interface ICacheStrategies<T> {
  passive: UseQueryOptions<T, Error>;
  basic: UseQueryOptions<T, Error>;
  aggressive: UseQueryOptions<T, Error>;
  curious: UseQueryOptions<T, Error>;
}

export const queryCacheStrategies: ICacheStrategies<any> = {
  passive: {
    keepPreviousData: false,
    cacheTime: 60 * 60 * 1000, // one hour
    staleTime: 60 * 60 * 1000, // one hour
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    notifyOnChangeProps: [],
  },
  basic: {
    keepPreviousData: false,
    cacheTime: 10 * 60 * 1000, // 10 minutes
    staleTime: 10 * 60 * 1000, // 10 minutes
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  },
  curious: {
    keepPreviousData: false,
    cacheTime: 1000, // 1 second
    staleTime: 1000, // 1 second
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  },
  aggressive: {
    keepPreviousData: false,
    cacheTime: 10 * 1000, // 10 seconds
    staleTime: 10 * 1000, // 10 seconds
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    refetchInterval: 10 * 1000, // 10 seconds
    refetchIntervalInBackground: true, // 30 secondds
  },
};
export type CacheStrategyName = 'passive' | 'aggressive' | 'basic' | 'curious';
export const { passive, basic, aggressive } = queryCacheStrategies;
export default queryCacheStrategies;
