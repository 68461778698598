import {
  Address,
  defaultAddress,
  defaultMobilePhone,
  MobilePhone,
} from './common';
import {
  OrganizationGroupItemLink,
  OrganizationItemLink,
} from './organization';

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  INACTIVE = 'INACTIVE',
  LOCKED = 'LOCKED',
  UNKNOWN = 'UNKNOWN',
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  email: string;
  mobilePhone: MobilePhone;
  address: Address;
  username: string;
  status: string;
  defaultOrganization?: OrganizationItemLink;
  organizations?: OrganizationItemLink[];
  groups?: OrganizationGroupItemLink[];
  sendWelcomeMessage?: boolean;
  firstPassword?: string;
  lastSession?: string;
}

export const defaultUser: User = {
  id: '',
  firstName: '',
  lastName: '',
  jobTitle: '',
  email: '',
  mobilePhone: defaultMobilePhone,
  address: defaultAddress,
  username: '',
  status: 'ACTIVE',
  sendWelcomeMessage: true,
};

export interface RequestUser {
  defaultOrganizationId: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  email: string;
  mobilePhone: MobilePhone;
  status: string;
  organizationGroupIds: OrganizationItemLink['id'][];
  sendWelcomeMessage: boolean;
  firstPassword?: string;
}

export const convertUserToRequestUser = (user: User) => ({
  ...user,
  defaultOrganizationId: user.defaultOrganization?.id,
  firstName: user.firstName.trim(),
  lastName: user.lastName.trim(),
  jobTitle: user.jobTitle.trim(),
  email: user.email.trim(),
  mobilePhone: user.mobilePhone,
  status: user.status,
  organizationGroupIds: user.groups?.map((group) => group?.id),
  sendWelcomeMessage: user.sendWelcomeMessage,
  firstPassword: user.firstPassword,
});
