import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import queryString from 'query-string';

import { convertUserToRequestUser, RequestUser, User } from 'models';
import { defaultMetaQueryFn, defaultMutationFn, GetUserQueryData } from 'api';
import { configuredFetch } from '../base';

export type UsersRequestParams = {
  s?: string;
  startIndex?: number;
  pageSize?: number;
};

export type SpecificUsersRequestParams = {
  userId: User['id'];
};

export type CreateUsersRequestParams = {
  newUsers: RequestUser[];
};

export type UpdateUserRequestParams = {
  updatedUser: RequestUser;
  userId: User['id'];
};

export type DeleteUserRequestParams = {
  userId: User['id'];
};

const usersUrl = '/users';

function specificUserUrl({ userId }: SpecificUsersRequestParams) {
  return `/users/${userId}`;
}

/**
 * Get Users
 * Endpoint: GET /users
 */
async function getUsers({
  s = '',
  startIndex = 0,
  pageSize = 50,
}: UsersRequestParams) {
  const optionalParams = {
    pageSize,
    startIndex,
    s,
  };

  const usersUrlWithQuery = `${usersUrl}?${queryString.stringify(
    optionalParams
  )}`;

  const { data } = await configuredFetch<User[]>(usersUrlWithQuery);
  return data;
}

/**
 * Create Users
 * Endpoint: POST /users
 */
async function createUsers({
  newUsers,
}: CreateUsersRequestParams): Promise<User[]> {
  const { data } = await configuredFetch<User[]>(usersUrl, {
    method: 'post',
    body: JSON.stringify(newUsers),
  });
  return data;
}

/**
 * Update User
 * Endpoint: PUT /users
 */
async function updateUser({
  updatedUser,
  userId,
}: UpdateUserRequestParams): Promise<User[]> {
  const url = specificUserUrl({ userId });

  const { data } = await configuredFetch<User[]>(url, {
    method: 'put',
    body: JSON.stringify(updatedUser),
  });
  return data;
}

/**
 * Delete User
 * Endpoint: DELETE /users
 */
async function deleteUser({ userId }: DeleteUserRequestParams) {
  const url = specificUserUrl({ userId });

  const { data } = await configuredFetch<boolean>(url, {
    method: 'delete',
  });
  return data;
}

/**
 * Get Users
 * Endpoint: GET `/users?pageSize=50&startIndex=0&s=`
 */
export function useUsers(
  searchText: string = '',
  startIndex: number = 0,
  pageSize: number = 50
) {
  const searchParam = searchText ? `&s=${searchText}` : '';
  const url = [
    '/users',
    `?startIndex=${startIndex}&pageSize=${pageSize}${searchParam}`,
  ];
  const defaultParams = { startIndex, pageSize };
  return useInfiniteQuery<GetUserQueryData, Error>(
    url,
    async ({ pageParam = defaultParams }) =>
      defaultMetaQueryFn(
        `/users?startIndex=${pageParam.startIndex}&pageSize=${pageParam.pageSize}${searchParam}`
      ),
    {
      getNextPageParam: (lastPage, pages) => {
        const totalUsersLoaded = pages.reduce(
          (acc, page) => acc + page.meta.returnedRecords,
          0
        );

        if (totalUsersLoaded === lastPage.meta.totalRecords) return;

        return {
          startIndex: pages.length * 50,
          pageSize,
        };
      },
    }
  );
}

/**
 * Delete user
 * Endoint: DELETE `/users/{userId}`
 * @param userId ID of user to be deleted
 * @returns boolean indicating success of user deletion
 */
export function useDeleteUser() {
  const queryClient = useQueryClient();
  const mutation = useMutation((userId: string) => {
    const path = `/users/${userId}`;
    return defaultMutationFn(path, 'DELETE');
  });

  async function deleteUserAsync(data: any) {
    const response = await mutation.mutateAsync(data);
    await queryClient.invalidateQueries('/users*');
    await queryClient.invalidateQueries('/orgs/*/users');

    return response;
  }

  return {
    ...mutation,
    deleteUserAsync,
  };
}

/**
 * CRUD User
 * Endpoint: CRUD `/users/{userId}`
 */
export function useUser(userId?: User['id'], orgId?: string) {
  const queryClient = useQueryClient();
  const path = `/users/${userId ?? ''}`;
  const method = userId ? 'PUT' : 'POST';

  return {
    query: useQuery<User>(path, { enabled: !!userId }),
    mutation: useMutation(
      (data: User) =>
        defaultMutationFn(
          path,
          method,
          data.id
            ? convertUserToRequestUser(data)
            : [convertUserToRequestUser(data)]
        ),
      {
        onSuccess: async (data) => {
          if (orgId) {
            await queryClient.invalidateQueries(`/orgs/${orgId}/users`);
          }
          queryClient.refetchQueries('/users');
          if (data?.data) {
            if (data?.data?.length >= 0) {
              queryClient.setQueryData<User>(path, data.data[0]);
            } else {
              queryClient.setQueryData<User>(path, data.data);
            }
          }
        },
        onSettled: () => {
          queryClient.invalidateQueries(path);
        },
      }
    ),
  };
}

/**
 * POST sendPasswordReset
 * Endpoint: POST `/users/{userId}/security/sendPasswordReset`
 */
export async function sendPasswordReset(userId?: string) {
  const path = `/users/${userId}/security/sendPasswordReset`;
  const { data } = await configuredFetch<boolean>(path, {
    method: 'POST',
  });
  return data;
}

/**
 * POST changePassword
 * Endpoint: POST `/users/{userId}/security/changePassword`
 */
export function useChangePassword(userId?: string) {
  const path = `/users/${userId}/security/changePassword`;
  return useMutation((newPassword: string) =>
    defaultMutationFn(path, 'POST', { newPassword })
  );
}

/**
 * CRUD User
 * Endpoint: CRUD `/orgs/${organizationId}/users/${userId}/notifications/subscriptions`
 */
export function useUserSubscriptions(organizationId: string, userId: string) {
  const queryClient = useQueryClient();
  const path = `orgs/${organizationId}/users/${userId}/notifications/subscriptions`;
  return {
    query: useQuery<User>(path),
    mutation: useMutation(
      (data: User) => defaultMutationFn(path, 'PUT', data),
      {
        onSuccess: async (data) => {
          if (data) {
            queryClient.setQueryData<User>(path, data);
          }
        },
        onSettled: () => {
          queryClient.invalidateQueries(path);
        },
      }
    ),
  };
}

export default {
  getUsers,
  createUsers,
  updateUser,
  deleteUser,
};
