import { configuredFetch } from 'api/base';
import {
  FolioDocumentTemplateType,
  FolioTemplate,
  FolioTemplateListOption,
  FolioTemplateMatchRule,
  Organization,
} from 'models';

export type FolioTemplateRequestParams = {
  orgId: Organization['id'];
};

function baseUrl({ orgId }: FolioTemplateRequestParams) {
  return `/orgs/${orgId}/plugins/velocityvdp`;
}

/**
 * Get folio template document types.
 * Endpoint: GET /orgs/${orgId}/plugins/velocityvdp/folioTemplateDocumentTypes
 */
async function getFolioTemplateDocumentTypes({
  orgId,
}: FolioTemplateRequestParams): Promise<FolioDocumentTemplateType[]> {
  const url = `${baseUrl({ orgId })}/folioDocumentTemplateTypes`;

  const { data } = await configuredFetch<FolioDocumentTemplateType[]>(url);
  return data;
}

/**
 * Get folio template types.
 * Endpoint: GET /orgs/${orgId}/plugins/velocityvdp/folioTemplateTypes
 */
async function getFolioTemplateListOptions({
  orgId,
}: FolioTemplateRequestParams) {
  const url = `${baseUrl({ orgId })}/folioTemplates`;

  const { data } = await configuredFetch<FolioTemplateListOption[]>(url);
  return data;
}

/**
 * Get folio templates for a tenant.
 * Endpoint: GET /orgs/${orgId}/plugins/velocityvdp/folioTemplates/${folioTemplateId}
 */
type GetFolioTemplateParams = FolioTemplateRequestParams & {
  folioTemplateId: string;
};
async function getFolioTemplate({
  orgId,
  folioTemplateId,
}: GetFolioTemplateParams): Promise<FolioTemplate> {
  const url = `${baseUrl({ orgId })}/folioTemplates/${folioTemplateId}`;

  const { data } = await configuredFetch<FolioTemplate>(url);
  return data;
}

/**
 * Create a folio template for a tenant.
 * Endpoint: POST /orgs/${orgId}/plugins/velocityvdp/folioTemplate
 */
type CreateFolioTemplateParams = FolioTemplateRequestParams & {
  folioTemplate: FolioTemplate;
};
async function createFolioTemplate({
  orgId,
  folioTemplate,
}: CreateFolioTemplateParams): Promise<FolioTemplate> {
  const url = `${baseUrl({ orgId })}/folioTemplates/${folioTemplate.id}`;
  const options = {
    method: 'post',
    body: JSON.stringify(folioTemplate),
  };

  const { data } = await configuredFetch<FolioTemplate>(url, options);
  return data;
}

/**
 * Update a folio template for a tenant.
 * Endpoint: PUT /orgs/${orgId}/plugins/velocityvdp/folioTemplate
 */
type UpdateFolioTemplateParams = FolioTemplateRequestParams & {
  folioTemplateId: string;
  folioTemplate: FolioTemplate;
};
async function updateFolioTemplate({
  orgId,
  folioTemplateId,
  folioTemplate,
}: UpdateFolioTemplateParams): Promise<FolioTemplate> {
  const url = `${baseUrl({ orgId })}/folioTemplates/${folioTemplateId}`;
  const options = {
    method: 'put',
    body: JSON.stringify(folioTemplate),
  };

  const { data } = await configuredFetch<FolioTemplate>(url, options);
  return data;
}

/**
 * Delete a custom folio template for a tenant.
 * Endpoint: DELETE /orgs/${orgId}/plugins/velocityvdp/folioTemplates/${folioTemplateId}
 */
type DeleteFolioTemplateParams = FolioTemplateRequestParams & {
  folioTemplateId: string;
};
async function deleteFolioTemplate({
  orgId,
  folioTemplateId,
}: DeleteFolioTemplateParams): Promise<string> {
  const url = `${baseUrl({ orgId })}/folioTemplates/${folioTemplateId}`;
  const options = {
    method: 'delete',
  };

  const { data } = await configuredFetch<string>(url, options);
  return data;
}

/**
 * Get folio template match rules for a tenant.
 * Endpoint: GET /orgs/${orgId}/plugins/velocityvdp/folioTemplateMatchRules
 */
async function getFolioTemplateMatchRules({
  orgId,
}: FolioTemplateRequestParams): Promise<FolioTemplateMatchRule[]> {
  const url = `${baseUrl({ orgId })}/folioTemplateMatchRules`;

  const { data } = await configuredFetch<FolioTemplateMatchRule[]>(url);
  return data;
}

/**
 * Create a folio template match rule for a tenant.
 * Endpoint: POST /orgs/${orgId}/plugins/velocityvdp/folioTemplateMatchRules
 */
type CreateFolioTemplateMatchRuleParams = FolioTemplateRequestParams & {
  folioTemplateMatchRule: FolioTemplateMatchRule;
};
async function createFolioTemplateMatchRule({
  orgId,
  folioTemplateMatchRule,
}: CreateFolioTemplateMatchRuleParams): Promise<FolioTemplateMatchRule[]> {
  const url = `${baseUrl({ orgId })}/folioTemplateMatchRules`;
  const options = {
    method: 'put',
    body: JSON.stringify(folioTemplateMatchRule),
  };

  const { data } = await configuredFetch<FolioTemplateMatchRule[]>(
    url,
    options
  );
  return data;
}

/**
 * Update a folio template match rule for a tenant.
 * Endpoint: PUT /orgs/${orgId}/plugins/velocityvdp/folioTemplateMatchRules
 */
type UpdateFolioTemplateMatchRuleParams = FolioTemplateRequestParams & {
  folioTemplateMatchRule: Partial<FolioTemplateMatchRule>;
};
async function updateFolioTemplateMatchRule({
  orgId,
  folioTemplateMatchRule,
}: UpdateFolioTemplateMatchRuleParams): Promise<FolioTemplateMatchRule[]> {
  const url = `${baseUrl({ orgId })}/folioTemplateMatchRules`;
  const options = {
    method: 'put',
    body: JSON.stringify(folioTemplateMatchRule),
  };

  const { data } = await configuredFetch<FolioTemplateMatchRule[]>(
    url,
    options
  );
  return data;
}

/**
 * Bulk update folios for tenant inventory.
 * Endpoint: PUT /orgs/${orgId}/plugins/velocityvdp/processAddedInventoryBulk
 */
type ProcessAddedInventoryBulkRequest = FolioTemplateRequestParams & {
  requestBody: {
    inventoryId: string; // Todo: find out why this is on the API. The list of IDs is all we need.
    tenantId: string;
    tenantExternalId: string | null;
    inventoryIds: string[];
  };
};
async function updateFoliosForInventoryBulk({
  orgId,
  requestBody,
}: ProcessAddedInventoryBulkRequest): Promise<string> {
  const url = `${baseUrl({ orgId })}/processAddedInventoryBulk`;
  const options = {
    method: 'put',
    body: JSON.stringify(requestBody),
  };

  const { data } = await configuredFetch<string>(url, options);
  return data;
}

type FolioMediaUploadRequestParams = FolioTemplateRequestParams & {
  folioTemplateId: string;
  file: FormData;
};

// interface MediaUploadResponse {
//   data: string;
// }

async function createMediaFolioDocumentMedia({
  orgId,
  folioTemplateId,
  file,
}: FolioMediaUploadRequestParams): Promise<string> {
  const url = `${baseUrl({ orgId })}/folioTemplates/${folioTemplateId}/media`;

  const { data } = await configuredFetch<string>(
    url,
    {
      method: 'post',
      body: file,
    },
    true
  );
  return data;
}

export default {
  getFolioTemplateDocumentTypes,
  getFolioTemplateListOptions,
  getFolioTemplate,
  createFolioTemplate,
  updateFolioTemplate,
  deleteFolioTemplate,
  getFolioTemplateMatchRules,
  createFolioTemplateMatchRule,
  updateFolioTemplateMatchRule,
  updateFoliosForInventoryBulk,
  createMediaFolioDocumentMedia,
};
