import React, { useCallback, useEffect, useState } from 'react';
import {
  DealerdotcomBadgeLocation,
  PluginItem,
  VelocityWindowStickerPluginItem,
} from 'models';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import LoadingIndicator from 'components/shared/LoadingIndicator';
import Alert, { useAlert } from 'components/shared/Alert';
import {
  createMediaBadgeImage,
  usePlugin,
  usePluginUpdate,
  usePluginCreate,
} from 'api';
import {
  Box,
  Button,
  createStyles,
  Dialog as MaterialDialog,
  DialogActions as MaterialDialogActions,
  DialogContent as MaterialDialogContent,
  DialogContentText as MaterialDialogContentText,
  DialogTitle as MaterialDialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { Close, Edit, Publish } from '@material-ui/icons';
import '../PluginDialog.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    badgeImage: {
      maxWidth: '300px',
    },
    button: {
      margin: theme.spacing(1),
    },
    input: {
      display: 'none',
    },
    formControl: {
      minWidth: 175,
      cursor: 'pointer',
    },
  })
);

export interface VelocityWindowStickerPluginDialogProps {
  plugin: PluginItem;
  onSave?: () => void;
  onClose?: () => void;
  orgId: string;
}

const VelocityWindowStickerPluginDialog: React.FC<VelocityWindowStickerPluginDialogProps> =
  (props) => {
    const { plugin, onClose, orgId } = props;

    const { isAlertOpen, alertMessage, openAlert, closeAlert, variant } =
      useAlert();
    const [enabled, setEnabled] = useState(true);
    const [
      dealerWebsiteIntegrationEnabled,
      setDealerWebsiteIntegrationEnabled,
    ] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isEditAccountIdModalOpen, setIsEditAccountIdModalOpen] =
      useState(false);
    const [accountId, setAccountId] = useState('');
    const [badgeIframeEnabled, setBadgeIframeEnabled] = useState(false);
    const [badgeImageUrl, setBadgeImageUrl] = useState('');
    const [dealerdotcomBadgeLocation, setDealerdotcomBadgeLocation] =
      useState<DealerdotcomBadgeLocation>('vehicle-badge');
    const [displayPriceFieldEnabled, setDisplayPriceFieldEnabled] =
      useState(false);
    const [showUrlEnabled, setShowUrlEnabled] = useState(false);
    // If plugin has no ID, it's newly created, so initial settings are unsaved.
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(
      !plugin?.id ? true : false
    );

    const classes = useStyles();

    const pluginTypeId = plugin.pluginName.toLowerCase().replace(/_/g, '');
    const { data: pluginData, isLoading: isReading } = usePlugin(
      orgId,
      pluginTypeId,
      plugin.id
    );
    const { updatePluginAsync, isLoading: isUpdating } = usePluginUpdate(
      orgId,
      pluginTypeId
    );
    const { createPluginAsync, isLoading: isCreating } = usePluginCreate(
      orgId,
      pluginTypeId
    );

    useEffect(() => {
      setIsLoading(isReading || isUpdating || isCreating);
    }, [isReading, isCreating, isUpdating]);

    useEffect(() => {
      if (!isReading && pluginData) {
        setEnabled(pluginData.enabled ?? true);
        setDealerWebsiteIntegrationEnabled(
          pluginData?.dealerWebsiteIntegrationEnabled ?? false
        );
        setAccountId(pluginData?.accountId ?? '');
        setBadgeIframeEnabled(pluginData?.badgeIframeEnabled ?? false);
        setBadgeImageUrl(pluginData?.badgeImageUrl ?? '');
        setDealerdotcomBadgeLocation(
          pluginData?.dealerdotcomBadgeLocation ?? 'vehicle-badge'
        );
        setDisplayPriceFieldEnabled(
          pluginData?.displayPriceFieldEnabled ?? false
        );
        setShowUrlEnabled(pluginData?.showUrlEnabled ?? false);
      }
    }, [pluginData, isReading]);

    const save = useCallback(async () => {
      const data: Partial<VelocityWindowStickerPluginItem> = {
        enabled,
        dealerWebsiteIntegrationEnabled,
        accountId,
        badgeIframeEnabled,
        badgeImageUrl,
        dealerdotcomBadgeLocation,
        displayPriceFieldEnabled,
        showUrlEnabled,
      };
      try {
        if (plugin.id) {
          await updatePluginAsync(data);
        } else {
          const newPlugin = { ...plugin, ...data };
          await createPluginAsync(newPlugin);
        }
      } catch (error) {
        console.error(error);
        openAlert(
          'An error occurred when saving this plug-in to the server.',
          'error'
        );
        return false;
      }
      return true;
    }, [
      accountId,
      badgeIframeEnabled,
      badgeImageUrl,
      createPluginAsync,
      dealerWebsiteIntegrationEnabled,
      dealerdotcomBadgeLocation,
      displayPriceFieldEnabled,
      enabled,
      openAlert,
      plugin,
      showUrlEnabled,
      updatePluginAsync,
    ]);

    useEffect(() => {
      const asyncSave = async () => await save();
      if (hasUnsavedChanges) {
        asyncSave();
        setHasUnsavedChanges(false);
      }
    }, [
      hasUnsavedChanges,
      enabled,
      dealerWebsiteIntegrationEnabled,
      accountId,
      badgeIframeEnabled,
      badgeImageUrl,
      dealerdotcomBadgeLocation,
      displayPriceFieldEnabled,
      showUrlEnabled,
      save,
    ]);

    const handleSaveAccountId = () => {
      setIsEditAccountIdModalOpen(false);
      save();
    };

    const uploadBadgeImage = async (files: FileList | null) => {
      if (files && orgId) {
        const imageData = new FormData();
        imageData.append('file', files[0]);
        const imageUrl = await createMediaBadgeImage({
          orgId,
          image: imageData,
          pluginName: 'velocitywindowsticker',
          settingName: 'BADGE_IMAGE_URL',
        });
        setBadgeImageUrl(imageUrl);
      }
    };

    return (
      <MaterialDialog open fullWidth maxWidth="sm">
        <div className="padding-lg">
          <div className="flex-row flex-space-between valign-center flex-grow padding-bottom-md">
            <Typography variant="h6">
              {`Configure ${plugin.title} Plug-in`}
            </Typography>
            <div className="flex-row valign-center">
              <FormControlLabel
                className="margin-right-md"
                label={
                  isLoading ? (
                    <LoadingIndicator size={26} />
                  ) : (
                    <FormLabel>{enabled ? 'Enabled' : 'Disabled'}</FormLabel>
                  )
                }
                labelPlacement="start"
                control={
                  <Switch
                    inputProps={{
                      'aria-label':
                        'Velocity Window Sticker Plugin Dialog Enable switch',
                    }}
                    checked={enabled}
                    disabled={isLoading}
                    onChange={(event) => {
                      setEnabled(event.target.checked);
                      setHasUnsavedChanges(true);
                    }}
                  />
                }
              />

              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </div>
          </div>
          <Divider />
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Typography variant="body1" className="align-left">
                Account ID:{' '}
                {pluginData?.accountId ?? 'No account ID configured.'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end">
                <Edit onClick={() => setIsEditAccountIdModalOpen(true)} />
                <MaterialDialog
                  open={isEditAccountIdModalOpen}
                  onClose={() => setIsEditAccountIdModalOpen(false)}
                  maxWidth="xs"
                  aria-labelledby="form-dialog-title"
                >
                  <MaterialDialogTitle id="form-dialog-title">
                    Edit website integration account ID
                  </MaterialDialogTitle>
                  <MaterialDialogContent>
                    <MaterialDialogContentText>
                      Enter a unique account ID to associate Velocity Window
                      Sticker button and badge links with your organization.
                      Usually the account ID is the full name of the dealership,
                      all lowercase with no spaces.
                    </MaterialDialogContentText>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="input-badge-account-id"
                      label="Account ID"
                      value={accountId}
                      onChange={(event) => setAccountId(event.target.value)}
                      fullWidth
                    />
                  </MaterialDialogContent>
                  <MaterialDialogActions>
                    <Button
                      onClick={() => setIsEditAccountIdModalOpen(false)}
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button onClick={handleSaveAccountId} color="primary">
                      Save
                    </Button>
                  </MaterialDialogActions>
                </MaterialDialog>
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" className="align-left">
                Website Button/Badge Integration
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end">
                <FormControlLabel
                  className="margin-right-md"
                  label={
                    isLoading ? (
                      <LoadingIndicator size={26} />
                    ) : (
                      <FormLabel>
                        {dealerWebsiteIntegrationEnabled
                          ? 'Enabled'
                          : 'Disabled'}
                      </FormLabel>
                    )
                  }
                  labelPlacement="start"
                  control={
                    <Switch
                      inputProps={{
                        'aria-label':
                          'Velocity Window Sticker website button integration enable checkbox.',
                      }}
                      checked={dealerWebsiteIntegrationEnabled}
                      disabled={isLoading}
                      onChange={(_, checked) => {
                        setDealerWebsiteIntegrationEnabled(checked);
                        setHasUnsavedChanges(true);
                      }}
                    />
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" className="align-left">
                Badge Image
              </Typography>
              <Typography variant="body2" className="align-left">
                Choose an image file for dealer site links. PNG and JPEG formats
                accepted.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end">
                <input
                  type="file"
                  id="dealerdotcom-badge-image"
                  accept="image/png, image/jpeg"
                  className={classes.input}
                  multiple
                  onChange={(e) => uploadBadgeImage(e.currentTarget.files)}
                />
                <label htmlFor="dealerdotcom-badge-image">
                  <Button
                    variant="outlined"
                    component="span"
                    className={classes.button}
                    disableElevation
                  >
                    <Publish />
                    Upload
                  </Button>
                </label>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" className="align-left">
                Current image:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Box display="flex" justifyContent="flex-end">
                {badgeImageUrl ? (
                  <img
                    src={badgeImageUrl}
                    alt="badge"
                    className={classes.badgeImage}
                  />
                ) : (
                  <p>* Currently using default image.</p>
                )}
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" className="align-left">
                Open badge links within dealer site.
              </Typography>
              <Typography variant="body2" className="align-left">
                Enable to open links in an iFrame embedded on dealer&apos;s
                Website. Disable to open links in a new tab.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end">
                <FormControlLabel
                  className="margin-right-md"
                  id="dealer-badge-toggle"
                  label={
                    isLoading ? (
                      <LoadingIndicator size={26} />
                    ) : (
                      <FormLabel>
                        {badgeIframeEnabled ? 'Enabled' : 'Disabled'}
                      </FormLabel>
                    )
                  }
                  labelPlacement="start"
                  control={
                    <Switch
                      inputProps={{
                        'aria-label':
                          'Velocity Engage Website button integration Enable checkbox',
                      }}
                      checked={badgeIframeEnabled}
                      disabled={isLoading || !dealerWebsiteIntegrationEnabled}
                      onChange={(_, checked) => {
                        setBadgeIframeEnabled(checked);
                        setHasUnsavedChanges(true);
                      }}
                    />
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" className="align-left">
                Badge Location (Dealer.com only)
              </Typography>
              <Typography variant="body2" className="align-left">
                Controls where the badge displays on Dealer.com vehicle
                listings.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end">
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="select-outlined-label">
                    Vehicle Badge Location
                  </InputLabel>
                  <Select
                    labelId="select-outlined-label-location"
                    id="select-outlined-location"
                    value={dealerdotcomBadgeLocation}
                    onChange={(event) => {
                      setDealerdotcomBadgeLocation(
                        event.target.value as DealerdotcomBadgeLocation
                      );
                      setHasUnsavedChanges(true);
                    }}
                    label="Location"
                    disabled={isLoading || !dealerWebsiteIntegrationEnabled}
                    variant="outlined"
                  >
                    <MenuItem value="vehicle-badge">Vehicle Badge</MenuItem>
                    <MenuItem value="vehicle-pricing">Vehicle Pricing</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" className="align-left">
                Display Pricing on window sticker
              </Typography>
              <Typography variant="body2" className="align-left">
                Enable to display all pricing on the window sticker.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end">
                <FormControlLabel
                  className="margin-right-md"
                  label={
                    isLoading ? (
                      <LoadingIndicator size={26} />
                    ) : (
                      <FormLabel>
                        {displayPriceFieldEnabled ? 'Enabled' : 'Disabled'}
                      </FormLabel>
                    )
                  }
                  labelPlacement="start"
                  control={
                    <Switch
                      inputProps={{
                        'aria-label':
                          'Velocity Engage Website window sticker show price checkbox',
                      }}
                      checked={displayPriceFieldEnabled}
                      disabled={isLoading}
                      onChange={(_, checked) => {
                        setDisplayPriceFieldEnabled(checked);
                        setHasUnsavedChanges(true);
                      }}
                    />
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" className="align-left">
                Display OEM window sticker if available
              </Typography>
              <Typography variant="body2" className="align-left">
                Enable to display an OEM window sticker first. Then, we'll
                display the available custom window sticker information.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end">
                <FormControlLabel
                  className="margin-right-md"
                  label={
                    isLoading ? (
                      <LoadingIndicator size={26} />
                    ) : (
                      <FormLabel>
                        {showUrlEnabled ? 'Enabled' : 'Disabled'}
                      </FormLabel>
                    )
                  }
                  labelPlacement="start"
                  control={
                    <Switch
                      inputProps={{
                        'aria-label':
                          'Velocity Engage Website window sticker show OEM checkbox',
                      }}
                      checked={showUrlEnabled}
                      disabled={isLoading}
                      onChange={(_, checked) => {
                        setShowUrlEnabled(checked);
                        setHasUnsavedChanges(true);
                      }}
                    />
                  }
                />
              </Box>
            </Grid>
          </Grid>
          <Alert
            open={isAlertOpen}
            message={alertMessage}
            onClose={closeAlert}
            duration={3500}
            variant={variant}
          />
        </div>
      </MaterialDialog>
    );
  };

export default VelocityWindowStickerPluginDialog;
