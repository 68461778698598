import { User } from 'models';
import {
  AuthenticationAction,
  authenticateActionTypes,
  refreshTokenActionTypes,
  logoutActionTypes,
} from 'store/actions/authentication';

interface AuthenticationState {
  user?: User;
  isAuthenticateLoading: boolean;
  authenticateError?: Error;
  isLogoutLoading: boolean;
  logoutError?: Error;
  permissions: string[];
}

export const initialState: AuthenticationState = {
  user: undefined,
  isAuthenticateLoading: false,
  authenticateError: undefined,
  isLogoutLoading: false,
  logoutError: undefined,
  permissions: [],
};

export default function authenticationReducer(
  state = initialState,
  action: AuthenticationAction
): AuthenticationState {
  switch (action.type) {
    case authenticateActionTypes.pending:
    case refreshTokenActionTypes.pending: {
      return {
        ...state,
        isAuthenticateLoading: true,
      };
    }
    case authenticateActionTypes.fulfilled:
    case refreshTokenActionTypes.fulfilled: {
      const { data } = action.payload ?? {};

      return {
        ...state,
        isAuthenticateLoading: false,
        user: data?.user,
      };
    }
    case authenticateActionTypes.rejected:
    case refreshTokenActionTypes.rejected: {
      const { error: authenticateError } = action.payload ?? {};

      return {
        ...state,
        isAuthenticateLoading: false,
        authenticateError,
      };
    }

    case logoutActionTypes.pending: {
      return {
        ...state,
        isLogoutLoading: true,
      };
    }
    case logoutActionTypes.fulfilled:
    case logoutActionTypes.rejected: {
      const { error: logoutError } = action.payload ?? {};
      console.warn(logoutError);
      return initialState;
    }

    default:
      return state;
  }
}
