import api from 'api';
import { User } from 'models';
import {
  ActionTypeFromCreators,
  createAsyncAction,
} from 'store/actions/helpers';
import {
  CreateUsersRequestParams,
  DeleteUserRequestParams,
  UsersRequestParams,
  UpdateUserRequestParams,
} from 'api/users';

const actions = {
  getUsers: 'Get Users',
  createUsers: 'Create User(s)',
  updateUser: 'Update user',
  deleteUser: 'Delete User',
} as const;

export const {
  types: getUsersActionTypes,
  creators: getUsersActionCreators,
  action: getUsers,
} = createAsyncAction<typeof actions.getUsers, UsersRequestParams, User[]>(
  actions.getUsers,
  ({ s = '', startIndex = 0, pageSize = 50 }) =>
    api.users.getUsers({ s, startIndex, pageSize })
);

type GetUsersAction = ActionTypeFromCreators<
  typeof actions.getUsers,
  UsersRequestParams,
  User[],
  typeof getUsersActionCreators
>;

export const {
  types: createUsersActionTypes,
  creators: createUsersActionCreators,
  action: createUsers,
} = createAsyncAction<
  typeof actions.createUsers,
  CreateUsersRequestParams,
  User[]
>(actions.createUsers, ({ newUsers }) => api.users.createUsers({ newUsers }));

type CreateUsersAction = ActionTypeFromCreators<
  typeof actions.createUsers,
  CreateUsersRequestParams,
  User[],
  typeof createUsersActionCreators
>;

export const {
  types: updateUserActionTypes,
  creators: updateUserActionCreators,
  action: updateUser,
} = createAsyncAction<
  typeof actions.updateUser,
  UpdateUserRequestParams,
  User[]
>(actions.updateUser, ({ updatedUser, userId }) =>
  api.users.updateUser({ updatedUser, userId })
);

type UpdateUserAction = ActionTypeFromCreators<
  typeof actions.updateUser,
  UpdateUserRequestParams,
  User[],
  typeof updateUserActionCreators
>;

export const {
  types: deleteUserActionTypes,
  creators: deleteUserActionCreators,
  action: deleteUser,
} = createAsyncAction<
  typeof actions.deleteUser,
  DeleteUserRequestParams,
  boolean
>(actions.deleteUser, async ({ userId }) => api.users.deleteUser({ userId }));

type DeleteUserAction = ActionTypeFromCreators<
  typeof actions.deleteUser,
  DeleteUserRequestParams,
  boolean,
  typeof deleteUserActionCreators
>;

/**
 * Export Consolidation
 */
export type UsersAction =
  | GetUsersAction
  | CreateUsersAction
  | UpdateUserAction
  | DeleteUserAction;
