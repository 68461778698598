import React, { useState } from 'react';
import MaterialTabs from '@material-ui/core/Tabs';
import MaterialTab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';

import './Tabs.scss';
import permissions from 'common/permissions';
import useSession from 'api/session';

/**
 * @name Tabs
 * @description Custom Tabs component that renders a Tabs header row which connects to
 * the keys of <InlineTabs /> (see InternalTabs) OR <Route /> based Tabs:
 *
 *  - (a) Tabs and InlineTabs: do NOT unmount / mount Tab components
 *  - (b) Tabs and Routes: unmount / mount Tab components
 *
 * @prop tabs - an array of TabHeaderItem elements, key is what Tabs will ID and mount / display
 * @prop defaultTabKey - the default tab to show
 * @prop basename - navigates to subdir, like `${userId}/` when tabs render. will auto-unmount
 *                  subdir
 *
 * @prop styleType - of type StyleType, currently Admin tabs have 2 types
 *
 * @example
 *  (b) <Tabs tabHeaderItems={configFormTabHeaderItems} basename={'userform'} />
 *      <InlineTab path="page1">
 *          <ChildComponent1 />
 *      </InlineTab>
 *      <InlineTab path="page1" element={<ChildComp2 />} />
 *      //etc...
 *
 *  (b) <Tabs tabHeaderItems={mainNavTabHeaderItems} basename={'orgs'} />
 *      <Routes basename={'orgs'}>
 *          <Route path="overview" >
 *              <ChildComponent1 />
 *          </Route>
 *          <Route path="overview" element={<ChildComp2 />} />
 *          //etc...
 *      <Routes>
 */

type StyleType = 'header' | 'subHeader';

export interface TabHeaderItem {
  permission?: keyof typeof permissions | null;
  label: string;
  key: string;
  showBadge?: boolean;
  disabled?: boolean;
}

interface TabsProps {
  tabHeaderItems: TabHeaderItem[];
  defaultTabKey: TabHeaderItem['key'];
  basename?: string;
  styleType?: StyleType;
}

interface TabPanelProps {
  path: string;
  className?: string;
  element?: React.ReactElement;
}

const lastPathLocation = (navPath: string) => {
  const tokens = navPath.split('/');
  return tokens[tokens.length - 1];
};

export const InlineTab: React.FC<TabPanelProps> = ({
  children,
  className,
  path,
  element,
}) => {
  const navigation = useLocation();
  const lastPath = lastPathLocation(navigation.pathname);
  return (
    <div hidden={lastPath !== path} className={className}>
      {children ?? element}
    </div>
  );
};

var Tabs = ({
  defaultTabKey,
  tabHeaderItems,
  basename = '',
  styleType = 'subHeader',
}: TabsProps) => {
  const navigate = useNavigate();
  const [tabRoute, setTabRoute] = useState(defaultTabKey);
  const { data: session } = useSession();

  const handleNewSelect = (event: React.ChangeEvent<{}>, value: string) => {
    event.preventDefault();
    setTabRoute(value);
    navigate(basename + value, { replace: true });
  };

  const InternalTabs =
    session &&
    tabHeaderItems.map(
      ({
        label,
        key,
        permission = null,
        showBadge,
        disabled,
      }: TabHeaderItem) => {
        if (
          permission &&
          !session?.permissions.includes(permission?.toString())
        ) {
          return null;
        }

        if (disabled) {
          return (
            <OverlayTrigger
              placement={'top'}
              overlay={
                <Tooltip id={`${key}-tooltip`}>
                  Active Inventory Feed Required
                </Tooltip>
              }
            >
              <span>
                <MaterialTab
                  id={`${label.replace(' ', '-')}-tab`}
                  key={key}
                  label={
                    <Badge variant="dot" color="primary" invisible={!showBadge}>
                      {label}
                    </Badge>
                  }
                  disabled={disabled}
                  value={key}
                  className={`tab-buttons ${
                    styleType === 'header'
                      ? 'header-tab-buttons'
                      : 'subheader-tab-buttons'
                  }`}
                />
              </span>
            </OverlayTrigger>
          );
        } else {
          return (
            <MaterialTab
              id={`${label.replace(' ', '-')}-tab`}
              key={key}
              label={
                <Badge variant="dot" color="primary" invisible={!showBadge}>
                  {label}
                </Badge>
              }
              value={key}
              className={`tab-buttons ${
                styleType === 'header'
                  ? 'header-tab-buttons'
                  : 'subheader-tab-buttons'
              }`}
            />
          );
        }
      }
    );

  return (
    <MaterialTabs
      indicatorColor="primary"
      value={tabRoute}
      onChange={handleNewSelect}
      className={`Tabs-Container ${
        styleType === 'header' ? 'header-container' : 'subheader-container'
      }`}
    >
      {InternalTabs}
    </MaterialTabs>
  );
};

export default Tabs;
