import React from 'react';
import pluralize from 'pluralize';
import { FormControl, MenuItem, Select } from '@material-ui/core';

import { ReconVelocityWorkflowStep } from 'models';

interface WorkflowStepPrecedingStepsSectionProps {
  step: ReconVelocityWorkflowStep;
  forUpdating?: boolean;
  precedingSteps: ReconVelocityWorkflowStep[];
  otherSteps: ReconVelocityWorkflowStep[];
  selectedStepId?: ReconVelocityWorkflowStep['id'];
  onSelectStep?: (nextStep: ReconVelocityWorkflowStep['id']) => void;
}

const WorkflowStepsPrecedingStepsSection = ({
  step,
  forUpdating = false,
  precedingSteps,
  otherSteps,
  selectedStepId,
  onSelectStep,
}: WorkflowStepPrecedingStepsSectionProps) => {
  if (precedingSteps.length === 0) return null;

  return (
    <>
      <div className="margin-top">
        {`The following ${pluralize(
          'step',
          precedingSteps.length,
          true
        )} currently ${pluralize('has', precedingSteps.length)} `}
        <b>{step.name}</b>
        {` as ${precedingSteps.length === 1 ? 'its' : 'their'} next step:`}
      </div>

      <div className="flex-column">
        {precedingSteps.map(
          ({ id: precedingStepId, name: precedingStepName }) => (
            <div
              key={`preceding-steps-workflow-step-${precedingStepId}`}
              className="flex-row valign-center margin-bottom"
            >
              <strong>{precedingStepName}</strong>
            </div>
          )
        )}
      </div>

      {forUpdating && (
        <>
          <div>
            {`Which step would you like to be the next step for ${pluralize(
              'this',
              precedingSteps.length
            )} ${pluralize('step', precedingSteps.length)}?`}
          </div>

          <br />

          <div className="flex-row">
            <FormControl variant="outlined" style={{ width: 250 }}>
              <Select
                variant="outlined"
                required={precedingSteps.length > 0}
                margin="dense"
                displayEmpty
                value={selectedStepId ?? ''}
                onChange={({ target: { value } }) =>
                  onSelectStep?.(value as string)
                }
              >
                <MenuItem value="">Select a Step</MenuItem>
                {otherSteps.map((stepDefinition) => (
                  <MenuItem
                    key={`preceding-steps-next-step-${stepDefinition.id}`}
                    value={stepDefinition.id}
                  >
                    {stepDefinition.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </>
      )}
    </>
  );
};

export default WorkflowStepsPrecedingStepsSection;
