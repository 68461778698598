import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  createStyles,
  Divider,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Snackbar,
  Typography,
} from '@material-ui/core';
import { AddCircleOutline, Close } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import { FolioTemplate, FolioTemplateListOption } from 'models';
import api from 'api';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import AddCustom from './AddCustom';
import VelocityEngageMediaTab from '../VelocityEngageMediaTab/VelocityEngageMediaTab';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';

import './FolioTemplate.scss';
import PriceSettings from './PriceSettings';

const useStyles = makeStyles(() =>
  createStyles({
    folioSelector: {
      width: '100%',
    },
    loadingContainer: {
      height: '100%',
    },
  })
);

interface FolioTemplateContainerProps {
  orgId: string;
}

var FolioTemplateContainer = ({ orgId }: FolioTemplateContainerProps) => {
  const templatePlaceholder = useMemo(
    () => ({
      id: 'none',
      name: 'None',
      type: '',
      tenantTemplate: true,
      tenantId: '',
    }),
    []
  );
  const addStandardTemplateOption = {
    id: 'addStandard',
    name: 'Add Standard',
    type: '',
    tenantTemplate: false,
    tenantId: '',
  };
  const standardTypePlaceholder = {
    id: 'none',
    name: 'Select Type',
    type: '',
    tenantTemplate: false,
    tenantId: '',
  };
  const [selectedTemplate, setSelectedTemplate] =
    useState<FolioTemplateListOption>(templatePlaceholder);
  const [templateListData, setTemplateListData] = useState<
    FolioTemplateListOption[]
  >([]);
  const [templateSelectorOptions, setTemplateSelectorOptions] = useState<
    JSX.Element[]
  >([]);
  const [folioTemplateData, setFolioTemplateData] = useState<FolioTemplate>();
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [uncreatedStandardTemplates, setUncreatedStandardTemplates] =
    useState<FolioTemplateListOption[]>();
  const [selectedStandardType, setSelectedStandardType] =
    useState<FolioTemplateListOption>(standardTypePlaceholder);
  const [showStandardTemplateSelector, setShowStandardTemplateSelector] =
    useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [processCurrentInventory, setProcessCurrentInventory] = useState(true);
  const [isLoadingTemplate, setIsLoadingTemplate] = useState(false);
  const [isOpenUnsavedChanges, setIsOpenUnsavedChanges] = useState(false);
  const [unsavedChangeEvent, setUnsavedChangeEvent] = useState<string>();
  type UnsavedEventSource = 'templateChange' | 'typeChange';
  const [unsavedEventSource, setUnsavedEventSource] =
    useState<UnsavedEventSource>();
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState(false);
  const classes = useStyles();

  const getTemplateList = useCallback(
    async (id: string) => {
      try {
        const data =
          await api.organizations.plugins.velocityengage.folioTemplate.getFolioTemplateListOptions(
            { orgId: id }
          );
        setTemplateListData(data);
        const currentOrgTemplates: FolioTemplateListOption[] = data.filter(
          (template) => !template.tenantTemplate
        );
        const vasTemplates: FolioTemplateListOption[] = data.filter(
          (template) => template.tenantTemplate
        );

        setUncreatedStandardTemplates(vasTemplates);

        let options: JSX.Element[] = [];
        if (currentOrgTemplates.length === 0) {
          options.push(
            <MenuItem
              key={templatePlaceholder.id}
              value={templatePlaceholder.id}
            >
              {templatePlaceholder.name}
            </MenuItem>
          );
        } else {
          options = currentOrgTemplates.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ));
        }
        if (vasTemplates.length > 0) {
          options.push(
            <MenuItem
              key={addStandardTemplateOption.id}
              value={addStandardTemplateOption.id}
            >
              {addStandardTemplateOption.name}
            </MenuItem>
          );
        }

        setTemplateSelectorOptions(options);

        setIsLoadingTemplate(true);
        if (currentOrgTemplates.length > 0) {
          const defaultTemplate: FolioTemplateListOption =
            currentOrgTemplates[0];
          const setDefault =
            await api.organizations.plugins.velocityengage.folioTemplate.getFolioTemplate(
              {
                orgId: defaultTemplate.tenantId,
                folioTemplateId: defaultTemplate.id,
              }
            );
          setFolioTemplateData(setDefault);
          setSelectedTemplate(defaultTemplate);
        } else {
          setSelectedTemplate(templatePlaceholder);
        }
      } catch (e) {
        setMessage(e.message);
        setOpen(true);
      } finally {
        setIsLoadingTemplate(false);
      }
    },
    [
      addStandardTemplateOption.id,
      addStandardTemplateOption.name,
      templatePlaceholder,
    ]
  );

  useEffect(() => {
    getTemplateList(orgId);
  }, [getTemplateList, orgId]);

  const handleOpenUnsavedChanges = (
    selectorValue: string,
    source: UnsavedEventSource
  ) => {
    setIsOpenUnsavedChanges(true);
    setUnsavedChangeEvent(selectorValue);
    setUnsavedEventSource(source);
  };

  const handleAddStandardClick = async () => {
    if (uncreatedStandardTemplates) {
      setShowStandardTemplateSelector(true);
      await setSelectedTemplate(addStandardTemplateOption);
    }
  };

  const handleChangeTemplate = async (
    selectorValue: string,
    checkUnsaved: boolean = true
  ) => {
    if (
      checkUnsaved &&
      selectedTemplate?.id === addStandardTemplateOption.id &&
      selectedStandardType.id !== standardTypePlaceholder.id
    ) {
      return handleOpenUnsavedChanges(selectorValue, 'templateChange');
    }
    setShowStandardTemplateSelector(false);
    setSelectedStandardType(standardTypePlaceholder);
    if (selectorValue === 'addStandard') {
      return handleAddStandardClick();
    }
    const matchingTemplate = templateListData.find(
      (template) => template.id === selectorValue
    );
    if (!matchingTemplate) {
      setShowStandardTemplateSelector(false);
      await setSelectedTemplate(templatePlaceholder);
      setFolioTemplateData(undefined);
      return;
    }
    await setSelectedTemplate(matchingTemplate);
    if (matchingTemplate) {
      setIsLoadingTemplate(true);
      try {
        const data =
          await api.organizations.plugins.velocityengage.folioTemplate.getFolioTemplate(
            {
              orgId: matchingTemplate.tenantId,
              folioTemplateId: matchingTemplate.id,
            }
          );
        setFolioTemplateData(data);
      } catch (e) {
        setMessage(e.message);
        setOpen(true);
      } finally {
        setIsLoadingTemplate(false);
      }
    }
  };

  const folioTemplateId = folioTemplateData?.id;
  const priceLabelFromServer = folioTemplateData?.settings?.PRICE_LABEL?.value;
  const [customPriceLabel, setCustomPriceLabel] =
    useState(priceLabelFromServer);
  useEffect(() => {
    setCustomPriceLabel(priceLabelFromServer);
  }, [priceLabelFromServer, setCustomPriceLabel, folioTemplateId]);

  const priceTypeToShowFromServer = useMemo<
    'MSRP' | 'retail' | 'internet' | 'CallForPrice'
  >(() => {
    // modifying existing value
    const priceTypeValue = folioTemplateData?.settings?.PRICE_TYPE?.value;
    if (
      priceTypeValue !== undefined &&
      (priceTypeValue === 'MSRP' ||
        priceTypeValue === 'retail' ||
        priceTypeValue === 'internet' ||
        priceTypeValue === 'CallForPrice')
    ) {
      return priceTypeValue;
    }
    // general logic for getting default price type
    function priceTypeToShow(
      templateOption: FolioTemplateListOption
    ): 'MSRP' | 'retail' | 'internet' | 'CallForPrice' {
      if (templateOption.type !== '') {
        if (templateOption.type.includes('NEW')) {
          return 'MSRP';
        }
        if (templateOption.type.includes('DEFAULT')) {
          return 'MSRP';
        }
        return 'retail';
      }
      // fallback
      return 'retail';
    }
    // currently choosing from "add standard".
    if (selectedStandardType?.id && selectedStandardType?.id !== 'none') {
      return priceTypeToShow(selectedStandardType);
    }
    // default for when "create standard" is not selected
    return priceTypeToShow(selectedTemplate);
  }, [folioTemplateData, selectedTemplate, selectedStandardType]);

  const [priceTypeToShow, setPriceTypeToShow] = useState<
    'MSRP' | 'retail' | 'internet' | 'CallForPrice'
  >(priceTypeToShowFromServer);
  useEffect(() => {
    setPriceTypeToShow(priceTypeToShowFromServer);
  }, [priceTypeToShowFromServer, setPriceTypeToShow, folioTemplateId]);

  const handleClickSave = async () => {
    setIsOpenUnsavedChanges(false);
    if (folioTemplateData) {
      try {
        setIsLoadingTemplate(true);
        if (
          showStandardTemplateSelector &&
          selectedStandardType.id !== 'none'
        ) {
          // POST
          const newTemplate =
            await api.organizations.plugins.velocityengage.folioTemplate.createFolioTemplate(
              {
                orgId,
                folioTemplate: {
                  ...folioTemplateData,
                  settings: {
                    PRICE_LABEL: {
                      dataType: 'STRING',
                      value: customPriceLabel ?? '',
                    },
                    PRICE_TYPE: {
                      dataType: 'STRING',
                      value: priceTypeToShow,
                    },
                  },
                  processCurrentInventory,
                },
              }
            );

          // Add newly created folio to template selector.
          setTemplateSelectorOptions([
            <MenuItem key={newTemplate.id} value={newTemplate.id}>
              {selectedStandardType.name}
            </MenuItem>,
            ...templateSelectorOptions,
          ]);
          setMessage(`${selectedStandardType?.name} template saved.`);
          // Select the newly added option.
          setSelectedTemplate({
            ...selectedStandardType,
            id: newTemplate.id,
            tenantId: newTemplate.tenantId,
          });
          // Remove the created type from the list of uncreated types.
          setUncreatedStandardTemplates(
            uncreatedStandardTemplates?.filter(
              (t) => t.id !== selectedStandardType.id
            )
          );
          setTemplateListData(
            templateListData.map((t) => {
              if (t.id === selectedStandardType.id) {
                return {
                  ...t,
                  id: newTemplate.id,
                  tenantId: newTemplate.tenantId,
                };
              }
              return t;
            })
          );
          // Reset selected standard to placeholder.
          setSelectedStandardType(standardTypePlaceholder);
          setShowStandardTemplateSelector(false);
          setFolioTemplateData({
            ...newTemplate,
            name: folioTemplateData.name,
          });
        } else {
          // PUT
          await api.organizations.plugins.velocityengage.folioTemplate.updateFolioTemplate(
            {
              orgId,
              folioTemplateId: folioTemplateData.id,
              folioTemplate: {
                ...folioTemplateData,
                settings: {
                  PRICE_LABEL: {
                    dataType: 'STRING',
                    value: customPriceLabel ?? '',
                  },
                  PRICE_TYPE: {
                    dataType: 'STRING',
                    value: priceTypeToShow,
                  },
                },
                processCurrentInventory,
              },
            }
          );
          setMessage(`${selectedTemplate?.name} template saved.`);
        }
      } catch (error) {
        setMessage(error.message);
      } finally {
        setOpen(true);
        setIsLoadingTemplate(false);
      }
    }
  };

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleChangeStandardTemplateType = async (
    selectorValue: string,
    checkUnsaved: boolean = true
  ) => {
    if (
      checkUnsaved &&
      selectedTemplate?.id === addStandardTemplateOption.id &&
      selectedStandardType.id !== standardTypePlaceholder.id
    ) {
      return handleOpenUnsavedChanges(selectorValue, 'typeChange');
    }
    const matchingTemplate = uncreatedStandardTemplates?.find(
      (template) => template.id === selectorValue
    );
    if (!matchingTemplate) {
      return console.error(
        `Standard template id ${selectorValue} not found in list of uncreated standard templates.`
      );
    }
    if (matchingTemplate) {
      await setSelectedStandardType(matchingTemplate);
      const data =
        await api.organizations.plugins.velocityengage.folioTemplate.getFolioTemplate(
          {
            orgId: matchingTemplate.tenantId,
            folioTemplateId: matchingTemplate.id,
          }
        );
      setFolioTemplateData(data);
    }
  };

  const handleCancelUnsavedChanges = async () => {
    await setIsOpenUnsavedChanges(false);
    if (unsavedChangeEvent) {
      if (unsavedEventSource === 'templateChange') {
        await handleChangeTemplate(unsavedChangeEvent, false);
      } else {
        await handleChangeStandardTemplateType(unsavedChangeEvent, false);
      }
    }
  };

  const handleDeleteTemplate = async () => {
    if (folioTemplateData) {
      try {
        setIsLoadingTemplate(true);
        await api.organizations.plugins.velocityengage.folioTemplate.deleteFolioTemplate(
          {
            orgId,
            folioTemplateId: folioTemplateData.id,
          }
        );

        setMessage(`${selectedTemplate?.name} template deleted.`);
        await getTemplateList(orgId);
      } catch (error: unknown) {
        if (
          error instanceof Error &&
          'message' in error &&
          typeof error.message === 'string'
        ) {
          setMessage(error.message);
        }
      } finally {
        setOpen(true);
        setIsLoadingTemplate(false);
        setIsOpenConfirmDelete(false);
      }
    }
  };

  const handleCancelDelete = () => {
    setIsOpenConfirmDelete(false);
  };

  const handleClickDelete = async () => {
    setIsOpenConfirmDelete(true);
  };

  const getFolioContentHeader = () => {
    if (folioTemplateData) {
      if (showStandardTemplateSelector && selectedStandardType?.id !== 'none') {
        return selectedStandardType?.name;
      }
      if (showStandardTemplateSelector) {
        return '';
      }
      return selectedTemplate?.name;
    }
    return '';
  };

  const getFolioContentBody = () => {
    if (
      folioTemplateData &&
      !(showStandardTemplateSelector && selectedStandardType?.id === 'none')
    ) {
      return (
        <VelocityEngageMediaTab
          key={folioTemplateData.id}
          folioId={folioTemplateData.id}
          orgId={folioTemplateData.tenantId}
          folders={folioTemplateData.folders}
        />
      );
    }

    if (showStandardTemplateSelector) {
      return (
        <>
          <Box display="flex" justifyContent="center">
            <Typography variant="h4">
              Select an available template type to add
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center">
            <Typography variant="body1">
              Only templates that do not already exist are available to be
              added.
            </Typography>
          </Box>
        </>
      );
    }

    return (
      <>
        <Box display="flex" justifyContent="center">
          <Typography variant="h4">
            Create a template to get started.
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography variant="body1">
            Templates allow you to assign a predefined set of documents to
            display for different groups of vehicles in your inventory.
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <AddCircleOutline onClick={handleAddStandardClick} />
        </Box>
      </>
    );
  };

  return (
    <>
      <div className="templateSelection">
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <div className="filter-section">
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <div className="filter-selection-wrapper">
                    <InputLabel htmlFor="folio-template-select">
                      Folio Template
                    </InputLabel>
                    <Select
                      label="Folio Template"
                      inputProps={{
                        name: 'folio-template',
                        id: 'folio-template-select',
                      }}
                      value={selectedTemplate?.id}
                      onChange={(event) =>
                        handleChangeTemplate(event.target.value as string)
                      }
                      variant="outlined"
                      className={classes.folioSelector}
                    >
                      {templateSelectorOptions}
                    </Select>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="filter-selection-wrapper">
                    {showStandardTemplateSelector && (
                      <div>
                        <InputLabel htmlFor="add-standard-type-select">
                          Type
                        </InputLabel>
                        <Select
                          label="Type"
                          inputProps={{
                            name: 'add-standard-type-select',
                            id: 'add-standard-type-select',
                          }}
                          value={selectedStandardType?.id}
                          onChange={(event) =>
                            handleChangeStandardTemplateType(
                              event.target.value as string,
                              true
                            )
                          }
                          variant="outlined"
                        >
                          <MenuItem
                            key={standardTypePlaceholder.id}
                            value={standardTypePlaceholder.id}
                          >
                            {standardTypePlaceholder.name}
                          </MenuItem>
                          {uncreatedStandardTemplates &&
                            uncreatedStandardTemplates.map((t) => (
                              <MenuItem key={t.id} value={t.id}>
                                {t.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="addCustom-Selection-Container">
              {selectedTemplate?.name === 'Add Custom' ? <AddCustom /> : ''}
            </div>
          </Grid>
          {/*TODO: re-enable checkbox when service is updated to allow updates of existing inventory. */}
          {/*<Grid item xs={4}>*/}
          {/* <Box display="flex" justifyContent="flex-end" alignContent="flex-end">*/}
          {/*   <FormControl component="fieldset">*/}
          {/*     <FormGroup>*/}
          {/*       <FormControlLabel*/}
          {/*         control={*/}
          {/*           <Checkbox*/}
          {/*             checked={processCurrentInventory}*/}
          {/*             onChange={event => setProcessCurrentInventory(event.target.checked)}*/}
          {/*             name="disclaimer"*/}
          {/*           />}*/}
          {/*         label="Update existing vehicles using this template."*/}
          {/*       />*/}
          {/*     </FormGroup>*/}
          {/*   </FormControl>*/}
          {/* </Box>*/}
          {/*</Grid>*/}
        </Grid>
      </div>
      <Divider />
      {isLoadingTemplate ? (
        <LoadingIndicator />
      ) : (
        <>
          <div className="folioContainer">
            <Typography variant="h4">{getFolioContentHeader()}</Typography>
            {folioTemplateData && (
              <>
                {!showStandardTemplateSelector && (
                  <button
                    type="button"
                    className="button cancel"
                    onClick={handleClickDelete}
                  >
                    Delete
                  </button>
                )}
                <button
                  type="button"
                  className="button"
                  onClick={handleClickSave}
                >
                  Save
                </button>
              </>
            )}
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
              message={message}
              action={
                <>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={handleClose}
                  >
                    <Close fontSize="small" />
                  </IconButton>
                </>
              }
            />
          </div>
          {folioTemplateData &&
            !(
              showStandardTemplateSelector &&
              selectedStandardType?.id === 'none'
            ) && (
              <PriceSettings
                customPriceLabel={customPriceLabel}
                setCustomPriceLabel={setCustomPriceLabel}
                priceTypeToShow={priceTypeToShow}
                setPriceTypeToShow={setPriceTypeToShow}
              />
            )}
          <div>{getFolioContentBody()}</div>
          <ConfirmationDialog
            openState={isOpenUnsavedChanges}
            onCancel={handleCancelUnsavedChanges}
            onConfirm={handleClickSave}
            titleText="Unsaved Changes"
            bodyText="You have unsaved changes on this page. Do you wish to save them before proceeding?"
          />
          <ConfirmationDialog
            openState={isOpenConfirmDelete}
            onCancel={handleCancelDelete}
            onConfirm={handleDeleteTemplate}
            titleText="Delete Folio Template"
            bodyText={`Are you sure you want to delete template: ${selectedTemplate.name}`}
          />
        </>
      )}
    </>
  );
};

export default FolioTemplateContainer;
