import React from 'react';
import { Form } from 'react-bootstrap';

import { PermissionItem, OrganizationGroup } from 'models';
import Table from 'components/shared/Table';
import CollectionHeaderBar from 'components/shared/CollectionHeaderBar';

interface EditGroupDialogDetailViewProps {
  selectedRole?: OrganizationGroup | 'OTHER' | 'ALL';
  permissions?: PermissionItem[];
  checkedPermissionIds: PermissionItem['id'][];
  onCheckPermission: (permissionId: PermissionItem['id']) => void;
}

var EditGroupDialogDetailView = ({
  selectedRole,
  permissions,
  checkedPermissionIds,
  onCheckPermission,
}: EditGroupDialogDetailViewProps) => {
  if (!selectedRole) return null;
  const displayPermissions =
    typeof selectedRole === 'string'
      ? permissions
      : selectedRole.roles.reduce(
          (acc: PermissionItem[], role) => [...acc, ...role.permissions],
          []
        );

  return (
    <div
      className="full-height full-width flex-rows"
      id="permissions-checkedlist-container"
    >
      <CollectionHeaderBar
        count={displayPermissions?.length}
        unit="Permission"
        checklistState={checkedPermissionIds}
      />
      <Table
        items={displayPermissions}
        columns={[
          {
            heading: '',
            dataKey: '__checked__',
            content: ({ rowData }: { rowData: PermissionItem }) => {
              const disabled = false;
              return (
                <div className="align-center">
                  <Form.Check
                    type="checkbox"
                    checked={checkedPermissionIds.includes(rowData.id)}
                    onChange={() => onCheckPermission(rowData.id)}
                    disabled={disabled}
                    // disabled={
                    //   !!disabledItems?.find((disabledItem) =>
                    //     isEqualWith(item, disabledItem, checklistState.equals),
                    //   )
                    // }
                  />
                </div>
              );
            },
            width: 25,
            minWidth: 25,
          },
          {
            heading: 'Application',
            dataKey: 'applicationName',
            minWidth: 100,
            flexGrow: 1,
          },
          {
            heading: 'Category',
            dataKey: 'category',
            minWidth: 100,
            flexGrow: 1,
          },
          {
            heading: 'Permission Name',
            dataKey: 'name',
            minWidth: 400,
            flexGrow: 4,
          },
        ]}
      />
    </div>
  );
};

export default EditGroupDialogDetailView;
