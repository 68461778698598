import React from 'react';
import { FormGroup, TextField, InputLabel } from '@material-ui/core';
import { ReconVelocityPluginItem } from 'models';
import utils from 'utils';

interface GoalTimeSettingProps {
  goalTimeInSeconds?: number;
  goalTimeLabel: string;
  goalTimeSettingKey: keyof ReconVelocityPluginItem;
  updateSetting: (key: keyof ReconVelocityPluginItem, value: number) => void;
}

var GoalTimeSetting = ({
  goalTimeInSeconds,
  goalTimeLabel,
  goalTimeSettingKey,
  updateSetting,
}: GoalTimeSettingProps) => {
  const setGoalTimeFromDayField = (value: any) => {
    const goalTime = utils.time.getDaysAndHoursFromSeconds(goalTimeInSeconds);
    goalTime.days = value;
    updateSetting(
      goalTimeSettingKey,
      utils.time.getSecondsFromDaysAndHours(goalTime)
    );
  };

  const setGoalTimeFromHourField = (value: any) => {
    const goalTime = utils.time.getDaysAndHoursFromSeconds(goalTimeInSeconds);
    goalTime.hours = value;
    updateSetting(
      goalTimeSettingKey,
      utils.time.getSecondsFromDaysAndHours(goalTime)
    );
  };

  const getDaysGoalTime = () => {
    const goalTime = utils.time.getDaysAndHoursFromSeconds(goalTimeInSeconds);
    return goalTime.days;
  };

  const getHoursGoalTime = () => {
    const goalTime = utils.time.getDaysAndHoursFromSeconds(goalTimeInSeconds);
    return goalTime.hours;
  };

  return (
    <FormGroup row>
      <InputLabel margin="dense" style={{ minWidth: 160, color: 'black' }}>
        {goalTimeLabel}:
      </InputLabel>
      <TextField
        id={`${goalTimeSettingKey}-Days`}
        variant="outlined"
        style={{ width: 90, margin: 5 }}
        margin="dense"
        inputMode="numeric"
        label="Days"
        value={getDaysGoalTime()}
        onChange={({ target: { value } }) => setGoalTimeFromDayField(value)}
        required
      />
      <TextField
        id={`${goalTimeSettingKey}-Hours`}
        variant="outlined"
        style={{ width: 90, margin: 5 }}
        margin="dense"
        inputMode="numeric"
        label="Hours"
        value={getHoursGoalTime()}
        onChange={({ target: { value } }) => setGoalTimeFromHourField(value)}
        required
      />
    </FormGroup>
  );
};

export default GoalTimeSetting;
