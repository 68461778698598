import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';

import {
  Organization,
  OrganizationGroup,
  PermissionItem,
  defaultOrganizationGroup,
} from 'models';
import Dialog from 'components/shared/Dialog';
import { SelectionState } from 'common/types';
import Alert, { useAlert } from 'components/shared/Alert';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import {
  useCreateOrganizationGroup,
  useOrganizationGroups,
  useUpdateOrganizationGroup,
  usePermissions,
} from 'api/organizations/groups';
import EditGroupDialogDetailView from './EditGroupDialogDetailView';
import EditGroupDialogListView from './EditGroupDialogListView';

import './EditGroupDialog.scss';

interface EditGroupDialogProps {
  onClose?: () => void;
  group?: OrganizationGroup;
  orgId: Organization['id'];
}

var EditGroupDialog = (props: EditGroupDialogProps) => {
  const { onClose, group, orgId } = props;
  const { data: groups, isLoading: isLoadingGroups } =
    useOrganizationGroups(orgId);
  const { data: permissions, isLoading: permissionsAreLoading } =
    usePermissions();
  const { createOrgGroupAsync, isLoading: isCreatingGroup } =
    useCreateOrganizationGroup(orgId);
  const { updateOrgGroupAsync, isLoading: isUpdatingGroup } =
    useUpdateOrganizationGroup(orgId, group?.id);
  const isLoading = isLoadingGroups || permissionsAreLoading;
  const isMutating = isCreatingGroup || isUpdatingGroup;
  const [stateGroup, setStateGroup] = useState<OrganizationGroup | undefined>(
    group ?? defaultOrganizationGroup
  );
  const [statePermissions, setStatePermissions] = useState(
    group?.roles.reduce((acc, role) => {
      const permissionIds = role.permissions.map((rolePerm) => rolePerm.id);
      return [...acc, ...permissionIds];
    }, [] as PermissionItem['id'][]) ?? []
  );
  const handlePermissionCheck = (permissionId: PermissionItem['id']) => {
    if (statePermissions.includes(permissionId)) {
      const filteredPermissions = statePermissions.filter(
        (statePermissionId) => statePermissionId !== permissionId
      );
      setStatePermissions(filteredPermissions);
      return;
    }

    const newPermissions = [...statePermissions, permissionId];
    setStatePermissions(newPermissions);
  };

  const { isAlertOpen, alertMessage, openAlert, closeAlert, variant } =
    useAlert();
  const [selectionState, setSelectionState] = useState<
    Partial<SelectionState<OrganizationGroup | 'OTHER' | 'ALL'>>
  >(() => ({
    index: 0,
    item: 'ALL',
  }));
  const updateSelectionState = (
    newSelection: Partial<SelectionState<OrganizationGroup | 'OTHER' | 'ALL'>>
  ) => setSelectionState(newSelection);

  const onPrimary = async () => {
    if (statePermissions.length <= 0) {
      openAlert('Must choose at least one permission.', 'error');
      return false;
    }

    const data = {
      groupName: stateGroup?.name,
      permissionIds: statePermissions,
    };
    try {
      if (group?.id) {
        await updateOrgGroupAsync(data);
        return;
      }

      await createOrgGroupAsync(data);
      // await onClose?.();
    } catch (error) {
      const requestType = group?.id ? 'updating' : 'creating';
      openAlert(`There was an error ${requestType} the group`, 'error');
    }
  };

  return (
    <>
      <Dialog
        open
        block={isMutating}
        title={group?.id ? `Edit Group ID: ${group.id}` : 'New Group'}
        height={570}
        maxWidth="xl"
        fullWidth
        primaryButtonLabel="Save"
        onPrimary={onPrimary}
        onClose={onClose}
        className="EditGroupDialog"
      >
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <div className="full-height full-width flex-rows">
            <div className="padding no-padding-top flex-columns">
              <div className="left-column flex-grow">
                {/* TODO: Add Formik validation to prevent dialog close if there is no name or permissions */}
                <TextField
                  className="EditGroupDialog__input"
                  label="Name"
                  variant="outlined"
                  required
                  value={stateGroup?.name}
                  onChange={(e) => {
                    if (stateGroup) {
                      setStateGroup({
                        ...stateGroup,
                        name: e.target.value,
                      });
                    }
                  }}
                  margin="dense"
                />
              </div>
            </div>

            <div
              id="permission-view-container"
              className="EditGroupDialog__main-container flex-grow full-width border-top border-bottom flex-rows"
            >
              <EditGroupDialogListView
                selectionState={selectionState}
                updateSelectionState={updateSelectionState}
                groups={groups}
                checkedPermissionIds={statePermissions}
                permissions={permissions}
                updateCheckedPermissionIds={setStatePermissions}
              />
              <EditGroupDialogDetailView
                selectedRole={selectionState.item}
                permissions={permissions}
                checkedPermissionIds={statePermissions}
                onCheckPermission={handlePermissionCheck}
              />
            </div>
          </div>
        )}
      </Dialog>
      <Alert
        variant={variant}
        message={alertMessage}
        open={isAlertOpen}
        onClose={closeAlert}
      />
    </>
  );
};

export default EditGroupDialog;
