import React, { useEffect, useState } from 'react';
import Alert, { useAlert } from 'components/shared/Alert';
import { AddCircle, DeleteRounded, Search } from '@material-ui/icons';
import { debounce } from 'lodash';
import { memberTypeIcons, memberTypeLabels } from 'common/types';

import './AssignMemberDialog.scss';
import '../../../../SettingsView.scss';
import {
  FeatureFlag,
  FeatureFlagDeciderDataMembersMappers,
  FeatureFlagMember,
} from 'models';
import {
  useUpdateFeatureFlagApplicationsFlagMember,
  useFeatureFlagApplicationsFlagMembers,
  useFeatureFlagApplicationsFlags,
  useFeatureFlagMembers,
} from 'api';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import LoadingIndicator from 'components/shared/LoadingIndicator';

interface AssignMemberDialogProps {
  featureFlag: FeatureFlag;
  applicationName: string;
  onClose: () => void;
  isAssignMemberDialogOpen: boolean;
}

const AssignMemberDialog = ({
  featureFlag,
  applicationName,
  onClose,
  isAssignMemberDialogOpen,
}: AssignMemberDialogProps) => {
  const [searchText, setSearchText] = useState<string>('');
  const [selectedMemberId, setSelectedMemberId] = useState<string>('');
  const { data: memberTypes } = useFeatureFlagMembers(searchText);
  const mutation = useUpdateFeatureFlagApplicationsFlagMember(
    applicationName,
    featureFlag.id
  );
  const { data: flagMembers, refetch: refetchFlagMembers } =
    useFeatureFlagApplicationsFlagMembers(applicationName, featureFlag.id);
  const { refetch: refetchFlags } =
    useFeatureFlagApplicationsFlags(applicationName);
  const { isAlertOpen, alertMessage, variant, openAlert, closeAlert } =
    useAlert();

  const debouncedSearch = debounce(
    (nextValue: React.SetStateAction<string>) => setSearchText(nextValue),
    1000
  );
  const searchTextDebounce = (value: string) => debouncedSearch(value);

  useEffect(() => {
    if (mutation.isError) {
      openAlert('Unable to save feature flag changes.', 'error');
    }
  }, [mutation.isError, openAlert]);

  useEffect(() => {
    if (mutation.isSuccess) {
      refetchFlagMembers();
      refetchFlags();
    }
  }, [mutation.isSuccess, refetchFlagMembers, refetchFlags]);

  const saveFeatureFlag = (member: FeatureFlagMember) => {
    if (member?.memberType) {
      setSelectedMemberId(member.memberId);
      const allowedType =
        FeatureFlagDeciderDataMembersMappers[member?.memberType];
      const updateFeatureFlag = {
        ...featureFlag,
        deciderData: {
          ...featureFlag.deciderData,
          [allowedType]: [
            ...featureFlag.deciderData[allowedType],
            member.memberId,
          ],
        },
      };
      mutation.mutateAsync(updateFeatureFlag);
    }
  };

  const deleteMember = (member: FeatureFlagMember) => {
    if (member?.memberType) {
      setSelectedMemberId(member.memberId);
      const allowedType =
        FeatureFlagDeciderDataMembersMappers[member?.memberType];
      const members = featureFlag.deciderData[allowedType].filter(
        (item) => item !== member.memberId
      );
      const updateFeatureFlag = {
        ...featureFlag,
        deciderData: {
          ...featureFlag.deciderData,
          [allowedType]: members,
        },
      };
      mutation.mutate(updateFeatureFlag);
    }
  };

  const displayNameButton = (
    member: FeatureFlagMember,
    selectable: boolean
  ) => {
    if (mutation.isLoading && member.memberId === selectedMemberId) {
      return (
        <Button>
          <LoadingIndicator size={25} />
        </Button>
      );
    }

    if (selectable) {
      return (
        <Button onClick={() => saveFeatureFlag(member)}>
          {/* <PermissionGate
            permissions={[permissions.SETTINGS_FEATUREFLAG_ADD]}
          > */}
          <AddCircle />
          {/* </PermissionGate> */}
        </Button>
      );
    }
    return (
      <Button onClick={() => deleteMember(member)}>
        {/* <PermissionGate
          permissions={[permissions.SETTINGS_FEATUREFLAG_DELETE]}
        > */}
        <DeleteRounded />
        {/* </PermissionGate> */}
      </Button>
    );
  };

  return (
    <>
      <Dialog
        open={isAssignMemberDialogOpen}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
        scroll="paper"
      >
        <DialogTitle>
          <div id="dialog-title">Assign Membership</div>
          <div className="Settings-row">
            <div>Search</div>
            <input
              className="AssignMember-search-input-container-border"
              onChange={({ target: { value } }) => searchTextDebounce(value)}
            />
            <Search />
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="full-height full-width flex-rows">
            <div className="Settings-row Settings-border Settings-header">
              <div className="Settings-small">TYPE</div>
              <div className="Settings-medium">NAME</div>
            </div>
            {memberTypes?.map((member: FeatureFlagMember) => {
              const { memberType, memberId, displayName } = member;
              const friendlyType = memberTypeLabels[memberType];
              const Icon = memberTypeIcons[memberType];
              const organizationMembers =
                flagMembers?.organizationMembers || [];
              const userMembers = flagMembers?.userMembers || [];
              const selectable = ![
                ...organizationMembers,
                ...userMembers,
              ]?.find((item) => item.memberId === member.memberId);
              return (
                <div
                  key={member.memberId}
                  role="button"
                  className={`Settings-row${
                    selectable ? ' Settings-selectable' : ' Settings-disabled'
                  }`}
                  tabIndex={-1}
                >
                  <div className="Settings-row Settings-small" id={memberId}>
                    <Icon />
                    <div className="flex-grow margin-left-sm">
                      {friendlyType}
                    </div>
                  </div>
                  <div className="Settings-medium">{displayName}</div>
                  <div>{displayNameButton(member, selectable)}</div>
                </div>
              );
            })}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()}>Close</Button>
        </DialogActions>
      </Dialog>

      <Alert
        open={isAlertOpen}
        message={alertMessage}
        variant={variant}
        onClose={closeAlert}
        duration={3500}
      />
    </>
  );
};

export default AssignMemberDialog;
