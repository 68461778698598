/* eslint-disable no-nested-ternary */ // TODO: remove this later
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Create, Delete } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import useSession from 'api/session';
import PERMISSIONS from 'common/permissions';
import { AuthenticationResponse, User } from 'models';
import { memo } from 'react';
import { areEqual } from 'react-window';
import utils from 'utils';
import { Column, RowProps } from './types';
import './UsersList.scss';

const ROW_HEIGHT = 30;

const getColumnData = (
  session: AuthenticationResponse | undefined,
  user: User,
  column: Column,
  editUserDialog: (openDialog: boolean, selectedUserId?: string) => void,
  onDeleteUser?: (user: User) => void
) => {
  const editPermission = session?.permissions.includes(
    PERMISSIONS.USERS_UPDATE
  );
  // const selfEditPermission = session?.permissions.includes(PERMISSIONS.USERS_SELF_UPDATE);
  const deletePermission = session?.permissions.includes(
    PERMISSIONS.USERS_DELETE
  );
  // const selfDeletePermission = session?.permissions.includes(PERMISSIONS.USERS_SELF_DELETE);

  const userProperty = column.dataKey as keyof User;
  if (column.dataKey === 'actionButtons') {
    return (
      <>
        {deletePermission && (
          <Delete className="icon" onClick={async () => onDeleteUser?.(user)} />
        )}
        {editPermission && (
          <Create
            className="icon"
            onClick={() => editUserDialog(true, user?.id)}
          />
        )}
      </>
    );
  }

  if (column.dataKey === 'fullName') {
    return `${user.firstName || ''} ${user.lastName || ''}`;
  }

  if (column.dataKey === 'phone') {
    return user?.mobilePhone?.number;
  }

  if (column.dataKey === `lastSession`) {
    const padTo2Digits = (num: number) => num.toString().padStart(2, `0`);

    if (user.lastSession) {
      const date = new Date(user.lastSession);
      const AMPM = date.getHours() >= 12 ? `PM` : `AM`;
      return `${padTo2Digits(date.getMonth() + 1)}/${padTo2Digits(
        date.getDate()
      )}/${date.getFullYear()} ${padTo2Digits(date.getHours())}:${padTo2Digits(
        date.getMinutes()
      )} ${AMPM}`;
    }
    return ``;
  }

  return user[userProperty];
};

const UsersListRow = memo(
  ({
    style,
    index,
    user,
    editUserDialog,
    headerColumns,
    onDeleteUser,
  }: RowProps) => {
    const { data: session } = useSession();

    // const { onClickAction: handleEditClick, permission: editPermission } = editUserProcess; // TODO handle permissions the same and not over engineered objects

    return (
      <TableRow component="div" className="row" style={style} key={index}>
        {headerColumns.map((column: Column) => {
          if (!user) {
            return (
              <Skeleton
                className="loadingSkeleton"
                height={ROW_HEIGHT}
                key={`${utils.strings.createRandomCharacters()}skeleton`}
              />
            );
          }

          return (
            <TableCell
              key={`${user.id ?? 'emptyKey'}${column.dataKey}`}
              component="div"
              variant="body"
              align={column.dataKey === 'actionButtons' ? 'right' : 'left'}
              className={`cell ${
                column.dataKey === 'actionButtons' ? 'actionButtons' : ''
              }`}
              style={{
                minWidth: column.width,
                height: ROW_HEIGHT,
              }}
            >
              {getColumnData(
                session,
                user,
                column,
                editUserDialog,
                onDeleteUser
              )}
            </TableCell>
          );
        })}
      </TableRow>
    );
  },
  areEqual
);

export default UsersListRow;
