import React, { useState, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from '@material-ui/core';
import { isUndefined, isNull } from 'lodash';

import LoadingIndicator from 'components/shared/LoadingIndicator';

export interface OpenConfirmationDialogArgs {
  titleOverride?: string;
  messageOverride?: string;
  primaryButtonLabelOverride?: string;
  secondaryButtonLabelOverride?: string;
  onAcceptOverride?: ConfirmationDialogProps['onPrimaryButtonClick'];
  onDenyOverride?: ConfirmationDialogProps['onSecondaryButtonClick'];
  stayOpenOnAcceptOverride?: boolean;
}

export type OpenConfirmationDialog = (args: OpenConfirmationDialogArgs) => void;
export interface UseConfirmationDialogArgs {
  defaultMessage?: string;
  defaultTitle?: string;
  defaultPrimaryButtonLabel?: string;
  defaultSecondaryButtonLabel?: string;
  defaultOnAccept?: ConfirmationDialogProps['onPrimaryButtonClick'];
  defaultOnDeny?: ConfirmationDialogProps['onSecondaryButtonClick'];
}

export function useConfirmationDialog({
  defaultTitle = '',
  defaultMessage = '',
  defaultPrimaryButtonLabel = 'OK',
  defaultSecondaryButtonLabel = 'Cancel',
  defaultOnAccept,
  defaultOnDeny,
}: UseConfirmationDialogArgs = {}) {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [isConfirmationLoading, setIsConfirmationLoading] =
    useState<boolean>(false);
  const [confirmationMessage, setConfirmationMessage] =
    useState<string>(defaultMessage);
  const [confirmationTitle, setConfirmationTitle] =
    useState<string>(defaultTitle);
  const [primaryButtonLabel, setPrimaryButtonLabel] = useState<string>(
    defaultPrimaryButtonLabel
  );
  const [secondaryButtonLabel, setSecondaryButtonLabel] = useState<string>(
    defaultSecondaryButtonLabel
  );
  const [stayOpenOnAccept, setStayOpenOnAccept] = useState<boolean>(false);
  const onAccept = useRef(defaultOnAccept);
  const onDeny = useRef(defaultOnDeny);

  const openConfirmationDialog = ({
    titleOverride,
    messageOverride,
    primaryButtonLabelOverride,
    secondaryButtonLabelOverride,
    onAcceptOverride,
    onDenyOverride,
    stayOpenOnAcceptOverride,
  }: OpenConfirmationDialogArgs) => {
    if (titleOverride) {
      setConfirmationTitle(titleOverride);
    }
    if (messageOverride) {
      setConfirmationMessage(messageOverride);
    }
    if (primaryButtonLabelOverride) {
      setPrimaryButtonLabel(primaryButtonLabelOverride);
    }
    if (secondaryButtonLabelOverride) {
      setSecondaryButtonLabel(secondaryButtonLabelOverride);
    }
    if (onAcceptOverride) {
      onAccept.current = onAcceptOverride;
    }
    if (onDenyOverride) {
      onDeny.current = onDenyOverride;
    }
    if (
      !isUndefined(stayOpenOnAcceptOverride) &&
      !isNull(stayOpenOnAcceptOverride)
    ) {
      setStayOpenOnAccept(stayOpenOnAcceptOverride);
    }

    setIsConfirmationDialogOpen(true);
  };

  const closeConfirmationDialog = () => {
    setIsConfirmationDialogOpen(false);

    setTimeout(() => {
      setConfirmationTitle(defaultTitle);
      setConfirmationMessage(defaultMessage);
      setPrimaryButtonLabel(defaultPrimaryButtonLabel);
      setSecondaryButtonLabel(defaultSecondaryButtonLabel);
      onAccept.current = defaultOnAccept;
      onDeny.current = defaultOnDeny;
    }, 500);
  };

  const acceptAndClose: ConfirmationDialogProps['onPrimaryButtonClick'] =
    async (...args) => {
      try {
        setIsConfirmationLoading(true);
        if (!onAccept.current) {
          throw new Error(
            'No handler provided for onAccept in ConfirmationDialog'
          );
        }
        await onAccept.current?.(...args);
        setIsConfirmationLoading(false);
        if (!stayOpenOnAccept) {
          closeConfirmationDialog();
        }
      } catch (e) {
        setIsConfirmationLoading(false);
      }
    };

  const denyAndClose: ConfirmationDialogProps['onSecondaryButtonClick'] = (
    ...args
  ) => {
    onDeny.current?.(...args);
    closeConfirmationDialog();
  };

  return {
    isConfirmationDialogOpen,
    confirmationMessage,
    confirmationTitle,
    primaryButtonLabel,
    secondaryButtonLabel,
    isConfirmationLoading,
    onAccept: acceptAndClose,
    onDeny: denyAndClose,
    openConfirmationDialog,
    closeConfirmationDialog,
  };
}

interface ConfirmationDialogProps {
  open: boolean;
  isLoading?: boolean;
  onPrimaryButtonClick: React.DOMAttributes<HTMLButtonElement>['onClick'];
  onSecondaryButtonClick: React.DOMAttributes<HTMLButtonElement>['onClick'];
  title?: string;
  message?: string;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
}

var ConfirmationDialog = ({
  open,
  title,
  message,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  primaryButtonLabel = 'OK',
  secondaryButtonLabel = 'Cancel',
  isLoading,
}: ConfirmationDialogProps) => {
  return (
    <Dialog open={open} className="ConfirmationDialog">
      {title && (
        <DialogTitle>
          <Typography variant="h6">{title}</Typography>
        </DialogTitle>
      )}
      {message && <DialogContent>{message}</DialogContent>}
      <DialogActions>
        <Button onClick={onSecondaryButtonClick}>{secondaryButtonLabel}</Button>
        <Button
          id="delete-group-button"
          color="secondary"
          style={{ color: 'white' }}
          variant="contained"
          onClick={onPrimaryButtonClick}
        >
          {isLoading ? <LoadingIndicator size={20} /> : primaryButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
