import React, { useState, useMemo, useEffect } from 'react';
import { useReconVelocityWorkflowSteps } from 'api/organizations/plugins/reconvelocity/workflows';
import permissions from 'common/permissions';
import PermissionGate from 'components/PermissionGate';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import './WorkflowDetailView.scss';
import WorkflowStepDetailView from './WorkflowStepDetailView';
import WorkflowStepsListView from './WorkflowStepsListView';

interface WorkflowDetailViewProps {
  orgId: string;
}

var WorkflowDetailView = (props: WorkflowDetailViewProps) => {
  const { orgId } = props;

  const { data: steps, isLoading } = useReconVelocityWorkflowSteps(orgId);

  const [selectedStepId, setSelectedStepId] = useState<string | undefined>();

  const topLevelWorkflowSteps = useMemo(
    () =>
      (steps ? steps?.data.filter((step) => !step.parentStep) : []).sort(
        ({ stepOrder: A }, { stepOrder: B }) => A - B
      ),
    [steps]
  );
  useEffect(() => {
    if (!selectedStepId && topLevelWorkflowSteps.length > 0) {
      setSelectedStepId(topLevelWorkflowSteps[0].id);
    }
  }, [selectedStepId, setSelectedStepId, topLevelWorkflowSteps]);

  return (
    <div className="Workflows full-height flex-columns">
      <div className="flex-grow">
        {isLoading || !steps ? (
          <LoadingIndicator />
        ) : (
          <div className="WorkflowDetailView full-height flex-rows">
            <div className="flex-columns flex-grow">
              <PermissionGate
                permissions={[
                  permissions.ORGS_PLUGINS_RECONVELOCITY_WORKFLOWS_STEPS_VIEW,
                ]}
              >
                <div className="WorkflowDetailView__list">
                  <WorkflowStepsListView
                    selectedStepId={selectedStepId}
                    setSelectedStepId={setSelectedStepId}
                    orgId={orgId}
                  />
                </div>
                <div className="flex-grow">
                  <WorkflowStepDetailView
                    orgId={orgId}
                    workflowStepDefinitionId={selectedStepId ?? ''}
                    setSelectedStepId={setSelectedStepId}
                  />
                </div>
              </PermissionGate>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkflowDetailView;
