/* eslint-disable prefer-arrow-callback */
import {
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
  TextField,
} from '@material-ui/core';
import { Add, Edit } from '@material-ui/icons';
import { debounce, isUndefined } from 'lodash';
import pluralize from 'pluralize';
import React, { useState } from 'react';
import './CollectionHeaderBar.scss';

const SEARCH_DELAY_MS = 400;

interface CollectionHeaderBarProps<T> {
  count?: number;
  collection?: Array<T> | any;
  isLoading?: boolean;
  isReloading?: boolean;
  onAdd?: IconButtonProps['onClick'];
  onEdit?: IconButtonProps['onClick'];
  onImport?: ButtonProps['onClick'];
  onExport?: ButtonProps['onClick'];
  onSearch?: (searchText: string) => void;
  unit: string;
  checklistState?: T[];
}

const CollectionHeaderBar = <T extends unknown>({
  collection,
  count,
  isLoading,
  isReloading,
  onAdd,
  onEdit,
  onImport,
  onExport,
  onSearch,
  unit,
  checklistState,
}: CollectionHeaderBarProps<T>) => {
  const [searchText, setSearchText] = useState('');
  const debouncedOnSearch = debounce(
    (text: string) => onSearch?.(text),
    SEARCH_DELAY_MS
  );
  const handleSearchFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!debouncedOnSearch) return;
    setSearchText(event.target.value);
    debouncedOnSearch(event.target.value);
  };

  const isInALoadingState = isLoading || isReloading;

  let unitText = pluralize(unit, collection?.data.totalLength ?? count, true);
  unitText =
    collection && !isUndefined(count) && count !== collection.data.totalLength
      ? `${collection.data.totalLength} of ${count}`
      : unitText;

  unitText = checklistState
    ? `${unitText}, ${checklistState.length} checked`
    : unitText;

  const displayText = isInALoadingState ? 'Loading...' : unitText;

  return (
    <div className="CollectionHeaderBar">
      <div className="CollectionHeaderBar-text">
        {displayText}
        {onEdit && (
          <IconButton
            onClick={onEdit}
            size="small"
            className="CollectionHeaderBar-edit-icon-container"
          >
            <Edit className="CollectionHeaderBar-edit-icon" />
          </IconButton>
        )}
      </div>
      <div className="CollectionHeaderBar-actions">
        {onSearch && (
          <TextField
            variant="outlined"
            margin="dense"
            value={searchText}
            onChange={handleSearchFieldChange}
            placeholder="Search"
          />
        )}
        {onAdd && (
          <IconButton onClick={onAdd} size="small">
            <Add />
          </IconButton>
        )}
        {onImport && <Button onClick={onImport}>Import</Button>}
        {onExport && <Button onClick={onExport}>Export</Button>}
      </div>
    </div>
  );
};

export default CollectionHeaderBar;
