import React, { useState, useCallback, useMemo } from 'react';
import Dialog from 'components/shared/Dialog';
import { ReconVelocityWorkflowStep } from 'models';
import utils from 'utils';

import './NewParentWorkflowStepDialog.scss';
import WorkflowStepUnsupportedActionDialog from './shared/WorkflowStepUnsupportedActionDialog';
import WorkflowStepExistingVehiclesSection from './shared/WorkflowStepExistingVehiclesSection';
import WorkflowStepsPrecedingStepsSection from './shared/WorkflowStepPrecedingStepsSection';

export interface NewParentWorkflowStepDialogProps {
  orgId: string;
  workflowStepDefinition: ReconVelocityWorkflowStep;
  onComplete?: (event: any) => void;
  onClose?: () => void;
  allSteps: ReconVelocityWorkflowStep[];
}

var NewParentWorkflowStepDialog = (props: NewParentWorkflowStepDialogProps) => {
  const { orgId, workflowStepDefinition, onComplete, onClose, allSteps } =
    props;

  const [newStepIdForExistingVehicles, setNewStepIdForExistingVehicles] =
    useState<string>();
  const [newNextStepIdForExistingSteps, setNewNextStepIdForExistingSteps] =
    useState<string>();
  const [isSaving, setIsSaving] = useState(false);

  const onPrimary = useCallback(
    async (event) => {
      try {
        setIsSaving(true);
        await onComplete?.(event);
        setIsSaving(false);
      } catch (error) {
        setIsSaving(false);
        return false;
      }
      return true;
    },
    [onComplete]
  );

  // Steps that are not the current step and also not parent steps (vehicles cannot be moved to parent steps)
  const otherSteps = useMemo(
    () =>
      allSteps?.filter((step) => {
        const childSteps = utils.workflow.getChildSteps(step.id, allSteps);
        return step.id !== workflowStepDefinition.id && !childSteps?.length;
      }),
    [allSteps, workflowStepDefinition.id]
  );

  const precedingSteps = useMemo(
    () =>
      otherSteps?.filter(
        (step) => step.nextStep?.id === workflowStepDefinition.id
      ),
    [otherSteps, workflowStepDefinition.id]
  );

  const { activeVehicleCount } = workflowStepDefinition;

  const userResolutionRequired =
    activeVehicleCount > 0 || precedingSteps!.length > 0;
  const allowUserResolution = false;

  return !allowUserResolution && userResolutionRequired ? (
    <WorkflowStepUnsupportedActionDialog
      orgId={orgId}
      step={workflowStepDefinition}
      action="promote"
      vehicleCount={activeVehicleCount}
      precedingSteps={precedingSteps ?? []}
      otherSteps={otherSteps ?? []}
      onClose={onClose}
      allSteps={allSteps}
    />
  ) : (
    <Dialog
      open
      title={`Promote ${workflowStepDefinition.name} to Parent Step`}
      maxWidth={userResolutionRequired ? 'md' : 'sm'}
      primaryButtonLabel="Continue"
      onPrimary={onPrimary}
      onClose={onClose}
      primaryButtonDisabled={
        userResolutionRequired &&
        !newStepIdForExistingVehicles &&
        !newNextStepIdForExistingSteps
      }
      block={isSaving}
      className="PluginDialog NewParentWorkflowStepDialog"
    >
      {userResolutionRequired && (
        <>
          <WorkflowStepExistingVehiclesSection
            step={workflowStepDefinition}
            vehicleCount={activeVehicleCount}
            action="promote"
            otherSteps={otherSteps ?? []}
            forUpdating
            selectedStepId={newStepIdForExistingVehicles}
            onSelectStep={(stepId) => {
              setNewStepIdForExistingVehicles(stepId);
            }}
          />
          <WorkflowStepsPrecedingStepsSection
            step={workflowStepDefinition}
            precedingSteps={precedingSteps ?? []}
            otherSteps={otherSteps ?? []}
            forUpdating
            selectedStepId={newNextStepIdForExistingSteps}
            onSelectStep={(stepId) => {
              setNewNextStepIdForExistingSteps(stepId);
            }}
          />
        </>
      )}
    </Dialog>
  );
};

export default NewParentWorkflowStepDialog;
