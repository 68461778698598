import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataProviderDmsPluginItem } from 'models';
import { compact, isEmpty } from 'lodash';
import Dialog from 'components/shared/Dialog';
import {
  BLANK_REGEXP,
  BLANK_ROW_COUNT,
} from 'components/shared/KeyValueFieldTable';
import Alert, { useAlert } from 'components/shared/Alert';
import ConfirmationDialog, {
  useConfirmationDialog,
} from 'components/shared/ConfirmationDialog';

import './PluginDialog.scss';
import { usePlugin, usePluginCreate, usePluginUpdate } from 'api';
import Tabs, { InlineTab, TabHeaderItem } from 'components/shared/Tabs';
import MatchCriteriaList, { MatchCriteriaProps } from './MatchCriteriaList';

export interface DmsPluginDialogProps {
  plugin: DataProviderDmsPluginItem;
  onSave?: () => void;
  onClose?: () => void;
  orgId: string;
  isInventoryEnabled: boolean;
}

const TabsForMatchCriteriaPerBusinessLinePluginDialog: React.FC<DmsPluginDialogProps> =
  (props) => {
    const { plugin, onClose, orgId, isInventoryEnabled } = props;

    const [inventoryDealerId, setInventoryDealerId] = useState('');
    const [salesDealerId, setSalesDealerId] = useState('');
    const [repairDealerId, setRepairDealerId] = useState('');
    const [matchCriteriaInventory, setMatchCriteriaInventory] = useState<
      MatchCriteriaProps[]
    >([]);
    const [matchCriteriaSales, setMatchCriteriaSales] = useState<
      MatchCriteriaProps[]
    >([]);
    const [matchCriteriaRepair, setMatchCriteriaRepair] = useState<
      MatchCriteriaProps[]
    >([]);
    const [isLoading, setIsLoading] = useState(false);

    const pluginTypeId = plugin.pluginName.toLowerCase().replace('_', '');
    const { data: pluginData, isLoading: isReading } = usePlugin(
      orgId,
      pluginTypeId,
      plugin.id
    );
    const { updatePluginAsync, isLoading: isUpdating } = usePluginUpdate(
      orgId,
      pluginTypeId
    );
    const { createPluginAsync, isLoading: isCreating } = usePluginCreate(
      orgId,
      pluginTypeId
    );

    const navigate = useNavigate();

    const BUSINESS_LINE_TABS: TabHeaderItem[] = useMemo(
      () => [
        { key: 'Inventory', label: 'Inventory', disabled: false },
        {
          key: 'Repair',
          label: 'Repair Orders',
          disabled: !isInventoryEnabled,
        },
      ],
      [isInventoryEnabled]
    );
    //CDK is the only provider that needs a business line to handle sales
    const DEFAULT_TAB_KEY = BUSINESS_LINE_TABS[0].key;
    if (plugin.pluginName === 'CDK' && BUSINESS_LINE_TABS.length - 1 < 2) {
      BUSINESS_LINE_TABS.push({
        key: 'Sales',
        label: 'Sales',
        disabled: !isInventoryEnabled,
      });
    }

    const InlineTabsCollection = [
      {
        element: (
          <MatchCriteriaList
            dealerId={inventoryDealerId}
            setDealerId={setInventoryDealerId}
            matchCriteria={matchCriteriaInventory}
            setMatchCriteria={setMatchCriteriaInventory}
          />
        ),
        path: BUSINESS_LINE_TABS[0].key,
      },
      {
        element: (
          <MatchCriteriaList
            dealerId={salesDealerId}
            setDealerId={setSalesDealerId}
            matchCriteria={matchCriteriaSales}
            setMatchCriteria={setMatchCriteriaSales}
          />
        ),
        path: BUSINESS_LINE_TABS[2]?.key,
      },
      {
        element: (
          <MatchCriteriaList
            dealerId={repairDealerId}
            setDealerId={setRepairDealerId}
            matchCriteria={matchCriteriaRepair}
            setMatchCriteria={setMatchCriteriaRepair}
          />
        ),
        path: BUSINESS_LINE_TABS[1].key,
      },
    ].map(({ element, path }) => (
      <InlineTab
        key={path}
        path={path}
        className="full-height"
        element={element}
      />
    ));

    useEffect(() => {
      setIsLoading(isReading || isUpdating || isCreating);
    }, [isReading, isCreating, isUpdating]);

    useEffect(() => {
      const blankRows = Array.from(Array(BLANK_ROW_COUNT), () => ({
        key: '',
        value: '',
      }));

      const getMatchCriteriaByBusinessLine = (
        businessLineStr: string
      ): MatchCriteriaProps[] =>
        pluginData.matchCriteria
          .map((criterion: any) => ({
            key: criterion.columnName,
            value: criterion.regexPattern,
            businessLine: criterion.businessLine,
          }))
          .filter(
            (e: { businessLine: string }) => e.businessLine === businessLineStr
          );

      const getDealerId = (businessLineStr: string): string => {
        const result = pluginData.matchCriteria.find(
          (e: { businessLine: string }) => e.businessLine === businessLineStr
        );
        return result?.dealerId;
      };

      if (!isReading && pluginData) {
        if (pluginData.matchCriteria) {
          // Split for each business line:
          setMatchCriteriaInventory(
            getMatchCriteriaByBusinessLine(BUSINESS_LINE_TABS[0].key).concat(
              blankRows
            )
          );
          setMatchCriteriaSales(
            getMatchCriteriaByBusinessLine(BUSINESS_LINE_TABS[2]?.key).concat(
              blankRows
            )
          );
          setMatchCriteriaRepair(
            getMatchCriteriaByBusinessLine(BUSINESS_LINE_TABS[1].key).concat(
              blankRows
            )
          );
          setInventoryDealerId(getDealerId(BUSINESS_LINE_TABS[0].key));
          setSalesDealerId(getDealerId(BUSINESS_LINE_TABS[2]?.key));
          setRepairDealerId(getDealerId(BUSINESS_LINE_TABS[1].key));
        }
      } else if (!isReading) {
        // Creating a new plugin.
        setMatchCriteriaInventory(blankRows);
        setMatchCriteriaSales(blankRows);
        setMatchCriteriaRepair(blankRows);
      }
    }, [pluginData, isReading, BUSINESS_LINE_TABS]);

    useEffect(() => {
      navigate(DEFAULT_TAB_KEY, { replace: true });
    }, [navigate, DEFAULT_TAB_KEY]);

    const { isAlertOpen, alertMessage, openAlert, closeAlert, variant } =
      useAlert();

    const {
      isConfirmationDialogOpen,
      isConfirmationLoading,
      confirmationMessage,
      onAccept,
      onDeny,
      primaryButtonLabel,
    } = useConfirmationDialog();

    const createMatchCriteriaList = (
      matchCriteriaList: MatchCriteriaProps[],
      businessLine: string,
      dealerId: string
    ) => {
      if (isEmpty(dealerId)) return [];

      const res = compact(
        matchCriteriaList.map(({ key, value }) =>
          BLANK_REGEXP.test(key) && BLANK_REGEXP.test(value)
            ? undefined
            : { columnName: key, regexPattern: value, businessLine, dealerId }
        )
      );

      if (res.length === 0)
        return [{ columnName: '', regexPattern: '', businessLine, dealerId }];
      return res;
    };

    const consolidateMatchCriteriaPerBusinessLine = () => [
      ...createMatchCriteriaList(
        matchCriteriaInventory,
        BUSINESS_LINE_TABS[0].key,
        inventoryDealerId
      ),
      ...createMatchCriteriaList(
        matchCriteriaSales,
        BUSINESS_LINE_TABS[2]?.key,
        salesDealerId
      ),
      ...createMatchCriteriaList(
        matchCriteriaRepair,
        BUSINESS_LINE_TABS[1].key,
        repairDealerId
      ),
    ];

    const save = async () => {
      const data: Partial<DataProviderDmsPluginItem> = {
        matchCriteria: consolidateMatchCriteriaPerBusinessLine(),
      };
      try {
        if (plugin.id) {
          await updatePluginAsync(data);
        } else {
          const newPlugin = { ...plugin, ...data };
          await createPluginAsync(newPlugin);
        }
      } catch (error) {
        console.error(error);
        openAlert(
          'An error occurred when saving this plug-in to the server.',
          'error'
        );
        return false;
      }
      return true;
    };

    return (
      <>
        <Dialog
          open
          title={`Configure ${plugin.title} Plug-in`}
          maxWidth="sm"
          fullWidth
          primaryButtonLabel="Save"
          onPrimary={save}
          onClose={onClose}
          block={isLoading}
          className="PluginDialog DmsPluginDialog"
        >
          <Tabs
            tabHeaderItems={BUSINESS_LINE_TABS}
            defaultTabKey={DEFAULT_TAB_KEY}
          />
          {InlineTabsCollection}
        </Dialog>

        <Alert
          open={isAlertOpen}
          message={alertMessage}
          duration={3500}
          variant={variant}
          onClose={closeAlert}
        />
        <ConfirmationDialog
          open={isConfirmationDialogOpen}
          primaryButtonLabel={primaryButtonLabel}
          onPrimaryButtonClick={onAccept}
          onSecondaryButtonClick={onDeny}
          isLoading={isConfirmationLoading}
          message={confirmationMessage}
        />
      </>
    );
  };

export default TabsForMatchCriteriaPerBusinessLinePluginDialog;
