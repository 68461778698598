import { Column, TableColumnsProps } from '../../../shared/UsersList/types';
import TableRow from '@material-ui/core/TableRow';
import { TableCell } from '@material-ui/core';
import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import strings from '../../../../common/strings';
import { AxiosError } from 'axios';
import './BulkUserDialog.scss';

const defaultHeaderColumns: Column[] = [
  {
    dataKey: 'user',
    label: 'User',
    width: '200px',
  },
  {
    dataKey: 'errorMessage',
    label: 'Error Message',
    width: '400px',
  },
];

const fileErrorColumns: Column[] = [
  {
    dataKey: 'fileError',
    label: 'File Error',
    width: '600px',
  },
];

const TableColumns = ({ columns }: TableColumnsProps) => {
  return (
    <TableRow className="row-header">
      {columns.map((column: Column) => {
        return (
          <TableCell
            key={`${column.dataKey}-columnHeader`}
            variant="head"
            align="left"
            className="columnHeader"
            scope="col"
          >
            {column.label}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

interface BulkUploadErrorTableProps {
  headerColumns?: Column[];
  failedUploadResponse: AxiosError;
}

const BulkUploadErrorTable: React.FC<BulkUploadErrorTableProps> = ({
  headerColumns = defaultHeaderColumns,
  failedUploadResponse,
}) => {
  if (failedUploadResponse.response?.data.message) {
    return (
      <Table>
        <TableHead>
          <TableColumns columns={fileErrorColumns} />
        </TableHead>
        <TableBody className="full-height">
          <TableRow className="row">
            <TableCell>{failedUploadResponse.response?.data.message}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  } else {
    return (
      <Table className="table">
        <TableHead>
          <TableColumns columns={headerColumns} />
        </TableHead>

        {failedUploadResponse.response?.data.data.map((record: any) => (
          <TableBody className="full-height">
            {record.errorMessage.includes('Username is not valid') && (
              <TableRow className="row">
                <TableCell variant="body">{record.email}</TableCell>
                <TableCell variant="body">{record.errorMessage}</TableCell>
              </TableRow>
            )}
            {record.errorMessage.includes('already in use. Item: userName') && (
              <TableRow className="row">
                <TableCell variant="body">{record.email}</TableCell>
                <TableCell variant="body">{strings.USERNAME_ERROR}</TableCell>
              </TableRow>
            )}
            {record.errorMessage.includes('Required field missing') && (
              <TableRow className="row">
                <TableCell variant="body">
                  {record.email != '' ? (
                    <p>{record.email}</p>
                  ) : (
                    <p>{`${record.firstName} ${record.lastName}`}</p>
                  )}
                </TableCell>
                <TableCell variant="body">{record.errorMessage}</TableCell>
              </TableRow>
            )}
          </TableBody>
        ))}
      </Table>
    );
  }
};

export default BulkUploadErrorTable;
