import { useState } from 'react';

import OrgGroupUsersList from 'components/shared/UsersList/OrgGroupUsersList';
import 'components/shared/UsersList/UsersList.scss';

import PERMISSIONS from 'common/permissions';
import ConfirmationDialog, {
  useConfirmationDialog,
} from 'components/shared/ConfirmationDialog';
import { User } from 'models';
import {
  useDeleteUserFromOrganizationGroup,
  useGroupUsers,
  useOrganizationGroup,
} from 'api/organizations/groups';
// import UsersListLoading from "components/shared/UsersList/UsersListLoading";
import EditUserDialog from 'components/UsersView/EditUserDialog';
import useSession from 'api/session';
import { useOrganization } from 'api';
import AddUserDialog from '../../AddUserDialog';

interface UsersViewProps {
  organizationId: string;
  groupId: string;
}

// TODO combine with the other UsersView component.
var UsersView = ({ organizationId, groupId }: UsersViewProps) => {
  const { data: session } = useSession();
  const { data: org } = useOrganization(organizationId);
  const { data: group } = useOrganizationGroup(organizationId, groupId);
  const { deleteOrganizationUserFromGroupAsync } =
    useDeleteUserFromOrganizationGroup(organizationId, groupId);
  const {
    isConfirmationDialogOpen,
    isConfirmationLoading,
    confirmationMessage,
    primaryButtonLabel,
    onAccept,
    onDeny,
    openConfirmationDialog,
  } = useConfirmationDialog();

  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false);
  const [isEditUserDialogOpen, setIsEditUserDialogOpen] = useState(false);

  const openAddUserDialog = () => {
    setIsAddUserDialogOpen(true);
  };

  const closeAddUserDialog = () => {
    setIsAddUserDialogOpen(false);
  };

  const [userId, setUserId] = useState<string>('');
  const editUserDialog = (openDialog: boolean, selectedUserId: string = '') => {
    setUserId(selectedUserId);
    setIsEditUserDialogOpen(openDialog);
  };

  const handleDeleteClick = (user: User) => {
    const hasSelfDeletePermission =
      user.id === session?.user.id &&
      session?.permissions.includes(PERMISSIONS.USERS_SELF_DELETE);
    const hasDeletePermission =
      session?.permissions.includes(PERMISSIONS.USERS_DELETE) ||
      hasSelfDeletePermission;

    if (!hasDeletePermission) return;

    openConfirmationDialog({
      messageOverride: `Remove user ${user.firstName} ${user.lastName} from group ${group?.name} of organization ${org?.name}?`,
      primaryButtonLabelOverride: 'Delete',
      onAcceptOverride: async () =>
        deleteOrganizationUserFromGroupAsync(user.id),
    });
  };

  // TODO add status === 'loading' or 'error'
  return (
    <div className="full-height flex-rows">
      <OrgGroupUsersList
        fetchUsers={useGroupUsers}
        organizationId={organizationId}
        groupId={groupId}
        editUserDialog={editUserDialog}
        onDeleteUser={handleDeleteClick}
        openAddUserDialog={openAddUserDialog}
      />
      {isAddUserDialogOpen && (
        <AddUserDialog
          organizationId={organizationId}
          onClose={closeAddUserDialog}
          groupId={groupId}
        />
      )}
      {isEditUserDialogOpen && (
        <EditUserDialog
          onClose={editUserDialog}
          userId={userId}
          refreshActionAfterSave={() => {
            /* TODO */
          }}
          organizationId={organizationId}
        />
      )}

      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        isLoading={isConfirmationLoading}
        message={confirmationMessage}
        primaryButtonLabel={primaryButtonLabel}
        onPrimaryButtonClick={onAccept}
        onSecondaryButtonClick={onDeny}
      />
    </div>
  );
};

export default UsersView;
