import workflow from './workflow';
import strings from './strings';
import time from './time';
import permissions from './permissions';

export default {
  strings,
  workflow,
  time,
  permissions,
};
