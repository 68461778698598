import { combineReducers } from 'redux';
import authenticationReducer from './authentication';
import organizationsReducer from './organizations';
import usersReducer from './users';
import utilityReducer from './utility';

const rootReducer = combineReducers({
  authentication: authenticationReducer,
  organizations: organizationsReducer,
  users: usersReducer,
  utility: utilityReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
