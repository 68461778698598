import { Button, Card, CardContent, CardHeader } from '@material-ui/core';
import { Backup, CloudUpload, Done, Warning } from '@material-ui/icons';
import { getAPIHeaders } from 'api';
import { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import theme from 'common/theme';
import axios from 'axios';
import { useBrochureStyles } from './materialStyles';
import { BrochureManagerContext } from './brochureContext';

// @todo - this entire component is a WIP
export var RVInlineDebug = ({ children }: { children: ReactNode }) => {
  if (process.env.NODE_ENV !== 'development') return null;
  const styles = { fontSize: 12 };
  return (
    <Card style={styles}>
      <CardHeader>Debug Info</CardHeader>
      <CardContent>
        <pre>{children}</pre>
      </CardContent>
    </Card>
  );
};

export var BrochureUploader = () => {
  const classes = useBrochureStyles(theme);
  const [isLoading, setLoading] = useState(false);
  const [status, setStatus] = useState<
    'idle' | 'loading' | 'error' | 'complete' | 'ready'
  >('idle');

  const progressRef = useRef(0);
  const { state } = useContext(BrochureManagerContext);
  const [currentFile, setFile] = useState<any>();

  const { vehicleYear, vehicleMake, vehicleModel, hasBrochure, uri } = state;

  useEffect(() => {
    setStatus('idle');
  }, [vehicleYear]);

  const currentModelName = `${vehicleYear} ${vehicleMake} ${vehicleModel}`;
  const uploadPath = `/brochures/save/${vehicleYear}/${vehicleMake}/${vehicleModel}`;
  const uploadFilename = `${vehicleMake}_US_${vehicleModel}_${vehicleYear}.pdf`;
  const uploadURL = `${uploadPath}?file=${uploadFilename}`;

  async function uploadFile() {
    const { host, options } = getAPIHeaders('POST');
    const formData: FormData = new FormData();
    formData.append('file', currentFile);
    console.log(uploadURL);

    const response = await axios.post(host + uploadURL, formData, {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        const previousCompleted = progressRef.current;
        if (percentCompleted - previousCompleted > 5) {
          progressRef.current = percentCompleted;
        }
      },
      headers: {
        ...options.headers,
        'Content-Type': 'multipart/form-data',
      },
    });

    console.log('upload ended', response);

    // error
    if (response.status !== 200) {
      // specific error
      if (response.status === 413) {
        throw new Error('This file exceeds the maximum allowed size');
      }

      // generic error
      throw new Error('Generic error');
    }

    // success
    console.log('SUCCESS!');
  }

  async function handleUploadClick() {
    setLoading(true);
    progressRef.current = 0;

    setStatus('loading');

    try {
      await uploadFile();
      setStatus('complete');
    } catch (error) {
      console.log('Ran into a problem', error);
      setStatus('error');
    }

    setLoading(false);
    progressRef.current = 0;
  }

  var DropArea = () => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      accept: 'application/pdf',
      onDropAccepted: (files) => {
        setStatus('ready');
        console.log(`Drop accepted for ${files.length} items`);
      },
      onDropRejected: () => {
        setStatus('error');
      },
      onDrop: (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (!file) return;
        const preparedFileName = uploadFilename;
        const preparedFile = new File([file], preparedFileName, {
          type: file.type,
        });

        console.log(acceptedFiles[0], preparedFile);
        setFile(preparedFile);
      },
    });
    return (
      <div
        {...getRootProps()}
        className={isDragActive ? classes.dropAreaActive : classes.dropArea}
      >
        <div>
          {status === 'error' && <Warning className={classes.uploadIcon} />}
          {status === 'idle' && <CloudUpload className={classes.uploadIcon} />}
          {status === 'ready' && <CloudUpload className={classes.uploadIcon} />}
          {status === 'complete' && <Done className={classes.uploadIcon} />}
          {status === 'loading' && <LoadingIndicator />}
        </div>
        <input {...getInputProps()} />
        <span className={classes.uploadTitle}>Upload a brochure.</span>
        <div className={classes.uploadText}>
          {status === 'error' && (
            <Button variant="outlined">Invalid file, select another.</Button>
          )}
          {status === 'idle' && (
            <Button variant="outlined">
              Select or drop brochure file (PDF)
            </Button>
          )}
          {status === 'ready' && <p>Ready to upload {currentFile?.name}</p>}
          {status === 'complete' && (
            <p>Finished uploading {currentFile.name}</p>
          )}
          {status === 'loading' && (
            <p>
              Uploading {uploadFilename} ...{progressRef.current}%...
            </p>
          )}
        </div>
      </div>
    );
  };

  var DropControls = () => {
    return (
      <div>
        <Button
          className={classes.buttonRow}
          href={uri}
          disabled={!hasBrochure || isLoading}
          color="secondary"
          variant="text"
        >
          {hasBrochure ? 'View Brochure' : 'No Brochure Found'}
        </Button>
        <Button
          className={classes.buttonRow}
          disabled={status !== 'ready'}
          startIcon={
            hasBrochure ? (
              <Warning style={{ color: theme.palette.common.white }} />
            ) : (
              <Backup style={{ color: theme.palette.common.white }} />
            )
          }
          color="secondary"
          style={{
            color: theme.palette.common.white,
          }}
          onClick={handleUploadClick}
          variant="contained"
        >
          {hasBrochure ? 'Upload Replacement Brochure' : 'Upload Brochure'}
        </Button>
        <div className={classes.helperText}>
          {status === 'ready' && (
            <>
              {hasBrochure &&
                ' This will replace the current brochure permanently.'}
            </>
          )}
          {status === 'error' && (
            <p>There was a problem uploading this file.</p>
          )}
          {status === 'loading' && (
            <p>Uploading {currentModelName} brochure...</p>
          )}
          {status === 'complete' && (
            <p>Succesfully uploaded {currentModelName}</p>
          )}
        </div>
        <p>WARNING: Changes are permanent and will be live immediately!</p>
        <p>Document size limit: 10MB.</p>
      </div>
    );
  };

  return (
    <div className={classes.uploadContainer}>
      <DropArea />
      <DropControls />
    </div>
  );
};

export default { BrochureUploader };
