import React, { useEffect, useState } from 'react';
import { DataProviderDmsPluginItem } from 'models';
import { compact } from 'lodash';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import Dialog from 'components/shared/Dialog';
import KeyValueFieldTable, {
  BLANK_REGEXP,
  BLANK_ROW_COUNT,
} from 'components/shared/KeyValueFieldTable';
import Alert, { useAlert } from 'components/shared/Alert';
import ConfirmationDialog, {
  useConfirmationDialog,
} from 'components/shared/ConfirmationDialog';
import LoadingIndicator from 'components/shared/LoadingIndicator';

import './PluginDialog.scss';
import { usePlugin, usePluginCreate, usePluginUpdate } from 'api';

export interface SalesBusinessLinePluginDialogProps {
  plugin: DataProviderDmsPluginItem;
  onSave?: () => void;
  onClose?: () => void;
  orgId: string;
  isInventoryEnabled: boolean;
}

const SalesBusinessLinePluginDialog: React.FC<SalesBusinessLinePluginDialogProps> =
  (props) => {
    const { plugin, onClose, orgId, isInventoryEnabled } = props;

    const [enabled, setEnabled] = useState(true);
    const [dealerId, setDealerId] = useState('');
    const [notes, setNotes] = useState('');
    const [matchCriteria, setMatchCriteria] = useState<
      { key: string; value: string }[]
    >([]);
    const [enableInventory, setEnableInventory] = useState(false);
    const [enableSales, setEnableSales] = useState(false);
    const [enableRepairOrders, setEnableRepairOrders] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const pluginTypeId = plugin.pluginName.toLowerCase().replace('_', '');
    const { data: pluginData, isLoading: isReading } = usePlugin(
      orgId,
      pluginTypeId,
      plugin.id
    );
    const { updatePluginAsync, isLoading: isUpdating } = usePluginUpdate(
      orgId,
      pluginTypeId
    );
    const { createPluginAsync, isLoading: isCreating } = usePluginCreate(
      orgId,
      pluginTypeId
    );

    useEffect(() => {
      setIsLoading(isReading || isUpdating || isCreating);
    }, [isReading, isCreating, isUpdating]);

    useEffect(() => {
      const blankRows = Array.from(Array(BLANK_ROW_COUNT), () => {
        return { key: '', value: '' };
      });

      if (!isReading && pluginData) {
        setEnabled(pluginData.enabled ?? true);
        setDealerId(pluginData.dealerId || '');
        setNotes(pluginData.notes || '');
        if (pluginData.matchCriteria) {
          setMatchCriteria(
            pluginData.matchCriteria
              .map((criterion: any) => {
                return {
                  key: criterion.columnName,
                  value: criterion.regexPattern,
                };
              })
              .concat(blankRows)
          );
        }

        setEnableInventory(pluginData?.options?.enableInventory || false);
        setEnableSales(pluginData?.options?.enableSales || false);
        setEnableRepairOrders(pluginData?.options?.enableRepairOrders || false);
      } else if (!isReading) {
        // Creating a new plugin.
        setMatchCriteria(blankRows);
        // Default to inventory business line.
        setEnableInventory(true);
      }
    }, [pluginData, isReading]);

    const { isAlertOpen, alertMessage, openAlert, closeAlert, variant } =
      useAlert();

    const {
      isConfirmationDialogOpen,
      isConfirmationLoading,
      confirmationMessage,
      onAccept,
      onDeny,
      openConfirmationDialog,
      primaryButtonLabel,
    } = useConfirmationDialog();

    const onDeleteRow = (rowIndex: number, key: string) => {
      openConfirmationDialog({
        messageOverride: `Remove column "${key}"?`,
        primaryButtonLabelOverride: 'Delete',
        onAcceptOverride: () => {
          setMatchCriteria(
            matchCriteria.filter((criteria) => criteria.key !== key)
          );
        },
      });
    };

    const save = async () => {
      const data: Partial<DataProviderDmsPluginItem> = {
        enabled,
        dealerId,
        notes,
        matchCriteria: compact(
          matchCriteria.map(({ key, value }) =>
            BLANK_REGEXP.test(key) && BLANK_REGEXP.test(value)
              ? undefined
              : { columnName: key, regexPattern: value }
          )
        ),
        options: {
          enableInventory,
          enableSales,
          enableRepairOrders,
        },
      };
      try {
        if (plugin.id) {
          await updatePluginAsync(data);
        } else {
          const newPlugin = { ...plugin, ...data };
          await createPluginAsync(newPlugin);
        }
      } catch (error) {
        console.error(error);
        openAlert(
          'An error occurred when saving this plug-in to the server.',
          'error'
        );
        return false;
      }
      return true;
    };

    return (
      <>
        <Dialog
          open
          title={`Configure ${plugin.title} Plug-in`}
          maxWidth="sm"
          fullWidth
          primaryButtonLabel="Save"
          onPrimary={save}
          onClose={onClose}
          block={isLoading}
          className="PluginDialog DmsPluginDialog"
        >
          {isInventoryEnabled ? (
            <>
              <div>
                <FormControlLabel
                  className="PluginDialog__switch"
                  label={
                    isLoading ? (
                      <LoadingIndicator size={26} />
                    ) : (
                      <FormLabel>{enabled ? 'Enabled' : 'Disabled'}</FormLabel>
                    )
                  }
                  labelPlacement="start"
                  control={
                    <Switch
                      inputProps={{
                        'aria-label': 'DMS Plugin Dialog Enable switch',
                      }}
                      checked={enabled ?? false}
                      disabled={isLoading}
                      onChange={(event) => setEnabled(event.target.checked)}
                    />
                  }
                />
              </div>
              <TextField
                autoFocus
                className="PluginDialog__text-input"
                label="Sales"
                variant="outlined"
                required
                value={dealerId}
                onChange={(e) => {
                  setDealerId(e.target.value);
                }}
                margin="dense"
              />
              <div className="PluginDialog__checkbox_group">
                {/* /* Not Needed, will leave in case needed in the future. This componenet is only being used for one business line so we don't need checkboxes.
          <FormControlLabel
            control={
              <Checkbox
                checked={enableInventory}
                onChange={(event) => setEnableInventory(event.target.checked)}
                color="primary"
              />
            }
            label="Inventory"
          /> */}
                {/* Not Needed, will leave in case needed in the future. This componenet is only being used for one business line so we don't need checkboxes.
          <FormControlLabel
            control={
              <Checkbox
                checked={enableSales}
                onChange={(event) => setEnableSales(event.target.checked)}
                color="primary"
              />
            }
            label="Sales"
          /> */}
                {/* /* Not Needed, will leave in case needed in the future. This componenet is only being used for one business line so we don't need checkboxes.
          <FormControlLabel
            control={
              <Checkbox
                checked={enableRepairOrders}
                onChange={(event) =>
                  setEnableRepairOrders(event.target.checked)
                }
                color="primary"
              />
            }
            label="Repair Orders"
          /> */}
              </div>
              <KeyValueFieldTable
                label="Match Criteria"
                matchCriteria={matchCriteria}
                onDeleteRow={onDeleteRow}
                onChange={(index, property, newValue) => {
                  const currentKeyValuePair = matchCriteria[index];
                  let newKeyValuePair = { ...currentKeyValuePair };
                  newKeyValuePair[property] = newValue;
                  let newMatchCriteria = [...matchCriteria];
                  newMatchCriteria.splice(index, 1, newKeyValuePair);
                  setMatchCriteria(newMatchCriteria);
                }}
              />
              <TextareaAutosize
                className="PluginDialog__text-area"
                placeholder="Notes"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                rowsMax={4}
                rowsMin={4}
              />
            </>
          ) : (
            <p>Please set up inventory data provider first</p>
          )}
        </Dialog>
        <Alert
          open={isAlertOpen}
          message={alertMessage}
          duration={3500}
          variant={variant}
          onClose={closeAlert}
        />
        <ConfirmationDialog
          open={isConfirmationDialogOpen}
          primaryButtonLabel={primaryButtonLabel}
          onPrimaryButtonClick={onAccept}
          onSecondaryButtonClick={onDeny}
          isLoading={isConfirmationLoading}
          message={confirmationMessage}
        />
      </>
    );
  };

export default SalesBusinessLinePluginDialog;
