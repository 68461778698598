import React, { RefObject, useState } from 'react';
import { MuuriComponent, AutoScroller } from 'muuri-react';
import { FolioDocumentTemplate } from 'models';
import strings from 'common/strings';
import EngageMediaItem from '../EngageMediaItem';
import './MediaContainer.scss';

interface MediaContainerProps {
  title: string;
  icon?: React.ReactElement | null;
  media: FolioDocumentTemplate[];
  scrollRef: RefObject<HTMLDivElement>;
  folderId: string;
  orgId: string;
  folioId: string;
  onStar: (document: FolioDocumentTemplate) => void;
  onPublish: (document: FolioDocumentTemplate) => void;
  onDelete: (document: FolioDocumentTemplate) => void;
  onEdit: (
    document: FolioDocumentTemplate,
    title: string,
    previewImageUrl: string,
    mediaUrl?: string
  ) => void;
  onReorder: (folderId: string, reorderObject: Record<string, number>) => void;
}

const MediaContainer: React.FC<MediaContainerProps> = ({
  title,
  icon,
  media,
  scrollRef,
  onStar,
  onPublish,
  onDelete,
  onEdit,
  onReorder,
  folderId,
  folioId,
  orgId,
}: MediaContainerProps) => {
  const [isDragging, setIsDragging] = useState(false);
  const children = media.map((item: FolioDocumentTemplate) => (
    <EngageMediaItem
      orgId={orgId}
      folioId={folioId}
      key={item.id}
      media={item}
      inFeaturedMediaContainer={title === strings.FEATURED}
      onStar={onStar}
      onPublish={onPublish}
      onDelete={onDelete}
      onEdit={onEdit}
      isDragging={isDragging}
    />
  ));

  // TODO: This component is rendering in
  //  grid location 0 under any documents already in a folder.
  //  problem is solved if an EngageMediaItem is passed in
  //  instead of an EngageMediaPlaceholder.
  // if (title !== strings.FEATURED) {
  //   children.push(
  //     <EngageMediaPlaceholder
  //       title={strings.ADD_MEDIA}
  //       folderId={folderId}
  //       orgId={orgId}
  //     />
  //   );
  // }

  return (
    <div className="MediaContainer" ref={scrollRef}>
      <div className="MediaContainer-title">
        {icon && icon}
        {title}
      </div>
      {media.length > 0 ? (
        <MuuriComponent
          dragEnabled
          dragStartPredicate={{ distance: 25 }}
          onDragStart={() => setIsDragging(true)}
          onDragEnd={async (item: any) => {
            const reorderObject = item
              .getGrid()
              .getItems()
              .reduce(
                (acc: Record<string, number>, listItem: any, idx: number) => {
                  if (listItem.getKey()) {
                    acc[listItem.getKey()] = idx;
                  }
                  return acc;
                },
                {}
              );

            if (onReorder) {
              await onReorder(folderId, reorderObject);
            }

            setIsDragging(false);
          }}
          dragAutoScroll={{
            targets: [
              {
                element: scrollRef,
                axis: AutoScroller.AXIS_Y,
              },
            ],
          }}
        >
          {children}
        </MuuriComponent>
      ) : (
        <div className="MediaContainer-no-media">
          {strings.NO_ENGAGE_MEDIA_CONTAINER(title)}
        </div>
      )}
    </div>
  );
};

export default MediaContainer;
