import App from 'components/App';
import React from 'react';
import ReactDOM from 'react-dom';
import 'scss/index.scss';
import 'typeface-open-sans';
import 'typeface-source-sans-pro';
import * as serviceWorker from './serviceWorker';

const render = (AppComponent: any) => {
  ReactDOM.render(<AppComponent />, document.getElementById('root'));
};

async function start() {
  // TODO should we have a force update
  // onForceUpgrade: () => {
  //   window.location.reload();
  //   return false;
  // },
  render(App);
}

start();

serviceWorker.unregister();

// Hot Module Replacement (HMR).
if ((module as any).hot) {
  (module as any).hot.accept('./components/App.tsx', () => {
    const NextApp = App; // eslint-disable-line global-require
    render(NextApp);
  });
}
