import React, { useLayoutEffect, useState } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { Create } from '@material-ui/icons';

import Tabs, { TabHeaderItem } from 'components/shared/Tabs';
import PermissionGate from 'components/PermissionGate';
import permissions from 'common/permissions';
import { OrganizationType } from 'models';
import Alert, { useAlert } from 'components/shared/Alert';
import TagsList from 'components/shared/TagsList';
import { useOrganization } from 'api/organizations';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import GroupsView from './GroupsView';
import OverviewView from './OverviewView';
import PluginsView from './PluginsView';
import OrgUsersView from './UsersView';
import OrganizationDialog from '../OrganizationDialog';

import './OrganizationDetailView.scss';

const PARENT_ORG_TABS: TabHeaderItem[] = [
  { key: 'overview', label: 'Overview' },
  { key: 'users', label: 'Users', permission: permissions.USERS_VIEW },
  { key: 'groups', label: 'Groups', permission: permissions.ORGS_GROUPS_VIEW },
];

const ROOFTOP_TABS: TabHeaderItem[] = [
  ...PARENT_ORG_TABS,
  {
    key: 'plugins',
    label: 'Plug-ins',
    permission: permissions.ORGS_PLUGINS_VIEW,
  },
];

const DEFAULT_TAB_KEY = 'overview';

const OrganizationDetailView: React.FC = () => {
  const { organizationId } = useParams();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const { data: organization, isLoading } = useOrganization(organizationId);

  const { isAlertOpen, alertMessage, openAlert, closeAlert, variant } =
    useAlert();

  useLayoutEffect(() => {
    if (!organizationId) {
      openAlert('Unable to load organization. Please try again.', 'error');
    }
  }, [openAlert, organizationId]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (organization === undefined) {
    return null;
  }

  const isRooftop = organization.type === OrganizationType.ROOFTOP;
  const determinedTabs = isRooftop ? ROOFTOP_TABS : PARENT_ORG_TABS;

  return (
    <div className="OrganzationDetailView full-height flex-rows flex-grow">
      <div className="heading">
        <div className="full-width flex-columns flex-space-between">
          <div className="inline-block">
            <h2 id="organization-name" className="inline">
              {organization?.name ?? ''}
            </h2>
            &nbsp;{' '}
            <span className="nowrap">
              ID:
              {organization.id}
            </span>
          </div>
          {organization.type !== 'UNKNOWN' && (
            <PermissionGate permissions={[permissions.ORGS_UPDATE]}>
              <Create
                className="icon-hover"
                onClick={() => setIsDialogOpen(true)}
              />
            </PermissionGate>
          )}
        </div>
        <TagsList orgId={organizationId} tags={organization?.tagInstances} />
      </div>
      <>
        <Tabs tabHeaderItems={determinedTabs} defaultTabKey={DEFAULT_TAB_KEY} />
        <div className="flex-grow">
          <Routes>
            <Route
              path="overview/*"
              element={<OverviewView organizationId={organizationId} />}
            />
            <Route
              path="users/*"
              element={<OrgUsersView organizationId={organization.id} />}
            />
            <Route
              path="groups/*"
              element={<GroupsView organizationId={organization.id} />}
            />
            {isRooftop && (
              <Route
                path="plugins/*"
                element={<PluginsView organization={organization} />}
              />
            )}
            <Route path="*">
              <Navigate to={DEFAULT_TAB_KEY} />
            </Route>
          </Routes>
        </div>
      </>
      {isDialogOpen && (
        <OrganizationDialog
          organization={organization}
          onClose={() => {
            setIsDialogOpen(false);
          }}
        />
      )}
      <Alert
        open={isAlertOpen}
        message={alertMessage}
        onClose={closeAlert}
        duration={3500}
        variant={variant}
      />
    </div>
  );
};

export default OrganizationDetailView;
