import React, { useState } from 'react';
import { FeatureFlag } from 'models';
import { useFeatureFlagApplicationsFlags } from 'api';
import { FlagRounded, FolderRounded } from '@material-ui/icons';
import FeatureFlagDetailView from './FeatureFlagDetailView';

import '../../SettingsView.scss';

interface FeatureFlagsListDetailLayoutProps {
  applicationName: string;
}

var FeatureFlagsListDetailLayout = ({
  applicationName,
}: FeatureFlagsListDetailLayoutProps) => {
  const { data: featureFlags, isLoading } =
    useFeatureFlagApplicationsFlags(applicationName);
  const [selectedFeatureFlag, setSelectedFeatureFlag] =
    useState<FeatureFlag | null>(
      featureFlags && featureFlags?.length > 1 ? featureFlags[0] : null
    );

  return (
    <div className="full-height flex-columns">
      <div className="Settings-container" id="feature-flag-list-container">
        <div className="Settings-title">
          {isLoading ? 'Loading...' : `${featureFlags?.length} Feature Flags`}
        </div>
        <div className="flex-grow">
          {featureFlags?.map((featureFlag) => (
            <div
              id={featureFlag.name}
              key={featureFlag.name}
              role="button"
              tabIndex={-1}
              className="flex-stretch"
              onClick={() => setSelectedFeatureFlag(featureFlag)}
            >
              <div className="Settings-row Settings-selectable">
                <FlagRounded />
                <span className="FeatureFlagRow-name margin-left-sm">
                  {featureFlag.name}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex-grow">
        {selectedFeatureFlag === null && (
          <div className="flex-column align-center valign-center margin-lg full-height">
            <div>
              <FolderRounded fontSize="large" />
            </div>
            <h3>Select a feature flag</h3>
          </div>
        )}
        {selectedFeatureFlag && selectedFeatureFlag?.deciderData === null && (
          <h4 className="padding-lg align-center valign-center">
            {`${selectedFeatureFlag?.name} is ${
              selectedFeatureFlag?.enabled ? 'enabled' : 'disabled'
            } for everyone.`}
          </h4>
        )}
        {selectedFeatureFlag && selectedFeatureFlag?.deciderData !== null && (
          <FeatureFlagDetailView
            applicationName={applicationName}
            featureFlagId={selectedFeatureFlag.id}
          />
        )}
      </div>
    </div>
  );
};

export default FeatureFlagsListDetailLayout;
