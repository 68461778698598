import React, { ReactElement, ReactNode, useMemo } from 'react';
import { every } from 'lodash';
import allPermissions from 'common/permissions';
import useSession from 'api/session';

interface PermissionGateProps {
  permissions?: Array<keyof typeof allPermissions>;
  children: ReactNode;
}
const PermissionGate = ({
  permissions = [],
  children,
}: PermissionGateProps): ReactElement | null => {
  const { data: session } = useSession();
  const hasPermission = useMemo(
    () =>
      every(
        permissions,
        (permission) =>
          permission && session?.permissions.includes(permission?.toString())
      ),
    [permissions, session]
  );

  if (!hasPermission) return null;

  return <>{children}</>;
};

export default PermissionGate;
