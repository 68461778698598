import React, { useState } from 'react';
import { debounce } from 'lodash';
import { TextField } from '@material-ui/core';

import '../../../shared/CollectionHeaderBar/CollectionHeaderBar.scss';

const SEARCH_DELAY_MS = 400;

interface UserOrganizationsHeaderProps {
  isLoading?: boolean;
  isReloading?: boolean;
  onSearch: (searchText: string) => void;
  totalChecked: number;
  totalRecords: number;
}

const UserOrganizationsHeader: React.FC<UserOrganizationsHeaderProps> = ({
  isLoading,
  isReloading,
  onSearch,
  totalChecked,
  totalRecords,
}: UserOrganizationsHeaderProps) => {
  const [searchText, setSearchText] = useState('');
  const debouncedOnSearch = debounce(
    (text: string) => onSearch?.(text),
    SEARCH_DELAY_MS
  );
  const handleSearchFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!debouncedOnSearch) return;
    setSearchText(event.target.value);
    debouncedOnSearch(event.target.value);
  };
  const isInALoadingState = isLoading || isReloading;
  const unitText = `${totalChecked} of ${totalRecords} checked`;
  const displayText = isInALoadingState ? 'Loading...' : unitText;
  return (
    <div className="CollectionHeaderBar">
      <div className="CollectionHeaderBar-text">{displayText}</div>
      <div className="CollectionHeaderBar-actions">
        <TextField
          variant="outlined"
          margin="dense"
          value={searchText}
          onChange={handleSearchFieldChange}
          placeholder="Search"
        />
      </div>
    </div>
  );
};

export default UserOrganizationsHeader;
