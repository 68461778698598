import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Create, Delete } from '@material-ui/icons';
import { TableCellProps } from 'react-virtualized';

import Table from 'components/shared/Table';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import Alert, { useAlert } from 'components/shared/Alert';
import CollectionHeaderBar from 'components/shared/CollectionHeaderBar';
import ConfirmationDialog, {
  useConfirmationDialog,
} from 'components/shared/ConfirmationDialog';

import {
  useTaskDefinitions,
  useDeleteTaskDefinition,
} from 'api/organizations/plugins/reconvelocity/tasks';
import { useOrganizationUsers } from 'api';
import useSession from 'api/session';
import { ReconVelocityTaskDefinition } from 'models/plugins/reconVelocityPlugin';
import { Organization, User } from 'models';
import PermissionGate from 'components/PermissionGate';
import permissions from 'common/permissions';
import EditTaskTemplateDialog from './EditTaskTemplateDialog';

interface TasksViewProps {
  orgId: Organization['id'];
  fetchOnMount: boolean;
}

var TasksView = ({ orgId, fetchOnMount }: TasksViewProps) => {
  const {
    data: taskDefinitions,
    isLoading: taskDefinitionsIsLoading,
    isError: taskDefinitionsError,
  } = useTaskDefinitions(orgId, { enabled: fetchOnMount });
  const { deleteTaskDefinitionAsync } = useDeleteTaskDefinition(orgId);
  const {
    data: organizationUsersData,
    isLoading: organizationUsersAreLoading,
    isError: organizationUsersError,
  } = useOrganizationUsers(orgId);
  const organizationUsersDataPages = organizationUsersData?.pages;
  const organizationUsers = useMemo(
    () =>
      organizationUsersDataPages?.reduce(
        (acc: User[], pageUsers) => [...acc, ...pageUsers.data],
        []
      ) ?? [],
    [organizationUsersDataPages]
  ); // TODO use Memo or handle flat users differently
  const {
    data: session,
    isLoading: sessionIsLoading,
    isError: sessionError,
  } = useSession();

  const { isAlertOpen, alertMessage, openAlert, closeAlert, variant } =
    useAlert();
  const {
    isConfirmationDialogOpen,
    isConfirmationLoading,
    onAccept,
    onDeny,
    confirmationMessage,
    openConfirmationDialog,
  } = useConfirmationDialog();

  const [editingTaskDefinition, setEditingTaskDefinition] =
    useState<ReconVelocityTaskDefinition>();

  const isLoading =
    taskDefinitionsIsLoading || organizationUsersAreLoading || sessionIsLoading;

  useEffect(() => {
    if (taskDefinitionsError || organizationUsersError || sessionError) {
      openAlert('There was an API error loading the task definitions', 'error');
    }
  }, [taskDefinitionsError, organizationUsersError, sessionError, openAlert]);

  function openEditTaskTemplateDialog(
    taskDefinition?: ReconVelocityTaskDefinition
  ) {
    setEditingTaskDefinition({
      title: '',
      description: '',
      requiresApproval: true,
      addByDefaultForNewRecon: false,
      defaultAssigneeId: null,
      defaultPartsCost: {
        amount: 0,
        currency: 'USD',
      },
      defaultLaborRate: {
        amount: 0,
        currency: 'USD',
      },
      defaultLaborCost: {
        amount: 0,
        currency: 'USD',
      },
      defaultLaborHours: 0,
      tags: [],

      ...(taskDefinition ?? {}),
    });
  }

  const handleDeleteClick = useCallback(
    (taskDefinition: ReconVelocityTaskDefinition) => {
      openConfirmationDialog({
        primaryButtonLabelOverride: 'Remove',
        onAcceptOverride: async () => {
          await deleteTaskDefinitionAsync(taskDefinition.id);
        },
        messageOverride: `Remove task template ${taskDefinition.title}?`,
      });
    },
    [deleteTaskDefinitionAsync, openConfirmationDialog]
  );

  const handleAdd = () => openEditTaskTemplateDialog();

  // TODO:
  // const handleSearch = () => navigate('search');
  // const handleImport = () =>
  //   openAlert(`Import Tasks not implemented yet`, `info`);
  // const handleExport = () =>
  //   openAlert(`Export Tasks not implemented yet`, `info`);

  const closeEditTaskTemplateDialog = () => {
    setEditingTaskDefinition(undefined);
  };

  const defaultLaborRateAmount = 0;

  const totalLaborPriceAmount = (
    taskDefinition: ReconVelocityTaskDefinition
  ) => {
    if (taskDefinition.defaultLaborCost?.amount)
      return taskDefinition.defaultLaborCost.amount;
    return (
      taskDefinition.defaultLaborHours *
      (taskDefinition.defaultLaborRate?.amount ?? defaultLaborRateAmount)
    );
  };

  const totalPriceAmount = useCallback(
    (taskDefinition: ReconVelocityTaskDefinition) =>
      totalLaborPriceAmount(taskDefinition) +
      (taskDefinition.defaultPartsCost?.amount ?? 0),
    []
  );

  const hasAddTaskPermission = session?.permissions.includes(
    permissions.ORGS_PLUGINS_RECONVELOCITY_TASK_DEFINITIONS_CREATE
  );

  const taskDefinitionsData = taskDefinitions?.data;

  const SafeTable = useCallback(() => {
    if (!taskDefinitionsData) {
      return null;
    }

    return (
      <Table
        items={taskDefinitionsData}
        columns={[
          {
            heading: 'Task Type',
            dataKey: 'title',
            flexGrow: 2,
          },
          {
            heading: 'Default Assignee',
            content: ({
              rowData: taskDefinition,
            }: {
              rowData: ReconVelocityTaskDefinition;
            }) => {
              if (
                taskDefinition.defaultAssigneeId &&
                organizationUsersAreLoading
              ) {
                return <LoadingIndicator size={15} color="secondary" />;
              }

              const defaultUser = organizationUsers.find(
                ({ id }) => id === taskDefinition.defaultAssigneeId
              );

              if (!defaultUser) return null;

              return (
                <span>
                  {defaultUser.firstName} {defaultUser.lastName}
                </span>
              );
            },
            flexGrow: 1,
          },
          {
            heading: 'Labor',
            dataKey: 'labor',
            value: ({ rowData: taskDefinition }) =>
              totalLaborPriceAmount(taskDefinition),
            flexGrow: 0,
            fixedDecimalScale: true,
            decimalScale: 2,
            thousandSeparator: true,
            className: 'align-right',
            headerClassName: 'align-right',
          },
          {
            heading: 'Parts',
            dataKey: 'defaultPartsCost.amount',
            flexGrow: 0,
            fixedDecimalScale: true,
            decimalScale: 2,
            thousandSeparator: true,
            className: 'align-right',
            headerClassName: 'align-right',
          },
          {
            heading: 'Total',
            dataKey: 'total',
            value: ({ rowData: taskDefinition }) =>
              totalPriceAmount(taskDefinition),
            flexGrow: 0,
            fixedDecimalScale: true,
            decimalScale: 2,
            thousandSeparator: true,
            className: 'align-right',
            headerClassName: 'align-right',
          },
          {
            heading: 'Tags',
            dataKey: 'tags',
            flexGrow: 8,
          },
          {
            heading: '',
            dataKey: 'actions',
            content: ({ rowData: taskDefinition }: TableCellProps) => (
              <div>
                <PermissionGate
                  permissions={[
                    permissions.ORGS_PLUGINS_RECONVELOCITY_TASK_DEFINITIONS_UPDATE,
                  ]}
                >
                  <Create
                    id="edit-tasks-button"
                    onClick={() => openEditTaskTemplateDialog(taskDefinition)}
                  />
                </PermissionGate>
                <PermissionGate
                  permissions={[
                    permissions.ORGS_PLUGINS_RECONVELOCITY_TASK_DEFINITIONS_DELETE,
                  ]}
                >
                  <Delete
                    id="delete-tasks-button"
                    onClick={() => handleDeleteClick(taskDefinition)}
                  />
                </PermissionGate>
              </div>
            ),
            width: 50,
            minWidth: 0,
          },
        ]}
      />
    );
  }, [
    handleDeleteClick,
    organizationUsers,
    organizationUsersAreLoading,
    taskDefinitionsData,
    totalPriceAmount,
  ]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div className="full-height flex-rows" id="tasks-overview-container">
      <CollectionHeaderBar
        count={taskDefinitions?.data?.length ?? 0}
        unit="Task Template"
        onAdd={hasAddTaskPermission ? handleAdd : undefined}
        // onImport={handleImport}
        // onExport={handleExport}
      />
      <div className="flex-grow">
        {isLoading ? <LoadingIndicator /> : <SafeTable />}
      </div>

      {editingTaskDefinition && (
        <EditTaskTemplateDialog
          orgId={orgId}
          taskDefinition={editingTaskDefinition}
          onClose={closeEditTaskTemplateDialog}
        />
      )}
      <Alert
        open={isAlertOpen}
        message={alertMessage}
        onClose={closeAlert}
        duration={3500}
        variant={variant}
      />
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        isLoading={isConfirmationLoading}
        onPrimaryButtonClick={onAccept}
        onSecondaryButtonClick={onDeny}
        message={confirmationMessage}
      />
    </div>
  );
};

export default TasksView;
