import api from 'api';
import {
  GetSubscriptionsRequestParams,
  AddSubscriptionRequestParams,
  UnsubscribeRequestParams,
  StepSubscriptionRequestParams,
} from 'api/organizations/users/notifications';
import {
  GetOrganizationUserNotificationSubscriptionsResponse,
  NotificationSubscription,
} from 'models';
import { createAsyncAction, ActionTypeFromCreators } from '../../helpers';

const actions = {
  getSubscriptions: 'Get Organization User Notification Subscriptions',
  addSubscription: 'Subscribe Organization User to Notifications',
  unsubscribe: 'Unsubscribe Notification from Organization User',
  subscribeStep: 'Subscribe Organization User to Step Notification',
  unsubscribeStep: 'Unsubscribe Step Notification from Organization User',
} as const;

export const {
  types: getSubscriptionsActionTypes,
  creators: getSubscriptionsActionCreators,
  action: getSubscriptions,
} = createAsyncAction<
  typeof actions.getSubscriptions,
  GetSubscriptionsRequestParams,
  GetOrganizationUserNotificationSubscriptionsResponse
>(actions.getSubscriptions, ({ orgId, userId }) =>
  api.organizations.users.notifications.getSubscriptions({ orgId, userId })
);

type GetSubscriptionsAction = ActionTypeFromCreators<
  typeof actions.getSubscriptions,
  GetSubscriptionsRequestParams,
  GetOrganizationUserNotificationSubscriptionsResponse,
  typeof getSubscriptionsActionCreators
>;

export const {
  types: addSubscriptionActionTypes,
  creators: addSubscriptionActionCreators,
  action: addSubscription,
} = createAsyncAction<
  typeof actions.addSubscription,
  AddSubscriptionRequestParams,
  NotificationSubscription
>(actions.addSubscription, ({ orgId, userId, subscription }) =>
  api.organizations.users.notifications.addSubscription({
    orgId,
    userId,
    subscription,
  })
);

type AddSubscriptionAction = ActionTypeFromCreators<
  typeof actions.addSubscription,
  AddSubscriptionRequestParams,
  NotificationSubscription,
  typeof addSubscriptionActionCreators
>;

export const {
  types: unsubscribeActionTypes,
  creators: unsubscribeActionCreators,
  action: unsubscribe,
} = createAsyncAction<
  typeof actions.unsubscribe,
  UnsubscribeRequestParams,
  Pick<UnsubscribeRequestParams, 'eventId' | 'eventName' | 'channelId'>
>(actions.unsubscribe, ({ orgId, userId, eventId, eventName, channelId }) =>
  api.organizations.users.notifications.unsubscribe({
    orgId,
    userId,
    eventId,
    eventName,
    channelId,
  })
);

type UnsubscribeAction = ActionTypeFromCreators<
  typeof actions.unsubscribe,
  UnsubscribeRequestParams,
  Pick<UnsubscribeRequestParams, 'eventId' | 'eventName' | 'channelId'>,
  typeof unsubscribeActionCreators
>;

export const {
  types: subscribeStepActionTypes,
  creators: subscribeStepActionCreators,
  action: subscribeStep,
} = createAsyncAction<
  typeof actions.subscribeStep,
  StepSubscriptionRequestParams,
  Pick<
    StepSubscriptionRequestParams,
    'eventId' | 'stepDefinitionId' | 'subscribed'
  >
>(actions.subscribeStep, ({ orgId, userId, eventId, stepDefinitionId }) =>
  api.organizations.users.notifications.setStepSubscription({
    orgId,
    userId,
    eventId,
    stepDefinitionId,
    subscribed: true,
  })
);

type SubscribeStepAction = ActionTypeFromCreators<
  typeof actions.subscribeStep,
  StepSubscriptionRequestParams,
  Pick<
    StepSubscriptionRequestParams,
    'eventId' | 'stepDefinitionId' | 'subscribed'
  >,
  typeof subscribeStepActionCreators
>;

export const {
  types: unsubscribeStepActionTypes,
  creators: unsubscribeStepActionCreators,
  action: unsubscribeStep,
} = createAsyncAction<
  typeof actions.unsubscribeStep,
  StepSubscriptionRequestParams,
  Pick<
    StepSubscriptionRequestParams,
    'eventId' | 'stepDefinitionId' | 'subscribed'
  >
>(actions.unsubscribeStep, ({ orgId, userId, eventId, stepDefinitionId }) =>
  api.organizations.users.notifications.setStepSubscription({
    orgId,
    userId,
    eventId,
    stepDefinitionId,
    subscribed: false,
  })
);

type UnsubscribeStepAction = ActionTypeFromCreators<
  typeof actions.unsubscribeStep,
  StepSubscriptionRequestParams,
  Pick<
    StepSubscriptionRequestParams,
    'eventId' | 'stepDefinitionId' | 'subscribed'
  >,
  typeof unsubscribeStepActionCreators
>;

export type NotificationsAction =
  | GetSubscriptionsAction
  | AddSubscriptionAction
  | UnsubscribeAction
  | SubscribeStepAction
  | UnsubscribeStepAction;
