import { configuredFetch } from './base';
import { HttpMethod } from './lib';

// TODO: consolidate with ApiResponse in models
export interface APIResponse<T> {
  data: T;
  meta?: {
    returnedRecords: number;
    totalRecords: number;
  };
  links?: any;
  errors?: any;
}

type DefaultQueryFnParams = { queryKey: string[] };

async function fetchApi(url: string, options: any = {}) {
  return configuredFetch(url, options);
  // TODO: if i remove this code Typescript goes bananas.
  const response = await fetch(url, options);

  if (!response.ok) {
    throw new Error(`Network response was not ok: ${response.status}`);
  }

  return response.json(); // TODO there are times we want the meta along with data.
}

// This function will be call anytime useQuery is used
export async function defaultQueryFn({ queryKey }: DefaultQueryFnParams) {
  const url = Array.isArray(queryKey) ? queryKey.join('/') : queryKey;
  return fetchApi(url).then((json) => (json.data ? json.data : json));
}

export async function defaultMetaQueryFn(url: string) {
  return fetchApi(url);
}

export async function defaultMutationFn(
  url: string,
  method: HttpMethod,
  data: any = {}
) {
  return fetchApi(url, {
    method,
    body: JSON.stringify(data),
  });
}
