import { mapValues } from 'lodash';
import { Dispatch, Action } from 'redux';

const actionStatuses = <const>['pending', 'fulfilled', 'rejected'];

interface AsyncActionTypes<ActionName extends string = string> {
  pending: `${ActionName}/pending`;
  fulfilled: `${ActionName}/fulfilled`;
  rejected: `${ActionName}/rejected`;
}

type AsyncAction<ActionName extends string = string, ParamsType = void> = (
  params: ParamsType
) => (
  dispatch: Dispatch<
    Action<AsyncActionTypes<ActionName>[keyof AsyncActionTypes<ActionName>]>
  >
) => Promise<void>;

type WithPayload<ParamsType = void, DataType = void> = {
  payload?: {
    params?: ParamsType;
    data?: DataType;
    error?: Error;
  };
};

type AsyncActionCreators<
  ActionName extends string = string,
  ParamsType = void,
  DataType = void
> = {
  [key in typeof actionStatuses[number]]: (
    payload?: WithPayload<ParamsType, DataType>['payload']
  ) => Action<
    AsyncActionTypes<ActionName>[keyof AsyncActionTypes<ActionName>]
  > &
    WithPayload<ParamsType, DataType>;
};

interface AsyncActionWithTypes<
  ActionName extends string = string,
  ParamsType = void,
  DataType = void
> {
  types: AsyncActionTypes<ActionName>;
  creators: AsyncActionCreators<ActionName, ParamsType, DataType>;
  action: AsyncAction<ActionName, ParamsType>;
}

export type ActionTypeFromCreators<
  ActionName extends string = string,
  ParamsType = void,
  DataType = void,
  CreatorsType extends AsyncActionCreators<
    ActionName,
    ParamsType,
    DataType
  > = AsyncActionCreators<ActionName, ParamsType, DataType>
> = ReturnType<CreatorsType[typeof actionStatuses[number]]>;

export function createAsyncAction<
  ActionName extends string = string,
  ParamsType = void,
  DataType = void
>(
  actionName: ActionName,
  asyncAction: (params: ParamsType) => Promise<DataType>
): AsyncActionWithTypes<ActionName, ParamsType, DataType> {
  const types = actionStatuses.reduce(
    (result, status) => ({
      ...result,
      [status]: `${actionName}/${status}`,
    }),
    {} as AsyncActionTypes<ActionName>
  );

  const creators = mapValues(
    types,
    (type) => (payload?: WithPayload<ParamsType, DataType>['payload']) => ({
      type,
      payload,
    })
  );

  const action =
    (params: ParamsType) =>
    async (
      dispatch: Dispatch<
        Action<AsyncActionTypes<ActionName>[keyof AsyncActionTypes<ActionName>]>
      >
    ) => {
      const { pending, fulfilled, rejected } = creators;

      try {
        dispatch(pending({ params }));

        const data = await asyncAction(params);

        dispatch(fulfilled({ params, data }));
      } catch (error) {
        dispatch(rejected({ params, error }));
      }
    };

  return { types, creators, action };
}

export default {
  createAsyncAction,
};
