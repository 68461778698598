import { PluginItem } from 'models/plugins';
import {
  getSupportedPluginsActionTypes,
  PluginsAction,
} from 'store/actions/utility/plugins';
import { FetchedData } from '../types';

export type PluginsState = FetchedData<PluginItem[] | undefined>;

export const initialState: PluginsState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export default function pluginsReducer(
  state = initialState,
  action: PluginsAction
) {
  switch (action.type) {
    case getSupportedPluginsActionTypes.pending: {
      return {
        ...state,
        loading: true,
      };
    }
    case getSupportedPluginsActionTypes.fulfilled: {
      const { data } = action.payload ?? {};
      return {
        ...state,
        loading: false,
        data,
        error: undefined,
      };
    }
    case getSupportedPluginsActionTypes.rejected: {
      const { error } = action.payload ?? {};
      return {
        ...state,
        loading: false,
        error,
      };
    }
    default:
      return state;
  }
}
