import {
  ChangeEvent,
  useState,
  useEffect,
  ReactElement,
  useCallback,
} from 'react';

import { useOrganizations } from 'api/organizations';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import testIds, { generateTestId } from 'common/testIds';
import { IconButton, TextField } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { OrganizationItemLink } from 'models';
import useSession from 'api/session';
import PERMISSIONS from 'common/permissions';
import OrganizationDialog from './OrganizationDialog';

import './OrganizationsView.scss';
import { useOnChange } from 'common/hooks';

const INDENT_PX = 20;
const SEARCH_DELAY_MS = 400;

const getOrgIdFromPathname = (pathname: string) => {
  const pathTokens = pathname.split('/');
  const orgIdIndex = pathTokens.indexOf('orgs') + 1;
  return pathTokens[orgIdIndex];
};

var OrganizationsView = () => {
  const { data: session } = useSession();
  const location = useLocation();

  const [organizationId, setOrganizationId] = useState<string>();
  const [searchText, setSearchText] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState(searchText);
  const { data, isLoading } = useOrganizations(searchQuery);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const onMount = useCallback(() => {
    if (location.pathname) {
      setOrganizationId(getOrgIdFromPathname(location.pathname));
    }
  }, [location.pathname]);

  useOnChange(onMount, null, { callOnMount: true });

  // debounce
  useEffect(() => {
    const id = setTimeout(() => {
      setSearchQuery(searchText);
      if (searchText !== searchQuery) {
        navigate(``);
      }
    }, SEARCH_DELAY_MS);

    return () => {
      clearTimeout(id);
    };
  }, [navigate, searchQuery, searchText]);

  const hasOrganizationCreatePermission = session?.permissions.includes(
    PERMISSIONS.ORGS_CREATE
  );

  const handleClick = (id: string) => {
    navigate(`${id}/overview`);
    setOrganizationId(id);
  };

  const displayOrganizations = (
    level: number,
    orgs?: OrganizationItemLink[]
  ): ReactElement => (
    <>
      {orgs?.map((organization: OrganizationItemLink) => (
        <div key={`org-${organization?.id}`}>
          <li
            id="organization-list-item"
            key={organization.id}
            onClick={() => handleClick(organization.id)}
            className={organization.id === organizationId ? 'selected' : ''}
          >
            <span
              data-vas-testing={`${generateTestId(
                testIds.ORGANIZATION_LIST_ITEM,
                {
                  organization: organization.name.replace(/ /g, '_'),
                  organizationType: organization.type?.toLowerCase(),
                }
              )}`}
              style={{
                paddingLeft: level * INDENT_PX,
              }}
            >
              {organization.name}
            </span>
          </li>
          {organization?.children &&
            organization.children.length > 0 &&
            displayOrganizations(level + 1, organization?.children)}
        </div>
      ))}
    </>
  );

  return (
    <div className="OrganizationsView page-container">
      <div className="OrganizationsSideBar full-height flex-rows">
        <div className="CollectionHeaderBar">
          <div className="CollectionHeaderBar-text">
            {`${data?.meta?.totalRecords ?? 'Loading'} Organizations`}
          </div>
          <div className="CollectionHeaderBar-actions">
            <TextField
              variant="outlined"
              margin="dense"
              value={searchText}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setSearchText(event.target.value)
              }
              placeholder="Search"
            />
          </div>
          {hasOrganizationCreatePermission && (
            <IconButton
              onClick={() => {
                setIsDialogOpen(true);
              }}
              size="small"
            >
              <Add />
            </IconButton>
          )}
        </div>
        <div className="flex-grow">
          <ul className="OrganizationList">
            {isLoading ? (
              <LoadingIndicator />
            ) : (
              displayOrganizations(0, data?.data)
            )}
          </ul>
        </div>
      </div>
      {!organizationId && (
        <div
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            fontSize: 'larger',
            paddingTop: '50px',
          }}
        >
          Please select an organization
        </div>
      )}
      <Outlet />
      {isDialogOpen && (
        <OrganizationDialog onClose={() => setIsDialogOpen(false)} />
      )}
    </div>
  );
};

export default OrganizationsView;
