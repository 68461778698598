import * as yup from 'yup';

import {
  emailValidationSchema,
  phoneValidationSchema,
} from 'common/validation';

const addressValidationSchema = yup.object({
  street1: yup.string().min(1).required(),
  street2: yup.string().optional().nullable(),
  city: yup.string().min(1).required(),
  state: yup.object({
    id: yup.string().required(),
  }),
  postalCode: yup.string().min(1).required(),
});

const contactValidationSchema = yup.object({
  firstName: yup.string(),
  lastName: yup.string(),
  jobTitle: yup.string(),
  email: emailValidationSchema.notRequired(),
  mobilePhone: yup.object({
    number: phoneValidationSchema.notRequired(),
  }),
});

const dayValidationSchema = yup
  .object({
    openTime: yup.string().required(),
    closeTime: yup
      .string()
      .test(
        'open_close_validation',
        'Close time should be after Open time',
        (closeTime, schema) =>
          schema.parent.openTime.localeCompare(closeTime) < 0
      )
      .required(),
  })
  .nullable()
  .notRequired()
  .default(undefined);

const departmentValidationSchema = yup.object({
  phone: phoneValidationSchema.nullable().notRequired(),
  email: emailValidationSchema.nullable().notRequired(),
  schedule: yup
    .object({
      monday: dayValidationSchema,
      tuesday: dayValidationSchema,
      wednesday: dayValidationSchema,
      thursday: dayValidationSchema,
      friday: dayValidationSchema,
      saturday: dayValidationSchema,
      sunday: dayValidationSchema,
    })
    .nullable(),
});

const validationSchema = yup.object({
  name: yup.string().min(1).required(),
  parent: yup
    .object({
      id: yup.string().nullable(),
    })
    .nullable(),
  websiteUrl: yup.string().url('Enter a valid URL').required(),
  timezone: yup.object({
    label: yup.string().required(),
    timezone: yup.string().required(),
  }),
  mainDepartment: yup.object({
    phone: phoneValidationSchema,
    email: emailValidationSchema,
  }),
  billingAddress: addressValidationSchema.required(),
  billingContact: contactValidationSchema,
  mailingAddress: addressValidationSchema.required(),
  mailingContact: contactValidationSchema,
  salesDepartment: departmentValidationSchema,
  partsDepartment: departmentValidationSchema,
  serviceDepartment: departmentValidationSchema,
});

export default validationSchema;
