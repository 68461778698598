import React, { useState, useEffect } from 'react';
import {
  AutoSizer,
  Column as VirtualizedColumn,
  ColumnProps as VirtualizedColumnProps,
  defaultTableRowRenderer,
  Index,
  InfiniteLoader,
  InfiniteLoaderChildProps,
  RowMouseEventHandlerParams,
  Table as VirtualizedTable,
  TableRowRenderer as VirtualizedTableRowRenderer,
} from 'react-virtualized';
import Skeleton from '@material-ui/lab/Skeleton';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { isUndefined, get, pick, isEmpty, isBoolean } from 'lodash';

import { SelectionState } from 'common/types';
import {
  CellProps,
  OuterSelection,
  RowProps,
  SupportedNumberFormatProps,
  supportedNumberFormatPropNames,
  TableProps,
} from './types';

import 'react-virtualized/styles.css';
import './Table.scss';
import LoadingIndicator from '../LoadingIndicator';

const DEFAULT_COLUMN_WIDTH = 100;
const DEFAULT_ROW_HEIGHT = 30;

const LOADING_ITEM = { __Admin_Table_itemLoading: true };
const SKELETON_ROW_COUNT = 100;

let lastTableId = 0;

const useSpecificSelectionState = <T,>({
  outerSelectionState,
  outerlUpdateSelectionState,
}: OuterSelection<T>): [
  selectionState: SelectionState<T>,
  updateSelectionState: (newSelection: Partial<SelectionState<T>>) => void
] => {
  const [internalSelectionState, setInternalSelectionState] = useState<
    SelectionState<T>
  >({ index: undefined, item: undefined });

  const internalUpdateSelectionState = (
    newSelection: Partial<SelectionState<T>>
  ) =>
    setInternalSelectionState((prevState) => ({
      ...prevState,
      ...newSelection,
    }));

  if (outerSelectionState && outerlUpdateSelectionState) {
    return [outerSelectionState, outerlUpdateSelectionState];
  }

  return [internalSelectionState, internalUpdateSelectionState];
};

const Table = <T extends unknown>({
  collection,
  columns,
  height,
  width,
  headerHeight,
  rowHeight,
  rowClassName,
  selectable,
  defaultSelectedIndex,
  selectedRowClassName,
  selectedRowStyle,
  hideHeader,
  onRowClick,
  loadingPlaceholder,
  showLoadingSkeletons,
  skeletonRows,
  totalRowCount = 0,
  isInfiniteLoading,
  fetchMoreRows,
  ...otherProps
}: TableProps<T>) => {
  const [selectionState, updateSelectionState] = useSpecificSelectionState({
    outerlUpdateSelectionState: otherProps.updateSelectionState,
    outerSelectionState: otherProps.selectionState,
  });

  const items = (collection?.data || otherProps.items) as T[];
  if (!items) {
    throw new Error('Must provide collection or items prop to Table component');
  }

  const [id] = useState(`Admin.Table${(lastTableId += 1)}`);

  const getItemAtIndex = (index: number) =>
    items?.length ? items[index] : undefined;

  const rowIdKey = 'id';

  const handleRowClick = selectable
    ? (info: RowMouseEventHandlerParams) => {
        updateSelectionState({ index: info.index, item: items[info.index] });
        return onRowClick?.(info);
      }
    : onRowClick;

  const isLoading = collection?.data.totalLength === -1;

  useEffect(() => {
    if (
      !selectable ||
      isLoading ||
      isUndefined(defaultSelectedIndex) ||
      (defaultSelectedIndex && items.length <= defaultSelectedIndex)
    ) {
      updateSelectionState({ index: undefined, item: undefined });
    } else {
      updateSelectionState({
        index: defaultSelectedIndex,
        item: items[defaultSelectedIndex],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const numberFormatPropsByColumnIndex = columns.map((column) => {
    const numberFormatProps: SupportedNumberFormatProps & {
      format?: NumberFormatProps['format'];
    } = pick(column, supportedNumberFormatPropNames);
    if (!column.numberFormat && isEmpty(numberFormatProps)) return undefined;
    if (!isBoolean(column.numberFormat)) {
      numberFormatProps.format = column.numberFormat;
    }
    return numberFormatProps;
  });

  const Row = React.useCallback(
    ({ className: outerClassName, index, ...otherRowProps }: RowProps) => {
      let className = outerClassName;
      if (index === selectionState.index) {
        const appropriateSelectedClassName =
          typeof selectedRowClassName === 'function'
            ? selectedRowClassName({ index })
            : selectedRowClassName;
        className = `${outerClassName} Admin_Table__selectedRow ${appropriateSelectedClassName}`;
      }
      return defaultTableRowRenderer({
        className,
        index,
        ...otherRowProps,
      }) as React.ReactElement;
    },
    [selectionState, selectedRowClassName]
  );

  const Cell = React.useCallback(
    ({
      content,
      value: valueFunction,
      columnIndex,
      dataKey,
      rowIndex,
      rowData,
      ...otherCellProps
    }: CellProps) => {
      const item = getItemAtIndex(rowIndex);
      if (!item && showLoadingSkeletons) {
        return <Skeleton />;
      }

      let value;
      if (content || valueFunction) {
        const contentProps = {
          content,
          columnIndex,
          dataKey,
          rowIndex,
          rowData: item,
          ...otherCellProps,
        };
        if (content) {
          return typeof content === 'function'
            ? content(contentProps)
            : content;
        }
        value = valueFunction?.(contentProps);
      } else {
        value = get(item, dataKey);
      }

      const numberFormatProps = numberFormatPropsByColumnIndex[columnIndex];

      return numberFormatProps ? (
        <NumberFormat value={value} displayType="text" {...numberFormatProps} />
      ) : (
        <div>{value}</div>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getItemAtIndex, showLoadingSkeletons]
  );

  const rowCount =
    (showLoadingSkeletons &&
      collection?.data.totalLength === -1 &&
      (skeletonRows || SKELETON_ROW_COUNT)) ||
    otherProps.rowCount ||
    Math.max(0, items.length);

  const isReloading = collection?.data.isReloading;

  const className = [
    'Admin_Table full-height flex-columns',
    selectable ? 'Admin_Table__selectable' : '',
  ].join(' ');

  const getRowKeyForRowData = (rowData: any, defaultKey: string | number) =>
    `${id}.Row-${rowData?.[rowIdKey] || defaultKey}`;

  const rowGetter = ({ index }: Index) => getItemAtIndex(index) || LOADING_ITEM;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const rowRenderer = React.useCallback(
    (({ index, key, ...otherRowRendererProps }) => {
      const mergedKey = getRowKeyForRowData(items[index], key);
      return (
        <Row
          index={index}
          rowKey={mergedKey}
          key={mergedKey}
          {...otherRowRendererProps}
        />
      );
    }) as VirtualizedTableRowRenderer,
    [getRowKeyForRowData, items]
  );

  const virtualizedTableProps = {
    ...otherProps,
    rowGetter,
    rowRenderer,
    key: `${id}-VirtualizedTable`,
    headerHeight: headerHeight || DEFAULT_ROW_HEIGHT,
    rowHeight: rowHeight || DEFAULT_ROW_HEIGHT,
    rowCount,
    onRowClick: handleRowClick,
    rowClassName: `${rowClassName} ${
      isReloading ? 'Admin_Table__reloadingRow' : ''
    }`,
    disableHeader: hideHeader,
  };

  const renderVirtualizedTable = (
    tableHeight: number,
    tableWidth: number,
    infiniteLoaderProps?: InfiniteLoaderChildProps
  ) => (
    <VirtualizedTable
      height={tableHeight}
      width={tableWidth}
      ref={infiniteLoaderProps?.registerChild}
      onRowsRendered={infiniteLoaderProps?.onRowsRendered}
      {...virtualizedTableProps}
    >
      {columns.map(
        (
          {
            content,
            value,
            heading,
            width: specificColumnWidth,
            dataKey: columnDataKey,
            ...otherColumnProps
          },
          index
        ) => {
          const columnProps: VirtualizedColumnProps = {
            dataKey: columnDataKey || index.toString(),
            width: specificColumnWidth || DEFAULT_COLUMN_WIDTH,
            label: heading,
            /* eslint-disable react/prop-types */
            cellRenderer: ({
              dataKey,
              rowData,
              rowIndex,
              ...otherCellRendererProps
            }) => {
              /* eslint-enable react/prop-types */
              const key = `${getRowKeyForRowData(
                rowData,
                rowIndex
              )}.Cell-${dataKey}`;
              return (
                <Cell
                  key={key}
                  content={content}
                  value={value}
                  dataKey={dataKey}
                  rowData={rowData}
                  rowIndex={rowIndex}
                  {...otherCellRendererProps}
                />
              );
            },
            ...otherColumnProps,
          };
          return (
            <VirtualizedColumn
              key={`${id}.Column-${columnDataKey}`}
              {...columnProps}
            />
          );
        }
      )}
    </VirtualizedTable>
  );

  const renderAutoSizedVirtualizedTable = (
    infiniteLoaderProps?: InfiniteLoaderChildProps
  ) => (
    <AutoSizer {...virtualizedTableProps}>
      {({ height: autoHeight, width: autoWidth }: any) =>
        renderVirtualizedTable(
          height || autoHeight,
          width || autoWidth,
          infiniteLoaderProps
        )
      }
    </AutoSizer>
  );

  const renderInfiniteLoadingVirtualizedTable = () => {
    if (!fetchMoreRows || !rowCount || !items) return <LoadingIndicator />;

    return (
      <InfiniteLoader
        rowCount={totalRowCount}
        isRowLoaded={({ index }) => !!items[index]}
        loadMoreRows={fetchMoreRows}
      >
        {(props) => renderAutoSizedVirtualizedTable(props)}
      </InfiniteLoader>
    );
  };

  if (isInfiniteLoading) {
    return renderInfiniteLoadingVirtualizedTable();
  }

  return (
    <div className={className}>
      {height && width
        ? renderVirtualizedTable(height, width)
        : renderAutoSizedVirtualizedTable()}
      {isLoading && loadingPlaceholder && (
        <div className="Admin__loadingPlaceholder">{loadingPlaceholder}</div>
      )}
    </div>
  );
};

export default Table;
