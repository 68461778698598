import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { BrochureManagerContext } from './brochureContext';
import { useBrochureStyles } from './materialStyles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export var BrochureManagerToolbar = () => {
  const classes = useBrochureStyles();
  const { state, dispatch } = React.useContext(BrochureManagerContext);
  const { data } = state;

  const handleMakeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selected = data?.find((i) => i.vehicleMake === event.target.value);
    dispatch({ type: 'setMake', payload: selected });
  };

  const handleModelChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selected = state.modelOptions.find(
      (i) => i.vehicleModel === event.target.value
    );
    dispatch({ type: 'setModel', payload: selected });
  };

  const handleYearChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selected = state.yearOptions.find(
      (i) => i.vehicleYear === event.target.value
    );
    dispatch({ type: 'setYear', payload: selected });
  };
  if (!state.data) return null;
  const selectProps = {
    input: <Input />,
    renderValue: (selected: unknown) => <>{selected}</>,
    MenuProps: {
      PaperProps: {
        style: { maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP },
      },
      className: classes.selectEmpty,
    },
  };

  return (
    <div className="CollectionHeaderBar">
      <div className="flex-columns" style={{ alignItems: 'center', gap: 10 }}>
        <div style={{ width: 240 }}>
          <h2>Manage Brochures</h2>
        </div>
        <FormControl className={classes.formControl}>
          <InputLabel id="make-select-label">Make</InputLabel>
          <Select
            {...selectProps}
            labelId="make-select"
            value={state.vehicleMake}
            onChange={handleMakeChange}
          >
            <MenuItem key="initial-make" value="">
              ---
            </MenuItem>
            {data.map((m) => (
              <MenuItem key={m.vehicleMake} value={m.vehicleMake}>
                {m.vehicleMake}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="make-select-label">Model</InputLabel>
          <Select
            {...selectProps}
            labelId="make-select"
            value={state.vehicleModel}
            onChange={handleModelChange}
          >
            <MenuItem key="initial-model" value="">
              ---
            </MenuItem>
            {state.modelOptions.map((m: any) => (
              <MenuItem key={m.vehicleModel} value={m.vehicleModel}>
                {m.vehicleModel}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="year-select-label">Year</InputLabel>
          <Select
            {...selectProps}
            labelId="year-select-label"
            id="demo-mutiple-checkbox"
            value={state.vehicleYear}
            onChange={handleYearChange}
          >
            <MenuItem key="initial-year" value="">
              ---
            </MenuItem>
            {state.yearOptions.map((year: any) => (
              <MenuItem key={year.vehicleYear} value={year.vehicleYear}>
                {year.vehicleYear}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default BrochureManagerToolbar;
