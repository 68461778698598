import {
  useQueryClient,
  useQuery,
  useQueries,
  useInfiniteQuery,
  useMutation,
} from 'react-query';
import { getAPIHeaders, defaultMetaQueryFn } from 'api';
import { configuredFetch } from 'api/base';
import {
  Organization,
  Region,
  User,
  OrganizationGroup,
  OrganizationItemLink,
} from 'models';
import { defaultMutationFn, APIResponse } from 'api/useAPI';

import users from './users';
import plugins from './plugins';
import groups from './groups';

export type OrganizationOverviewRequestParams = {
  orgId: Organization['id'];
};

export type UpdateOrganizationOverviewRequestParams = {
  org: Partial<Organization>;
};

/**
 * Get Organizations Flat
 * Endpoint: GET `/orgs/flat`
 */
export function useOrganizationsFlat(s?: string) {
  const path = `/orgs/flat${s ? `?s=${s}` : ''}`;
  return useQuery<APIResponse<OrganizationItemLink[]>>(
    path,
    () => defaultMetaQueryFn(path),
    {
      // placeholderData: { data: { data: [], meta: { returnedRecords: 0, totalRecords: 0}} },
    }
  );
}

/**
 * Get Organizations Flat Rooftops
 * Endpoint: GET `/orgs/flat?orgType=ROOFTOP`
 */
export function useOrganizationsFlatRooftops(s?: string) {
  const path = `/orgs/flat?orgType=ROOFTOP${s ? `&s=${s}` : ''}`;
  return useQuery<APIResponse<OrganizationItemLink[]>>(
    path,
    () => defaultMetaQueryFn(path),
    {
      // placeholderData: { data: { data: [], meta: { returnedRecords: 0, totalRecords: 0}} },
    }
  );
}

/**
 * Get Organizations
 * Endpoint: GET `/orgs/hierarchy`
 */
export function useOrganizations(s?: string) {
  const path = `/orgs/hierarchy${s ? `?s=${s}` : ''}`;
  return useQuery<APIResponse<OrganizationItemLink[]>>(
    path,
    () => defaultMetaQueryFn(path),
    {
      // placeholderData: [],
    }
  );
}

export function useOrganization(organizationId?: string) {
  const path = `/orgs/${organizationId}`;
  const enabled = !!organizationId && organizationId.length > 10;
  return useQuery<Organization>(path, {
    enabled,
  });
}

// Endpoint: PUT `/orgs/{organizationId}`
export function useUpdateOrganization(organizationId: string) {
  const queryClient = useQueryClient();
  const mutation = useMutation((data: any) => {
    const path = `/orgs/${organizationId}`;
    return defaultMutationFn(path, 'PUT', data);
  });

  const updateOrganizationAsync = async (data: any) => {
    const response = await mutation.mutateAsync(data);
    await queryClient.invalidateQueries(`/orgs/${organizationId}`);
    await queryClient.invalidateQueries('/orgs');
    await queryClient.invalidateQueries('/orgs/hierarchy');
    return response;
  };

  return {
    ...mutation,
    updateOrganizationAsync,
  };
}

// Endpoint: POST `/orgs/`
export function useCreateOrganization() {
  const queryClient = useQueryClient();
  const mutation = useMutation((data: any) => {
    const path = '/orgs/';
    return defaultMutationFn(path, 'POST', data);
  });

  const createOrganizationAsync = async (data: any) => {
    const response = await mutation.mutateAsync(data);
    await queryClient.invalidateQueries('/orgs');
    await queryClient.invalidateQueries('/orgs/hierarchy');
    return response;
  };

  return {
    ...mutation,
    createOrganizationAsync,
  };
}

/**
 * Get Organization Groups
 * Endpoint: GET `/orgs/{organizationId}/groups`
 */
export function useOrganizationGroups(organizationId?: string) {
  const path = `/orgs/${organizationId}/groups`;
  return useQuery<OrganizationGroup[]>(path, {
    placeholderData: [],
    enabled: !!organizationId,
  });
}

/**
 * Get Organization Group Users
 * Endpoint: GET `/orgs/{organizationId}/groups/{groupId}/users?pageSize=50&startIndex=0&s=`
 */
export function useGroupUsers(
  organizationId: string,
  groupId: string,
  searchText: string = '',
  startIndex: number = 0,
  pageSize: number = 50
) {
  const searchParam = searchText ? `&s=${searchText}` : '';
  const url = [
    `/orgs/${organizationId}/groups`,
    `/${groupId}/users`,
    `?startIndex=${startIndex}&pageSize=${pageSize}${searchParam}`,
  ];
  const defaultParams = { startIndex, pageSize };
  return useInfiniteQuery<GetUserQueryData, Error>(
    url,
    async ({ pageParam = defaultParams }) =>
      defaultMetaQueryFn(
        `/orgs/${organizationId}/groups/${groupId}/users?startIndex=${pageParam.startIndex}&pageSize=${pageParam.pageSize}${searchParam}`
      ),
    {
      getNextPageParam: (lastPage, pages) => {
        const totalUsersLoaded = pages.reduce(
          (acc, page) => acc + page.meta.returnedRecords,
          0
        );

        if (totalUsersLoaded === lastPage.meta.totalRecords) return;

        return {
          startIndex: pages.length * 50,
          pageSize,
        };
      },
      enabled: !!organizationId && !!groupId,
    }
  );
}

export function useRegions() {
  const path = '/utility/regions';
  return useQuery<Region[]>(path);
}

/**
 * Get Organization Users
 * Endpoint: GET `/orgs/{organizationId}/users?pageSize=50&startIndex=0&s=`
 */
export interface GetUserQueryData {
  data: User[];
  meta: {
    returnedRecords: number;
    totalRecords: number;
  };
}

export function useOrganizationUsers(
  organizationId: string,
  searchText: string = '',
  startIndex: number = 0,
  pageSize: number = 50
) {
  const searchParam = searchText ? `&s=${searchText}` : '';
  const url = [
    `/orgs/${organizationId}/users`,
    `?startIndex=${startIndex}&pageSize=${pageSize}${searchParam}`,
  ];
  const defaultParams = { startIndex, pageSize };
  return useInfiniteQuery<GetUserQueryData, Error>(
    url,
    async ({ pageParam = defaultParams }) =>
      defaultMetaQueryFn(
        `/orgs/${organizationId}/users?startIndex=${pageParam.startIndex}&pageSize=${pageParam.pageSize}${searchParam}`
      ),
    {
      getNextPageParam: (lastPage, pages) => {
        const totalUsersLoaded = pages.reduce(
          (acc, page) => acc + page.meta.returnedRecords,
          0
        );

        if (totalUsersLoaded === lastPage.meta.totalRecords) return;

        return {
          startIndex: pages.length * 50,
          pageSize,
        };
      },
      enabled: !!organizationId,
    }
  );
}

/**
 * Get Organization Users By ID
 * Endpoint: GET `/orgs/{organizationId}/user/{userId}
 */
export const ORG_USERS_BY_ID_KEY = 'orgUsersById';

export const getOrgUser = async (orgId: string, userId: User['id']) => {
  const { host, options } = getAPIHeaders('GET');
  const url = `/orgs/${orgId}/users/${userId}`;
  const response = await fetch(host + url, {
    ...options,
  });

  if (!response.ok) {
    throw new Error(`Failed to fetch user(${userId}) for org: ${orgId}`);
  }

  const { data } = await response.json();
  return data;
};
export function useOrganizationUsersById(orgId: string, userIds: User['id'][]) {
  return useQueries(
    userIds.map((userId) => ({
      queryKey: [ORG_USERS_BY_ID_KEY, orgId, userId],
      queryFn: () => getOrgUser(orgId, userId),
    }))
  );
}

function organizationOverviewUrl({ orgId }: OrganizationOverviewRequestParams) {
  return `/orgs/${orgId}`;
}

/**
 * Get Organization Overview
 * Endpoint: GET /orgs/{orgId}
 */
async function getOrganizationOverview({
  orgId,
}: OrganizationOverviewRequestParams) {
  const url = organizationOverviewUrl({ orgId });

  const { data } = await configuredFetch<Organization>(url);
  return data;
}

/**
 * Update Organization Overview
 * Endpoint: PUT /orgs/{orgId}
 */
async function updateOrganizationOverview({
  org,
}: UpdateOrganizationOverviewRequestParams) {
  if (!org.id) return Promise.reject();
  const url = organizationOverviewUrl({ orgId: org.id });
  const { data } = await configuredFetch<Organization>(url, {
    method: 'PUT',
    body: JSON.stringify(org),
  });
  return data;
}

export * from './plugins';

export default {
  getOrganizationOverview,
  updateOrganizationOverview,

  users,
  plugins,
  groups,
};
