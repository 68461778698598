import React, { useEffect, useState } from 'react';
import {
  Box,
  Divider,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import './Content.scss';
import strings from 'common/strings';
import { VelocityEngagePluginItem } from '../../../../../../models';
import GoogleAnalyticsTrackingIDSetting from './components/GoogleAnalyticsTrackingIDSetting';
import GoogleAnalyticsMethodSelector from './components/GoogleAnalyticsMethodSelector';
import CurrencyTextField from 'components/shared/CurrencyTextField';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    formInputFieldControl: {
      marginTop: theme.spacing(2),
      width: '100%',
      minwidth: '100%',
    },
    formToggleControl: {
      margin: theme.spacing(1),
    },
    opcodeList: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    topBarSpacing: {
      marginTop: theme.spacing(2),
    },
  })
);

interface ContentProps {
  engagePluginSettings: {
    data: VelocityEngagePluginItem | undefined;
    loading: boolean;
    error?: Error;
  };
  saveEngageSettings: (engageSettings: VelocityEngagePluginItem) => void;
  setIsUnsavedChanges: (open: boolean) => void;
  setUnsavedChanges: (engageSettings: VelocityEngagePluginItem) => void;
}

var Content = ({
  engagePluginSettings,
  saveEngageSettings,
  setIsUnsavedChanges,
  setUnsavedChanges,
}: ContentProps) => {
  const classes = useStyles();
  const [ephemeralSettings, setEphemeralSettings] = useState(
    engagePluginSettings.data
  );
  const [touched, touch] = useState(false);
  const [opcode, setOpcode] = React.useState('');
  const [opcodeExclusionsList, setOpcodeExlusionList] = React.useState<
    string[]
  >([]);
  const [checkedOpcodes, setCheckedOpcodes] = React.useState<string[]>([]);
  const [checkAllOpcodes, setCheckAllOpcodes] = React.useState(false);

  useEffect(() => {
    setEphemeralSettings(engagePluginSettings.data);
    if (
      !engagePluginSettings.loading &&
      engagePluginSettings?.data?.settings?.EXCLUDED_RO_OPCODES?.value
    ) {
      setOpcodeExlusionList(
        engagePluginSettings?.data?.settings?.EXCLUDED_RO_OPCODES?.value?.split(
          ','
        )
      );
    }
  }, [engagePluginSettings]);

  const handleClickSave = async () => {
    try {
      if (ephemeralSettings) {
        saveEngageSettings(ephemeralSettings);
        setIsUnsavedChanges(false);
      }
    } catch (error) {
    } finally {
      touch(false);
    }
  };

  const handleSettingChange = async (
    settingValue: string | boolean,
    settingKey: string,
    settingType: string = 'STRING'
  ) => {
    touch(true);
    const updatedSettings: { [key: string]: any } = {
      ...ephemeralSettings?.settings,
      [settingKey]: {
        dataType: settingType,
        value: settingValue,
      },
    };
    setEphemeralSettings({
      ...ephemeralSettings,
      settings: updatedSettings,
    } as VelocityEngagePluginItem);

    setIsUnsavedChanges(true);
    if (ephemeralSettings) {
      setUnsavedChanges({
        ...ephemeralSettings,
        settings: updatedSettings,
      } as VelocityEngagePluginItem);
    }
  };

  const handleCheckboxChange =
    (settingKey: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      handleSettingChange(event.target.checked, settingKey, 'BOOL');
    };

  const handleMeetingInviteChange = async (displayMeetingInvite: boolean) => {
    touch(true);
    setEphemeralSettings({
      ...ephemeralSettings,
      displayMeetingInvite,
    } as VelocityEngagePluginItem);

    setIsUnsavedChanges(true);
    setUnsavedChanges({ ...ephemeralSettings } as VelocityEngagePluginItem);
  };

  const handleRoCostChange = async (showRoTotal: boolean) => {
    touch(true);
    setEphemeralSettings({
      ...ephemeralSettings,
      showRoTotal,
    } as VelocityEngagePluginItem);

    setIsUnsavedChanges(true);
    setUnsavedChanges({ ...ephemeralSettings } as VelocityEngagePluginItem);
  };

  const opcodeExclusionsToEphemeralSetting = (
    newOpcodeExclusionList: string[]
  ) => {
    setEphemeralSettings({
      ...ephemeralSettings,
      excludedRoOpcodes: newOpcodeExclusionList,
    } as VelocityEngagePluginItem);
  };

  const handleOpcodeKeyPress = (key: string) => {
    if (key === 'Enter') {
      const newOpcodeExclusionList = [...opcodeExclusionsList, opcode].sort();
      setOpcodeExlusionList(newOpcodeExclusionList);
      setOpcode('');
      opcodeExclusionsToEphemeralSetting(newOpcodeExclusionList);
      touch(true);
    }
  };

  const handleToggleOpcode = (value: string) => () => {
    const index = checkedOpcodes.indexOf(value);
    const newCheckedOpcodes = [...checkedOpcodes];
    if (index === -1) {
      newCheckedOpcodes.push(value);
    } else {
      newCheckedOpcodes.splice(index, 1);
    }
    setCheckedOpcodes(newCheckedOpcodes);
  };

  const handleRemoveFromOpcodeExclusionList = () => {
    const newOpcodeExclusionList = opcodeExclusionsList.filter(
      (opcode) => checkedOpcodes.indexOf(opcode) === -1
    );
    setOpcodeExlusionList(newOpcodeExclusionList);
    opcodeExclusionsToEphemeralSetting(newOpcodeExclusionList);
    setCheckedOpcodes([]);
    setCheckAllOpcodes(false);
    touch(true);
  };

  const handleToggleCheckAllOpcodes = () => {
    if (checkAllOpcodes) {
      setCheckedOpcodes([]);
      setCheckAllOpcodes(false);
    } else {
      setCheckedOpcodes([...opcodeExclusionsList]);
      setCheckAllOpcodes(true);
    }
  };

  const handlePriceAlertThresholdChange = (priceAlertThreshold: number) => {
    touch(true);
    setEphemeralSettings({
      ...ephemeralSettings,
      priceAlertThreshold,
    } as VelocityEngagePluginItem);

    setIsUnsavedChanges(true);
    setUnsavedChanges({ ...ephemeralSettings } as VelocityEngagePluginItem);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <div className={`button-wrapper ${classes.topBarSpacing}`}>
              <button
                style={
                  !touched
                    ? { boxShadow: 'none', opacity: 0.1, cursor: 'not-allowed' }
                    : {}
                }
                type="button"
                className="button submit"
                disabled={!touched}
                onClick={handleClickSave}
              >
                {'Save Changes'}
              </button>
            </div>
          </Box>
        </Grid>
      </Grid>
      <div className="contentContainer">
        {/* <Snackbar
          anchorOrigin={{
            vertical: `top`,
            horizontal: `center`,
          }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message={message}
          action={
            <>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        /> */}
        <div className="container">
          <div className="contentSetting-wrapper">
            <div className="contentForm-wrapper">
              <h2>{strings.CONTENT_SETTINGS}</h2>
              <FormControl
                component="fieldset"
                className={classes.formToggleControl}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          !ephemeralSettings
                            ? false
                            : ephemeralSettings?.displayMeetingInvite
                        }
                        onChange={(event) =>
                          handleMeetingInviteChange(event.target.checked)
                        }
                        name="display-meeting-invite"
                      />
                    }
                    label="Display Meeting Invite"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          !ephemeralSettings
                            ? false
                            : ephemeralSettings?.showRoTotal
                        }
                        onChange={(event) =>
                          handleRoCostChange(event.target.checked)
                        }
                        name="display-ro-cost"
                      />
                    }
                    label="Display Repair Order Totals"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          !ephemeralSettings
                            ? false
                            : ephemeralSettings?.settings
                                ?.ALLOW_PRICE_DROP_ALERTS?.value
                        }
                        onChange={handleCheckboxChange(
                          'ALLOW_PRICE_DROP_ALERTS'
                        )}
                        name="priceDroptAlert"
                      />
                    }
                    label="Allow Price Change Alerts"
                  />
                  <CurrencyTextField
                    variant="outlined"
                    margin="dense"
                    placeholder="$0.00"
                    name="priceAlertThreshold"
                    label="Price Alert Threshold"
                    value={ephemeralSettings?.priceAlertThreshold ?? 0}
                    onValueChange={({ floatValue: amount }) =>
                      amount !== undefined &&
                      handlePriceAlertThresholdChange(amount)
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          !ephemeralSettings
                            ? false
                            : ephemeralSettings?.settings
                                ?.VEHICLE_RECOMMENDATION?.value
                        }
                        onChange={handleCheckboxChange(
                          'VEHICLE_RECOMMENDATION'
                        )}
                        name="similarVehicles"
                      />
                    }
                    label="Display Similar Vehicles"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          !ephemeralSettings
                            ? false
                            : ephemeralSettings?.settings
                                ?.SHOW_SNAPCELL_IN_GALLERY?.value
                        }
                        onChange={handleCheckboxChange(
                          'SHOW_SNAPCELL_IN_GALLERY'
                        )}
                        name="showSnapcellInGallery"
                      />
                    }
                    label="Show Snapcell video in images gallery"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          !ephemeralSettings
                            ? false
                            : ephemeralSettings?.settings?.DISABLE_STOCK_IMAGES
                                ?.value
                        }
                        onChange={handleCheckboxChange('DISABLE_STOCK_IMAGES')}
                        name="disableStockImages"
                      />
                    }
                    label="Disable stock images on folio"
                  />
                </FormGroup>
              </FormControl>
            </div>
          </div>
        </div>
        <Divider />
        <h2>{strings.COMMUNITCATION_LINKS}</h2>
        <div className="communication-wrapper">
          <form className={classes.root} noValidate autoComplete="off">
            <TextField
              label="SMTP Domain"
              variant="outlined"
              size="small"
              value={ephemeralSettings?.settings?.SMTP_DOMAIN?.value || ''}
              onChange={(event) =>
                handleSettingChange(event.target.value, 'SMTP_DOMAIN')
              }
            />
            <TextField
              label="SMTP Reply To"
              variant="outlined"
              size="small"
              value={ephemeralSettings?.settings?.SMTP_REPLY_TO?.value || ''}
              onChange={(event) =>
                handleSettingChange(event.target.value, 'SMTP_REPLY_TO')
              }
            />
            <TextField
              label="CRM Inbox Email Address"
              variant="outlined"
              size="small"
              value={
                ephemeralSettings?.settings?.CRM_INBOX_EMAIL_ADDRESS?.value ||
                ''
              }
              onChange={(event) =>
                handleSettingChange(
                  event.target.value,
                  'CRM_INBOX_EMAIL_ADDRESS'
                )
              }
            />
            <TextField
              label="CRM Lead Source"
              variant="outlined"
              size="small"
              value={ephemeralSettings?.settings?.CRM_LEAD_SOURCE?.value || ''}
              onChange={(event) =>
                handleSettingChange(event.target.value, 'CRM_LEAD_SOURCE')
              }
            />
            <TextField
              label="Disclaimer URL"
              variant="outlined"
              size="small"
              value={ephemeralSettings?.settings?.DISCLAIMER_URL?.value || ''}
              onChange={(event) =>
                handleSettingChange(event.target.value, 'DISCLAIMER_URL')
              }
            />
            <TextField
              label="Disclaimer Text"
              variant="outlined"
              size="small"
              value={ephemeralSettings?.settings?.DISCLAIMER_TEXT?.value || ''}
              onChange={(event) =>
                handleSettingChange(event.target.value, 'DISCLAIMER_TEXT')
              }
            />
          </form>
        </div>
        <Divider />
        <h2>Exclude Opcodes from Service Records</h2>
        <div className="opcode-exclude-wrapper">
          <div className="input-container">
            <TextField
              label="Enter Opcode to Exclude"
              variant="outlined"
              size="small"
              value={opcode}
              onChange={(event) => setOpcode(event.target.value)}
              onKeyPress={(event) => handleOpcodeKeyPress(event.key)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <KeyboardReturnIcon color="disabled" />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <Box border={1} borderColor="grey.400" borderRadius={4}>
            <List className={classes.opcodeList}>
              <ListItem
                role={undefined}
                dense
                button
                onClick={handleToggleCheckAllOpcodes}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checkAllOpcodes}
                    checkedIcon={<IndeterminateCheckBoxIcon />}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': 'opcode-list-header' }}
                  />
                </ListItemIcon>
                <ListItemText
                  id="opcode-list-header"
                  primary={<strong>EXCLUDED OPCODES</strong>}
                />
              </ListItem>
              {opcodeExclusionsList.map((opcodeValue, index) => {
                const labelId = `opcode-list-item-${index}`;
                return (
                  <ListItem
                    key={opcodeValue}
                    role={undefined}
                    dense
                    button
                    onClick={handleToggleOpcode(opcodeValue)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checkedOpcodes.indexOf(opcodeValue) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={`${opcodeValue}`} />
                  </ListItem>
                );
              })}
            </List>
          </Box>
          <Box pt={1}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <div className="button-wrapper">
                    <button
                      style={
                        checkedOpcodes.length === 0
                          ? {
                              boxShadow: 'none',
                              opacity: 0.1,
                              cursor: 'not-allowed',
                            }
                          : {}
                      }
                      type="button"
                      className="button submit"
                      disabled={checkedOpcodes.length === 0}
                      onClick={handleRemoveFromOpcodeExclusionList}
                    >
                      Remove Opcodes from Exclusion List
                    </button>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </div>
        <Divider />
        <GoogleAnalyticsTrackingIDSetting
          settingContent={
            ephemeralSettings?.settings?.GOOGLE_ANALYTICS_IDS?.value ?? '[]'
          }
          onChange={(newValue: string) =>
            handleSettingChange(newValue, 'GOOGLE_ANALYTICS_IDS', 'STRING')
          }
        />
        <GoogleAnalyticsMethodSelector
          value={
            ephemeralSettings?.settings?.GOOGLE_ANALYTICS_ENABLED_METHODS
              ?.value ?? '[]'
          }
          onChange={(newValue: string) =>
            handleSettingChange(
              newValue,
              'GOOGLE_ANALYTICS_ENABLED_METHODS',
              'STRING'
            )
          }
          visible={
            ephemeralSettings?.settings?.GOOGLE_ANALYTICS_IDS?.value &&
            ephemeralSettings?.settings?.GOOGLE_ANALYTICS_IDS?.value !== '[]'
              ? true
              : false
          }
        />
      </div>
    </>
  );
};

export default Content;
