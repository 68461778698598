import { defaultMutationFn, queryCacheStrategies } from 'api';
import {
  FeatureFlagApplication,
  FeatureFlag,
  FeatureFlagMemberTypes,
  FeatureFlagMember,
} from 'models';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

/**
 * Get Feature Flag Applications
 * Endpoint: GET `/settings/featureFlags/applications`
 */
export function useFeatureFlagApplications() {
  const path = '/settings/featureflags/applications';
  return useQuery<FeatureFlagApplication[]>(path);
}

/**
 * Get Feature Flag Application Flags
 * Endpoint: GET `/settings/featureFlags/applications/{application}/flags`
 */
export function useFeatureFlagApplicationsFlags(application: string) {
  const path = `/settings/featureflags/applications/${application}/flags`;
  return useQuery<FeatureFlag[]>(path);
}

/**
 * Get Feature Flag Application Flags
 * Endpoint: GET `/settings/featureFlags/applications/{application}/flags/{featureFlagId}`
 */
export function useFeatureFlagApplicationsFlag(
  application: string,
  featureFlagId: string
) {
  const path = `/settings/featureflags/applications/${application}/flags/${featureFlagId}`;
  return useQuery<FeatureFlag>(path);
}

/**
 * Get Feature Flag Application Flag
 * Endpoint: GET `/settings/featureFlags/applications/{application}/flags/{featureFlagId}/members`
 */
export function useFeatureFlagApplicationsFlagMembers(
  application: string,
  featureFlagId: string
) {
  const path = `/settings/featureflags/applications/${application}/flags/${featureFlagId}/members`;
  return useQuery<FeatureFlagMemberTypes>(
    path,
    queryCacheStrategies.curious as UseQueryOptions<FeatureFlagMemberTypes>
  );
}

/**
 * Get Feature Flag Members
 * Endpoint: GET `/settings/featureFlags/members`
 */
export function useFeatureFlagMembers(
  search: string,
  pageSize: number = 50,
  startIndex: number = 0
) {
  const path = `/settings/featureflags/members?s=${search}&pageSize=${pageSize}&startIndex=${startIndex}`;
  return useQuery<FeatureFlagMember[]>(path);
}

/**
 * Update Feature Flag Application Flag
 * Endpoint: PUT `/settings/featureFlags/applications/{application}/flags/{flagId}/members`
 */
export function useUpdateFeatureFlagApplicationsFlagMember(
  application: string,
  flagId: string
) {
  const queryClient = useQueryClient();
  const path = `/settings/featureflags/applications/${application}/flags/${flagId}`;
  return useMutation(
    (data: FeatureFlag) => defaultMutationFn(path, 'PUT', data),
    {
      onSuccess: async (data, variables, context) => {
        if (data) {
          queryClient.setQueryData<FeatureFlag>(path, data);
        }
      },
      onSettled: (data, error, variables, context) => {
        queryClient.invalidateQueries(path);
      },
      // onError: (error, variables, context: any) => { TODO
      // },
    }
  );
}
