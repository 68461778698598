import { useLogin } from 'api/session';
import { useOnChange } from 'common/hooks';
import Alert, { useAlert } from 'components/shared/Alert';
import Dialog from 'components/shared/Dialog';
import React, { useState, useCallback } from 'react';

import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { version } from '../../package.json';

var LoginView = () => {
  const navigate = useNavigate();
  const { mutate, isError, isSuccess, isLoading } = useLogin();

  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');

  const { isAlertOpen, alertMessage, openAlert, closeAlert, variant } =
    useAlert();

  if (isSuccess) {
    navigate('/');
  }

  const isErrorCallback = useCallback(() => {
    if (isError) {
      openAlert('Login failed.', 'error');
    }
  }, [isError, openAlert]);

  useOnChange(isErrorCallback, isError);

  const handleSubmit = async () => {
    if (!emailAddress) {
      openAlert('Enter email address.', 'error');
      return;
    }

    if (!password) {
      openAlert('Enter password.', 'error');
      return;
    }

    mutate({ username: emailAddress, password });
  };

  return (
    <>
      <Dialog
        open
        title="Login"
        maxWidth="md"
        primaryButtonLabel="Login"
        onPrimary={handleSubmit}
        hideCancelButton
        auxiliaryContent={
          <div
            className="version"
            data-vas-testing="admin-login-app-version-text"
          >
            v{version}
          </div>
        }
        block={isLoading}
        data-vas-testing="admin-login-form"
      >
        <Form.Group>
          <Form.Label>Email Address:</Form.Label>
          <Form.Control
            type="text"
            name="emailAddress"
            value={emailAddress}
            onChange={(event) => {
              setEmailAddress(event.target.value);
            }}
            data-vas-testing="admin-login-email-address-text"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Password:</Form.Label>
          <Form.Control
            type="password"
            name="password"
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            data-vas-testing="admin-login-password-text"
          />
        </Form.Group>
      </Dialog>
      <Alert
        open={isAlertOpen}
        variant={variant}
        message={alertMessage}
        onClose={closeAlert}
        duration={3500}
      />
    </>
  );
};

export default LoginView;
