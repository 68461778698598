import React, { SetStateAction, useState } from 'react';
import {
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  makeStyles,
  Theme,
} from '@material-ui/core';

import './UserOrganizationsDialog.scss';
import { useOrganizationsFlat } from 'api';
import { OrganizationItemLink, User } from 'models';
import UserOrganizationsHeader from './UserOrganizationsHeader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      paddingLeft: 16,
      paddingRight: 0,
    },
    checkbox: {
      margin: 0,
      marginRight: theme.spacing(1),
      padding: 0,
    },
  })
);

interface UserOrganizationsDialogProps {
  onClose?: () => void;
  checkedOrganizations: { [key: string]: OrganizationItemLink };
  setCheckedOrganizations: (checkedOrganizations: {
    [key: string]: OrganizationItemLink;
  }) => void;
  userOrganizations: OrganizationItemLink[];
  userId: User['id'];
  setUser: (user: SetStateAction<User>) => void;
}

const UserOrganizationsDialog = ({
  onClose,
  checkedOrganizations,
  setCheckedOrganizations,
  setUser,
}: UserOrganizationsDialogProps) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState<string>('');
  const {
    data: flatOrganizations = {
      data: [],
      meta: { returnedRecords: 0, totalRecords: 0 },
    },
  } = useOrganizationsFlat(searchText);
  // TODO move defaults to useOrganizationsFlat

  const onPrimary = async () => {
    const organizations = Object.values(checkedOrganizations);
    setUser((userState) => {
      return {
        ...userState,
        defaultOrganization:
          userState.defaultOrganization || organizations?.[0],
        organizations,
      };
    });
    onClose?.();
  };

  const handleToggleOrganization = (value: OrganizationItemLink) => () => {
    if (checkedOrganizations[value.id]) {
      const { [value.id]: removedCheckedOrg, ...newCheckedOrganizationIds } =
        checkedOrganizations;
      setCheckedOrganizations(newCheckedOrganizationIds);
    } else {
      setCheckedOrganizations({ [value.id]: value, ...checkedOrganizations });
    }
  };

  const totalChecked = Object.keys(checkedOrganizations)?.length;

  return (
    <Dialog open className="UserOrganizationsDialog">
      <DialogTitle>Add User Organizations</DialogTitle>
      <DialogContent className={classes.content}>
        <UserOrganizationsHeader
          totalRecords={flatOrganizations?.meta?.totalRecords ?? 0}
          totalChecked={totalChecked}
          onSearch={setSearchText}
        />
        <List>
          {flatOrganizations.data.map((organization, index) => {
            const labelId = `organization-list-item-${index}`;
            return (
              <ListItem
                key={`${organization?.id}-${organization.name}`}
                role={undefined}
                dense
                onClick={handleToggleOrganization(organization)}
              >
                <Checkbox
                  className={classes.checkbox}
                  checked={!!checkedOrganizations[organization.id]}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
                <ListItemText id={labelId} primary={`${organization.name}`} />
                <ListItemSecondaryAction>
                  {organization.type}
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose?.()}>Cancel</Button>
        <Button
          id="add-org-button"
          color="secondary"
          style={{ color: 'white' }}
          variant="contained"
          onClick={() => onPrimary()}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserOrganizationsDialog;
