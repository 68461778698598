import React, { useState } from 'react';

import ListDetailLayout from 'components/shared/ListDetailLayout';
import {
  ReconVelocityTagDefinition,
  ReconVelocityTagDefinitionType,
} from 'models/plugins/reconVelocityPlugin';
import { Organization } from 'models';
import EditTagDefinitionDialog from './EditTagDefinitionDialog';
import TagDefinitionDetailView from './TagDefinitionDetailView';
import TagDefinitionsListView from './TagDefinitionsListView';

interface TagDefinitionsViewProps {
  orgId: Organization['id'];
  fetchOnMount: boolean;
}

const TagDefinitionsView = ({
  orgId,
  fetchOnMount,
}: TagDefinitionsViewProps) => {
  const [
    [editingTagDefinition, setEditingTagDefinition],
    [creatingTagDefinition, setCreatingTagDefinition],
    [selectedTagDefinition, setSelectedTagDefinition],
  ] = [
    useState(false),
    useState(false),
    useState<ReconVelocityTagDefinition | undefined>(undefined),
  ];

  return (
    <>
      <ListDetailLayout<ReconVelocityTagDefinition>
        listView={() => (
          <TagDefinitionsListView
            fetchOnMount={fetchOnMount}
            orgId={orgId}
            selectedTagDefinition={selectedTagDefinition}
            setSelectedTagDefinition={(
              tagDefinition?: ReconVelocityTagDefinition
            ) => {
              setSelectedTagDefinition(tagDefinition);
            }}
            openTagDefinitionDialog={() => {
              setCreatingTagDefinition(true);
            }}
          />
        )}
        detailView={() => (
          <TagDefinitionDetailView
            tagDefinition={selectedTagDefinition}
            openEditTagDefinitionDialog={() => {
              setEditingTagDefinition(true);
            }}
          />
        )}
      />
      {editingTagDefinition && (
        <EditTagDefinitionDialog
          orgId={orgId}
          tagDefinition={selectedTagDefinition!}
          setSelectedTagDefinition={(
            tagDefinition?: ReconVelocityTagDefinition
          ) => {
            setSelectedTagDefinition(tagDefinition);
          }}
          onClose={() => setEditingTagDefinition(false)}
        />
      )}
      {creatingTagDefinition && (
        <EditTagDefinitionDialog
          orgId={orgId}
          tagDefinition={{
            type: ReconVelocityTagDefinitionType.DEALER,
            label: '',
            colorHex: '',
          }}
          setSelectedTagDefinition={(
            tagDefinition?: ReconVelocityTagDefinition
          ) => {
            setSelectedTagDefinition(tagDefinition);
          }}
          onClose={() => setCreatingTagDefinition(false)}
        />
      )}
    </>
  );
};

export default TagDefinitionsView;
