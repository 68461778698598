import useSession, { useLogout } from 'api/session';
import logoImage from 'assets/Velocity-Logo-White-250.png';
import { useOnChange } from 'common/hooks';
import permissions from 'common/permissions';
import strings from 'common/strings';
import Tabs, { TabHeaderItem } from 'components/shared/Tabs';
import React, { useCallback, useMemo } from 'react';
import { Image, Navbar } from 'react-bootstrap';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import './Header.scss';

const FIRST_PATH_INDEX = 1;

const firstPathLocation = (navPath: string) =>
  navPath.split('/')[FIRST_PATH_INDEX] || '';

var Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const firstPath = firstPathLocation(location.pathname);

  const { data: session } = useSession();

  const mutation = useLogout();

  const headerTabs: TabHeaderItem[] = useMemo(
    () => [
      {
        key: 'orgs',
        label: strings.ORGANIZATIONS,
        permission: permissions.USERS_VIEW,
      },
      { key: 'users', label: strings.USERS, permission: permissions.ORGS_VIEW },
      {
        key: 'settings',
        label: strings.SETTINGS,
        permission: permissions.SETTINGS_FEATUREFLAG_VIEW,
      },
    ],
    []
  );

  const handleLogoutClick = async () => {
    const result = await mutation.mutateAsync();
    // TODO what should we do if this return false?
    if (result) {
      navigate('/login');
    }
  };

  const sessionChangeCallback = useCallback(() => {
    if (!session) {
      return;
    }

    const availableTabs = headerTabs.filter(
      ({ permission }) =>
        permission && session?.permissions.includes(permission?.toString())
    );
    const passThroughTab = availableTabs.find(({ key }) => key === firstPath);

    if (!passThroughTab) {
      navigate(availableTabs[0]?.key || '', { replace: true });
    }
  }, [firstPath, headerTabs, navigate, session]);

  useOnChange(sessionChangeCallback, session, { callOnMount: true });

  return (
    <>
      <div className="Header">
        <Navbar
          fixed="top"
          className="Header-Navbar"
          variant="dark"
          data-vas-testing="admin-header-navbar"
        >
          <Navbar.Brand>
            <Image fluid src={logoImage} />
          </Navbar.Brand>

          <Tabs
            tabHeaderItems={headerTabs}
            defaultTabKey={firstPath}
            styleType="header"
          />
          <button
            onClick={handleLogoutClick}
            className="Header-logout"
            type="button"
          >
            Logout
          </button>
        </Navbar>
      </div>
      <Outlet />
    </>
  );
};

export default Header;
