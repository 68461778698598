import React, { useEffect, useState } from 'react';
import { DataProviderPluginItem } from 'models';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import Dialog from 'components/shared/Dialog';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import Alert, { useAlert } from 'components/shared/Alert';

import './PluginDialog.scss';
import './EnhancerPluginDialog.scss';
import { makeStyles } from '@material-ui/core/styles';

import { usePlugin, usePluginUpdate, usePluginCreate } from 'api';

const useStyles = makeStyles({
  root: {
    display: 'flex !important',
    alignItems: 'center !important',
  },
});

export interface EnhancerPluginDialogProps {
  plugin: DataProviderPluginItem;
  orgId: string;
  onSave?: () => void;
  onClose?: () => void;
}

const EnhancerPluginDialog: React.FC<EnhancerPluginDialogProps> = (props) => {
  const { plugin, orgId, onClose } = props;
  const classes = useStyles();

  const { isAlertOpen, alertMessage, openAlert, closeAlert, variant } =
    useAlert();
  const [enabled, setEnabled] = useState(true);
  const [dealerId, setDealerId] = useState('');
  const [dealerCode, setDealerCode] = useState('');

  const pluginTypeId = plugin.pluginName.toLowerCase().replace('_', '');
  const { data: pluginData, isLoading } = usePlugin(
    orgId,
    pluginTypeId,
    plugin.id
  );
  const { updatePluginAsync, isLoading: isUpdating } = usePluginUpdate(
    orgId,
    pluginTypeId
  );
  const { createPluginAsync, isLoading: isCreating } = usePluginCreate(
    orgId,
    pluginTypeId
  );

  useEffect(() => {
    if (!isLoading && pluginData) {
      setEnabled(pluginData.enabled);
      setDealerId(pluginData.dealerId ?? '');
      setDealerCode(pluginData.dealerCode ?? '');
    }
  }, [isLoading, pluginData]);

  async function save() {
    const data: Partial<DataProviderPluginItem> = {
      enabled,
      dealerId,
      dealerCode,
    };

    try {
      // If there's already a plugin ID, do an update. Otherwise create.
      if (plugin.id) {
        await updatePluginAsync(data);
      } else {
        const newPlugin = { ...plugin, ...data };
        await createPluginAsync(newPlugin);
      }
    } catch (error) {
      openAlert(
        'An error occurred when saving this plug-in to the server.',
        'error'
      );
    }
  }

  return (
    <>
      <Dialog
        open
        title={`Configure ${plugin.title} Plug-in`}
        maxWidth="sm"
        fullWidth
        primaryButtonLabel="Save"
        onPrimary={save}
        onClose={onClose}
        block={isLoading || isUpdating || isCreating}
        className="PluginDialog EnhancerPluginDialog"
      >
        <div className={`main-content ${classes.root}`}>
          {plugin.pluginName === 'AUTOCHECK' ? (
            <TextField
              id="dealerCodeInput"
              className="PluginDialog_Text-input"
              label="Dealer Code"
              variant="outlined"
              value={dealerCode}
              onChange={(e) => {
                setDealerCode(e.target.value);
              }}
              margin="dense"
            />
          ) : (
            ''
          )}
          {(plugin.pluginName === 'HOMENET_ENHANCER' ||
            plugin.pluginName === 'NETLOOK') && (
            <TextField
              className="PluginDialog__text-input"
              label="IMS ID"
              variant="outlined"
              required
              value={dealerId}
              onChange={(e) => {
                setDealerId(e.target.value);
              }}
              margin="dense"
            />
          )}
          <FormControlLabel
            className="PluginDialog__switch"
            label={
              isLoading ? (
                <LoadingIndicator size={26} />
              ) : (
                <FormLabel>{enabled ? 'Enabled' : 'Disabled'}</FormLabel>
              )
            }
            labelPlacement="start"
            control={
              <Switch
                inputProps={{
                  'aria-label': 'Enhancer Plugin Dialog Enable switch',
                }}
                checked={enabled ?? false}
                disabled={isLoading}
                onChange={(_, checked) => {
                  setEnabled(checked);
                }}
              />
            }
          />
        </div>
      </Dialog>
      <Alert
        open={isAlertOpen}
        message={alertMessage}
        onClose={closeAlert}
        duration={3500}
        variant={variant}
      />
    </>
  );
};

export default EnhancerPluginDialog;
