import { defaultMutationFn, defaultMetaQueryFn, APIResponse } from 'api/useAPI';
import { Organization, ReconVelocityTagDefinition } from 'models';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

// Path building functions

/**
 * Get tags path
 * @param orgId - Organization ID
 * @returns path to get all tags for a given organization
 */
const getTagsDefinitionsPath = (orgId: Organization['id']) =>
  `/orgs/${orgId}/plugins/reconvelocity/tags`;

/**
 * Get single tag path
 * @param orgId - Organization ID
 * @param tagId - Tag ID
 * @returns path to a given tag identified by tagId
 */
const getTagDefinitionPath = (
  orgId: Organization['id'],
  tagId: ReconVelocityTagDefinition['id']
) => `${getTagsDefinitionsPath(orgId)}/${tagId}`;

// Hooks

/**
 * Get Tags Definitions for a given Organization
 * Endpoint: GET `/orgs/{orgId}/plugins/reconvelocity/tags`
 * @param orgId - Organization ID
 * @param options? - Query Options
 * @returns Full linear Recon Velocity Tags Definitions
 */
export function useTagsDefinitions(
  orgId: Organization['id'],
  options?: UseQueryOptions<APIResponse<ReconVelocityTagDefinition[]>>
) {
  const path = getTagsDefinitionsPath(orgId);

  return useQuery<APIResponse<ReconVelocityTagDefinition[]>>(
    path,
    () => defaultMetaQueryFn(path),
    options
  );
}

/**
 * Get Tag Definition by ID for a given Organization
 * @param orgId - Organization ID
 * @param tagId - Tag ID
 */
export function useTagDefinition(
  orgId: Organization['id'],
  tagId: ReconVelocityTagDefinition['id']
) {
  const path = getTagDefinitionPath(orgId, tagId);
  return useQuery<APIResponse<ReconVelocityTagDefinition>>(path, () =>
    defaultMetaQueryFn(path)
  );
}

/**
 * Creates a new tag definition
 * @param orgId - Organization ID
 * @param tagDefinition - Tag definition
 * @returns newly created tag definition
 */
export function useCreateTagDefinition(orgId: Organization['id']) {
  const queryClient = useQueryClient();
  const mutation = useMutation((data) => {
    const path = getTagsDefinitionsPath(orgId);
    return defaultMutationFn(path, 'POST', data);
  });

  async function createTagDefinitionAsync(tagDefinition: any) {
    await queryClient.cancelQueries('tags');
    const response = await mutation.mutateAsync(tagDefinition);
    await queryClient.invalidateQueries(getTagsDefinitionsPath(orgId));
    return response;
  }

  return {
    ...mutation,
    createTagDefinitionAsync,
  };
}

/**
 * Updates a tag definition
 * @param orgId - Organization ID
 * @param tagDefinitionId - Tag definition ID
 * @returns updated tag definition
 */
export function useUpdateTagDefinition(
  orgId: Organization['id'],
  tagDefinitionId: ReconVelocityTagDefinition['id']
) {
  const queryClient = useQueryClient();
  const mutation = useMutation((tagDefinition) => {
    const path = getTagDefinitionPath(orgId, tagDefinitionId);
    return defaultMutationFn(path, 'PUT', tagDefinition);
  });

  async function updateTagDefinitionAsync(tagDefinition: any) {
    await queryClient.cancelQueries('tags');
    const response = await mutation.mutateAsync(tagDefinition);
    await queryClient.invalidateQueries(getTagsDefinitionsPath(orgId));
    return response;
  }

  return {
    ...mutation,
    updateTagDefinitionAsync,
  };
}

/**
 * Deletes a given tag definition
 * @param orgId - Organization ID
 * @param tagDefinitionId - Tag definition ID
 * @returns boolean indicating deletion success or failure
 */
export function useDeleteTagDefinition(orgId: Organization['id']) {
  const queryClient = useQueryClient();
  const mutation = useMutation((tagDefinitionId: any) => {
    const path = getTagDefinitionPath(orgId, tagDefinitionId);
    return defaultMutationFn(path, 'DELETE');
  });

  async function deleteTagDefinitionAsync(tagDefinitionId: any) {
    await queryClient.cancelQueries('tags');
    const response = await mutation.mutateAsync(tagDefinitionId);
    await queryClient.invalidateQueries(getTagsDefinitionsPath(orgId));
    return response;
  }

  return {
    ...mutation,
    deleteTagDefinitionAsync,
  };
}
