import React from 'react';
import { Dropdown } from 'react-bootstrap';

import { PluginItem, PluginOption, PluginItemCategory } from 'models';
import PermissionGate from 'components/PermissionGate';
import permissions from 'common/permissions';
import strings from 'common/strings';
import { usePluginDelete } from 'api';
import ConfirmationDialog, {
  useConfirmationDialog,
} from 'components/shared/ConfirmationDialog';
import PluginButton from './PluginButton';

interface PluginSectionProps {
  category: PluginItemCategory;
  heading: string;
  onAddPluginOption: (
    pluginOption: PluginOption,
    settings: Record<string, any>
  ) => any;
  plugins: PluginItem[];
  pluginOptions: PluginOption[];
  onEditPlugin: (plugin: PluginItem) => void;
  orgId: string;
  onDeletePlugin: () => void;
  isAddDropdownDisabled: boolean;
}

const PluginSection: React.FC<PluginSectionProps> = ({
  category,
  heading,
  onAddPluginOption,
  onEditPlugin,
  plugins,
  pluginOptions,
  orgId,
  onDeletePlugin,
  isAddDropdownDisabled,
}: PluginSectionProps) => {
  const deletePlugin = usePluginDelete();
  const {
    isConfirmationDialogOpen,
    isConfirmationLoading,
    onAccept,
    confirmationMessage,
    onDeny,
    openConfirmationDialog,
    primaryButtonLabel,
  } = useConfirmationDialog();
  const displayPlugins = plugins
    ? plugins.filter((plugin: PluginItem) => plugin.category === category)
    : [];
  const displayPluginOptions = pluginOptions
    ? pluginOptions
        .slice()
        .filter((option) => option.available && option.category === category)
    : [];

  const handleDeletePluginClick = async (plugin: PluginItem) => {
    openConfirmationDialog({
      messageOverride: `Are you sure you want to delete the ${plugin.title} plug-in?`,
      primaryButtonLabelOverride: 'Delete',
      onAcceptOverride: async () => {
        await deletePlugin.mutateAsync({ orgId, pluginId: plugin.id ?? '' });
        onDeletePlugin();
      },
    });
  };

  return (
    <>
      <div className="PluginSection__header">
        <h3>{heading}</h3>
        <Dropdown className="inline-block margin-left">
          <PermissionGate permissions={[permissions.ORGS_PLUGINS_CREATE]}>
            <Dropdown.Toggle
              id={`${heading.replace(' ', '-')}-add-button`}
              disabled={isAddDropdownDisabled}
            >
              {strings.ADD}
            </Dropdown.Toggle>
          </PermissionGate>
          <Dropdown.Menu id={`${heading.replace(' ', '-')}-plugin-dropdown`}>
            {displayPluginOptions.map((option) => (
              <Dropdown.Item
                key={option.id}
                onSelect={() =>
                  onAddPluginOption(
                    option,
                    option.pluginName === 'RECON_VELOCITY' // TODO - move saving and default into ReconVelocity Plugin component
                      ? {
                          RECON_TIME_IGNORE_SCHEDULE: {
                            dataType: 'BOOL',
                            value: true,
                          },
                        }
                      : {}
                  )
                }
              >
                {option.title}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="PluginsList" id={`${heading.replace(' ', '-')}-plugin`}>
        {displayPlugins.length
          ? displayPlugins.map((plugin) => (
              <PluginButton
                key={plugin.id}
                plugin={plugin}
                onEditClick={() => onEditPlugin(plugin)}
                onDeleteClick={() => handleDeletePluginClick(plugin)}
              />
            ))
          : strings.NO_CATEGORY_PLUGINS(heading)}
      </div>
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        isLoading={isConfirmationLoading}
        onPrimaryButtonClick={onAccept}
        onSecondaryButtonClick={onDeny}
        message={confirmationMessage}
        primaryButtonLabel={primaryButtonLabel}
      />
    </>
  );
};

export default PluginSection;
