import React, { useEffect, useState } from 'react';
import {
  FormControlLabel,
  Checkbox,
  FormGroup,
  Select,
  TextField,
  MenuItem,
  InputLabel,
  FormControl,
  Divider,
  Typography,
  Button,
} from '@material-ui/core';

import {
  ReconTime,
  ReconVelocityPluginItem,
  ReconVelocityPluginItemReconMode,
} from 'models/plugins/reconVelocityPlugin';
import { Currency, OdometerUnit, Organization } from 'models';
import CurrencyTextField from 'components/shared/CurrencyTextField';
import theme from 'common/theme';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import strings from 'common/strings';
import {
  useCreateReconVelocityPlugin,
  useReconVelocityPlugin,
  useUpdateReconVelocityPlugin,
} from 'api/organizations/plugins/reconvelocity';

import WholesaleUnitsSetting from './WholesaleUnitsSetting';
import GoalTimeSetting from './GoalTimeSetting';

import './GeneralView.scss';

interface GeneralViewProps {
  orgId: Organization['id'];
  fetchOnMount: boolean;
  isCreatingPlugin: boolean;
}

// TODO - this belongs in the ReconVelovityPluginItem model
const getDefaultSettings = (
  currency: Currency = 'USD'
): Partial<ReconVelocityPluginItem> => ({
  reconMode: ReconVelocityPluginItemReconMode.USED,
  odometerUnit: OdometerUnit.MILES,
  onboarding: true,
  excludeWholesaleUnitsFromImport: false,
  autoClearCompletedReconVehiclesAfter24Hours: false,
  enablePostRecon: false,
  enableRequireLaborAndPartsOnTasks: false,
  monthlySubscriptionFee: { amount: 0, currency },
  dealershipCosts: {
    defaultLaborRate: { amount: null, currency },
    defaultEstimatedRecon: { amount: 0, currency },
    newPack: { amount: 0, currency },
    usedPack: { amount: 0, currency },
    dailyHoldingCost: { amount: 0, currency },
    dailyDepreciationCost: { amount: 0, currency },
  },
  reconTimeIgnoreSchedule: true,
  settings: {
    // TODO - and another place for default settings for plugins. Refactor needed
    RECON_TIME_IGNORE_SCHEDULE: { dataType: 'BOOL', value: true },
  },
});

const GeneralView = ({
  orgId,
  fetchOnMount,
  isCreatingPlugin,
}: GeneralViewProps) => {
  const { data: generalSettings, isLoading: generalSettingsAreLoading } =
    useReconVelocityPlugin(orgId, {
      enabled: fetchOnMount,
    });
  const {
    updateReconVelocityPluginAsync,
    isLoading: isUpdatingReconVelocityPlugin,
  } = useUpdateReconVelocityPlugin(orgId);
  const { isLoading: isCreatingReconVelocityPlugin } =
    useCreateReconVelocityPlugin(orgId);
  const isLoading =
    generalSettingsAreLoading ||
    isUpdatingReconVelocityPlugin ||
    isCreatingReconVelocityPlugin ||
    isCreatingPlugin;

  const [currency, setCurrency] = useState(
    generalSettings?.data?.monthlySubscriptionFee.currency ?? 'USD'
  );

  const [updatedSettings, setUpdatedSettings] = useState<
    Partial<ReconVelocityPluginItem>
  >({
    ...getDefaultSettings(currency),
    ...generalSettings?.data,
  });

  const generalSettingsData = generalSettings?.data;
  useEffect(() => {
    setUpdatedSettings({
      ...getDefaultSettings(currency),
      ...generalSettingsData,
    });
  }, [currency, generalSettingsData, setUpdatedSettings]);

  const handleSaveButtonClick = () => {
    updateReconVelocityPluginAsync(updatedSettings);
  };

  const updateSetting = (
    key: keyof ReconVelocityPluginItem,
    value: ReconVelocityPluginItem[typeof key]
  ) => {
    setUpdatedSettings((settings) => ({
      ...settings,
      [key]: value,
    }));
  };

  const updateSubsetting = (inputKey: string, inputValue: Record<any, any>) => {
    setUpdatedSettings((settings) => {
      const subsetSettings = settings.settings;
      const newSettings = {
        ...subsetSettings,
        [inputKey]: inputValue,
      };

      return {
        ...settings,
        settings: newSettings,
      };
    });
  };

  const updateDealershipCost = (
    key: keyof ReconVelocityPluginItem['dealershipCosts'],
    value: ReconVelocityPluginItem['dealershipCosts'][typeof key]
  ) => {
    updateSetting('dealershipCosts', {
      ...updatedSettings.dealershipCosts,
      [key]: value,
    });
  };

  const reconTime: Record<ReconTime, boolean> = {
    CALENDAR_HOURS: true,
    BUSINESS_HOURS: false,
  };

  const getReconTime = (currentSetting: boolean): ReconTime =>
    currentSetting ? 'CALENDAR_HOURS' : 'BUSINESS_HOURS';

  return isCreatingPlugin ? (
    <LoadingIndicator />
  ) : (
    <FormGroup className="ReconVelocityPluginDialog__GeneralView">
      <div className="align-right full-width padding">
        <Button
          id="save-button"
          color="secondary"
          variant="contained"
          style={{
            color: theme.palette.common.white,
          }}
          onClick={handleSaveButtonClick}
          disabled={isLoading}
        >
          {isLoading && <LoadingIndicator color="secondary" size={20} />}
          <b>{strings.SAVE}</b>
        </Button>
      </div>
      <div className="full-width flex-columns flex-justify-center align-center">
        <div className="align-left">
          <div className="flex-columns flex-wrap flex-justify-center padding">
            <div className="flex-rows padding">
              <FormControl variant="outlined" margin="dense" required>
                <InputLabel variant="outlined" margin="dense">
                  {strings.RECON_MODE}
                </InputLabel>
                <Select
                  name="reconMode"
                  label="Recon Mode"
                  value={updatedSettings.reconMode}
                  variant="outlined"
                  margin="dense"
                  onChange={({ target: { value } }) =>
                    updateSetting(
                      'reconMode',
                      value as ReconVelocityPluginItemReconMode
                    )
                  }
                  required
                >
                  <MenuItem value="ALL">{strings.ALL}</MenuItem>
                  <MenuItem value="NEW">{strings.NEW}</MenuItem>
                  <MenuItem value="USED">{strings.USED}</MenuItem>
                </Select>
              </FormControl>

              <FormControl
                className="margin-top"
                variant="outlined"
                margin="dense"
                required
              >
                <InputLabel variant="outlined" margin="dense">
                  {strings.ODOMETER_UNITS}
                </InputLabel>

                <Select
                  name="odometerUnit"
                  label="Odometer Units"
                  value={updatedSettings.odometerUnit}
                  variant="outlined"
                  margin="dense"
                  onChange={({ target: { value } }) =>
                    updateSetting('odometerUnit', value as OdometerUnit)
                  }
                  required
                >
                  <MenuItem value="MILES">{strings.MILES}</MenuItem>
                  <MenuItem value="KILOMETERS">{strings.KILOMETERS}</MenuItem>
                </Select>
              </FormControl>

              <FormControl variant="outlined" margin="dense">
                <TextField
                  id="session-timeout"
                  style={{ minWidth: 220 }}
                  variant="outlined"
                  margin="dense"
                  placeholder="0"
                  inputMode="numeric"
                  name="sessionTimeoutAsSeconds"
                  label="Session Timeout (seconds)"
                  value={updatedSettings.sessionTimeoutAsSeconds ?? 0}
                  onChange={({ target: { value } }) =>
                    updateSetting('sessionTimeoutAsSeconds', +value)
                  }
                  required
                />
              </FormControl>
              <FormControl
                className="margin-top"
                variant="outlined"
                margin="dense"
                required
              >
                <InputLabel variant="outlined" margin="dense">
                  Recon Time
                </InputLabel>

                <Select
                  name="reconTime"
                  label="Recon Time"
                  value={
                    updatedSettings.reconTimeIgnoreSchedule !== undefined
                      ? getReconTime(updatedSettings.reconTimeIgnoreSchedule)
                      : 'CALENDAR_HOURS'
                  }
                  variant="outlined"
                  margin="dense"
                  onChange={({ target: { value } }) => {
                    updateSetting(
                      'reconTimeIgnoreSchedule',
                      reconTime[value as ReconTime] as boolean
                    );
                    updateSubsetting('RECON_TIME_IGNORE_SCHEDULE', {
                      dataType: 'BOOL',
                      value: reconTime[value as ReconTime] as boolean,
                    });
                  }}
                  required
                >
                  <MenuItem value="CALENDAR_HOURS">Calendar Hours</MenuItem>
                  <MenuItem value="BUSINESS_HOURS">Business Hours</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="padding" id="checkbox-container">
              <FormControlLabel
                control={
                  <Checkbox
                    id="onboarding-checkbox"
                    checked={updatedSettings.onboarding ?? false}
                    onChange={({ target: { checked } }) =>
                      updateSetting('onboarding', checked)
                    }
                  />
                }
                name="onboarding"
                label="Onboarding"
              />

              <br />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      updatedSettings.autoClearCompletedReconVehiclesAfter24Hours ??
                      false
                    }
                    onChange={({ target: { checked } }) =>
                      updateSetting(
                        'autoClearCompletedReconVehiclesAfter24Hours',
                        checked
                      )
                    }
                  />
                }
                name="autoClearCompletedReconVehiclesAfter24Hours"
                label="Force Completion for Vehicle in Ending Steps - Nightly"
              />

              <br />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={updatedSettings.enablePostRecon ?? false}
                    onChange={({ target: { checked } }) =>
                      updateSetting('enablePostRecon', checked)
                    }
                  />
                }
                name="enablePostRecon"
                label="Post Recon"
              />

              <br />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      updatedSettings.enableRequireLaborAndPartsOnTasks ?? false
                    }
                    onChange={({ target: { checked } }) =>
                      updateSetting(
                        'enableRequireLaborAndPartsOnTasks',
                        checked
                      )
                    }
                  />
                }
                name="enableRequireLaborAndPartsOnTasks"
                label="Require Labor and Parts on Tasks"
              />

              <br />

              <WholesaleUnitsSetting
                excludeWholesaleUnitsFromImport={
                  updatedSettings.excludeWholesaleUnitsFromImport
                }
                excludeWholesaleUnitsFromImportRemoveRecon={
                  updatedSettings.excludeWholesaleUnitsFromImportRemoveRecon
                }
                updateSetting={updateSetting}
                updateSubsetting={updateSubsetting}
              />

              <br />

              <div className="flex-columns flex-justify-center margin-top">
                <div className="full-width flex-justify-center">
                  <GoalTimeSetting
                    goalTimeInSeconds={
                      updatedSettings.retailReadyDangerAsSeconds
                    }
                    goalTimeLabel="Retail Ready Goal Time"
                    goalTimeSettingKey="retailReadyDangerAsSeconds"
                    updateSetting={updateSetting}
                  />
                  <GoalTimeSetting
                    goalTimeInSeconds={updatedSettings.reconDangerAsSeconds}
                    goalTimeLabel="Recon Goal Time"
                    goalTimeSettingKey="reconDangerAsSeconds"
                    updateSetting={updateSetting}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex-columns flex-justify-center valign-center padding-bottom">
            <FormControl
              className="margin-right"
              variant="outlined"
              margin="dense"
              required
            >
              <InputLabel variant="outlined" margin="dense">
                {strings.CURRENCY}
              </InputLabel>

              <Select
                style={{ minWidth: 100 }}
                name="currency"
                label="Currency"
                value={currency}
                variant="outlined"
                margin="dense"
                onChange={({ target: { value } }) =>
                  setCurrency(value as Currency)
                }
                required
              >
                <MenuItem value="USD">{strings.USD}</MenuItem>
                <MenuItem value="CAD">{strings.CAD}</MenuItem>
              </Select>
            </FormControl>

            <FormControl
              className="margin-sm"
              variant="outlined"
              margin="dense"
            >
              <CurrencyTextField
                style={{ minWidth: 200 }}
                variant="outlined"
                margin="dense"
                placeholder="$0.00"
                name="monthlySubscriptionFee"
                label="Monthly Subscription Fee"
                value={updatedSettings.monthlySubscriptionFee?.amount ?? 0}
                onValueChange={({ floatValue: amount }) =>
                  updateSetting('monthlySubscriptionFee', { amount, currency })
                }
                required
              />
            </FormControl>
          </div>

          <div className="flex-columns flex-justify-center valign-center padding">
            <Divider className="flex-grow margin-right" />
            <Typography variant="h6">{strings.DEALERSHIP_COSTS}</Typography>
            <Divider className="flex-grow margin-left" />
          </div>

          <div className="flex-columns flex-justify-center padding">
            <div className="full-width flex-justify-center">
              <FormGroup row>
                <FormControl
                  style={{ flex: 1 }}
                  variant="outlined"
                  margin="dense"
                  className="margin-sm"
                >
                  <CurrencyTextField
                    variant="outlined"
                    margin="dense"
                    placeholder="$0.00"
                    name="defaultLaborRate"
                    label="Default Labor Rate"
                    value={
                      updatedSettings.dealershipCosts?.defaultLaborRate
                        ?.amount ?? undefined
                    }
                    onValueChange={({ floatValue: amount }) =>
                      updateDealershipCost('defaultLaborRate', {
                        amount,
                        currency,
                      })
                    }
                  />
                </FormControl>

                <FormControl
                  style={{ flex: 1 }}
                  variant="outlined"
                  margin="dense"
                  className="margin-sm"
                >
                  <CurrencyTextField
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    placeholder="$0.00"
                    name="defaultEstimatedRecon"
                    label="Default Estimated Recon"
                    value={
                      updatedSettings.dealershipCosts?.defaultEstimatedRecon
                        .amount ?? 0
                    }
                    onValueChange={({ floatValue: amount }) =>
                      updateDealershipCost('defaultEstimatedRecon', {
                        amount,
                        currency,
                      })
                    }
                    required
                  />
                </FormControl>
              </FormGroup>

              <FormGroup row>
                <FormControl
                  style={{ flex: 1 }}
                  variant="outlined"
                  margin="dense"
                  className="margin-sm"
                >
                  <CurrencyTextField
                    variant="outlined"
                    margin="dense"
                    placeholder="$0.00"
                    name="newPack"
                    label="New Pack"
                    value={updatedSettings.dealershipCosts?.newPack.amount ?? 0}
                    onValueChange={({ floatValue: amount }) =>
                      updateDealershipCost('newPack', {
                        amount,
                        currency,
                      })
                    }
                    required
                  />
                </FormControl>

                <FormControl
                  style={{ flex: 1 }}
                  variant="outlined"
                  margin="dense"
                  className="margin-sm"
                >
                  <CurrencyTextField
                    variant="outlined"
                    margin="dense"
                    placeholder="$0.00"
                    name="usedPack"
                    label="Used Pack"
                    value={
                      updatedSettings.dealershipCosts?.usedPack.amount ?? 0
                    }
                    onValueChange={({ floatValue: amount }) =>
                      updateDealershipCost('usedPack', {
                        amount,
                        currency,
                      })
                    }
                    required
                  />
                </FormControl>
              </FormGroup>

              <FormGroup row>
                <FormControl
                  style={{ flex: 1 }}
                  variant="outlined"
                  margin="dense"
                  className="margin-sm"
                >
                  <CurrencyTextField
                    variant="outlined"
                    margin="dense"
                    placeholder="$0.00"
                    name="dailyHoldingCost"
                    label="Daily Holding"
                    value={
                      updatedSettings.dealershipCosts?.dailyHoldingCost
                        .amount ?? 0
                    }
                    onValueChange={({ floatValue: amount }) =>
                      updateDealershipCost('dailyHoldingCost', {
                        amount,
                        currency,
                      })
                    }
                    required
                  />
                </FormControl>

                <FormControl
                  style={{ flex: 1 }}
                  variant="outlined"
                  margin="dense"
                  className="margin-sm"
                >
                  <CurrencyTextField
                    variant="outlined"
                    margin="dense"
                    placeholder="$0.00"
                    name="dailyDepreciationCost"
                    label="Daily Depreciation"
                    value={
                      updatedSettings.dealershipCosts?.dailyDepreciationCost
                        .amount ?? 0
                    }
                    onValueChange={({ floatValue: amount }) =>
                      updateDealershipCost('dailyDepreciationCost', {
                        amount,
                        currency,
                      })
                    }
                    required
                  />
                </FormControl>
              </FormGroup>
            </div>
          </div>
        </div>
      </div>
    </FormGroup>
  );
};

export default GeneralView;
