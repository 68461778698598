import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Create } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import useSession from 'api/session';
import PERMISSIONS from 'common/permissions';
import { User } from 'models';
import React from 'react';
import { Form } from 'react-bootstrap';
import utils from 'utils';

const ROW_HEIGHT = 30;

interface UsersListRowProps {
  user: User;
  style: any;
  checkedUsers: string[];
  handleCheckboxChange: any;
  openEditUserDialog: (userId?: string) => void;
}

const UsersListRow: React.FC<UsersListRowProps> = ({
  user,
  style,
  checkedUsers,
  handleCheckboxChange,
  openEditUserDialog,
}) => {
  const { data: session } = useSession();
  const hasEditPermission = session?.permissions.includes(
    PERMISSIONS.USERS_UPDATE
  );

  const checked = checkedUsers.find((userId: any) => userId === user?.id);

  if (!user) {
    return (
      <Skeleton
        className="loadingSkeleton"
        height={ROW_HEIGHT}
        key={`${utils.strings.createRandomCharacters()}skeleton`}
      />
    );
  }

  return (
    <TableRow component="div" className="row" style={style} key={user?.id}>
      <TableCell
        component="div"
        variant="body"
        align="left"
        className="cell"
        style={{
          minWidth: '200px',
          height: ROW_HEIGHT,
        }}
      >
        <Form.Check
          id={`UserOrganizationDetailView.users.${user?.id}`}
          type="checkbox"
          name="UserOrganizationDetailView.users"
          label={`${user?.firstName || ''} ${user?.lastName || ''}`}
          value={user?.id}
          onChange={handleCheckboxChange}
          checked={!!checked}
        />
      </TableCell>
      <TableCell
        component="div"
        variant="body"
        align="left"
        className="cell"
        style={{
          minWidth: '100px',
          height: ROW_HEIGHT,
        }}
      >
        {user?.email}
      </TableCell>
      <TableCell
        component="div"
        variant="body"
        align="right"
        className="cell actionButtons"
        style={{
          minWidth: '75px',
          height: ROW_HEIGHT,
        }}
      >
        {hasEditPermission && (
          <Create
            className="icon"
            onClick={() => openEditUserDialog(user.id)}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default UsersListRow;
