import {
  Box,
  Button,
  createStyles,
  Dialog as MaterialDialog,
  DialogActions as MaterialDialogActions,
  DialogContent as MaterialDialogContent,
  DialogTitle as MaterialDialogTitle,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import strings from 'common/strings';
import { FolioDocumentTemplate } from 'models';
import { useRef, useState } from 'react';
import api from '../../../../../../../api';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import Cropper, { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';

export async function dataUrlToImageUrl({
  dataUrl,
  folioId,
  orgId,
}: {
  dataUrl: string;
  orgId: string;
  folioId: string;
}) {
  const blob = await fetch(dataUrl).then((res) => res.blob());
  const formData = new FormData();
  formData.append('file', blob);
  return await api.organizations.plugins.velocityengage.folioTemplate.createMediaFolioDocumentMedia(
    {
      orgId,
      folioTemplateId: folioId,
      file: formData,
    }
  );
}

interface EngageMediaItemEditModalProps {
  open: boolean;
  onClose: () => void;
  orgId: string;
  folioId: string;
  media: FolioDocumentTemplate;
  onEdit: (
    document: FolioDocumentTemplate,
    title: string,
    previewImageUrl: string,
    mediaUrl?: string,
    originalPrevieImageUrl?: string
  ) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    engageMediaItemEditModal: {
      width: '560px',
    },
    editFields: {
      width: '100%',
    },
    input: {
      display: 'none',
    },
  })
);

const EngageMediaItemEditModal: React.FC<EngageMediaItemEditModalProps> = ({
  open,
  onClose,
  folioId,
  orgId,
  media,
  onEdit,
}) => {
  const [mediaTitle, setMediaTitle] = useState(media.displayLabel || '');
  const [isMediaFileUpload, setIsMediaFileUpload] = useState(false);
  const [mediaUrl, setMediaUrl] = useState(media.url || '');
  const [selectedFile, setSelectedFile] = useState<File>();
  const [isMediaPreviewFileUpload, setIsMediaPreviewFileUpload] =
    useState(false);
  const [mediaPreviewUrl, setMediaPreviewUrl] = useState(media.previewUrl);
  const [originalMediaPreviewUrl, setOriginalMediaPreviewUrl] = useState(
    media.originalPreviewUrl || ''
  );
  const [isUpdating, setIsUpdating] = useState(false);
  const cropperRef = useRef<ReactCropperElement>(null);
  const [dataUrl, setDataUrl] = useState<string | null>(null);
  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    setDataUrl(cropper?.getCroppedCanvas().toDataURL() || null);
  };

  console.log({ dataUrl });

  const handleChangeEditMediaType = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setIsMediaFileUpload(event.target.value === 1);
  };

  const handleChangeEditMediaPreviewImageType = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setIsMediaPreviewFileUpload(event.target.value === 1);
  };

  const handleSelectFile = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.currentTarget.files) {
      const file = event.currentTarget.files[0];
      setSelectedFile(file);
      const fileData = new FormData();
      fileData.append('file', file);
      const fileUrl =
        await api.organizations.plugins.velocityengage.folioTemplate.createMediaFolioDocumentMedia(
          {
            orgId,
            folioTemplateId: folioId,
            file: fileData,
          }
        );
      setMediaUrl(fileUrl);
    }
  };

  const handleSelectPreviewImageFile = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.currentTarget.files) {
      const file = event.currentTarget.files[0];
      setSelectedFile(file);
      const imageData = new FormData();
      imageData.append('file', file);
      const imageUrl =
        await api.organizations.plugins.velocityengage.folioTemplate.createMediaFolioDocumentMedia(
          {
            orgId,
            folioTemplateId: folioId,
            file: imageData,
          }
        );
      setOriginalMediaPreviewUrl(imageUrl);
    }
  };

  const handleCancelEdit = () => {
    onClose();
    setMediaTitle(media.displayLabel || '');
    setIsMediaFileUpload(false);
    setMediaUrl(media.url || '');
    setSelectedFile(undefined);
    setIsMediaPreviewFileUpload(false);
    setMediaPreviewUrl(media.previewUrl || '');
    setOriginalMediaPreviewUrl(media.originalPreviewUrl || '');
  };
  const classes = useStyles();
  return (
    <MaterialDialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      aria-labelledby="edit-media-title-dialog-title"
    >
      <MaterialDialogTitle
        id="edit-media-title-dialog-title"
        className={classes.engageMediaItemEditModal}
      >
        {strings.EDIT_MEDIA}
      </MaterialDialogTitle>
      <MaterialDialogContent className={classes.engageMediaItemEditModal}>
        {media.canUpdateUrl && (
          <div>
            <InputLabel shrink htmlFor="edit-media-doc-type-select">
              Document Type
            </InputLabel>
            <Select
              label="Document Type"
              id="edit-media-doc-type-select"
              value={isMediaFileUpload ? 1 : 0}
              onChange={handleChangeEditMediaType}
              name="edit-media-type-input"
              variant="outlined"
              className={classes.editFields}
            >
              <MenuItem value={0}>Link</MenuItem>
              <MenuItem value={1}>File Upload</MenuItem>
            </Select>
          </div>
        )}
        <div>
          <TextField
            label="Title"
            value={mediaTitle}
            onChange={(event) => setMediaTitle(event.target.value)}
            margin="normal"
            variant="outlined"
            className={classes.editFields}
          />
        </div>
        {media.canUpdateUrl &&
          (isMediaFileUpload ? (
            <Box my={1}>
              <input
                type="file"
                id={`${media.id}file-input`}
                multiple
                onChange={handleSelectFile}
                className={classes.input}
              />
              <label htmlFor={`${media.id}file-input`}>
                <Button variant="outlined" component="span" disableElevation>
                  Select File
                </Button>
              </label>
              <Box component="span" ml={2}>
                {selectedFile?.name
                  ? selectedFile?.name
                  : 'Select a file to upload.'}
              </Box>
            </Box>
          ) : (
            <div>
              <TextField
                label="URL - Use {vin} to pass the VIN to the URL, e.g., https://kbb.com/kbbreport/{vin}"
                value={mediaUrl}
                onChange={(event) => setMediaUrl(event.target.value)}
                margin="normal"
                variant="outlined"
                className={classes.editFields}
              />
            </div>
          ))}
        <div>
          <InputLabel shrink htmlFor="edit-media-preview-image-type-select">
            Thumbnail Type
          </InputLabel>
          <Select
            label="Thumbnail Type"
            id="edit-media-preview-image-type-select"
            value={isMediaPreviewFileUpload ? 1 : 0}
            onChange={handleChangeEditMediaPreviewImageType}
            name="edit-media-preview-image-type-input"
            variant="outlined"
            className={classes.editFields}
          >
            <MenuItem value={0}>Link</MenuItem>
            <MenuItem value={1}>File Upload</MenuItem>
          </Select>
        </div>
        {isMediaPreviewFileUpload ? (
          <Box my={2}>
            <input
              type="file"
              id={`${media.id}preview-image-file-input`}
              multiple
              onChange={handleSelectPreviewImageFile}
              className={classes.input}
            />
            <label htmlFor={`${media.id}preview-image-file-input`}>
              <Button variant="outlined" component="span" disableElevation>
                SELECT THUMBNAIL IMAGE
              </Button>
            </label>
          </Box>
        ) : (
          <div>
            <TextField
              label="Thumbnail Image URL"
              value={mediaPreviewUrl}
              onChange={(event) => setMediaPreviewUrl(event.target.value)}
              margin="normal"
              variant="outlined"
              className={classes.editFields}
            />
          </div>
        )}
        <div>
          {originalMediaPreviewUrl || mediaPreviewUrl ? (
            <Cropper
              src={originalMediaPreviewUrl || mediaPreviewUrl}
              style={{ height: 400, width: '100%' }}
              aspectRatio={210.5 / 162.9}
              guides
              crop={onCrop}
              ref={cropperRef}
              autoCrop={false}
              autoCropArea={1}
            />
          ) : (
            <LoadingIndicator />
          )}
        </div>
      </MaterialDialogContent>
      <MaterialDialogActions className={classes.engageMediaItemEditModal}>
        <Button onClick={handleCancelEdit} color="primary">
          {strings.CANCEL}
        </Button>
        <Button
          onClick={async () => {
            if (onEdit) {
              setIsUpdating(true);
              const croppedMediaPreviewUrl = dataUrl
                ? await dataUrlToImageUrl({
                    dataUrl,
                    folioId,
                    orgId,
                  })
                : mediaPreviewUrl;
              await onEdit(
                media,
                mediaTitle,
                croppedMediaPreviewUrl,
                mediaUrl,
                originalMediaPreviewUrl
              );
              setIsUpdating(false);
              onClose();
            }
          }}
          color="primary"
        >
          {isUpdating ? <LoadingIndicator /> : strings.SAVE}
        </Button>
      </MaterialDialogActions>
    </MaterialDialog>
  );
};

export default EngageMediaItemEditModal;
