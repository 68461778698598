import { Money, OdometerUnit } from 'models/common';
import { PluginItem } from '../pluginItem';
import { ReconVelocityTagDefinition } from './tagDefinition';
import { ReconVelocityTaskDefinition } from './taskDefinition';
import { ReconVelocityWorkflow } from './workflow';

export interface ReconVelocityDealershipCosts {
  defaultEstimatedRecon: Money;
  usedPack: Money;
  newPack: Money;
  dailyHoldingCost: Money;
  dailyDepreciationCost: Money;
  defaultLaborRate: Money;
}

export enum ReconVelocityPluginItemReconMode {
  ALL = 'ALL',
  NEW = 'NEW',
  USED = 'USED',
}

export type ReconTime = 'BUSINESS_HOURS' | 'CALENDAR_HOURS';

export type WholesaleUnitOption =
  | 'INCLUDE_WHOLESALE'
  | 'EXCLUDE_WHOLESALE'
  | 'EXCLUDE_WHOLESALE_REMOVE_FROM_RECON';

export interface ReconVelocityPluginItem extends PluginItem {
  allowSendingNotifications: boolean;
  autoClearCompletedReconVehiclesAfter24Hours: boolean;
  enablePostRecon: boolean;
  enableRequireLaborAndPartsOnTasks: boolean;
  dealershipCosts: ReconVelocityDealershipCosts;
  excludeWholesaleUnitsFromImport: boolean;
  excludeWholesaleUnitsFromImportRemoveRecon: boolean;
  monthlySubscriptionFee: Money;
  onboarding: boolean;
  reconMode: ReconVelocityPluginItemReconMode;
  reconTimeIgnoreSchedule: boolean;
  odometerUnit: OdometerUnit;
  sessionTimeoutAsSeconds: number;
  settings: Record<string, any>;
  tagDefinitions: ReconVelocityTagDefinition[];
  taskDefinitions: ReconVelocityTaskDefinition[];
  workflows: ReconVelocityWorkflow[];
  retailReadyDangerAsSeconds: number;
  reconDangerAsSeconds: number;
}

export * from './tagDefinition';
export * from './taskDefinition';
export * from './workflow';
