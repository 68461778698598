import React, { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import { FlagRounded, Map } from '@material-ui/icons';
import { SettingsKeys } from 'common/types';
import { SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import allPermissions from 'common/permissions';
import PermissionGate from 'components/PermissionGate';
import SettingsSectionLink from './SettingsSectionLink';
import ApplicationsListDetailLayout from './ApplicationsListDetailLayout';
import BrochureMangerLayout from './BrochureMangerLayout';

interface SettingsItem {
  key: SettingsKeys;
  icon: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>>;
  label: string;
  path: string;
  element: ReactElement;
  permissions?: Array<keyof typeof allPermissions>;
}

const SETTINGS: Array<SettingsItem> = [
  {
    key: 'featureflags',
    icon: FlagRounded,
    label: 'Feature Flags',
    path: '/featureflags/*',
    element: <ApplicationsListDetailLayout />,
    permissions: [allPermissions.SETTINGS_FEATUREFLAG_VIEW],
  },
  {
    key: 'brochures',
    icon: Map,
    label: 'Manage Brochures',
    path: '/brochures/*',
    element: <BrochureMangerLayout />,
    permissions: [allPermissions.SETTINGS_FEATUREFLAG_VIEW],
  },
];

var SettingsView = () => {
  return (
    <div className="page-container">
      <Routes>
        <Route path="/">
          <div className="margin align-center" id="settings-view-container">
            {SETTINGS.map(({ key, icon, label, permissions }) => (
              <PermissionGate key={key} permissions={permissions}>
                <div style={{ margin: '5px' }}>
                  <SettingsSectionLink icon={icon} label={label} to={key} />
                </div>
              </PermissionGate>
            ))}
          </div>
        </Route>

        {SETTINGS.map(({ key, path, element, permissions }) => (
          <PermissionGate key={`${key}_route`} permissions={permissions}>
            <Route path={path} element={element} />
          </PermissionGate>
        ))}
      </Routes>
    </div>
  );
};

export default SettingsView;
