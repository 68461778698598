import React, { useMemo, useState } from 'react';
import pluralize from 'pluralize';
import { Button, FormControl, MenuItem, Select } from '@material-ui/core';
import utils from 'utils';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import Alert, { useAlert } from 'components/shared/Alert';
import { Check } from '@material-ui/icons';
import { ReconVelocityWorkflowStep } from 'models';
import { useUpdateReconVelocityWorkflowStep } from 'api/organizations/plugins/reconvelocity/workflows';

interface WorkflowStepChildStepsSectionProps {
  orgId: string;
  step: ReconVelocityWorkflowStep;
  forUpdating?: boolean;
  childSteps: ReconVelocityWorkflowStep[];
  otherSteps: ReconVelocityWorkflowStep[];
  allSteps: ReconVelocityWorkflowStep[];
}

const WorkflowStepChildStepsSection = ({
  orgId,
  step,
  forUpdating = false,
  childSteps,
  otherSteps,
  allSteps,
}: WorkflowStepChildStepsSectionProps) => {
  const { isAlertOpen, alertMessage, variant, openAlert, closeAlert } =
    useAlert();
  const updateStepMutation = useUpdateReconVelocityWorkflowStep(orgId, step.id);
  const [
    [newParentStepForChildSteps, setNewParentStepForChildSteps],
    [isUpdatingSteps, setIsUpdatingSteps],
    [childStepsUpdateState, setChildStepsUpdateState],
  ] = [
    useState<ReconVelocityWorkflowStep['id']>(),
    useState(false),
    useState<{ [key: string]: boolean }>({}),
  ];

  // potential parent steps are any steps that do not have the given step in their ancestry and are not the next step of any child steps
  const potentialParentSteps = useMemo(
    () =>
      otherSteps.filter(
        (otherStep) =>
          !utils.workflow.doesStepHaveAncestor(otherStep, step, allSteps) &&
          !childSteps.find(({ nextStep }) => nextStep?.id === otherStep.id)
      ),
    [childSteps, otherSteps, step, allSteps]
  );

  const onContinueClick = async () => {
    if (!allSteps) {
      openAlert('An unexpected error has occurred.', 'error');
      return;
    }

    const parentStep = allSteps.find(
      ({ id }) => id === newParentStepForChildSteps
    );

    if (!parentStep) {
      openAlert('No parent step selected.', 'error');
      return;
    }

    try {
      setIsUpdatingSteps(true);
      await childSteps.reduce(
        (promise, childStep) =>
          promise.then(async () => {
            setChildStepsUpdateState((current) => ({
              ...current,
              [childStep.id ?? '']: true,
            }));

            try {
              await updateStepMutation.updateStepAsync({
                ...childStep,
                parentStepId: parentStep.id,
              });
            } finally {
              setChildStepsUpdateState((current) => ({
                ...current,
                [childStep.id ?? '']: false,
              }));
            }
          }),
        Promise.resolve()
      );
    } finally {
      setIsUpdatingSteps(false);
    }
  };

  if (childSteps.length === 0) return null;

  return (
    <>
      <div>
        {`The following ${pluralize(
          'step',
          childSteps.length,
          childSteps.length > 1
        )} ${childSteps.length === 1 ? 'is a' : 'are'} ${pluralize(
          'child',
          childSteps.length
        )} of the `}
        <b>{step.name}</b>
        {' step:'}
      </div>

      <div className="margin">
        <div className="flex-column">
          {childSteps.map(
            ({
              id: childStepId,
              name: childStepName,
              nextStep: childStepNextStep,
            }) => (
              <div
                key={`child-steps-workflow-step-${childStepId}`}
                className="flex-row valign-center margin-bottom"
              >
                {childStepsUpdateState[childStepId ?? ''] !== undefined &&
                  (childStepsUpdateState[childStepId ?? ''] ? (
                    <LoadingIndicator size={20} className="margin-md" />
                  ) : (
                    <Check color="action" className="margin-md" />
                  ))}

                <div style={{ width: 200 }}>
                  <strong>{childStepName}</strong>
                  {childStepNextStep && (
                    <i className="block">Next step: {childStepNextStep.name}</i>
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </div>

      {forUpdating && (
        <>
          <div>
            {`Which step would you like to be the new parent step for ${pluralize(
              'this',
              childSteps.length
            )} ${pluralize('step', childSteps.length)}?`}
          </div>

          <br />

          <div className="flex-row">
            <FormControl variant="outlined" style={{ width: 250 }}>
              <Select
                variant="outlined"
                required
                value={newParentStepForChildSteps ?? ''}
                margin="dense"
                displayEmpty
                disabled={Boolean(isUpdatingSteps)}
                onChange={({ target: { value } }) =>
                  setNewParentStepForChildSteps(value as string)
                }
              >
                <MenuItem value="">Select a Step</MenuItem>
                {potentialParentSteps.map((stepDefinition) => (
                  <MenuItem
                    key={`child-steps-next-step-${stepDefinition.id}`}
                    value={stepDefinition.id}
                  >
                    {stepDefinition.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              variant="outlined"
              className="margin-left-sm"
              disabled={!newParentStepForChildSteps}
              onClick={onContinueClick}
            >
              {isUpdatingSteps ? <LoadingIndicator size={20} /> : 'Continue'}
            </Button>
          </div>
        </>
      )}
      <Alert
        open={isAlertOpen}
        message={alertMessage}
        variant={variant}
        onClose={closeAlert}
        duration={3500}
      />
    </>
  );
};

export default WorkflowStepChildStepsSection;
