import * as yup from 'yup';
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const phoneValidationSchema = yup
  .string()
  .min(10)
  .matches(phoneRegExp, 'Phone number is not valid')
  .required();

export const emailValidationSchema = yup.string().email().required();
