import times from 'lodash/times';
import random from 'lodash/random';

function stripDashes(value = '') {
  return value.replace(/[-]/g, '');
}

// NOTE: This was only written to handle time formatting
//       So, it does not take into account negative values
//       or anything like that.
function zeroPad(value: number) {
  return `0${value}`.slice(-2);
}

function createRandomCharacters() {
  return times(20, () => random(35).toString(36)).join('');
}

function cleanPhoneNumber(value: string) {
  return value ? value.replace(/[()\s-\s_]/g, '') : '';
}

function formatPhoneNumber(phoneNumber?: string) {
  if (!phoneNumber) return '';
  const areaCode = phoneNumber.slice(0, 3);
  const exchangeCode = phoneNumber.slice(3, 6);
  const lineNumber = phoneNumber.slice(6);

  return `(${areaCode}) ${exchangeCode}-${lineNumber}`;
}

function formatCAPostalCode(value: string) {
  if (!value) return '';
  const trimmed = value.slice(0, 6);
  const firstHalf = trimmed.slice(0, 3);
  const secondHalf = trimmed.slice(3, 6);
  return `${firstHalf}${secondHalf ? `-${secondHalf}` : ''}`;
}

function formatUSZipCode(value: string) {
  if (!value) return '';
  return value.slice(0, 5);
}

export default {
  createRandomCharacters,
  stripDashes,
  zeroPad,
  cleanPhoneNumber,
  formatCAPostalCode,
  formatUSZipCode,
  formatPhoneNumber,
};
