import filter from 'lodash/filter';

import { User } from 'models';
import { FetchedData } from 'store/reducers/types';
import {
  getUsersActionTypes,
  createUsersActionTypes,
  updateUserActionTypes,
  deleteUserActionTypes,
  UsersAction,
} from 'store/actions/users';

type UsersState = FetchedData<User[] | undefined>;

const initialState: UsersState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export default function usersReducer(
  state = initialState,
  action: UsersAction
): UsersState {
  switch (action.type) {
    case getUsersActionTypes.pending: {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }
    case getUsersActionTypes.fulfilled: {
      const { data } = action.payload ?? {};
      if (!data)
        return {
          ...state,
          loading: false,
        };

      return {
        ...state,
        loading: false,
        data: state.data ? [...state.data, ...data] : data,
        error: undefined,
      };
    }
    case getUsersActionTypes.rejected: {
      const { error } = action.payload ?? {};
      return {
        ...state,
        loading: false,
        error,
      };
    }

    case createUsersActionTypes.pending: {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }
    case createUsersActionTypes.fulfilled: {
      const { data } = action.payload ?? {};

      if (!data || data.length < 1) {
        return {
          ...state,
          loading: false,
        };
      }

      return {
        ...state,
        data: state.data ? [...state.data, ...data] : data,
        loading: false,
        error: undefined,
      };
    }
    case createUsersActionTypes.rejected: {
      const { error } = action.payload ?? {};
      return {
        ...state,
        loading: false,
        error,
      };
    }

    case updateUserActionTypes.pending: {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }
    case updateUserActionTypes.fulfilled: {
      const { data } = action.payload ?? {};

      if (!data || data.length < 1) {
        return {
          ...state,
          loading: false,
          error: undefined,
        };
      }

      const updatedUser: User = data[0];
      const outdatedUserPosition = state.data?.findIndex(
        (user) => user.id === updatedUser.id
      );

      if (outdatedUserPosition === -1) {
        const newListWithNewUser = state.data
          ? [...state.data, updatedUser]
          : [updatedUser];
        return {
          ...state,
          data: newListWithNewUser,
          loading: false,
          error: undefined,
        };
      }

      const newListWithReplacedUser =
        state.data && outdatedUserPosition
          ? [...state.data].splice(outdatedUserPosition, 1, updatedUser)
          : [updatedUser];

      return {
        ...state,
        data: newListWithReplacedUser,
        loading: false,
        error: undefined,
      };
    }
    case updateUserActionTypes.rejected: {
      const { error } = action.payload ?? {};
      return {
        ...state,
        loading: false,
        error,
      };
    }

    case deleteUserActionTypes.pending: {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }
    case deleteUserActionTypes.fulfilled: {
      const { data: success, params } = action.payload ?? {};
      const { userId: deletedUserId } = params ?? {};

      if (!success) {
        return {
          ...state,
          loading: false,
          error: undefined,
        };
      }

      const listWithoutDeletedUser = filter(
        state.data,
        (user) => user.id !== deletedUserId
      );

      return {
        ...state,
        data: listWithoutDeletedUser,
        loading: false,
        error: undefined,
      };
    }
    case deleteUserActionTypes.rejected: {
      const { error } = action.payload ?? {};

      return {
        ...state,
        loading: false,
        error,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}
