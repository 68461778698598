import { combineReducers } from 'redux';
import pluginsReducer from './plugins';

const utilityReducer = combineReducers({
  plugins: pluginsReducer,
});

export type UtilityState = ReturnType<typeof utilityReducer>;

export default utilityReducer;
