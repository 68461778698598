import { Button, DialogActions, DialogContent } from '@material-ui/core';
import Dialog from 'components/shared/Dialog';
import React, { useState } from 'react';
import axios, { AxiosError } from 'axios';
import { getAPIHeaders } from '../../../../api';
import LoadingIndicator from '../../../shared/LoadingIndicator';
import BulkUploadErrorTable from './BulkUploadErrorTable';
import Alert, { useAlert } from 'components/shared/Alert';
import strings from '../../../../common/strings';
import './BulkUserDialog.scss';

interface BulkUserDialogProps {
  onClose: () => void;
  organizationId: string;
  groupId?: string;
}

const BulkUserDialog = ({ onClose, organizationId }: BulkUserDialogProps) => {
  const [failedUploadResponse, setFailedUploadResponse] =
    useState<AxiosError | null>();
  const { isAlertOpen, alertMessage, openAlert, closeAlert, variant } =
    useAlert();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isFilePicked, setIsFilePicked] = useState<Boolean>(false);
  const [status, setStatus] = useState<
    'idle' | 'loading' | 'error' | 'complete' | 'ready'
  >('idle');

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files != null && event.target.files.length > 0) {
      const file = event.target.files[0];
      const preparedFile = new File([file], file.name, {
        type: file.type,
      });
      setSelectedFile(preparedFile);
      console.log(preparedFile.name);
      setIsFilePicked(true);
      setStatus('ready');
    }
  };

  const uploadBulkUsers = async () => {
    const { host, options } = getAPIHeaders('POST');
    const formData: FormData = new FormData();
    if (selectedFile != null) {
      formData.append('file', selectedFile);
      const url = `${host}/orgs/${organizationId}/users`;
      return await axios.post(url, formData, {
        headers: {
          ...options.headers,
          'Content-Type': 'multipart/form-data',
        },
      });
    }
  };

  async function handleUploadClick() {
    setStatus('loading');
    try {
      await uploadBulkUsers();
      setStatus('complete');
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status != 400) {
        openAlert(strings.API_MESSAGE + err.message, 'error');
        setStatus('idle');
      } else {
        setStatus('error');
        setFailedUploadResponse(err);
      }
    }
    setIsFilePicked(false);
  }

  return (
    <>
      <Dialog
        open
        maxWidth="md"
        title="Bulk User Upload"
        hideCancelButton={true}
        hidePrimaryButton={true}
      >
        <DialogContent>
          {status != 'complete' && (
            <div>
              Upload a CSV file with the first record as headers: <br />
              First Name, Last Name, Job Title, Email and Phone
              <br />
            </div>
          )}
          {status != 'complete' && status != 'loading' && (
            <input
              type="file"
              name="usersCsv"
              id="bulk-user-upload-button"
              className="bulk-user-dialogue-input"
              accept="text/csv"
              multiple={false}
              onChange={onFileChange}
            />
          )}
          {status === 'error' && failedUploadResponse != undefined && (
            <BulkUploadErrorTable failedUploadResponse={failedUploadResponse} />
          )}
          {status === 'complete' && (
            <div className="align-center">SUCCESS!</div>
          )}
        </DialogContent>
        {status != 'complete' && (
          <DialogActions>
            <Button color={'primary'} onClick={() => onClose()}>
              Cancel
            </Button>
            <Button
              color="secondary"
              style={{ color: 'white' }}
              variant="contained"
              onClick={handleUploadClick}
              disabled={!isFilePicked || status === 'loading'}
              disableRipple={!isFilePicked}
              startIcon={
                status === 'loading' && (
                  <LoadingIndicator size={13} color="primary" />
                )
              }
            >
              Create Users
            </Button>
          </DialogActions>
        )}
        {status === 'complete' && (
          <DialogActions>
            <Button
              color={'secondary'}
              variant="contained"
              fullWidth
              onClick={() => onClose()}
            >
              Done
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Alert
        open={isAlertOpen}
        message={alertMessage}
        onClose={closeAlert}
        duration={3500}
        variant={variant}
      />
    </>
  );
};

export default BulkUserDialog;
