import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import {
  Button,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Dialog from 'components/shared/Dialog';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import Alert, { useAlert } from 'components/shared/Alert';
import ConfirmationDialog, {
  useConfirmationDialog,
} from 'components/shared/ConfirmationDialog';
import NumberFormat from 'react-number-format';
import { useUser, sendPasswordReset } from 'api/users';
import { defaultUser, OrganizationItemLink, User } from 'models';
import { useNavigate } from 'react-router-dom';
import SetUserPasswordDialog from './SetUserPasswordDialog';
import UserOrganizationsListView from './UserOrganizationsListView';
import UserOrganizationDetailView from './UserOrganizationDetailView';
import utils from 'utils';

import './EditUserDialog.scss';
import { phoneValidationSchema } from 'common/validation';
import { useOrganization } from 'api';

interface EditUserDialogProps {
  onClose: (openDialog: boolean) => void;
  refreshActionAfterSave?: () => void;
  userId?: string;
  organizationId?: string;
}

const validationSchema = yup.object({
  firstName: yup.string().trim().min(1).required(),
  lastName: yup.string().trim().min(1).required(),
  email: yup.string().trim().email().required(),
  jobTitle: yup.string().trim().min(1).notRequired(),
  phone: phoneValidationSchema.notRequired(),
});

const EditUserDialog = (props: EditUserDialogProps) => {
  const { onClose, userId, organizationId } = props;

  const { isAlertOpen, alertMessage, variant, openAlert, closeAlert } =
    useAlert();
  const {
    isConfirmationDialogOpen,
    isConfirmationLoading,
    confirmationMessage,
    onAccept,
    onDeny,
    openConfirmationDialog,
  } = useConfirmationDialog();

  const [initialValues, setInitialValues] = useState(false);
  const { data: organization } = useOrganization(organizationId);

  const [isSetUserPasswordDialogOpen, setIsSetUserPasswordDialogOpen] =
    useState<boolean>(false);
  const {
    query: { data },
  } = useUser(userId, organizationId);
  const [user, setUser] = useState<User>(
    data && userId
      ? data
      : {
          ...defaultUser,
          id: userId ?? '',
        }
  );
  const { mutation } = useUser(user.id, organizationId);
  const [selectedOrganizationId, setSelectedOrganizationId] =
    useState<string>('');
  const submissionError = useRef<boolean>(false);

  const validate = () => {
    if (!user?.organizations?.length) {
      openAlert('Must add at least one organization.', 'error');
      return false;
    }

    if (!user.defaultOrganization) {
      openAlert('Must choose a default organization.', 'error');
      return false;
    }

    if (user.defaultOrganization) {
      const defaultOrgId = user.defaultOrganization.id;
      const isDefaultOrgValid = user.organizations.find(
        (org) => org.id === defaultOrgId
      );

      if (!isDefaultOrgValid) {
        openAlert('Must choose a default organization.', 'error');
        return false;
      }
    }

    const orgWithoutGroup = user.organizations.find(
      (org) => !user.groups?.find((grp) => grp.organization.id === org.id)
    );
    if (orgWithoutGroup) {
      openAlert(
        `Must choose at least one group for organization ${orgWithoutGroup.name}.`,
        'error'
      );
      return false;
    }

    return true;
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      jobTitle: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      // This ensures that the state of the submissionError ref is reset upon every request
      submissionError.current = false;
      if (validate()) {
        try {
          await mutation.mutateAsync(
            {
              ...user,
              firstName: values.firstName,
              lastName: values.lastName,
              jobTitle: values.jobTitle,
              email: values.email,
              mobilePhone: {
                number: values.phone,
                allowSMS: user?.mobilePhone?.allowSMS,
              },
            },
            {
              onSuccess: (data) => {
                if (data.data.length > 0) {
                  setUser(data.data[0]);
                } else {
                  setUser(data.data);
                }
              },
              onError: () => {
                submissionError.current = true;
              },
            }
          );
        } catch (error: any) {
          if (error?.message === 'Conflict') {
            openAlert(
              `User with username ${values.email} already exists`,
              `error`
            );
          } else {
            openAlert(`There was an API error`, `error`);
          }
        }
      } else {
        submissionError.current = true;
      }
    },
  });

  // TODO move these into a common routing file as constants
  const defaultTabKey = 'groups';

  const navigate = useNavigate();
  useEffect(() => {
    if (user?.organizations && user?.organizations?.length > 0) {
      navigate(`${user.organizations?.[0].id}/${defaultTabKey}`, {
        replace: true,
      });
      setSelectedOrganizationId(organizationId || user.organizations?.[0].id);
    }
  }, [defaultTabKey, navigate, organizationId, user.organizations]);

  useEffect(() => {
    if (data && !!data?.id && user.id) {
      setUser(data);
    }
  }, [data, setUser, user.id]);

  useEffect(() => {
    if (data && !!data?.id && !!user.id && !initialValues) {
      const { firstName, lastName, mobilePhone, email, jobTitle } = data;
      formik.setValues({
        firstName: firstName ?? '',
        lastName: lastName ?? '',
        phone: mobilePhone?.number ?? '',
        email: email ?? '',
        jobTitle: jobTitle ?? '',
      });
      setInitialValues(true);
    }
  }, [formik, data, user.id, initialValues]);

  useEffect(() => {
    if (
      organization?.status &&
      organization?.positionType &&
      organization?.type
    ) {
      const userOrganization = user?.organizations?.filter(
        (o) => o.id === organization.id
      );
      if (!userOrganization || userOrganization?.length === 0) {
        const defaultOrganization = {
          id: organization.id,
          name: organization.name,
          status: organization.status,
          positionType: organization.positionType,
          type: organization.type,
        };
        setUser({
          ...user,
          defaultOrganization: user.defaultOrganization ?? defaultOrganization,
          organizations: [...(user?.organizations ?? []), defaultOrganization],
        });
      }
    }
  }, [organization, user]);

  const flatOrganizations = (
    organizations: OrganizationItemLink[]
  ): OrganizationItemLink[] =>
    organizations.reduce((acc, cur) => {
      acc.push(cur);
      if (cur.children) return acc.concat(flatOrganizations(cur.children));
      return acc;
    }, [] as OrganizationItemLink[]);

  const findOrganizationById = (id: string) => {
    const flatOrgs = flatOrganizations(user?.organizations || []);
    return flatOrgs?.find((org) => org?.id === id);
  };

  const handleSendPasswordResetEmailClick = async () => {
    if (!user) return;

    // TODO: check if email has been saved
    // if (false) {
    //   openConfirmationDialog({
    //     messageOverride: `You edited this user's email address but haven't saved yet. You must save this user before sending the password reset email to the new email address. Would you like to save this user now?`,
    //     stayOpenOnAcceptOverride: true,
    //     onAcceptOverride: async () => {
    //       // const success = await state.save();
    //       // if (!success) return;
    //       openConfirmationDialog({
    //         messageOverride: `Send a password-reset email to this user at ${user.email}?`,
    //         stayOpenOnAcceptOverride: false,
    //         onAcceptOverride: async () => sendPasswordReset(user.id)
    //       });
    //     },
    //   });
    //   return;
    // }

    openConfirmationDialog({
      messageOverride: `Send a password-reset email to this user at ${user.email}?`,
      stayOpenOnAcceptOverride: false,
      onAcceptOverride: async () => sendPasswordReset(user.id),
    });
  };

  return (
    <>
      <Dialog
        open
        className="EditUserDialog"
        fullWidth
        hideCancelButton
        hidePrimaryButton
        maxWidth="lg"
        onSubmit={formik.handleSubmit}
      >
        <DialogTitle>
          <Typography>
            {user?.id ?? userId
              ? `Edit User ID: ${user.id ?? userId}`
              : 'New User'}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div className="full-height full-width flex-rows">
            <div className="EditUserDialog__topForm full-width side-padding">
              <div className="column">
                <TextField
                  className="form-control"
                  id="EditUserDialog-first-name"
                  label="First Name"
                  name="firstName"
                  variant="outlined"
                  required
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  margin="dense"
                  error={formik.touched.firstName && !!formik.errors.firstName}
                />
                <TextField
                  className="form-control"
                  id="EditUserDialog-last-name"
                  label="Last Name"
                  name="lastName"
                  variant="outlined"
                  required
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  margin="dense"
                  error={formik.touched.lastName && !!formik.errors.lastName}
                />
              </div>
              <div className="column">
                <TextField
                  className="form-control"
                  id="EditUserDialog-job-title"
                  label="Job Title"
                  variant="outlined"
                  name="jobTitle"
                  value={formik.values.jobTitle}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  margin="dense"
                  error={formik.touched.jobTitle && !!formik.errors.jobTitle}
                />
                <TextField
                  className="form-control"
                  id="EditUserDialog-email"
                  label="Email"
                  variant="outlined"
                  required
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  margin="dense"
                  error={formik.touched.email && !!formik.errors.email}
                />
              </div>
              <div className="column">
                <div className="right-column-top-row">
                  <NumberFormat
                    id="EditUserDialog-phone"
                    customInput={TextField}
                    className="form-control form-control-phone"
                    label="Phone"
                    name="phone"
                    inputMode="tel"
                    type="tel"
                    variant="outlined"
                    margin="dense"
                    format="(###) ###-####"
                    mask="_"
                    value={formik.values.phone}
                    onChange={(e) => {
                      const newPhone = utils.strings.cleanPhoneNumber(
                        e.target.value
                      );
                      formik.setFieldValue('phone', newPhone);
                    }}
                    error={formik.touched.phone && !!formik.errors.phone}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={user?.mobilePhone?.allowSMS}
                        onChange={() =>
                          setUser({
                            ...user,
                            mobilePhone: {
                              ...user.mobilePhone,
                              allowSMS: !user?.mobilePhone?.allowSMS,
                            },
                          })
                        }
                      />
                    }
                    label="Allow SMS"
                  />
                </div>
                <div className="right-column-bottom-row">
                  <FormLabel>Status</FormLabel>
                  <RadioGroup
                    row
                    name="status"
                    value={user?.status ?? ''}
                    onChange={(e) =>
                      setUser({ ...user, status: e.target.value })
                    }
                  >
                    <FormControlLabel
                      value="ACTIVE"
                      control={<Radio />}
                      label="Active"
                    />
                    <FormControlLabel
                      value="INACTIVE"
                      control={<Radio />}
                      label="Inactive"
                    />
                  </RadioGroup>
                </div>
              </div>
            </div>
            <div className="flex-grow margin-top border-top border-bottom">
              <div className="EditUserDialog-rooftop-content">
                <UserOrganizationsListView
                  userId={user.id}
                  userOrganizations={user.organizations}
                  selectedOrganizationId={selectedOrganizationId}
                  setSelectedOrganizationId={setSelectedOrganizationId}
                  setUser={setUser}
                />
                <UserOrganizationDetailView
                  organization={findOrganizationById(selectedOrganizationId)}
                  user={user}
                  setUser={setUser}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <>
            {user?.id ? (
              <Button type="button" onClick={handleSendPasswordResetEmailClick}>
                Send Password Reset Email
              </Button>
            ) : (
              <Form.Check
                inline
                id="EditUserDialog.sendWelcomeMessage"
                type="checkbox"
                name="EditUserDialog.sendWelcomeMessage"
                label="Send Welcome Message"
                onChange={() =>
                  setUser({
                    ...user,
                    sendWelcomeMessage: !user.sendWelcomeMessage,
                  })
                }
                checked={user?.sendWelcomeMessage}
              />
            )}
            <Button
              type="button"
              onClick={() => setIsSetUserPasswordDialogOpen(true)}
            >
              Set Password&hellip;
            </Button>
          </>
          <div className="EditUserDialog-divider" />
          <Button onClick={() => onClose(false)} disabled={mutation.isLoading}>
            Cancel
          </Button>
          <Button
            id="modal-save-button"
            color="secondary"
            style={{ color: 'white' }}
            variant="contained"
            disabled={mutation.isLoading}
            onClick={() => formik.submitForm()}
          >
            {mutation.isLoading ? (
              <LoadingIndicator size={13} color="primary" />
            ) : (
              'Save'
            )}
          </Button>
          <Button
            id="modal-save-and-close-button"
            color="secondary"
            style={{ color: 'white' }}
            variant="contained"
            disabled={mutation.isLoading}
            onClick={async () => {
              await formik.submitForm();
              if (!submissionError.current && formik.isValid) {
                onClose(false);
              }
            }}
          >
            {mutation.isLoading ? (
              <LoadingIndicator size={13} color="primary" />
            ) : (
              'Save and Close'
            )}
          </Button>
        </DialogActions>
        {isSetUserPasswordDialogOpen && (
          <SetUserPasswordDialog
            userId={user.id}
            onClose={() => setIsSetUserPasswordDialogOpen(false)}
            setFirstPassword={(firstPassword: string) =>
              setUser({ ...user, firstPassword })
            }
          />
        )}
      </Dialog>
      <Alert
        open={isAlertOpen}
        message={alertMessage}
        variant={variant}
        duration={3500}
        onClose={closeAlert}
      />
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        isLoading={isConfirmationLoading}
        onPrimaryButtonClick={onAccept}
        onSecondaryButtonClick={onDeny}
        message={confirmationMessage}
      />
    </>
  );
};

export default EditUserDialog;
