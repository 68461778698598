import React, { useState } from 'react';
import {
  Close,
  Delete,
  Edit,
  Star,
  StarBorder,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import {
  Button,
  Dialog as MaterialDialog,
  DialogActions as MaterialDialogActions,
  DialogContent as MaterialDialogContent,
  DialogTitle as MaterialDialogTitle,
  IconButton,
  Snackbar,
  SnackbarContent,
} from '@material-ui/core';
import { FolioDocumentTemplate } from 'models';
import strings from 'common/strings';
import './EngageMediaItem.scss';
import { isMobileViewport } from 'common/viewport';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import EngageMediaPlaceholder from '../EngageMediaPlaceholder';
import EngageMediaItemEditModal from './EngageMediaItemEditModal';

interface EngageMediaItemProps {
  orgId: string;
  folioId: string;
  media: FolioDocumentTemplate;
  inFeaturedMediaContainer: boolean;
  onStar: (document: FolioDocumentTemplate) => void;
  onPublish: (document: FolioDocumentTemplate) => void;
  onDelete: (document: FolioDocumentTemplate) => void;
  onEdit: (
    document: FolioDocumentTemplate,
    title: string,
    previewImageUrl: string,
    mediaUrl?: string
  ) => void;
  isDragging: boolean;
}

interface EngageMediaItemComponentProps {
  inFeaturedMediaContainer: boolean;
  onStar: (document: FolioDocumentTemplate) => void;
  onPublish: (document: FolioDocumentTemplate) => void;
  media: FolioDocumentTemplate;
  onMediaClick: () => void;
  onEditClick: () => void;
  isDeleteModalOpen: boolean;
  setIsDeleteModalOpen: (isOpen: boolean) => void;
  isDragging: boolean;
}

const EngageMediaItemOverlay: React.FC<EngageMediaItemComponentProps> = ({
  inFeaturedMediaContainer = false,
  onStar,
  onPublish,
  media,
  onMediaClick,
  onEditClick,
  isDeleteModalOpen,
  setIsDeleteModalOpen,
  isDragging,
}: EngageMediaItemComponentProps) => {
  const { featured: isFeatured, visible: isVisible, displayLabel } = media;
  const StarIcon = isFeatured ? Star : StarBorder;
  const starIconClassName = isFeatured ? 'EngageMediaItem-star-icon' : '';

  const EyeIcon = isVisible ? Visibility : VisibilityOff;
  const eyeIconClassName = isVisible ? '' : 'EngageMediaItem-eye-icon';

  return (
    <div className="EngageMediaItem item">
      <div className="item-content">
        <div>
          <div
            className="EngageMediaItem-info"
            role="none"
            onClick={() => {
              if (!isDragging) {
                onMediaClick();
              }
            }}
          >
            {media.url || !media.canUpdateUrl ? (
              <>
                <img
                  className="EngageMediaItem-info-image"
                  src={media.previewUrl}
                  alt="car"
                />
                <div className="EngageMediaItem-info-image-overlay" />
                <span className="EngageMediaItem-info-title">
                  {displayLabel}
                </span>
              </>
            ) : (
              <EngageMediaPlaceholder
                title={media.displayLabel}
                onClick={onEditClick}
              />
            )}
          </div>
          <div className="EngageMediaItem-icons">
            <StarIcon
              className={`EngageMediaItem-icon ${starIconClassName}`}
              onClick={() => onStar(media)}
            />
            {!inFeaturedMediaContainer && (
              <>
                <EyeIcon
                  className={`EngageMediaItem-icon ${eyeIconClassName}`}
                  onClick={() => onPublish(media)}
                />
                <Edit className="EngageMediaItem-icon" onClick={onEditClick} />
                {media.canDelete && (
                  <>
                    <Delete
                      className="EngageMediaItem-icon"
                      onClick={() => {
                        if (isDeleteModalOpen) {
                          setIsDeleteModalOpen(false);
                        }
                        setIsDeleteModalOpen(!isDeleteModalOpen);
                      }}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const EngageMediaItemHorizontal: React.FC<EngageMediaItemComponentProps> = ({
  inFeaturedMediaContainer = false,
  onStar,
  onPublish,
  media,
  onMediaClick,
  onEditClick,
  isDeleteModalOpen,
  setIsDeleteModalOpen,
  isDragging,
}: EngageMediaItemComponentProps) => {
  const { featured, visible, displayLabel } = media;
  const StarIcon = featured ? Star : StarBorder;
  const starIconClassName = featured
    ? 'EngageMediaItemHorizontal-star-icon'
    : '';

  const EyeIcon = visible ? Visibility : VisibilityOff;
  const eyeIconClassName = visible ? '' : 'EngageMediaItemHorizontal-eye-icon';

  return (
    <div className="item EngageMediaItemHorizontal-margin">
      <div className="item-content">
        <div className="EngageMediaItemHorizontal">
          <div
            className="EngageMediaItemHorizontal-image-container"
            role="none"
            onClick={() => {
              if (!isDragging) {
                onMediaClick();
              }
            }}
          >
            {media.url ? (
              <img
                className="EngageMediaItemHorizontal-image"
                src={media.previewUrl}
                alt="car"
              />
            ) : (
              <EngageMediaPlaceholder
                title={media.displayLabel}
                onClick={onEditClick}
              />
            )}
          </div>
          <div
            className={`EngageMediaItemHorizontal-info${
              media.url ? '' : ' EngageMediaItemHorizontal-info-padding'
            }`}
          >
            <span className="EngageMediaItemHorizontal-title">
              {displayLabel}
            </span>
            <div className="EngageMediaItemHorizontal-icons">
              <StarIcon
                className={`EngageMediaItemHorizontal-icon ${starIconClassName}`}
                onClick={() => onStar(media)}
              />
              {!inFeaturedMediaContainer && (
                <>
                  <EyeIcon
                    className={`EngageMediaItemHorizontal-icon ${eyeIconClassName}`}
                    onClick={() => onPublish(media)}
                  />
                  {media.canDelete && (
                    <>
                      <Delete
                        className="EngageMediaItemHorizontal-icon"
                        onClick={() => {
                          if (isDeleteModalOpen) {
                            setIsDeleteModalOpen(false);
                          }
                          setIsDeleteModalOpen(!isDeleteModalOpen);
                        }}
                      />
                      <Edit
                        className="EngageMediaItemHorizontal-icon"
                        onClick={onEditClick}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EngageMediaItem: React.FC<EngageMediaItemProps> = ({
  folioId,
  orgId,
  media,
  inFeaturedMediaContainer,
  onStar,
  onEdit,
  onPublish,
  onDelete,
  isDragging,
}: EngageMediaItemProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const onMediaClick = () => {
    if (media.url) {
      window.open(media.url, '_blank');
    } else {
      setErrorMessage(strings.NO_DOCUMENT_URL);
    }
  };

  const onEditClick = () => {
    if (isEditModalOpen) {
      setIsEditModalOpen(false);
    }
    setIsEditModalOpen(!isEditModalOpen);
  };

  return (
    <>
      {isMobileViewport() ? (
        <EngageMediaItemHorizontal
          inFeaturedMediaContainer={inFeaturedMediaContainer}
          onStar={onStar}
          onPublish={onPublish}
          media={media}
          onMediaClick={onMediaClick}
          onEditClick={onEditClick}
          isDeleteModalOpen={isDeleteModalOpen}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          isDragging={isDragging}
        />
      ) : (
        <EngageMediaItemOverlay
          inFeaturedMediaContainer={inFeaturedMediaContainer}
          onStar={onStar}
          onPublish={onPublish}
          media={media}
          onMediaClick={onMediaClick}
          onEditClick={onEditClick}
          isDeleteModalOpen={isDeleteModalOpen}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          isDragging={isDragging}
        />
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={!!errorMessage}
        onClose={() => setErrorMessage('')}
      >
        <SnackbarContent
          aria-describedby="client-snackbar"
          message={<span>{errorMessage}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={() => setErrorMessage('')}
            >
              <Close />
            </IconButton>,
          ]}
        />
      </Snackbar>
      <EngageMediaItemEditModal
        folioId={folioId}
        media={media}
        onClose={() => setIsEditModalOpen(false)}
        onEdit={onEdit}
        open={isEditModalOpen}
        orgId={orgId}
      />
      <MaterialDialog
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        maxWidth="xs"
        aria-labelledby="edit-media-delete-dialog-title"
      >
        <MaterialDialogTitle id="edit-media-delete-dialog-title">
          {strings.ARE_YOU_SURE}
        </MaterialDialogTitle>
        <MaterialDialogContent>
          <div>
            Are you sure you want to delete media from this folio template?
          </div>
        </MaterialDialogContent>
        <MaterialDialogActions>
          <Button onClick={() => setIsDeleteModalOpen(false)} color="primary">
            {strings.CANCEL}
          </Button>
          <Button
            onClick={async () => {
              if (onDelete) {
                setIsDeleting(true);
                onDelete(media);
                setIsDeleting(false);
                setIsDeleteModalOpen(false);
              }
            }}
            color="primary"
          >
            {isDeleting ? strings.DELETE : <LoadingIndicator />}
          </Button>
        </MaterialDialogActions>
      </MaterialDialog>
    </>
  );
};

export default EngageMediaItem;
