import React, { useState, useCallback } from 'react';
import { compact } from 'lodash';
import {
  Draggable,
  DraggingStyle,
  Droppable,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import { Delete, DragIndicatorRounded } from '@material-ui/icons';

import {
  DeleteReconVelocityWorkflowStepData,
  ReconVelocityWorkflowStep,
} from 'models';
import utils from 'utils';
import { useDraggableInPortal } from 'common/hooks';
import theme from 'common/theme';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import PermissionGate from 'components/PermissionGate';
import permissions from 'common/permissions';
import { useDeleteReconVelocityWorkflowStep } from 'api/organizations/plugins/reconvelocity/workflows';
import Alert, { useAlert } from 'components/shared/Alert';

import DeleteWorkflowStepDialog from './DeleteWorkflowStepDialog';

const INDENT_PX = 15;

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined
): React.CSSProperties => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',

  // change background color if dragging
  background: isDragging
    ? theme.palette.action.focus
    : theme.palette.background.paper,

  // styles we need to apply on draggables
  ...draggableStyle,
});

type DisplayStepDefinitions = {
  [id: string]: DisplayStepDefinition;
};

export interface DisplayStepDefinition
  extends Pick<ReconVelocityWorkflowStep, 'id' | 'name' | 'stepOrder'> {
  childSteps?: DisplayStepDefinitions;
}

interface StepDefinitionRowProps {
  orgId: string;
  displayStep: DisplayStepDefinition;
  index: number;
  allSteps: ReconVelocityWorkflowStep[];
  movingStep?: ReconVelocityWorkflowStep['id'];
  parentDroppableId?: string;
  getListStyle: (isDraggingOver: boolean) => React.CSSProperties;
  selectedStepId: string | undefined;
  setSelectedStepId: (id: string | undefined) => void;
}

var StepDefinitionRow = (props: StepDefinitionRowProps) => {
  const {
    orgId,
    displayStep,
    index,
    allSteps,
    parentDroppableId,
    movingStep,
    getListStyle,
    selectedStepId,
    setSelectedStepId,
  } = props;

  const { isAlertOpen, alertMessage, variant, closeAlert, openAlert } =
    useAlert();
  const [isDeletingStep, setIsDeletingStep] = useState(false);
  const { deleteStepAsync, reset } = useDeleteReconVelocityWorkflowStep(
    orgId,
    displayStep.id
  );
  const renderDraggable = useDraggableInPortal();
  const stepDefinitionIndex =
    allSteps.findIndex(({ id }) => id === displayStep.id) ?? -1;
  const stepDefinition = allSteps[stepDefinitionIndex];
  const isSelectedStep = stepDefinition?.id === selectedStepId;
  const stepLevel = utils.workflow.getStepLevel(stepDefinition, allSteps);

  const onDelete = async (data: DeleteReconVelocityWorkflowStepData) => {
    try {
      await deleteStepAsync(data);
    } catch (error) {
      // Show alert on error deleting a step
      openAlert('There was an API error when deleting the step', 'error');
    }
  };

  const handleDeleteClick = useCallback(() => {
    setIsDeletingStep(true);
  }, [setIsDeletingStep]);

  if (!stepDefinition) return null;

  const droppableId = compact([parentDroppableId, displayStep.id]).join('->');

  const sortedChildSteps = Object.values(displayStep.childSteps ?? {}).sort(
    ({ stepOrder: A }, { stepOrder: B }) => A - B
  );

  const selectedStep = allSteps.find((s) => s.id === selectedStepId);

  return (
    <>
      <Draggable
        key={displayStep.id}
        draggableId={`workflowStepDefinitionDraggable_${displayStep.id}`}
        index={index}
        isDragDisabled={Boolean(movingStep)}
      >
        {renderDraggable((draggableProvided, draggableSnapshot) => (
          <div
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            style={getItemStyle(
              draggableSnapshot.isDragging,
              draggableProvided.draggableProps.style
            )}
          >
            <div
              role="listitem"
              className="ReactVirtualized__Table__row valign-center padding-right-sm"
              onClick={() => {
                setSelectedStepId(stepDefinition?.id);
              }}
              id={`step-definition-${stepLevel === 0 ? 'parent' : 'child'}`}
              style={{
                cursor: 'pointer',
                paddingLeft: stepLevel * INDENT_PX,
                backgroundColor: isSelectedStep
                  ? theme.palette.action.focus
                  : theme.palette.background.paper,
              }}
            >
              <div
                className="valign-center margin-sm"
                {...draggableProvided.dragHandleProps}
              >
                {Boolean(movingStep) && movingStep === stepDefinition.id ? (
                  <LoadingIndicator size={24} />
                ) : (
                  <DragIndicatorRounded
                    color="disabled"
                    style={{ cursor: 'drag' }}
                  />
                )}
              </div>
              <div className="flex-grow">
                <span>{stepDefinition.name}</span>
              </div>
              <PermissionGate
                permissions={[
                  permissions.ORGS_PLUGINS_RECONVELOCITY_WORKFLOWS_STEPS_DELETE,
                ]}
              >
                <Delete
                  className="margin-sm deleteStep"
                  onClick={() => handleDeleteClick()}
                />
              </PermissionGate>
            </div>

            <Droppable
              type={`childStepOf${displayStep.id}`}
              droppableId={droppableId}
            >
              {(droppableProvided, droppableSnapshot) => (
                <div
                  ref={droppableProvided.innerRef}
                  style={getListStyle(droppableSnapshot.isDraggingOver)}
                  {...droppableProvided.droppableProps}
                >
                  {sortedChildSteps.map((step, i) => (
                    <StepDefinitionRow
                      key={`workflow-step-${step.id}`}
                      orgId={orgId}
                      displayStep={step}
                      index={i}
                      allSteps={allSteps}
                      movingStep={movingStep}
                      getListStyle={getListStyle}
                      selectedStepId={selectedStepId}
                      setSelectedStepId={setSelectedStepId}
                    />
                  ))}
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        ))}
      </Draggable>
      {isDeletingStep && selectedStep && (
        <DeleteWorkflowStepDialog
          orgId={orgId}
          workflowStepDefinition={selectedStep}
          onDelete={onDelete}
          onClose={() => setIsDeletingStep(false)}
        />
      )}
      <Alert
        open={isAlertOpen}
        message={alertMessage}
        variant={variant}
        onClose={() => {
          reset();
          closeAlert();
        }}
        duration={3500}
      />
    </>
  );
};

export default StepDefinitionRow;
