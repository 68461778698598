import { defaultMutationFn } from 'api/useAPI';
import { VehicleMakeData } from 'models/settings';
import { useMutation, useQuery } from 'react-query';

interface BrochureUploadProps {
  year: string;
  make: string;
  model: string;
  file: File;
}

/**
 * Get Vehicle Brochure Catalog
 * Endpoint GET `/brochures/list`
 * @param placeholderData - dummy data for use with useAPI
 * @returns Full brochure catalog with URLs
 */
export function useBrochureList() {
  const path = '/brochures/list';
  return useQuery<VehicleMakeData[]>(path, {
    keepPreviousData: true,
  });
}

/**
 * For a given make, model and year - upload a PDF brochure to catalog.
 *
 * TODO - Support File transfer probably with axios.
 */
export function useBrochureUpload({ year, make, model }: BrochureUploadProps) {
  const mutation = useMutation((data) => {
    const filename = `${make}_${model}-${year}.pdf`;
    const path = `/brochures/save/${year}/${make}/${model}?file=${filename}`;
    return defaultMutationFn(path, 'PUT', data);
  });

  return {
    ...mutation,
    uploadBrochureAsync: mutation.mutateAsync,
  };
}

export default useBrochureList;
