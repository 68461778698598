import { configuredFetch } from 'api/base';
import { ReconVelocityPluginItem } from 'models/plugins/reconVelocityPlugin';
import { Organization } from 'models';
import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
  useMutation,
} from 'react-query';
import { APIResponse, defaultMetaQueryFn, defaultMutationFn } from 'api/useAPI';

export type ReconVelocityPluginRequestParams = {
  orgId: Organization['id'];
};

function baseUrl({ orgId }: ReconVelocityPluginRequestParams) {
  return `/orgs/${orgId}/plugins/reconvelocity`;
}

function getReconVelocityPath(orgId: Organization['id']) {
  return `/orgs/${orgId}/plugins/reconvelocity`;
}

/**
 * Gets Recon Velocity plugin
 * @param orgId
 * @returns Recon Velocity plugin
 */
export function useReconVelocityPlugin(
  orgId: Organization['id'],
  options?: UseQueryOptions<APIResponse<ReconVelocityPluginItem>>
) {
  const path = getReconVelocityPath(orgId);
  return useQuery<APIResponse<ReconVelocityPluginItem>>(
    path,
    () => defaultMetaQueryFn(path),
    options
  );
}

/**
 * Create new Recon Velocity plugin
 * @param orgId
 * @returns Newly created Recon Velocity plugin
 */
export function useCreateReconVelocityPlugin(orgId: Organization['id']) {
  const queryClient = useQueryClient();
  const mutation = useMutation((data) => {
    const path = getReconVelocityPath(orgId);
    return defaultMutationFn(path, 'POST', data);
  });

  async function createReconVelocityPluginAsync(reconVelocitySettings: any) {
    await queryClient.cancelQueries(getReconVelocityPath(orgId));
    const response = await mutation.mutateAsync(reconVelocitySettings);
    await queryClient.invalidateQueries(getReconVelocityPath(orgId));
    return response;
  }

  return {
    ...mutation,
    createReconVelocityPluginAsync,
  };
}

/**
 * Update Recon Velocity plugin
 * @param orgId
 * @returns Updated Recon Velocity plugin
 */
export function useUpdateReconVelocityPlugin(orgId: Organization['id']) {
  const queryClient = useQueryClient();
  const mutation = useMutation((data) => {
    const path = getReconVelocityPath(orgId);
    return defaultMutationFn(path, 'PUT', data);
  });

  async function updateReconVelocityPluginAsync(reconVelocitySettings: any) {
    await queryClient.cancelQueries(getReconVelocityPath(orgId));
    const response = await mutation.mutateAsync(reconVelocitySettings);
    await queryClient.invalidateQueries(getReconVelocityPath(orgId));
    return response;
  }

  return {
    ...mutation,
    updateReconVelocityPluginAsync,
  };
}

/**
 * Delete Recon Velocity plugin
 * @param orgId
 * @returns Updated Recon Velocity plugin
 */
export function useDeleteReconVelocityPlugin(orgId: Organization['id']) {
  const queryClient = useQueryClient();
  const mutation = useMutation((data) => {
    const path = getReconVelocityPath(orgId);
    return defaultMutationFn(path, 'DELETE', data);
  });

  async function deleteReconVelocityPluginAsync(reconVelocitySettings: any) {
    await queryClient.cancelQueries(getReconVelocityPath(orgId));
    const response = await mutation.mutateAsync(reconVelocitySettings);
    await queryClient.invalidateQueries(getReconVelocityPath(orgId));
    return response;
  }

  return {
    ...mutation,
    deleteReconVelocityPluginAsync,
  };
}
/**
 * Get ReconVelocity Plugin
 * Endpoint: GET /orgs/{orgId}/plugins/reconvelocity
 */
async function getPlugin({ orgId }: ReconVelocityPluginRequestParams) {
  const url = baseUrl({ orgId });
  const { data } = await configuredFetch<ReconVelocityPluginItem>(url);
  return data;
}

/**
 * Update ReconVelocity Plugin
 * Endpoint: PUT /orgs/{orgId}/plugins/reconvelocity
 */
export type UpdateReconVelocityPluginRequestParams =
  ReconVelocityPluginRequestParams & {
    data: Partial<ReconVelocityPluginItem>;
    isNewPlugin: boolean;
  };
async function updatePlugin({
  orgId,
  data: updateData,
  isNewPlugin,
}: UpdateReconVelocityPluginRequestParams) {
  const url = baseUrl({ orgId });
  const { data } = await configuredFetch<ReconVelocityPluginItem>(url, {
    method: isNewPlugin ? 'post' : 'put',
    body: JSON.stringify(updateData),
  });
  return data;
}

export default {
  baseUrl,
  getPlugin,
  updatePlugin,
};
