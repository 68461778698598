import React from 'react';
import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import { Tag } from 'models';

import './TagChip.scss';

interface TagChipProps {
  tag: Tag;
  removable?: boolean;
  onRemove?: () => void;
}

var TagChip = ({ tag, removable = false, onRemove }: TagChipProps) => {
  return (
    <div className="TagChip" style={{ backgroundColor: tag.color }}>
      {tag.label}
      {removable && (
        <IconButton
          disableRipple
          size="small"
          onClick={() => {
            onRemove?.();
          }}
        >
          <Close />
        </IconButton>
      )}
    </div>
  );
};

export default TagChip;
