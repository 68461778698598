import React, { useState } from 'react';

import { Business, Person } from '@material-ui/icons';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { FeatureFlagMember, MemberType } from 'models';
import {
  useFeatureFlagApplicationsFlag,
  useFeatureFlagApplicationsFlagMembers,
} from 'api';
import ConfirmationDialog, {
  useConfirmationDialog,
} from 'components/shared/ConfirmationDialog';
import AssignMemberDialog from './AssignMemberDialog';

interface FeatureFlagDetailViewProps {
  applicationName: string;
  featureFlagId: string;
}

const FeatureFlagDetailView = ({
  applicationName,
  featureFlagId,
}: FeatureFlagDetailViewProps) => {
  const [isAssignMemberDialogOpen, setIsAssignMemberDialogOpen] =
    useState<boolean>(false);
  const { data: featureFlag, isLoading } = useFeatureFlagApplicationsFlag(
    applicationName,
    featureFlagId
  );
  const { data: flagMembers } = useFeatureFlagApplicationsFlagMembers(
    applicationName,
    featureFlagId
  );

  const {
    isConfirmationDialogOpen,
    confirmationMessage,
    onDeny,
    onAccept,
    isConfirmationLoading,
  } = useConfirmationDialog();

  const sections = [
    {
      key: MemberType.ORGANIZATION,
      title: 'Organizations',
      icon: <Business />,
    },
    {
      key: MemberType.USER,
      title: 'Users',
      icon: <Person />,
    },
  ];

  return (
    <>
      <div className="Settings-container" id="feature-flag-detail-view">
        <div className="Settings-row Settings-title">
          <div>{isLoading ? 'Loading...' : `${featureFlag?.name}`}</div>
          <button
            id="edit-button"
            type="button"
            onClick={() => setIsAssignMemberDialogOpen(true)}
          >
            Edit
          </button>
        </div>
        <div className="padding-lg align-center valign-center">
          {featureFlag?.description}
        </div>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          sections.map(({ key, title, icon }) => {
            const { userMembers, organizationMembers } = flagMembers || {};
            const members =
              key === MemberType.USER
                ? userMembers || []
                : organizationMembers || [];
            return (
              <div key={key} id="dealer-settings-organizations-list">
                <div className="flex-stretch">
                  <span className="valign-center padding-sm padding-left-md">
                    {icon}
                    <b className="margin-left-sm">{title}</b>
                  </span>
                </div>
                {members.map((member: FeatureFlagMember) => (
                  <div
                    key={member.memberId}
                    className="flex-stretch padding-left-lg padding-right-lg"
                  >
                    <span>
                      <span className="valign-center padding-left-md">
                        {icon}
                        <span className="margin-left-sm">
                          {member.displayName}
                        </span>
                      </span>
                    </span>
                  </div>
                ))}
              </div>
            );
          })
        )}
        {featureFlag && (
          <AssignMemberDialog
            featureFlag={featureFlag}
            applicationName={applicationName}
            onClose={() => setIsAssignMemberDialogOpen(false)}
            isAssignMemberDialogOpen={isAssignMemberDialogOpen}
          />
        )}
      </div>

      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        message={confirmationMessage}
        isLoading={isConfirmationLoading}
        onPrimaryButtonClick={onAccept}
        onSecondaryButtonClick={onDeny}
      />
    </>
  );
};

export default FeatureFlagDetailView;
