import React, { useState } from 'react';
import {
  Box,
  Button,
  createStyles,
  Dialog as MaterialDialog,
  DialogActions as MaterialDialogActions,
  DialogContent as MaterialDialogContent,
  DialogContentText as MaterialDialogContentText,
  DialogTitle as MaterialDialogTitle,
  FormControlLabel,
  FormControl,
  FormLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Switch,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { Publish, Edit } from '@material-ui/icons';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import './MarketingTagsTab.scss';
import api from 'api';
import { VelocityEngagePluginItem } from 'models';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
    input: {
      display: 'none',
    },
    formControl: {
      minWidth: 175,
      cursor: 'pointer',
    },
  })
);

interface MarketingTagsTabProps {
  orgId: string;
  saveBadgeAccountId: (accountId: string) => void;
  saveDealerdotcomLocation: (dealerdotcomLocation: string) => void;
  toggleEnabledBadgeSwitch: (badgeEnabled: boolean) => void;
  toggleOpenEmbeddedSwitch: (openEmbeddedEnabled: boolean) => void;
  toggleDealerSiteShowModules: (dealerSiteShowModules: boolean) => void;
  engagePluginSettings: {
    data: VelocityEngagePluginItem | undefined;
    loading: boolean;
    error?: Error;
  };
}

const MarketingTagsTab = ({
  orgId,
  saveBadgeAccountId,
  saveDealerdotcomLocation,
  toggleEnabledBadgeSwitch,
  toggleOpenEmbeddedSwitch,
  toggleDealerSiteShowModules,
  engagePluginSettings,
}: MarketingTagsTabProps) => {
  const [buttonImageUrl, setButtonImageUrl] = useState<string | null>(null);
  const [isEditAccountIdModalOpen, setIsEditAccountIdModalOpen] =
    useState(false);
  const [newAccountId, setNewAccountId] = useState(
    engagePluginSettings?.data?.dealerdotcomAccountId ?? ''
  );

  const classes = useStyles();

  const uploadCustomBadgeImage = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.currentTarget.files && orgId) {
      const imageData = new FormData();
      imageData.append('file', event.currentTarget.files[0]);
      const mediaUrl =
        await api.organizations.plugins.velocityengage.media.createMediaBadgeImage(
          {
            orgId,
            image: imageData,
            pluginName: 'velocityvdp',
            settingName: 'DEALERDOTCOM_IMAGE_URL',
          }
        );
      setButtonImageUrl(mediaUrl);
    }
  };

  const handleEditAccountIdClick = () => {
    setIsEditAccountIdModalOpen(true);
  };

  const handleEditAccountIdClose = () => {
    setIsEditAccountIdModalOpen(false);
    setNewAccountId('');
  };

  const handleEditAccountIdSave = () => {
    setIsEditAccountIdModalOpen(false);
    saveBadgeAccountId(newAccountId);
  };

  const handleChangeAccountId = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewAccountId(event.target.value);
  };

  const uploadedImageUrl =
    buttonImageUrl || engagePluginSettings?.data?.dealerdotcomImageURL;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <Typography variant="h6" className="align-left">
            Website Button/Badge Integration
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" justifyContent="flex-end">
            <FormControlLabel
              className="margin-right-md"
              label={
                engagePluginSettings.loading ? (
                  <LoadingIndicator size={26} />
                ) : (
                  <FormLabel>
                    {engagePluginSettings.data?.dealerdotcomEnabled
                      ? 'Enabled'
                      : 'Disabled'}
                  </FormLabel>
                )
              }
              labelPlacement="start"
              control={
                <Switch
                  inputProps={{
                    'aria-label':
                      'Velocity Engage Website button integration Enable checkbox',
                  }}
                  checked={
                    engagePluginSettings.data?.dealerdotcomEnabled ?? false
                  }
                  disabled={engagePluginSettings.loading}
                  onChange={(_, checked) => toggleEnabledBadgeSwitch(checked)}
                />
              }
            />
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1" className="align-left">
            Account ID:{' '}
            {engagePluginSettings?.data?.dealerdotcomAccountId ??
              'No account ID configured.'}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" justifyContent="flex-end">
            <Edit onClick={handleEditAccountIdClick} />
            <MaterialDialog
              open={isEditAccountIdModalOpen}
              onClose={handleEditAccountIdClose}
              maxWidth="xs"
              aria-labelledby="form-dialog-title"
            >
              <MaterialDialogTitle id="form-dialog-title">
                Edit Website Button Account ID
              </MaterialDialogTitle>
              <MaterialDialogContent>
                <MaterialDialogContentText>
                  Enter a unique account ID to associate Velocity Engage button
                  and badge links with your organization. Usually the account ID
                  is the full name of the dealership, all lowercase with no
                  spaces.
                </MaterialDialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="input-badge-account-id"
                  label="Account ID"
                  value={newAccountId}
                  onChange={handleChangeAccountId}
                  fullWidth
                />
              </MaterialDialogContent>
              <MaterialDialogActions>
                <Button onClick={handleEditAccountIdClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleEditAccountIdSave} color="primary">
                  Save
                </Button>
              </MaterialDialogActions>
            </MaterialDialog>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1" className="align-left">
            Badge Image
          </Typography>
          <Typography variant="body2" className="align-left">
            Choose an image file for Dealer.com badges or HTML button links. PNG
            and JPEG formats accepted.
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" justifyContent="flex-end">
            <input
              type="file"
              id="dealerdotcom-badge-image"
              accept="image/png, image/jpeg"
              className={classes.input}
              multiple
              onChange={uploadCustomBadgeImage}
            />
            <label htmlFor="dealerdotcom-badge-image">
              <Button
                variant="outlined"
                component="span"
                className={classes.button}
                disableElevation
              >
                <Publish />
                Upload
              </Button>
            </label>
            {uploadedImageUrl ? (
              <img
                src={uploadedImageUrl}
                alt="badge"
                className="badge-image-preview"
              />
            ) : (
              <p>No image uploaded</p>
            )}
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1" className="align-left">
            Open badge links within dealer site.
          </Typography>
          <Typography variant="body2" className="align-left">
            Check the box to open links in an iFrame embedded on dealer&apos;s
            Website. Uncheck the box to open links in a new tab.
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" justifyContent="flex-end">
            <FormControlLabel
              className="margin-right-md"
              id="dealer-badge-toggle"
              label={
                engagePluginSettings.loading ? (
                  <LoadingIndicator size={26} />
                ) : (
                  <FormLabel>
                    {engagePluginSettings.data?.badgeOpenEmbedded
                      ? 'Enabled'
                      : 'Disabled'}
                  </FormLabel>
                )
              }
              labelPlacement="start"
              control={
                <Switch
                  inputProps={{
                    'aria-label':
                      'Velocity Engage Website button integration Enable checkbox',
                  }}
                  checked={
                    engagePluginSettings.data?.badgeOpenEmbedded ?? false
                  }
                  disabled={engagePluginSettings.loading}
                  onChange={(_, checked) => toggleOpenEmbeddedSwitch(checked)}
                />
              }
            />
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1" className="align-left">
            Velocity Engage Button Location
          </Typography>
          <Typography variant="body2" className="align-left">
            This configuration option determines whether the button goes in the
            vehicle badge or pricing section for Dealer.com
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" justifyContent="flex-end">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="select-outlined-label">
                Vehicle Badge Location
              </InputLabel>
              <Select
                labelId="select-outlined-label-location"
                id="select-outlined-location"
                value={engagePluginSettings.data?.dealerdotcomLocation}
                onChange={(event) => {
                  saveDealerdotcomLocation(event.target.value as string);
                }}
                label="Location"
                disabled={engagePluginSettings.loading}
                variant="outlined"
              >
                <MenuItem value="vehicle-badge">Vehicle Badge</MenuItem>
                <MenuItem value="vehicle-pricing">Vehicle Pricing</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1" className="align-left">
            Velocity Engage Display Modules
          </Typography>
          <Typography variant="body2" className="align-left">
            This configuration option determines whether the modules or vehicle
            badge is used for the dealer site
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" justifyContent="flex-end">
            <FormControlLabel
              className="margin-right-md"
              label={
                engagePluginSettings.loading ? (
                  <LoadingIndicator size={26} />
                ) : (
                  <FormLabel>
                    {engagePluginSettings.data?.dealerSiteShowModules
                      ? 'Enabled'
                      : 'Disabled'}
                  </FormLabel>
                )
              }
              labelPlacement="start"
              control={
                <Switch
                  inputProps={{
                    'aria-label':
                      'Velocity Engage Website show modules Enable checkbox',
                  }}
                  checked={
                    engagePluginSettings.data?.dealerSiteShowModules ?? false
                  }
                  disabled={engagePluginSettings.loading}
                  onChange={(_, checked) =>
                    toggleDealerSiteShowModules(checked)
                  }
                />
              }
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default MarketingTagsTab;
