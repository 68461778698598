import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';

import Alert, { useAlert } from 'components/shared/Alert';
import Dialog from 'components/shared/Dialog';

import { ReconVelocityTagDefinition } from 'models/plugins/reconVelocityPlugin';
import {
  useCreateTagDefinition,
  useUpdateTagDefinition,
} from 'api/organizations/plugins/reconvelocity/tags';

class EditTagDefinitionDialogState {
  tagDefinition: ReconVelocityTagDefinition;

  constructor(tagDefinition: ReconVelocityTagDefinition) {
    this.tagDefinition = tagDefinition;
  }
}

export interface EditTagDefinitionDialogProps {
  tagDefinition: ReconVelocityTagDefinition;
  orgId: string;
  setSelectedTagDefinition: (
    selectedTagDefinition?: ReconVelocityTagDefinition
  ) => void;
  onClose: () => void;
}

const EditTagDefinitionDialog: React.FC<EditTagDefinitionDialogProps> = ({
  tagDefinition,
  orgId,
  setSelectedTagDefinition,
  onClose,
}: EditTagDefinitionDialogProps) => {
  const [state] = useState(new EditTagDefinitionDialogState(tagDefinition));
  const [tagLabel, setTagLabel] = useState(tagDefinition.label);
  const [tagColorHex, setTagColorHex] = useState(
    tagDefinition.colorHex.replace('#', '')
  );
  const { isAlertOpen, alertMessage, openAlert, closeAlert, variant } =
    useAlert();

  const { createTagDefinitionAsync, isError: isCreateError } =
    useCreateTagDefinition(orgId);
  const { updateTagDefinitionAsync, isError: isUpdateError } =
    useUpdateTagDefinition(orgId, tagDefinition.id);

  useEffect(() => {
    if (isCreateError || isUpdateError) {
      openAlert(
        'There was an API error while saving the tag definition',
        'error'
      );
    }
  }, [isCreateError, isUpdateError, onClose, openAlert]);

  const handleSaveClick = async () => {
    const tagId = state.tagDefinition.id;
    const newTagDefinition = {
      label: tagLabel,
      colorHex: `#${tagColorHex}`,
    };
    if (tagId) {
      const updatedTag = await updateTagDefinitionAsync(newTagDefinition).catch(
        (error) => {
          console.error(error);
          openAlert(
            'There was an API error while updating the tag definition',
            'error'
          );
        }
      );
      setSelectedTagDefinition(updatedTag.data);
    } else {
      const createdTag = await createTagDefinitionAsync(newTagDefinition).catch(
        (error) => {
          console.error(error);
          openAlert(
            'There was an API error while creating the tag definition',
            'error'
          );
        }
      );
      setSelectedTagDefinition(createdTag.data);
    }
  };

  return (
    <>
      <Dialog
        open
        title={`${tagDefinition.id ? 'Edit' : 'Add'} Tag`}
        maxWidth="sm"
        fullWidth
        primaryButtonLabel="Save"
        onPrimary={handleSaveClick}
        onClose={onClose}
        primaryButtonDisabled={!tagLabel || !tagColorHex}
        className="PluginDialog EditTagDialog"
      >
        <TextField
          autoFocus
          className="PluginDialog__text-input"
          id="name-input"
          label="Name"
          variant="outlined"
          required
          value={tagLabel}
          onChange={(e) => {
            setTagLabel(e.target.value);
          }}
          margin="dense"
        />
        <TextField
          className="PluginDialog__text-input"
          id="color-input"
          label="Hex Color"
          variant="outlined"
          required
          value={tagColorHex}
          onChange={(e) => {
            setTagColorHex(e.target.value);
          }}
          margin="dense"
          inputProps={{
            maxLength: 6,
          }}
        />
      </Dialog>
      <Alert
        open={isAlertOpen}
        message={alertMessage}
        onClose={closeAlert}
        duration={3500}
        variant={variant}
      />
    </>
  );
};

export default EditTagDefinitionDialog;
