import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import LoadingIndicator from 'components/shared/LoadingIndicator';

interface TagDefinitionDeleteDialogProps {
  onClose: () => void;
  isDeletingTagDefinition: boolean;
  handleDelete: () => void;
}

var TagDefinitionDeleteDialog = ({
  onClose,
  isDeletingTagDefinition,
  handleDelete,
}: TagDefinitionDeleteDialogProps) => {
  return (
    <Dialog open>
      <DialogTitle>
        <Typography variant="h6">Delete this Tag?</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Are you sure you want to delete this tag?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} disabled={isDeletingTagDefinition}>
          Cancel
        </Button>
        <Button
          color="primary"
          style={{ color: 'white' }}
          variant="contained"
          disabled={isDeletingTagDefinition}
          startIcon={
            isDeletingTagDefinition ? (
              <LoadingIndicator size={13} color="secondary" />
            ) : (
              <Delete style={{ color: 'white' }} />
            )
          }
          onClick={() => handleDelete()}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TagDefinitionDeleteDialog;
