import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core';
import { Save } from '@material-ui/icons';

import LoadingIndicator from 'components/shared/LoadingIndicator';
import {
  Organization,
  OrganizationDepartment,
  ScheduleWeek,
  DAYS,
  defaultScheduleWeek,
} from 'models';
import { DeepPartial } from 'common/types';
import utils from 'utils';

export type DepartmentType = 'Sales' | 'Service' | 'Parts';

interface EditOrganizationDepartmentDialogProps {
  organization: Organization;
  department: OrganizationDepartment;
  type: DepartmentType;
  onClose: () => void;
  isUpdatingDepartment: boolean;
  onSave: (departmentData: DeepPartial<OrganizationDepartment>) => void;
}

const EditOrganizationDepartmentDialog: React.FC<EditOrganizationDepartmentDialogProps> =
  ({
    type,
    onClose,
    isUpdatingDepartment,
    onSave,
    department,
    organization,
  }: EditOrganizationDepartmentDialogProps) => {
    const {
      timezone: { offset },
    } = organization;
    const prepTimeInSecondsForDisplay = (
      timeInSeconds: number,
      timezoneOffset: number = 0
    ) =>
      utils.time.get24HrTimeStringFromSecondsAfterMidnight(
        utils.time.offsetSecondsAfterMidnight(timeInSeconds, -timezoneOffset)
      );

    const prepTimeStringForSaving = (
      timeString: string,
      timezoneOffset: number = 0
    ) => {
      if (!timeString) return null;
      return utils.time.offsetSecondsAfterMidnight(
        utils.time.getSecondsAfterMidnightFromTimeString(timeString),
        -timezoneOffset
      );
    };

    const [[phone, setPhone], [email, setEmail], [schedule, setSchedule]] = [
      useState(department && department.phone ? department.phone : ''),
      useState(department && department.email ? department.email : ''),
      useState<DeepPartial<ScheduleWeek>>(
        department?.schedule || defaultScheduleWeek
      ),
    ];
    const onSaveClick = () => {
      const ephemeralSchedule: ScheduleWeek = defaultScheduleWeek;
      const newSchedule = DAYS.reduce((acc, day) => {
        const openInSecondsUTC = schedule[day]?.openInSecondsUTC;
        const closedInSecondsUTC = schedule[day]?.closedInSecondsUTC;
        if (openInSecondsUTC && closedInSecondsUTC) {
          return {
            ...acc,
            [day]: {
              openInSecondsUTC,
              closedInSecondsUTC,
            },
          };
        }

        return {
          ...acc,
          [day]: null,
        };
      }, ephemeralSchedule);

      const departmentData: DeepPartial<OrganizationDepartment> = {
        phone,
        email,
        schedule: newSchedule,
      };

      onSave(departmentData);
    };

    return (
      <Dialog
        open
        className="EditOrganizationDepartmentDialog"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <Typography variant="h6">{`Edit ${type} Department`}</Typography>
        </DialogTitle>
        <DialogContent>
          <div className="EditOrganizationDepartmentDialog-content-container">
            <div className="EditOrganizationDepartmentDialog-contact-info">
              <TextField
                fullWidth
                id="EditOrganizationDepartmentDialog-phone"
                className="EditOrganizationDepartmentDialog-contact-info-phone"
                label="Phone"
                variant="outlined"
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
              />
              <TextField
                fullWidth
                id="EditOrganizationDepartmentDialog-email"
                className="EditOrganizationDepartmentDialog-email"
                label="Email"
                variant="outlined"
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
            <div className="EditOrganizationDepartmentDialog-schedule">
              <span className="EditOrganizationDepartmentDialog-spacer" />
              <span className="EditOrganizationDepartmentDialog-schedule-label">
                Open:
              </span>
              <span className="EditOrganizationDepartmentDialog-schedule-label">
                Close:
              </span>
              {DAYS.map((day) => {
                const openValue = schedule[day]?.openInSecondsUTC;
                const openDisplayValue = openValue
                  ? prepTimeInSecondsForDisplay(openValue, offset)
                  : '';
                const closedValue = schedule[day]?.closedInSecondsUTC;
                const closedDisplayValue = closedValue
                  ? prepTimeInSecondsForDisplay(closedValue, offset)
                  : '';
                return (
                  <>
                    <span className="EditOrganizationDepartmentDialog-schedule-label">
                      {day}
                    </span>
                    <TextField
                      fullWidth
                      id="EditOrganizationDepartmentDialog-time"
                      className="EditOrganizationDepartmentDialog-time-open"
                      variant="outlined"
                      type="time"
                      inputProps={{
                        step: 300,
                      }}
                      defaultValue={openDisplayValue}
                      onBlur={(event) =>
                        setSchedule({
                          ...schedule,
                          [day]: {
                            ...schedule[day],
                            openInSecondsUTC: prepTimeStringForSaving(
                              event.target.value
                            ),
                          },
                        })
                      }
                    />
                    <TextField
                      fullWidth
                      id="EditOrganizationDepartmentDialog-time"
                      className="EditOrganizationDepartmentDialog-time-close"
                      variant="outlined"
                      type="time"
                      defaultValue={closedDisplayValue}
                      onBlur={(event) =>
                        setSchedule({
                          ...schedule,
                          [day]: {
                            ...schedule[day],
                            closedInSecondsUTC: prepTimeStringForSaving(
                              event.target.value
                            ),
                          },
                        })
                      }
                    />
                  </>
                );
              })}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} disabled={isUpdatingDepartment}>
            Cancel
          </Button>
          <Button
            color="secondary"
            style={{ color: 'white' }}
            variant="contained"
            onClick={() => onSaveClick()}
            disabled={isUpdatingDepartment}
            startIcon={
              isUpdatingDepartment ? (
                <LoadingIndicator size={13} color="primary" />
              ) : (
                <Save style={{ color: 'white' }} />
              )
            }
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

export default EditOrganizationDepartmentDialog;
