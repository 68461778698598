import React, { useCallback } from 'react';
import Switch from '@material-ui/core/Switch';
import { FormLabel, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import {
  Organization,
  PluginItem,
  PluginItemCategory,
  PluginItemSubCategory,
} from 'models';

import Tabs, { TabHeaderItem, InlineTab } from 'components/shared/Tabs';
import Dialog from 'components/shared/Dialog';
import { useNavigate } from 'react-router-dom';

import {
  useCreateReconVelocityPlugin,
  useReconVelocityPlugin,
  useUpdateReconVelocityPlugin,
} from 'api/organizations/plugins/reconvelocity';
import permissions from 'common/permissions';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import GeneralView from './GeneralView';
import TagDefinitionsView from './TagDefinitionsView';
import TasksView from './TasksView';
import WorkflowDetailView from './WorkflowsView/WorkflowDetailView';

import './ReconVelocityPluginDialog.scss';
import { useOnChange } from 'common/hooks';

const defaultReconVelocityPlugin: PluginItem = {
  enabled: true,
  category: PluginItemCategory.MODULE,
  subCategory: PluginItemSubCategory.NOT_SET,
  title: 'Recon Velocity',
  pluginName: 'RECON_VELOCITY',
  settings: {
    // TODO - and another place for default settings for plugins. Refactor needed
    RECON_TIME_IGNORE_SCHEDULE: {
      dataType: 'BOOL',
      value: true,
    },
  },
  description: '',
  iconUrl: '',
  links: null,
};

interface ReconVelocityPluginDialogProps {
  orgId: Organization['id'];
  onClose?: () => void;
  isNewPlugin: boolean;
}

const rvPluginTabs: TabHeaderItem[] = [
  { key: 'general', label: 'General' },
  {
    key: 'tags',
    label: 'Tags',
    permission: permissions.ORGS_PLUGINS_RECONVELOCITY_TAG_DEFINITIONS_VIEW,
  },
  {
    key: 'tasks',
    label: 'Tasks',
    permission: permissions.ORGS_PLUGINS_RECONVELOCITY_TASK_DEFINITIONS_VIEW,
  },
  {
    key: 'workflows',
    label: 'Steps',
    permission: permissions.ORGS_PLUGINS_RECONVELOCITY_WORKFLOWS_VIEW,
  },
];

const ReconVelocityPluginDialog = ({
  orgId,
  onClose,
  isNewPlugin,
}: ReconVelocityPluginDialogProps) => {
  const {
    data: generalSettings,
    isLoading: generalSettingsAreLoading,
    refetch: reloadGeneralSettings,
  } = useReconVelocityPlugin(orgId, {
    enabled: !isNewPlugin,
  });
  const { updateReconVelocityPluginAsync, isLoading: isUpdating } =
    useUpdateReconVelocityPlugin(orgId);
  const { createReconVelocityPluginAsync, isLoading: isCreating } =
    useCreateReconVelocityPlugin(orgId);
  const navigate = useNavigate();

  const defaultTabKey = 'general';
  const baseRoute = 'reconvelocity/';

  const onMountNavigate = useCallback(() => {
    navigate(baseRoute + defaultTabKey, { replace: true });
  }, [navigate]);

  useOnChange(onMountNavigate, null, { callOnMount: true });

  // if new plugin, we'll assume they want it enabled
  // so we need this funky logic
  const generalSettingsData = generalSettings?.data;
  const onMountSetupPlugin = useCallback(() => {
    const createAndFetchNewPlugin = async () => {
      // TODO - none of these should be done in the UI
      const plugin = {
        ...defaultReconVelocityPlugin,
        ...generalSettingsData,
        reconTimeIgnoreSchedule: true,
        enabled: true,
      };
      // This call creates the plugin with a POST request.
      await createReconVelocityPluginAsync(plugin);

      // This call enables the plugin, since POST does not seem to allow it.
      await updateReconVelocityPluginAsync(plugin);
      await reloadGeneralSettings(); // TODO - there are too many calls for this plugin
    };
    if (isNewPlugin) {
      createAndFetchNewPlugin();
    }
  }, [
    createReconVelocityPluginAsync,
    generalSettingsData,
    isNewPlugin,
    reloadGeneralSettings,
    updateReconVelocityPluginAsync,
  ]);

  useOnChange(onMountSetupPlugin, null, { callOnMount: true });

  const onChange = async (event: React.ChangeEvent, checked: boolean) => {
    await updateReconVelocityPluginAsync({
      ...defaultReconVelocityPlugin,
      ...generalSettings?.data,
      enabled: checked,
    });
  };

  const InlineTabsCollection = [
    {
      element: (
        <GeneralView
          orgId={orgId}
          fetchOnMount={!isNewPlugin}
          isCreatingPlugin={isCreating || isUpdating}
        />
      ),
      path: rvPluginTabs[0].key,
    },
    {
      element: <TagDefinitionsView orgId={orgId} fetchOnMount={!isNewPlugin} />,
      path: rvPluginTabs[1].key,
    },
    {
      element: <TasksView orgId={orgId} fetchOnMount={!isNewPlugin} />,
      path: rvPluginTabs[2].key,
    },
    {
      element: <WorkflowDetailView orgId={orgId} />,
      path: rvPluginTabs[3].key,
    },
  ].map(({ element, path }) => (
    <InlineTab
      key={path}
      path={path}
      className="full-height"
      element={element}
    />
  ));

  return (
    <Dialog
      open
      title="ReconVelocity"
      className="ReconVelocityPluginDialog"
      maxWidth="lg"
      fullWidth
      height={740}
      showCloseButton
      hidePrimaryButton
      hideCancelButton
      noPadding
      closeElement={
        <div className="ReconVelocityPluginDialog__closeElement">
          <FormLabel id="switch-label">
            {generalSettings?.data?.enabled ? 'Enabled' : 'Disabled'}
          </FormLabel>
          <Switch
            id="plugin-toggle-button"
            checked={generalSettings?.data?.enabled ?? false}
            disabled={generalSettingsAreLoading}
            onChange={onChange}
          />
          <IconButton id="close-modal-icon-button" onClick={onClose}>
            <Close />
          </IconButton>
        </div>
      }
      block={generalSettingsAreLoading}
    >
      {generalSettingsAreLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Tabs
            tabHeaderItems={rvPluginTabs}
            defaultTabKey={defaultTabKey}
            basename={baseRoute}
          />
          {InlineTabsCollection}
        </>
      )}
    </Dialog>
  );
};

export default ReconVelocityPluginDialog;
