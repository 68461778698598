import React from 'react';
import { TextField } from '@material-ui/core';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

interface CurrencyTextFieldProps extends NumberFormatProps {}

var CurrencyTextField = ({ ...props }: CurrencyTextFieldProps) => {
  return (
    <NumberFormat
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      prefix="$"
      customInput={TextField}
      thousandSeparator
      decimalScale={2}
      fixedDecimalScale
      allowNegative={false}
    />
  );
};

export default CurrencyTextField;
