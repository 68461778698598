export default {
  SETTINGS_FEATUREFLAG_VIEW: 'SETTINGS_FEATUREFLAG_VIEW',
  SETTINGS_FEATUREFLAG_CREATE: 'SETTINGS_FEATUREFLAG_CREATE',
  SETTINGS_FEATUREFLAG_UPDATE: 'SETTINGS_FEATUREFLAG_UPDATE',
  SETTINGS_FEATUREFLAG_DELETE: 'SETTINGS_FEATUREFLAG_DELETE',

  ORGS_PLUGINS_CREATE: 'ORGS_PLUGINS_CREATE',
  ORGS_PLUGINS_DELETE: 'ORGS_PLUGINS_DELETE',
  ORGS_PLUGINS_UPDATE: 'ORGS_PLUGINS_UPDATE',
  ORGS_PLUGINS_VIEW: 'ORGS_PLUGINS_VIEW',

  ORGS_GROUPS_CREATE: 'ORGS_GROUPS_CREATE',
  ORGS_GROUPS_DELETE: 'ORGS_GROUPS_DELETE',
  ORGS_GROUPS_EXPORT_VIEW: 'ORGS_GROUPS_EXPORT_VIEW',
  ORGS_GROUPS_UPDATE: 'ORGS_GROUPS_UPDATE',
  ORGS_GROUPS_VIEW: 'ORGS_GROUPS_VIEW',

  ORGS_CREATE: 'ORGS_CREATE',
  ORGS_UPDATE: 'ORGS_UPDATE',
  ORGS_VIEW: 'ORGS_VIEW',
  ORGS_DELETE: 'ORGS_DELETE',

  USERS_CREATE: 'USERS_CREATE',
  USERS_DELETE: 'USERS_DELETE',
  USERS_EXPORT_VIEW: 'USERS_EXPORT_VIEW',
  USERS_UPDATE: 'USERS_UPDATE',
  USERS_VIEW: 'USERS_VIEW',
  USERS_SELF_UPDATE: 'USERS_SELF_UPDATE',
  USERS_SELF_DELETE: 'USERS_SELF_DELETE',

  ORGS_PLUGINS_RECONVELOCITY_VIEW: 'ORGS_PLUGINS_RECONVELOCITY_VIEW',
  ORGS_PLUGINS_RECONVELOCITY_UPDATE: 'ORGS_PLUGINS_RECONVELOCITY_UPDATE',

  ORGS_PLUGINS_RECONVELOCITY_WORKFLOWS_VIEW:
    'ORGS_PLUGINS_RECONVELOCITY_WORKFLOWS_VIEW',
  ORGS_PLUGINS_RECONVELOCITY_WORKFLOWS_CREATE:
    'ORGS_PLUGINS_RECONVELOCITY_WORKFLOWS_CREATE',
  ORGS_PLUGINS_RECONVELOCITY_WORKFLOWS_UPDATE:
    'ORGS_PLUGINS_RECONVELOCITY_WORKFLOWS_UPDATE',
  ORGS_PLUGINS_RECONVELOCITY_WORKFLOWS_DELETE:
    'ORGS_PLUGINS_RECONVELOCITY_WORKFLOWS_DELETE',

  ORGS_PLUGINS_RECONVELOCITY_WORKFLOWS_STEPS_VIEW:
    'ORGS_PLUGINS_RECONVELOCITY_WORKFLOWS_STEPS_VIEW',
  ORGS_PLUGINS_RECONVELOCITY_WORKFLOWS_STEPS_UPDATE:
    'ORGS_PLUGINS_RECONVELOCITY_WORKFLOWS_STEPS_UPDATE',
  ORGS_PLUGINS_RECONVELOCITY_WORKFLOWS_STEPS_DELETE:
    'ORGS_PLUGINS_RECONVELOCITY_WORKFLOWS_STEPS_DELETE',
  ORGS_PLUGINS_RECONVELOCITY_WORKFLOWS_STEPS_CREATE:
    'ORGS_PLUGINS_RECONVELOCITY_WORKFLOWS_STEPS_CREATE',

  ORGS_PLUGINS_RECONVELOCITY_TAG_DEFINITIONS_VIEW:
    'ORGS_PLUGINS_RECONVELOCITY_TAG_DEFINITIONS_VIEW',
  ORGS_PLUGINS_RECONVELOCITY_TAG_DEFINITIONS_CREATE:
    'ORGS_PLUGINS_RECONVELOCITY_TAG_DEFINITIONS_CREATE',
  ORGS_PLUGINS_RECONVELOCITY_TAG_DEFINITIONS_UPDATE:
    'ORGS_PLUGINS_RECONVELOCITY_TAG_DEFINITIONS_UPDATE',
  ORGS_PLUGINS_RECONVELOCITY_TAG_DEFINITIONS_DELETE:
    'ORGS_PLUGINS_RECONVELOCITY_TAG_DEFINITIONS_DELETE',

  ORGS_PLUGINS_RECONVELOCITY_TASK_DEFINITIONS_VIEW:
    'ORGS_PLUGINS_RECONVELOCITY_TASK_DEFINITIONS_VIEW',
  ORGS_PLUGINS_RECONVELOCITY_TASK_DEFINITIONS_CREATE:
    'ORGS_PLUGINS_RECONVELOCITY_TASK_DEFINITIONS_CREATE',
  ORGS_PLUGINS_RECONVELOCITY_TASK_DEFINITIONS_UPDATE:
    'ORGS_PLUGINS_RECONVELOCITY_TASK_DEFINITIONS_UPDATE',
  ORGS_PLUGINS_RECONVELOCITY_TASK_DEFINITIONS_DELETE:
    'ORGS_PLUGINS_RECONVELOCITY_TASK_DEFINITIONS_DELETE',
} as const;
