import { APIResponse, defaultMetaQueryFn, defaultMutationFn } from 'api/useAPI';
import { Organization } from 'models';
import { ReconVelocityTaskDefinition } from 'models/plugins/reconVelocityPlugin';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

/**
 * Gets the path to the Tasks endpoint
 * @param orgId
 * @returns path to tasks endpoint
 */
function getTaskDefinitionsPath(orgId: Organization['id']) {
  return `/orgs/${orgId}/plugins/reconvelocity/tasks`;
}

/**
 * Gets the path to a specific task endpoint
 * @param orgId
 * @param taskDefinitionId
 * @returns path to specific task endpoint
 */
function getTaskDefinitionPath(
  orgId: Organization['id'],
  taskDefinitionId: ReconVelocityTaskDefinition['id']
) {
  return `${getTaskDefinitionsPath(orgId)}/${taskDefinitionId}`;
}

/**
 * Gets the task definitions list for a rooftop
 * @param orgId
 * @param options
 * @returns Task definitions for a rooftops RV installation
 */
export function useTaskDefinitions(
  orgId: Organization['id'],
  options?: UseQueryOptions<APIResponse<ReconVelocityTaskDefinition[]>>
) {
  const path = getTaskDefinitionsPath(orgId);
  return useQuery<APIResponse<ReconVelocityTaskDefinition[]>>(
    path,
    () => defaultMetaQueryFn(path),
    options
  );
}

/**
 * Get single task definition
 * @param orgId
 * @param taskDefinitionId
 * @returns task definition
 */
export function useTaskDefinition(
  orgId: Organization['id'],
  taskDefinitionId: ReconVelocityTaskDefinition['id'],
  options?: UseQueryOptions<APIResponse<ReconVelocityTaskDefinition>>
) {
  const path = getTaskDefinitionPath(orgId, taskDefinitionId);
  return useQuery<APIResponse<ReconVelocityTaskDefinition>>(
    path,
    () => defaultMetaQueryFn(path),
    options
  );
}

/**
 * Create a new task definition
 * @param orgId
 * @param taskDefinition
 * @returns newly created task definition
 */
export function useCreateTaskDefinition(orgId: Organization['id']) {
  const queryClient = useQueryClient();
  const mutation = useMutation((data) => {
    const path = getTaskDefinitionsPath(orgId);
    return defaultMutationFn(path, 'POST', data);
  });

  async function createTaskDefinitionAsync(taskDefinition: any) {
    await queryClient.cancelQueries('tasks');
    const response = await mutation.mutateAsync(taskDefinition);
    await queryClient.invalidateQueries(getTaskDefinitionsPath(orgId));
    return response;
  }

  return {
    ...mutation,
    createTaskDefinitionAsync,
  };
}

/**
 * Update a task definition
 * @param orgId
 * @param taskDefinitionId
 * @returns updated task definition
 */
export function useUpdateTaskDefinition(
  orgId: Organization['id'],
  taskDefinitionId: ReconVelocityTaskDefinition['id']
) {
  const queryClient = useQueryClient();
  const mutation = useMutation((data) => {
    const path = getTaskDefinitionPath(orgId, taskDefinitionId);
    return defaultMutationFn(path, 'PUT', data);
  });

  async function updateTaskDefinitionAsync(data: any) {
    await queryClient.cancelQueries('tasks');
    const response = await mutation.mutateAsync(data);
    await queryClient.invalidateQueries(getTaskDefinitionsPath(orgId));
    return response;
  }

  return {
    ...mutation,
    updateTaskDefinitionAsync,
  };
}

/**
 * Delete a task definition
 * @param orgId
 * @returns boolean indicating success or failure
 */
export function useDeleteTaskDefinition(orgId: Organization['id']) {
  const queryClient = useQueryClient();
  const mutation = useMutation((taskDefinitionId: any) => {
    const path = getTaskDefinitionPath(orgId, taskDefinitionId);
    return defaultMutationFn(path, 'DELETE');
  });

  async function deleteTaskDefinitionAsync(data: any) {
    await queryClient.cancelQueries('tasks');
    const response = await mutation.mutateAsync(data);
    await queryClient.invalidateQueries(getTaskDefinitionsPath(orgId));
    return response;
  }

  return {
    ...mutation,
    deleteTaskDefinitionAsync,
  };
}
