import filter from 'lodash/filter';

import { User } from 'models';
import {
  getOrganizationUsersActionTypes,
  OrganizationUsersAction,
  updateUsersOrgGroupsActionTypes,
  bulkRemoveUsersFromOrgActionTypes,
} from 'store/actions/organizations/users';
import { NotificationsAction } from 'store/actions/organizations/users/notifications';
import { FetchedData } from 'store/reducers/types';
import notificationsReducer, {
  initialState as notificationsInitialState,
  NotificationsState,
} from './notifications';

interface OrganizationUsersState extends FetchedData<User[] | undefined> {
  notifications: NotificationsState;
}

const initialState: OrganizationUsersState = {
  loading: false,
  data: undefined,
  error: undefined,

  notifications: notificationsInitialState,
};

export default function organizationUsersReducer(
  state = initialState,
  action: OrganizationUsersAction
): OrganizationUsersState {
  switch (action.type) {
    case getOrganizationUsersActionTypes.pending: {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }
    case getOrganizationUsersActionTypes.fulfilled: {
      const { data = state.data } = action.payload ?? {};
      return {
        ...state,
        loading: false,
        data,
        error: undefined,
      };
    }
    case getOrganizationUsersActionTypes.rejected: {
      const { error } = action.payload ?? {};
      return {
        ...state,
        loading: false,
        error,
      };
    }

    case updateUsersOrgGroupsActionTypes.pending: {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }
    case updateUsersOrgGroupsActionTypes.fulfilled: {
      const { data } = action.payload ?? {};

      if (!data || data.length < 1) {
        return {
          ...state,
          loading: false,
          error: undefined,
        };
      }

      const updatedUser: User = data[0];
      const outdatedUserPosition = state.data?.findIndex(
        (user) => user.id === updatedUser.id
      );

      if (outdatedUserPosition === -1) {
        return {
          ...state,
          loading: false,
          error: undefined,
        };
      }

      const newListWithReplacedUser =
        state.data && outdatedUserPosition
          ? [...state.data].splice(outdatedUserPosition, 1, updatedUser)
          : [updatedUser];

      return {
        ...state,
        data: newListWithReplacedUser,
        loading: false,
        error: undefined,
      };
    }
    case updateUsersOrgGroupsActionTypes.rejected: {
      const { error } = action.payload ?? {};
      return {
        ...state,
        loading: false,
        error,
      };
    }

    case bulkRemoveUsersFromOrgActionTypes.pending: {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }
    case bulkRemoveUsersFromOrgActionTypes.fulfilled: {
      const { data } = action.payload ?? {};

      if (!data || data.length < 1) {
        return {
          ...state,
          loading: false,
          error: undefined,
        };
      }

      const deletedUserIds: User['id'][] = data.map(
        (deletedUser: User) => deletedUser.id
      );

      const listWithoutDeletedUsers = filter(
        state.data,
        (user: User) => !deletedUserIds.includes(user.id)
      );

      return {
        ...state,
        data: listWithoutDeletedUsers,
        loading: false,
        error: undefined,
      };
    }
    case bulkRemoveUsersFromOrgActionTypes.rejected: {
      const { error } = action.payload ?? {};
      return {
        ...state,
        loading: false,
        error,
      };
    }

    default: {
      return {
        ...state,
        notifications: notificationsReducer(
          state.notifications,
          action as NotificationsAction
        ),
      };
    }
  }
}
