import strings from './strings';

const MINUTE_SECONDS = 60;
const HOUR_SECONDS = 60 * MINUTE_SECONDS;
const DAY_SECONDS = 24 * HOUR_SECONDS;

function get24HrTimeStringFromSecondsAfterMidnight(
  secondsAfterMidnight: number
): string {
  const minutesAfterMidnight = Math.floor(secondsAfterMidnight / 60);
  const minutes = minutesAfterMidnight % 60;
  const hours = (minutesAfterMidnight - minutes) / 60;
  return `${strings.zeroPad(hours)}:${strings.zeroPad(minutes)}`;
}

function getAmPmTimeStringFromSecondsAfterMidnight(
  secondsAfterMidnight: number
): string {
  const minutesAfterMidnight = Math.floor(secondsAfterMidnight / 60);
  const minutes = minutesAfterMidnight % 60;
  let hours = (minutesAfterMidnight - minutes) / 60;
  const isAfternoon = hours > 11;
  if (hours > 12) {
    hours -= 12;
  } else if (hours === 0) {
    hours = 12;
  }
  return `${hours}:${minutes.toString().padStart(2, '0')} ${
    isAfternoon ? 'PM' : 'AM'
  }`;
}

function getSecondsAfterMidnightFromTimeString(time: string): number {
  const match = time.trim().match(/^(\d+)(:(\d+))?\s*([AP]M)?$/i);
  if (!match) {
    throw new Error('Not a valid time string');
  }
  let isAfternoon = false;
  if (/M$/i.test(match[match.length - 1])) {
    isAfternoon = match.pop()?.toUpperCase() === 'PM';
  }
  const hours = parseInt(match[1], 10);
  const minutes = match.length > 3 ? parseInt(match[3], 10) : 0;
  return ((hours + (isAfternoon ? 12 : 0)) * 60 + minutes) * 60;
}

function offsetSecondsAfterMidnight(
  secondsAfterMidnight: number,
  offsetSeconds: number
) {
  return (secondsAfterMidnight + offsetSeconds) % DAY_SECONDS;
}

const getHoursAndMinutesFromSeconds = (duration?: number) => {
  if (duration === 0 || !duration) {
    return { hours: 0, minutes: 0 };
  }

  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  return { hours, minutes };
};

const getDaysAndHoursFromSeconds = (duration?: number) => {
  if (duration === 0 || !duration) {
    return { days: 0, hours: 0 };
  }

  const days = Math.floor(duration / 86400);
  const hours = Math.floor((duration % 86400) / 3600);
  return { days, hours };
};

const getSecondsFromDaysAndHours = ({
  days,
  hours,
}: {
  days: number;
  hours: number;
}) => days * 86400 + hours * 3600;

const getSecondsFromHoursAndMinutes = ({
  hours,
  minutes,
}: {
  hours: number;
  minutes: number;
}) => hours * 3600 + minutes * 60;

const prepTimeInSecondsForDisplay = (
  timeInSeconds: number,
  orgTimezoneOffset: number = 0
) =>
  get24HrTimeStringFromSecondsAfterMidnight(
    offsetSecondsAfterMidnight(timeInSeconds, -orgTimezoneOffset)
  );

const prepTimeStringForSaving = (
  timeString: string,
  orgTimezoneOffset: number = 0
) => {
  if (!timeString) return null;
  return offsetSecondsAfterMidnight(
    getSecondsAfterMidnightFromTimeString(timeString),
    -orgTimezoneOffset
  );
};

export default {
  get24HrTimeStringFromSecondsAfterMidnight,
  getSecondsAfterMidnightFromTimeString,
  offsetSecondsAfterMidnight,
  getSecondsFromDaysAndHours,
  getHoursAndMinutesFromSeconds,
  getSecondsFromHoursAndMinutes,
  prepTimeInSecondsForDisplay,
  prepTimeStringForSaving,
  getAmPmTimeStringFromSecondsAfterMidnight,
  getDaysAndHoursFromSeconds,
};
