import React from 'react';
import { Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import isUndefined from 'lodash/isUndefined';

import { Money } from 'models';
import { PluginSubsetting } from 'models/plugins';
import {
  ReconVelocityDealershipCosts,
  ReconVelocityPluginItem,
  ReconVelocityTaskDefinition,
  ReconVelocityTagDefinition,
  ReconVelocityWorkflow,
  WholesaleUnitOption,
} from 'models/plugins/reconVelocityPlugin';
import strings from 'common/strings';

/**
 *   Component: WholesaleUnitsSetting in Recon Velocity Plugin - General View tab
 *
 *   Due to intricaticacy of API, the client must ensure appropriate values for correctly
 *   setting the WholesaleUnit Setting. Below is a description of what values must be
 *   maintained depending on the user selection:
 *
 *   Relevant Attributes from R.V. plugin data payload:
 *   (a) excludeWholesaleUnitsFromImport: boolean
 *   (aa) settings -> EXCLUDE_WHOLESALE: PluginSubsetting
 *
 *   (b) excludeWholesaleUnitsFromImportRemoveRecon: boolean
 *   (bb) settings -> EXCLUDE_WHOLESALE_REMOVE_RECON: PluginSubsetting
 *
 *   Wholesale setting options:
 *   1) Include Wholesale Units From Import: set a, aa, b, bb values to FALSE
 *   2) Exclude Wholesale Units From Import: set a, aa to TRUE. set b, bb values to FALSE
 *   3) Exclude Wholesale Units From Import and Remove From Recon:
 *                                           set a, aa, b, bb values to TRUE
 */

interface WholesaleUnitsSettingProps {
  excludeWholesaleUnitsFromImport?: boolean;
  excludeWholesaleUnitsFromImportRemoveRecon?: boolean;
  updateSetting: (
    key: keyof ReconVelocityPluginItem,
    value:
      | string
      | number
      | boolean
      | ReconVelocityDealershipCosts
      | Money
      | Record<string, any>
      | ReconVelocityTagDefinition[]
      | ReconVelocityTaskDefinition[]
      | ReconVelocityWorkflow[]
  ) => void;
  updateSubsetting: (inputKey: string, inputValue: Record<any, any>) => void;
}

interface WholesaleAttributesConfiguration
  extends Pick<
    ReconVelocityPluginItem,
    | 'excludeWholesaleUnitsFromImport'
    | 'excludeWholesaleUnitsFromImportRemoveRecon'
  > {
  SUBSETTING_EXCLUDE_WHOLESALE: PluginSubsetting;
  SUBSETTING_EXCLUDE_WHOLESALE_REMOVE_RECON: PluginSubsetting;
}

var WholesaleUnitsSetting = ({
  excludeWholesaleUnitsFromImport,
  excludeWholesaleUnitsFromImportRemoveRecon,
  updateSetting,
  updateSubsetting,
}: WholesaleUnitsSettingProps) => {
  const getWholesaleSetting = (
    excludeWholesale: WholesaleUnitsSettingProps['excludeWholesaleUnitsFromImport'],
    excludeWholesaleRemoveRecon: WholesaleUnitsSettingProps['excludeWholesaleUnitsFromImportRemoveRecon']
  ): WholesaleUnitOption =>
    excludeWholesale && !excludeWholesaleRemoveRecon
      ? 'EXCLUDE_WHOLESALE'
      : excludeWholesale && excludeWholesaleRemoveRecon
      ? 'EXCLUDE_WHOLESALE_REMOVE_FROM_RECON'
      : 'INCLUDE_WHOLESALE';

  const setWholesaleSetting = ({ target: { value } }: any) => {
    const newWholeSaleSetting: WholesaleUnitOption = value;
    const wholesaleSettingMap: Record<
      WholesaleUnitOption,
      WholesaleAttributesConfiguration
    > = {
      EXCLUDE_WHOLESALE: {
        excludeWholesaleUnitsFromImport: true,
        excludeWholesaleUnitsFromImportRemoveRecon: false,
        SUBSETTING_EXCLUDE_WHOLESALE: {
          dataType: 'BOOL',
          value: true,
        },
        SUBSETTING_EXCLUDE_WHOLESALE_REMOVE_RECON: {
          dataType: 'BOOL',
          value: false,
        },
      },
      EXCLUDE_WHOLESALE_REMOVE_FROM_RECON: {
        excludeWholesaleUnitsFromImport: true,
        excludeWholesaleUnitsFromImportRemoveRecon: true,
        SUBSETTING_EXCLUDE_WHOLESALE: {
          dataType: 'BOOL',
          value: true,
        },
        SUBSETTING_EXCLUDE_WHOLESALE_REMOVE_RECON: {
          dataType: 'BOOL',
          value: true,
        },
      },
      INCLUDE_WHOLESALE: {
        excludeWholesaleUnitsFromImport: false,
        excludeWholesaleUnitsFromImportRemoveRecon: false,
        SUBSETTING_EXCLUDE_WHOLESALE: {
          dataType: 'BOOL',
          value: false,
        },
        SUBSETTING_EXCLUDE_WHOLESALE_REMOVE_RECON: {
          dataType: 'BOOL',
          value: false,
        },
      },
    };
    if (newWholeSaleSetting in wholesaleSettingMap) {
      updateSetting(
        'excludeWholesaleUnitsFromImport',
        wholesaleSettingMap[newWholeSaleSetting].excludeWholesaleUnitsFromImport
      );
      updateSetting(
        'excludeWholesaleUnitsFromImportRemoveRecon',
        wholesaleSettingMap[newWholeSaleSetting]
          .excludeWholesaleUnitsFromImportRemoveRecon
      );
      updateSubsetting(
        'EXCLUDE_WHOLESALE',
        wholesaleSettingMap[newWholeSaleSetting].SUBSETTING_EXCLUDE_WHOLESALE
      );
      updateSubsetting(
        'EXCLUDE_WHOLESALE_REMOVE_RECON',
        wholesaleSettingMap[newWholeSaleSetting]
          .SUBSETTING_EXCLUDE_WHOLESALE_REMOVE_RECON
      );
    }
  };

  return (
    <FormControl variant="outlined" margin="dense" required>
      <InputLabel variant="outlined" margin="dense">
        {strings.WHOLESALE_UNITS}
      </InputLabel>
      <Select
        name="wholesaleUnits"
        label="Wholesale Units"
        value={
          !isUndefined(excludeWholesaleUnitsFromImport) &&
          !isUndefined(excludeWholesaleUnitsFromImportRemoveRecon)
            ? getWholesaleSetting(
                excludeWholesaleUnitsFromImport,
                excludeWholesaleUnitsFromImportRemoveRecon
              )
            : 'EXCLUDE_WHOLESALE'
        }
        variant="outlined"
        margin="dense"
        onChange={setWholesaleSetting}
        required
      >
        <MenuItem value="INCLUDE_WHOLESALE">
          {strings.INCLUDE_WHOLESALE_UNITS}
        </MenuItem>
        <MenuItem value="EXCLUDE_WHOLESALE">
          {strings.EXCLUDE_WHOLESALE_UNITS}
        </MenuItem>
        <MenuItem value="EXCLUDE_WHOLESALE_REMOVE_FROM_RECON">
          {strings.EXCLUDE_WHOLESALE_UNITS_REMOVE_FROM_RECON}
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default WholesaleUnitsSetting;
