import React from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';

import { ReconVelocityWorkflowStep } from 'models';

interface WorkflowStepNewBeginEndStepSectionProps {
  type: string;
  otherSteps: ReconVelocityWorkflowStep[];
  selectedStepId?: ReconVelocityWorkflowStep['id'];
  onSelectStep?: (nextStep: ReconVelocityWorkflowStep['id']) => void;
}

const WorkflowStepNewBeginEndStepSection = ({
  type,
  otherSteps,
  selectedStepId,
  onSelectStep,
}: WorkflowStepNewBeginEndStepSectionProps) => {
  return (
    <>
      <br />
      <div>{`Which step would you like to be the next ${type} step?`}</div>

      <br />

      <div className="flex-row">
        <FormControl variant="outlined" style={{ width: 250 }}>
          <Select
            variant="outlined"
            required={otherSteps.length > 0}
            margin="dense"
            displayEmpty
            value={selectedStepId ?? ''}
            onChange={({ target: { value } }) =>
              onSelectStep?.(value as string)
            }
          >
            <MenuItem value="">Select a Step</MenuItem>
            {otherSteps.map((stepDefinition) => (
              <MenuItem
                key={`preceding-steps-next-step-${stepDefinition.id}`}
                value={stepDefinition.id}
              >
                {stepDefinition.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </>
  );
};

export default WorkflowStepNewBeginEndStepSection;
