import React, { useState, Fragment, useEffect } from 'react';
import { capitalize } from 'lodash';
import { useDispatch } from 'react-redux';

import LoadingIndicator from 'components/shared/LoadingIndicator';
import { updateOrganizationOverview } from 'store/actions/organizations';
import utils from 'utils';

import {
  Address,
  Contact,
  OrganizationDepartment,
  Organization,
  DAYS,
} from 'models';
import NumberFormat from 'react-number-format';
import { DeepPartial } from 'common/types';
import { useOrganization } from 'api/organizations';
import EditOrganizationAddressDialog from './EditOrganizationAddressDialog';
import EditOrganizationDepartmentDialog, {
  DepartmentType,
} from './EditOrganizationDepartmentDialog';

import './OverviewView.scss';
import testIds, { generateTestId } from '../../../../common/testIds';

const CONTENT_WIDTH = '100%';
const MARGIN = '12px';
const CONTENT_STYLE = {
  width: CONTENT_WIDTH,
  marginBottom: MARGIN,
  padding: MARGIN,
};

interface DepartmentViewProps {
  organization?: Organization;
  type: DepartmentType;
  department?: OrganizationDepartment | null;
  onOpenDepartmentDialog: (
    type: DepartmentType,
    department?: OrganizationDepartment | null
  ) => void;
}

var DepartmentView = ({
  organization,
  type,
  department,
  onOpenDepartmentDialog,
}: DepartmentViewProps) => {
  if (!organization) return null;

  function convertSecondsToTime(seconds: number): string {
    return utils.time.getAmPmTimeStringFromSecondsAfterMidnight(
      utils.time.offsetSecondsAfterMidnight(seconds, 0)
    );
  }

  const organizationTimezone =
    organization?.timezone?.timezone !== 'UNKNOWN'
      ? organization?.timezone
      : undefined;

  return (
    <div className="DepartmentView flex-grow">
      <div>
        <h4 className="inline margin-right">{type}</h4>
        {/* <PermissionGate permissions={[permissions.ORGS_UPDATE]}>
          <Create
            data-vas-testing={generateTestId(
              testIds.ORGANIZATION_DEPT_EDIT_ICON,
              {
                type: type.toLowerCase(),
              },
            )}
            onClick={() => onOpenDepartmentDialog(type, department)}
          />
        </PermissionGate> */}
      </div>

      <div className="DepartmentView__schedule-grid">
        <label>Phone:</label>
        <span
          data-vas-testing={generateTestId(testIds.ORGANIZATION_DEPT_PHONE, {
            type: type.toLowerCase(),
          })}
        >
          {department?.phone ? (
            <NumberFormat
              value={department?.phone}
              displayType="text"
              format="###-###-####"
            />
          ) : (
            'None'
          )}
        </span>
        <label>Email:</label>
        <div
          data-vas-testing={generateTestId(testIds.ORGANIZATION_DEPT_EMAIL, {
            type: type.toLowerCase(),
          })}
        >
          {department?.email ? (
            <a href={`mailto:${department.email}`}>{department.email}</a>
          ) : (
            'None'
          )}
        </div>

        <div className="separator" />

        {DAYS.map((day) => {
          const daySchedule = department?.schedule?.[day];
          return (
            <Fragment key={day}>
              <label>{capitalize(day)}:</label>
              <div>
                {daySchedule
                  ? [
                      convertSecondsToTime(daySchedule.openInSecondsUTC),
                      '-',
                      convertSecondsToTime(daySchedule.closedInSecondsUTC),
                      ' ',
                      organizationTimezone?.timezone ?? '',
                    ].join('')
                  : 'Closed'}
              </div>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

interface OverviewProps {
  organizationId: string;
}

var OverviewView = ({ organizationId }: OverviewProps) => {
  const [isEditAddressDialogOpen, setIsEditAddressDialogOpen] =
    useState<boolean>(false);
  const editingAddressType = 'Location';
  const [isEditDepartmentDialogOpen, setIsEditDepartmentDialogOpen] =
    useState<boolean>(false);

  const [editingDepartment, setEditingDepartment] = useState<
    OrganizationDepartment | undefined | null
  >();
  const [editingDepartmentType, setEditingDepartmentType] = useState<
    DepartmentType | undefined
  >();
  const [isUpdatingAddress, setIsUpdatingAddress] = useState<boolean>(false);
  const [isUpdatingDepartment, setIsUpdatingDepartment] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const { data: org, isLoading } = useOrganization(organizationId);
  const [organization, setOrganization] = useState<Organization>();

  useEffect(() => {
    setOrganization(org);
  }, [org, setOrganization]);

  if (isLoading) return <LoadingIndicator />;

  const openDepartmentDialog = (
    departmentType: DepartmentType,
    department?: OrganizationDepartment | null
  ) => {
    setIsEditDepartmentDialogOpen(true);
    setEditingDepartment(department);
    setEditingDepartmentType(departmentType);
  };

  const displayCityStateZip = (address?: Address) => {
    if (!address) return null;

    const array = [address.city, address.state?.shortName, address.postalCode];
    if (address.city && address.state) {
      array[0] += ',';
    }
    return array.join(' ');
  };

  const displayFullName = (contact?: Contact | null) => {
    if (!contact) return null;

    return `${contact.firstName} ${contact.lastName}`.trim();
  };

  return (
    <div className="OverviewView padding full-height full-width flex-rows flex-align-center flex-justify-start">
      <div className="flex-columns" style={CONTENT_STYLE}>
        <div className="flex-grow">
          <div>
            <h4 className="inline margin-right">Location Address/Contact</h4>
            {/* <PermissionGate permissions={[permissions.ORGS_UPDATE]}>
              <Create onClick={() => openAddressDialog(`Location`)} />
            </PermissionGate> */}
          </div>
          <p>
            <span className="block">
              {organization?.mailingAddress?.street1}
            </span>
            <span className="block">
              {organization?.mailingAddress?.street2}
            </span>
            <span className="block">
              {displayCityStateZip(organization?.mailingAddress)}
            </span>
          </p>
          <p>
            <span className="block">
              {displayFullName(organization?.mailingContact) ||
                'No Contact Name'}
            </span>
            <span className="block">
              {organization?.mailingContact?.jobTitle}
            </span>
            <span className="block">
              <NumberFormat
                value={
                  organization?.mailingContact?.mobilePhone?.number ?? 'None'
                }
                displayType="text"
                format="###-###-####"
              />
            </span>
            <span className="block">
              <a href={`mailto:${organization?.mailingContact?.email}`}>
                {organization?.mailingContact?.email}
              </a>
            </span>
          </p>
        </div>

        <div className="flex-grow">
          <div>
            <h4 className="inline margin-right">Billing Address/Contact</h4>
            {/* <PermissionGate permissions={[permissions.ORGS_UPDATE]}>
              <Create onClick={() => openAddressDialog(`Billing`)} />
            </PermissionGate> */}
          </div>
          <p>
            <span className="block">
              {organization?.billingAddress?.street1}
            </span>
            <span className="block">
              {organization?.billingAddress?.street2}
            </span>
            <span className="block">
              {displayCityStateZip(organization?.billingAddress)}
            </span>
          </p>
          <p>
            <span className="block">
              {displayFullName(organization?.billingContact) ||
                'No Contact Name'}
            </span>
            <span className="block">
              {organization?.billingContact?.jobTitle}
            </span>
            <span className="block">
              <NumberFormat
                value={
                  organization?.billingContact?.mobilePhone?.number ?? 'None'
                }
                displayType="text"
                format="###-###-####"
              />
            </span>
            <span className="block">
              <a href={`mailto:${organization?.billingContact?.email}`}>
                {organization?.billingContact?.email}
              </a>
            </span>
          </p>
        </div>
      </div>

      <div className="flex-columns" style={CONTENT_STYLE}>
        <DepartmentView
          key="Sales"
          type="Sales"
          department={organization?.salesDepartment}
          onOpenDepartmentDialog={openDepartmentDialog}
          organization={organization}
        />
        <DepartmentView
          key="Service"
          type="Service"
          department={organization?.serviceDepartment}
          onOpenDepartmentDialog={openDepartmentDialog}
          organization={organization}
        />
        <DepartmentView
          key="Parts"
          type="Parts"
          department={organization?.partsDepartment}
          onOpenDepartmentDialog={openDepartmentDialog}
          organization={organization}
        />
      </div>

      {isEditAddressDialogOpen && organization! && (
        <EditOrganizationAddressDialog
          organization={organization!}
          type={editingAddressType}
          isUpdatingAddress={isUpdatingAddress}
          onClose={() => {
            setIsEditAddressDialogOpen(false);
          }}
          onSave={async (
            addressData: Partial<Address>,
            contactData: Partial<Contact>
          ) => {
            setIsUpdatingAddress(true);
            const addressKey = 'mailingAddress' as string;
            const contactKey = 'mailingContact' as string;
            const newOrg = {
              id: organization?.id,
              [addressKey]: addressData,
              [contactKey]: contactData,
            };
            await dispatch(
              updateOrganizationOverview({
                org: newOrg,
              })
            );
            setIsUpdatingAddress(false);
            setIsEditAddressDialogOpen(false);
          }}
        />
      )}
      {isEditDepartmentDialogOpen && organization && (
        <EditOrganizationDepartmentDialog
          organization={organization}
          department={editingDepartment!}
          type={editingDepartmentType!}
          onClose={() => {
            setIsEditDepartmentDialogOpen(false);
          }}
          isUpdatingDepartment={isUpdatingDepartment}
          onSave={async (
            departmentData: DeepPartial<OrganizationDepartment>
          ) => {
            setIsUpdatingDepartment(true);

            const departmentKey = `${editingDepartmentType!.toLowerCase()}Department`;
            const newOrg = {
              id: organization?.id,
              [departmentKey]: departmentData,
            };
            await dispatch(updateOrganizationOverview({ org: newOrg }));

            setIsUpdatingDepartment(false);
            setIsEditDepartmentDialogOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default OverviewView;
