import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { useUpdateUsersOrgGroups } from 'api/organizations/users';
import useSession from 'api/session';
import PERMISSIONS from 'common/permissions';
import Alert, { useAlert } from 'components/shared/Alert';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import EditUserDialog from 'components/UsersView/EditUserDialog';
import React, { useEffect, useState } from 'react';
import GroupsListView from './GroupsListView';
import UsersListView from './UsersListView';

interface AddUserDialogProps {
  organizationId: string;
  onClose: () => void;
  groupId?: string;
}

var AddUserDialog = ({
  organizationId,
  onClose,
  groupId,
}: AddUserDialogProps) => {
  const [checkedGroups, setCheckedGroups] = useState<string[]>([]);
  const [checkedUsers, setCheckedUsers] = useState<string[]>([]);
  const [isEditUserDialogOpen, setIsEditUserDialogOpen] = useState(false);
  const [userId, setUserId] = useState<string>('');

  const {
    isLoading: isSaving,
    mutate,
    isError,
    isSuccess,
  } = useUpdateUsersOrgGroups();

  const { data: session } = useSession();
  const hasCreatePermission = session?.permissions.includes(
    PERMISSIONS.USERS_CREATE
  );

  const closeEditUserDialog = () => {
    setIsEditUserDialogOpen(false);
    setUserId('');
  };

  const openEditUserDialog = (selectedUserId: string = '') => {
    setIsEditUserDialogOpen(true);
    setUserId(selectedUserId);
  };

  const { isAlertOpen, alertMessage, openAlert, closeAlert, variant } =
    useAlert();

  useEffect(() => {
    if (groupId) {
      setCheckedGroups([groupId]);
    }
  }, [groupId]);

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess, onClose]);

  useEffect(() => {
    if (isError) {
      openAlert(
        'An error occurred when trying to save these changes to the server.',
        'error'
      );
    }
  }, [isError, openAlert]);

  const onPrimary = async () => {
    if (checkedUsers.length === 0) {
      openAlert('Must choose at least one user.', 'error');
      return;
    }

    if (checkedGroups.length === 0) {
      openAlert('Must choose at least one group.', 'error');
      return;
    }

    mutate({
      orgId: organizationId,
      userIds: checkedUsers,
      orgGroupIds: checkedGroups,
      groupIdSelected: groupId ?? '',
    });
  };

  return (
    <>
      <Dialog open maxWidth="xl" fullWidth onClose={onClose}>
        <DialogTitle>Add Users to Organization Groups</DialogTitle>
        <DialogContent style={{ height: 540 }}>
          <div className="full-height full-width border-top border-bottom flex-columns">
            <div
              id="users-collections-container"
              className="full-height flex-grow flex-rows"
              style={{ width: 620 }}
            >
              <div className="flex-grow full-width">
                <UsersListView
                  checkedUsers={checkedUsers}
                  setCheckedUsers={setCheckedUsers}
                  openEditUserDialog={openEditUserDialog}
                />
              </div>
            </div>
            <div className="separator" />
            <GroupsListView
              checkedGroups={checkedGroups}
              setCheckedGroups={setCheckedGroups}
              organizationId={organizationId}
            />
          </div>
        </DialogContent>
        <DialogActions>
          {hasCreatePermission && (
            <Button id="new-user-button" onClick={() => openEditUserDialog()}>
              {`New User${String.fromCharCode(8230)}`}
            </Button>
          )}
          <div className="Dialog-divider" />
          <Button onClick={onClose} disabled={isSaving}>
            Cancel
          </Button>
          <Button
            color="secondary"
            style={{ color: 'white' }}
            variant="contained"
            onClick={onPrimary}
            disabled={isSaving}
            startIcon={
              isSaving && <LoadingIndicator size={13} color="primary" />
            }
          >
            Ok
          </Button>
        </DialogActions>
        {isEditUserDialogOpen && (
          <EditUserDialog
            organizationId={organizationId}
            onClose={closeEditUserDialog}
            userId={userId}
          />
        )}
      </Dialog>
      <Alert
        open={isAlertOpen}
        message={alertMessage}
        variant={variant}
        onClose={closeAlert}
      />
    </>
  );
};

export default AddUserDialog;
