import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { useFormik } from 'formik';

import Tabs, { TabHeaderItem } from 'components/shared/Tabs';
import Dialog from 'components/shared/Dialog';
import ConfirmationDialog, {
  useConfirmationDialog,
} from 'components/shared/ConfirmationDialog';
import Alert, { useAlert } from 'components/shared/Alert';
import testIds from 'common/testIds';
import utils from 'utils';
import {
  useUpdateOrganization,
  useOrganizationsFlat,
  useCreateOrganization,
} from 'api/organizations';

import './OrganizationDialog.scss';
import { defaultOrganization, Organization, Timezone } from 'models';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import OrganizationDepartmentSubform from '../OrganizationDepartmentSubform';
import ContactForm from './ContactForm';
import validationSchema from './validationSchema';

const TIMEZONE_LIST: Array<Pick<Timezone, 'timezone' | 'label'>> = [
  {
    timezone: 'AKT',
    label: 'Alaska',
  },
  {
    timezone: 'AT',
    label: 'Atlantic',
  },
  {
    timezone: 'CT',
    label: 'Central',
  },
  {
    timezone: 'ET',
    label: 'Eastern',
  },
  {
    timezone: 'HT',
    label: 'Hawaii',
  },
  {
    timezone: 'MT',
    label: 'Mountain',
  },
  {
    timezone: 'PT',
    label: 'Pacific',
  },
];
const DEFAULT_TAB_KEY = 'location';

// TODO We need to refactor the router
const getOrgTabPath = (locationPathname: string) => {
  const pathTokens = locationPathname.split('/');
  const indexOfEditToken = pathTokens.indexOf('org');
  return pathTokens
    .slice(3, indexOfEditToken === -1 ? pathTokens.length : indexOfEditToken)
    .join('/');
};

interface OrganizationDialogProps {
  organization?: Organization;
  onClose?: () => void;
}

var OrganizationDialog = ({
  organization: org,
  onClose,
}: OrganizationDialogProps) => {
  const { isAlertOpen, alertMessage, variant, closeAlert, openAlert } =
    useAlert();
  const {
    isConfirmationLoading,
    isConfirmationDialogOpen,
    confirmationMessage,
    onDeny,
    onAccept,
  } = useConfirmationDialog();
  const { data: organizations, isLoading } = useOrganizationsFlat();
  const location = useLocation();
  const navigate = useNavigate();
  const [currentOrgBasePath] = useState(getOrgTabPath(location.pathname));
  const [organization] = useState<Organization>(org ?? defaultOrganization);
  const { updateOrganizationAsync, isLoading: isUpdating } =
    useUpdateOrganization(organization.id);
  const { createOrganizationAsync, isLoading: isCreating } =
    useCreateOrganization();

  const isMutating = isUpdating || isCreating;

  useEffect(() => {
    if (currentOrgBasePath) {
      navigate(`${currentOrgBasePath}/org/${DEFAULT_TAB_KEY}`, {
        replace: true,
      });
    } else {
      navigate(`org/${DEFAULT_TAB_KEY}`, { replace: true });
    }
  }, [currentOrgBasePath, navigate]);

  const initialValues: Organization = {
    id: org?.id ?? defaultOrganization.id,
    name: org?.name ?? defaultOrganization.name,
    externalId: org?.externalId ?? defaultOrganization.externalId,
    status: org?.status ?? defaultOrganization.status,
    positionType: org?.positionType ?? defaultOrganization.positionType,
    type: org?.type ?? defaultOrganization.type,
    billingAddress: org?.billingAddress ?? defaultOrganization.billingAddress,
    billingContact: org?.billingContact ?? defaultOrganization.billingContact,
    mailingAddress: org?.mailingAddress ?? defaultOrganization.mailingAddress,
    mailingContact: org?.mailingContact ?? defaultOrganization.mailingContact,
    timezone: org?.timezone ?? defaultOrganization.timezone,
    mainDepartment: org?.mainDepartment ?? defaultOrganization.mainDepartment,
    salesDepartment:
      org?.salesDepartment ?? defaultOrganization.salesDepartment,
    serviceDepartment:
      org?.serviceDepartment ?? defaultOrganization.serviceDepartment,
    partsDepartment:
      org?.partsDepartment ?? defaultOrganization.partsDepartment,
    websiteUrl: org?.websiteUrl ?? defaultOrganization.websiteUrl,
    parent: org?.parent ?? defaultOrganization.parent,
    level: org?.level ?? defaultOrganization.level,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        if (values.id) {
          await updateOrganizationAsync(values);
        } else {
          await createOrganizationAsync({
            ...values,
            parentId: values.parent?.id,
          });
        }
        onClose?.();
      } catch (error) {
        const requestType = values.id ? 'updating' : 'creating';
        openAlert(
          `There was an error ${requestType} the Organization`,
          'error'
        );
      }
    },
  });

  const orgDialogTabs: TabHeaderItem[] = [
    {
      key: 'location',
      label: 'Location',
      showBadge:
        (formik.touched.mailingAddress && !!formik.errors.mailingAddress) ||
        (formik.touched.mailingContact && !!formik.errors.mailingContact),
    },
    {
      key: 'billing',
      label: 'Billing',
      showBadge:
        (formik.touched.billingAddress && !!formik.errors.billingAddress) ||
        (formik.touched.billingContact && !!formik.errors.billingContact),
    },
    {
      key: 'sales',
      label: 'Sales',
      showBadge:
        formik.touched.salesDepartment && !!formik.errors.salesDepartment,
    },
    {
      key: 'service',
      label: 'Service',
      showBadge:
        formik.touched.serviceDepartment && !!formik.errors.serviceDepartment,
    },
    {
      key: 'parts',
      label: 'Parts',
      showBadge:
        formik.touched.partsDepartment && !!formik.errors.partsDepartment,
    },
  ];

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (organization === undefined) {
    return <div>NO Organization Info</div>;
  }

  const basename = currentOrgBasePath ? `${currentOrgBasePath}/org/` : 'org/';

  return (
    <>
      <Dialog
        open
        title={`${organization?.id ? 'Edit' : 'New'} Organization`}
        height={575}
        maxWidth="md"
        fullWidth
        primaryButtonLabel="Save"
        block={isLoading || isMutating}
        onSubmit={formik.handleSubmit}
        onClose={onClose}
        className="OrganizationDialog"
      >
        <div className="flex-columns flex-justify-center">
          <div
            data-vas-testing={testIds.NEW_ORG_MAIN_1}
            className="OrganizationDialog-flex-container side-padding"
          >
            <TextField
              id="OrganizationDialog-Org-Name"
              className={`${testIds.NEW_ORG_NAME} OrganizationDialog-text-input OrganizationDialog-text-input-name`}
              autoFocus
              label="Name"
              name="name"
              variant="outlined"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={!!formik.errors.name}
              margin="dense"
            />
            <div className="OrganizationDialog-radio-group">
              <FormLabel className="OrganizationDialog-radio-group-label">
                Type
              </FormLabel>
              <RadioGroup
                row
                name="type"
                value={formik.values.type}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <FormControlLabel
                  value="ROOFTOP"
                  control={<Radio />}
                  label="Rooftop"
                />
                <FormControlLabel
                  value="GROUP"
                  control={<Radio />}
                  label="Group"
                />
              </RadioGroup>
            </div>
            <FormControl
              variant="outlined"
              className="OrganizationDialog-select OrganizationDialog-parent-select"
            >
              <InputLabel id="organization-dialog-parent-select">
                Parent
              </InputLabel>
              <Select
                id="OrganizationDialog-Organization-Parent"
                labelId="parent-organization-select-label"
                variant="outlined"
                value={formik.values.parent?.id ?? '-'}
                onChange={(event) => {
                  if (event.target.value === '-') {
                    formik.setFieldValue('parent', {
                      id: undefined,
                      name: undefined,
                    });
                    return;
                  }
                  formik.handleChange(event);
                }}
                onBlur={formik.handleBlur}
                error={!!formik.errors.parent}
                name="parent.id"
                label="Parent"
              >
                <MenuItem value="-" id="OrganizationDialog-no-parent-option">
                  No Parent (Root Organization)
                </MenuItem>
                {organizations?.data.map(({ id, name }) => (
                  // const optionIndent = new Array( TODO in the API
                  //   level * INDENT_SPACES + 1,
                  // ).join(NON_BREAKING_SPACE);
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div
            data-vas-testing={testIds.NEW_ORG_MAIN_2}
            className="OrganizationDialog-flex-container side-padding"
          >
            <NumberFormat
              id="OrganizationDialog-Main-Phone"
              name="mainDepartment.phone"
              customInput={TextField}
              className="OrganizationDialog-text-input OrganizationDialog-text-input-large-margin"
              label="Main Phone"
              inputMode="tel"
              type="tel"
              variant="outlined"
              margin="dense"
              format="(###) ###-####"
              mask="_"
              value={formik.values.mainDepartment?.phone}
              onChange={(e) => {
                const newPhone = utils.strings.cleanPhoneNumber(e.target.value);
                formik.setFieldValue('mainDepartment.phone', newPhone);
              }}
              onBlur={formik.handleBlur}
              error={!!formik.errors.mainDepartment?.phone}
            />
            <TextField
              id="OrganizationDialog-Main-Email"
              className="OrganizationDialog-text-input OrganizationDialog-text-input-large-margin"
              label="Main Email"
              inputMode="email"
              type="email"
              variant="outlined"
              name="mainDepartment.email"
              value={formik.values.mainDepartment?.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              margin="dense"
              error={!!formik.errors.mainDepartment?.email}
            />
            <TextField
              id="OrganizationDialog-Website-Url"
              className="OrganizationDialog-text-input OrganizationDialog-text-input-large-margin"
              label="Website URL"
              inputMode="url"
              type="url"
              variant="outlined"
              name="websiteUrl"
              value={formik.values.websiteUrl}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              margin="dense"
              error={!!formik.errors.websiteUrl}
            />
            <FormControl
              variant="outlined"
              className="OrganizationDialog-select OrganizationDialog-time-zone-select"
            >
              <InputLabel id="organization-dialog-time-zone-select">
                Time Zone
              </InputLabel>
              <Select
                id="OrganizationDialog-Time-Zone"
                labelId="time-zone-select-label"
                name="mainDepartment.timezone.timezone"
                value={formik.values.timezone.timezone ?? ''}
                onChange={(e) => {
                  const newTimeZone = TIMEZONE_LIST.find(
                    (listZone) => listZone.timezone === e.target.value
                  );
                  formik.setFieldValue('timezone', newTimeZone);
                }}
                onBlur={formik.handleBlur}
                placeholder="Choose Time Zone"
                label="Time Zone"
              >
                {TIMEZONE_LIST.map((item) => (
                  <MenuItem key={item.timezone} value={item.timezone}>
                    {`${item.timezone} - ${item.label}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <Tabs
          tabHeaderItems={orgDialogTabs}
          defaultTabKey={DEFAULT_TAB_KEY}
          basename={basename}
        />
        <Routes basename={basename}>
          <Route
            path="location"
            element={<ContactForm type="LOCATION" formik={formik} />}
          />
          <Route
            path="billing"
            element={
              <ContactForm
                type="BILLING"
                formik={formik}
                copyMailingAddressToBillingAddress={() => {
                  formik.setValues({
                    ...formik.values,
                    billingAddress: formik.values.mailingAddress,
                  });
                }}
                copyMailingContactToBillingContact={() => {
                  formik.setValues({
                    ...formik.values,
                    billingContact: formik.values.mailingContact,
                  });
                }}
              />
            }
          />
          <Route
            path="sales"
            element={
              <OrganizationDepartmentSubform
                departmentType="SALES"
                formik={formik}
              />
            }
          />
          <Route
            path="service"
            element={
              <OrganizationDepartmentSubform
                departmentType="SERVICE"
                formik={formik}
              />
            }
          />
          <Route
            path="parts"
            element={
              <OrganizationDepartmentSubform
                departmentType="PARTS"
                formik={formik}
              />
            }
          />
        </Routes>
      </Dialog>
      <Alert
        open={isAlertOpen}
        message={alertMessage}
        variant={variant}
        onClose={closeAlert}
        duration={3500}
      />
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        isLoading={isConfirmationLoading}
        onPrimaryButtonClick={onAccept}
        onSecondaryButtonClick={onDeny}
        message={confirmationMessage}
      />
    </>
  );
};

export default OrganizationDialog;
