import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextFieldProps,
  Typography,
} from '@material-ui/core';
import { Save } from '@material-ui/icons';

import { Address, Contact, Organization, Region } from 'models';
import { useRegions } from 'api';
import LoadingIndicator from 'components/shared/LoadingIndicator';

export type AddressType = 'Billing' | 'Location';

interface EditOrganizationAddressDialogProps {
  organization: Organization;
  type: AddressType;
  onClose: () => void;
  onSave: (
    addressData: Partial<Address>,
    contactData: Partial<Contact>
  ) => void;
  isUpdatingAddress: boolean;
}

interface RenderTextFieldArgs {
  id: string;
  label: string;
  value: string;
  required?: boolean;
  onChange: TextFieldProps['onChange'];
}

const EditOrganizationAddressDialog: React.FC<EditOrganizationAddressDialogProps> =
  ({
    organization,
    type,
    onClose,
    onSave,
    isUpdatingAddress,
  }: EditOrganizationAddressDialogProps) => {
    const { data: regions = [] } = useRegions();
    const addressType = type === 'Billing' ? 'billing' : 'mailing';
    const addressKey = `${addressType}Address` as keyof Organization;
    const contactKey = `${addressType}Contact` as keyof Organization;
    const organizationAddress = organization[addressKey] as Address;
    const organizationContact = organization[contactKey] as Contact;
    const [
      [street1, setStreet1],
      [street2, setStreet2],
      [city, setCity],
      [state, setState],
      [postalCode, setPostalCode],
      [firstName, setFirstName],
      [lastName, setLastName],
      [jobTitle, setJobTitle],
      [email, setEmail],
      [phone, setPhone],
    ] = [
      useState(organizationAddress?.street1 ?? ''),
      useState(organizationAddress?.street2 ?? ''),
      useState(organizationAddress?.city ?? ''),
      useState(organizationAddress?.state ?? ''),
      useState(organizationAddress?.postalCode ?? ''),
      useState(organizationContact?.firstName ?? ''),
      useState(organizationContact?.lastName ?? ''),
      useState(organizationContact?.jobTitle ?? ''),
      useState(organizationContact?.email ?? ''),
      useState(organizationContact?.mobilePhone?.number ?? ''),
    ];

    const renderTextField = ({
      id,
      label,
      onChange,
      value,
      required = false,
    }: RenderTextFieldArgs) => (
      <div className="EditOrganizationAddressDialog-input">
        <TextField
          fullWidth
          id={id}
          label={label}
          variant="outlined"
          required={required}
          value={value}
          onChange={onChange}
        />
      </div>
    );

    const renderStateSelect = () => (
      <div className="EditOrganizationAddressDialog-input">
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="state-select-label" variant="outlined">
            State
          </InputLabel>
          <Select
            id="state-select"
            labelId="state-select-label"
            variant="outlined"
            label="State"
            value={state.name || '--'}
            onChange={(event) => {
              const pickedState = regions?.find(
                (listRegion) => listRegion.label === event.target.value
              );
              const mappedState = {
                id: pickedState!.id,
                name: pickedState!.label,
                shortName: pickedState!.isoCode,
              };
              setState(mappedState);
            }}
          >
            {regions?.map(({ id, label }) => (
              <MenuItem key={id} value={label}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );

    const onSaveClick = () => {
      const addressData = {
        street1,
        street2,
        city,
        state,
        postalCode,
      };
      const contactData = {
        firstName,
        lastName,
        jobTitle,
        email,
        mobilePhone: {
          number: phone,
          allowSMS: organizationContact?.mobilePhone?.allowSMS ?? false,
        },
      };

      onSave(addressData, contactData);
    };

    const isCanadianProvinceSelected = () => {
      const province: Region | undefined = regions?.find(
        (listState) => listState.isoCode === state?.shortName
      );
      return province?.country.country === 'Canada';
    };

    const postalCodeLabel = isCanadianProvinceSelected()
      ? 'Postal Code'
      : 'Zip Code';

    return (
      <Dialog
        open
        className="EditOrganizationAddressDialog"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <Typography variant="h6">{`Edit ${type} Address/Contact`}</Typography>
        </DialogTitle>
        <DialogContent>
          <div className="EditOrganizationAddressDialog-content-container">
            {renderTextField({
              id: 'address-text-field',
              label: 'Address',
              required: true,
              value: street1,
              onChange: (event) => setStreet1(event.target.value),
            })}
            {renderTextField({
              id: 'address2-text-field',
              label: 'Address 2',
              value: street2,
              onChange: (event) => setStreet2(event.target.value),
            })}
            {renderTextField({
              id: 'city-text-field',
              label: 'City',
              required: true,
              value: city,
              onChange: (event) => setCity(event.target.value),
            })}
            {renderStateSelect()}
            {renderTextField({
              id: 'postal-code-text-field',
              label: postalCodeLabel,
              required: true,
              value: postalCode,
              onChange: (event) => setPostalCode(event.target.value),
            })}
          </div>
          <div className="EditOrganizationAddressDialog-content-container">
            {renderTextField({
              id: 'first-name-text-field',
              label: 'First Name',
              required: true,
              value: firstName,
              onChange: (event) => setFirstName(event.target.value),
            })}
            {renderTextField({
              id: 'last-name-text-field',
              label: 'Last Name',
              required: true,
              value: lastName,
              onChange: (event) => setLastName(event.target.value),
            })}
            {renderTextField({
              id: 'job-title-text-field',
              label: 'Job Title',
              value: jobTitle,
              onChange: (event) => setJobTitle(event.target.value),
            })}
            {renderTextField({
              id: 'email-text-field',
              label: 'Email',
              required: true,
              value: email,
              onChange: (event) => setEmail(event.target.value),
            })}
            {renderTextField({
              id: 'phone-text-field',
              label: 'Phone',
              required: true,
              value: phone,
              onChange: (event) => setPhone(event.target.value),
            })}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} disabled={isUpdatingAddress}>
            Cancel
          </Button>
          <Button
            color="secondary"
            style={{ color: 'white' }}
            variant="contained"
            onClick={() => onSaveClick()}
            disabled={isUpdatingAddress}
            startIcon={
              isUpdatingAddress ? (
                <LoadingIndicator size={13} color="primary" />
              ) : (
                <Save style={{ color: 'white' }} />
              )
            }
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

export default EditOrganizationAddressDialog;
