import { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Create } from '@material-ui/icons';

import Tabs, { TabHeaderItem } from 'components/shared/Tabs';
import PERMISSIONS from 'common/permissions';
import ConfirmationDialog, {
  useConfirmationDialog,
} from 'components/shared/ConfirmationDialog';
import { defaultOrganizationGroup, OrganizationGroup } from 'models';
import useSession from 'api/session';

import PermissionsView from './PermissionsView';
import UsersView from './UsersView';
import EditGroupDialog from '../EditGroupDialog';

const TABS: TabHeaderItem[] = [
  { label: 'Users', key: 'users' },
  { label: 'Permissions', key: 'permissions' },
];
const DEFAULT_TAB_KEY = 'users';

interface GroupDetailViewProps {
  group?: OrganizationGroup;
  organizationId: string;
}

var GroupDetailView = ({ group, organizationId }: GroupDetailViewProps) => {
  const {
    isConfirmationDialogOpen,
    isConfirmationLoading,
    confirmationMessage,
    primaryButtonLabel,
    onAccept,
    onDeny,
    openConfirmationDialog,
  } = useConfirmationDialog();
  const [isEditGroupDialogOpen, setIsEditGroupDialogOpen] = useState(false);
  const [groupForEdit, setGroupForEdit] = useState(group);

  const { data: session } = useSession();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(DEFAULT_TAB_KEY, { replace: true });
  }, [navigate]);

  if (!group) {
    return null;
  }

  function cloneGroup() {
    const clonedGroup: OrganizationGroup = {
      ...defaultOrganizationGroup,
      roles: group?.roles ?? [],
    };
    setGroupForEdit(clonedGroup);
    setIsEditGroupDialogOpen(true);
  }

  function editGroup() {
    setGroupForEdit(group);
    setIsEditGroupDialogOpen(true);
  }

  const handleEditButtonClick = () => {
    if (group.type === 'INTERNAL') {
      openConfirmationDialog({
        messageOverride: `This group ${group.name} is internal and cannot be edited. Would you like to clone this group?`,
        primaryButtonLabelOverride: 'Clone',
        onAcceptOverride: cloneGroup,
      });
      return;
    }

    editGroup();
  };

  const updateGroupPermission = session?.permissions.includes(
    PERMISSIONS.ORGS_GROUPS_UPDATE
  );

  return (
    <div className="full-height flex-rows">
      <div className="heading full-width flex-columns flex-space-between">
        <div className="inline-block">
          <h2 className="inline">{group.name}</h2>
          &nbsp;{' '}
          <span className="nowrap">
            ID:
            {group.id}
          </span>
        </div>
        <div className="nowrap">
          {updateGroupPermission && <Create onClick={handleEditButtonClick} />}
        </div>
      </div>
      <Tabs tabHeaderItems={TABS} defaultTabKey={DEFAULT_TAB_KEY} />
      <div className="flex-grow">
        <Routes>
          <Route
            path="users/*"
            element={
              <UsersView
                key={group.id}
                organizationId={organizationId}
                groupId={group.id}
              />
            }
          />
          <Route
            path="permissions/*"
            // TODO:
            element={
              <PermissionsView
                key={group.id}
                permissions={group.roles[0].permissions}
              />
            }
          />
          {/* TODO pass permissions */}
        </Routes>
      </div>
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        message={confirmationMessage}
        onPrimaryButtonClick={onAccept}
        onSecondaryButtonClick={onDeny}
        isLoading={isConfirmationLoading}
        primaryButtonLabel={primaryButtonLabel}
      />
      {isEditGroupDialogOpen && groupForEdit && (
        <EditGroupDialog
          group={groupForEdit}
          onClose={() => {
            setIsEditGroupDialogOpen(false);
          }}
          orgId={organizationId}
        />
      )}
    </div>
  );
};

export default GroupDetailView;
