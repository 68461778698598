import React, { useState, useMemo } from 'react';
import Dialog from 'components/shared/Dialog';
import { Divider } from '@material-ui/core';
import {
  DeleteReconVelocityWorkflowStepData,
  ReconVelocityWorkflowStep,
} from 'models';
import utils from 'utils';
import { useReconVelocityWorkflowSteps } from 'api/organizations/plugins/reconvelocity/workflows';

import './DeleteWorkflowStepDialog.scss';
import WorkflowStepsPrecedingStepsSection from './shared/WorkflowStepPrecedingStepsSection';
import WorkflowStepExistingVehiclesSection from './shared/WorkflowStepExistingVehiclesSection';
import WorkflowStepsChildStepsSection from './shared/WorkflowStepChildStepsSection';
import WorkflowStepNewBeginEndStepSection from './shared/WorkflowStepNewBeginEndStepSection';

export interface DeleteWorkflowStepDialogProps {
  orgId: string;
  workflowStepDefinition: ReconVelocityWorkflowStep;
  onClose?: () => void;
  onDelete: (deleteData: DeleteReconVelocityWorkflowStepData) => void;
}

var DeleteWorkflowStepDialog = (props: DeleteWorkflowStepDialogProps) => {
  const { data: allSteps } = useReconVelocityWorkflowSteps(props.orgId);
  const [isDeleting, setIsDeleting] = useState(false);
  const [newStepIdForExistingVehicles, setNewStepIdForExistingVehicles] =
    useState<string | undefined>('');
  const [newNextStepIdForExistingSteps, setNewNextStepIdForExistingSteps] =
    useState<string | undefined>('');
  const [newBeginningStepId, setNewBeginningStepId] = useState<
    string | undefined
  >('');
  const [precedingStepsToUpdate, setPrecedingStepsToUpdate] = useState<
    (string | undefined)[]
  >([]);
  const { workflowStepDefinition, onClose, onDelete, orgId } = props;

  const onPrimary = async () => {
    const deleteData: DeleteReconVelocityWorkflowStepData = {};
    if (newStepIdForExistingVehicles) {
      deleteData.newIdForExistingInstances = newStepIdForExistingVehicles;
    }
    if (newNextStepIdForExistingSteps) {
      deleteData.newNextId = newNextStepIdForExistingSteps;
    }
    if (newBeginningStepId) {
      deleteData.newBeginningStepId = newBeginningStepId;
    }
    if (precedingStepsToUpdate) {
      deleteData.precedingSteps = precedingStepsToUpdate;
    }

    try {
      setIsDeleting(true);
      await onDelete(deleteData);
    } catch (error) {
      setIsDeleting(false);
      console.error(error);
      return false;
    }

    return true;
  };

  // Steps that are not the current step and also not parent steps (vehicles cannot be moved to parent steps)
  const otherSteps = useMemo(
    () =>
      allSteps?.data.filter((step) => {
        const childSteps = utils.workflow.getChildSteps(step.id, allSteps.data);
        return step.id !== workflowStepDefinition.id && !childSteps?.length;
      }),
    [workflowStepDefinition.id, allSteps]
  );

  const precedingSteps = useMemo(
    () =>
      allSteps?.data.filter(
        (step) =>
          step.id !== workflowStepDefinition.id &&
          step.nextStep?.id === workflowStepDefinition.id
      ),
    [allSteps, workflowStepDefinition.id]
  );

  const { activeVehicleCount } = workflowStepDefinition;

  const childSteps = utils.workflow.getChildSteps(
    workflowStepDefinition.id,
    allSteps?.data
  );
  const hasChildSteps = Boolean(childSteps.length);

  const isBeginningStep = workflowStepDefinition.beginningStep;

  const userResolutionRequired =
    activeVehicleCount > 0 ||
    precedingSteps!.length > 0 ||
    hasChildSteps ||
    isBeginningStep;

  return (
    <>
      <Dialog
        open
        title={`Delete ${workflowStepDefinition.name} Step?`}
        maxWidth={userResolutionRequired ? 'md' : 'sm'}
        primaryButtonLabel="Delete Step"
        onPrimary={onPrimary}
        onClose={onClose}
        primaryButtonDisabled={
          userResolutionRequired &&
          ((activeVehicleCount > 0 && !newStepIdForExistingVehicles) ||
            (precedingSteps!.length > 0 && !newNextStepIdForExistingSteps) ||
            (isBeginningStep && !newBeginningStepId) ||
            hasChildSteps)
        }
        block={isDeleting}
        className="PluginDialog DeleteWorkflowStepDialog"
      >
        {userResolutionRequired && (
          <>
            <WorkflowStepsPrecedingStepsSection
              step={workflowStepDefinition}
              precedingSteps={precedingSteps ?? []}
              otherSteps={otherSteps ?? []}
              forUpdating
              selectedStepId={newNextStepIdForExistingSteps}
              onSelectStep={(nextStepId) => {
                setNewNextStepIdForExistingSteps(nextStepId);
                setPrecedingStepsToUpdate(
                  precedingSteps!.map((step) => step.id)
                );
              }}
            />

            {hasChildSteps && (
              <WorkflowStepsChildStepsSection
                orgId={orgId}
                step={workflowStepDefinition}
                childSteps={childSteps ?? []}
                otherSteps={otherSteps ?? []}
                allSteps={allSteps?.data ?? []}
                forUpdating
              />
            )}

            {isBeginningStep && (
              <WorkflowStepNewBeginEndStepSection
                type="beginning"
                otherSteps={otherSteps ?? []}
                selectedStepId={newBeginningStepId}
                onSelectStep={(nextStepId) => {
                  setNewBeginningStepId(nextStepId);
                }}
              />
            )}

            <>
              <Divider className="margin-top margin-bottom" />

              <WorkflowStepExistingVehiclesSection
                step={workflowStepDefinition}
                action="delete"
                vehicleCount={activeVehicleCount}
                otherSteps={otherSteps ?? []}
                forUpdating
                selectedStepId={newStepIdForExistingVehicles}
                onSelectStep={(newStepId) => {
                  setNewStepIdForExistingVehicles(newStepId);
                }}
              />
            </>
          </>
        )}
      </Dialog>
    </>
  );
};

export default DeleteWorkflowStepDialog;
