import React, { FocusEvent, useMemo } from 'react';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import NumberFormat from 'react-number-format';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { FormikProps, getIn } from 'formik';

import { useRegions } from 'api/organizations';
import utils from 'utils';
import { Organization, AddressState } from 'models';

import './ContactForm.scss';

type ContactFormType = 'LOCATION' | 'BILLING';

interface ContactFormProps {
  type: ContactFormType;
  copyMailingAddressToBillingAddress?: () => void;
  copyMailingContactToBillingContact?: () => void;
  formik: FormikProps<Organization>;
}

var ContactForm = ({
  type,
  copyMailingAddressToBillingAddress,
  copyMailingContactToBillingContact,
  formik,
}: ContactFormProps) => {
  const { data: regions = [] } = useRegions();

  const typeDisplayName = type === 'LOCATION' ? 'Location' : 'Billing';
  const typeIdSelector = type === 'LOCATION' ? 'mailing' : 'billing';

  const addressKey = type === 'LOCATION' ? 'mailingAddress' : 'billingAddress';
  const address = {
    values: formik.values[addressKey],
    errors: formik.errors[addressKey],
    touched: formik.touched[addressKey],
  };

  const contactKey = type === 'LOCATION' ? 'mailingContact' : 'billingContact';
  const contact = {
    values: formik.values[contactKey],
    errors: formik.errors[contactKey],
    touched: formik.touched[contactKey],
  };

  const isBillingCanadianProvinceSelected = useMemo(() => {
    const region = regions.find((r) => r.id === address?.values?.state.id);
    return region?.country.label === 'Canada';
  }, [address.values.state.id, regions]);

  const billingPostalCodeLabel = isBillingCanadianProvinceSelected
    ? 'Postal Code'
    : 'Zip Code';
  const billingPostalCodeFormat = isBillingCanadianProvinceSelected
    ? utils.strings.formatCAPostalCode
    : utils.strings.formatUSZipCode;

  const autoFillBillingAddress = async (
    e: FocusEvent<HTMLTextAreaElement | HTMLInputElement>,
    property: 'street1' | 'street2' | 'city' | 'postalCode'
  ) => {
    if (
      type === 'LOCATION' &&
      formik.values.billingAddress[property]?.trim().length === 0
    ) {
      formik.setValues({
        ...formik.values,
        billingAddress: {
          ...formik.values.billingAddress,
          [property]: address.values[property],
        },
      });
    }
    formik.handleBlur(e);
  };

  return (
    <div className="ContactForm flex-columns flex-justify-center">
      <div className="ContactForm-flex-container padding">
        <div className="margin-bottom">
          <h4 className="inline margin-right">{typeDisplayName} Address</h4>
          {copyMailingAddressToBillingAddress && (
            <button
              type="button"
              role="link"
              className="ContactForm-SameAsLocation"
              onClick={copyMailingAddressToBillingAddress}
            >
              Same as Location Address
            </button>
          )}
        </div>
        <TextField
          id={`ContactForm-${typeIdSelector}-Address`}
          className="ContactForm-text-input"
          label="Address"
          variant="outlined"
          name={`${typeIdSelector}Address.street1`}
          value={address.values.street1}
          onChange={formik.handleChange}
          onBlur={(event) => autoFillBillingAddress(event, 'street1')}
          error={!!address.errors?.street1}
          margin="dense"
        />
        <TextField
          id={`ContactForm-${typeIdSelector}-Address-2`}
          className="ContactForm-text-input"
          label="Address 2"
          variant="outlined"
          name={`${typeIdSelector}Address.street2`}
          value={address.values.street2}
          onChange={formik.handleChange}
          onBlur={(event) => autoFillBillingAddress(event, 'street2')}
          error={!!address.errors?.street2}
          margin="dense"
        />
        <TextField
          id={`ContactForm-${typeIdSelector}-City`}
          className="ContactForm-text-input"
          label="City"
          variant="outlined"
          name={`${typeIdSelector}Address.city`}
          value={address.values.city}
          onChange={formik.handleChange}
          onBlur={(event) => autoFillBillingAddress(event, 'city')}
          error={!!address.errors?.city}
          margin="dense"
        />
        <FormControl
          id={`ContactForm-${typeIdSelector}-State`}
          className="ContactForm-select"
          variant="outlined"
        >
          <InputLabel>State</InputLabel>
          <Select
            variant="outlined"
            value={address.values.state.id ?? ''}
            name={`${typeIdSelector}Address.state.id`}
            onChange={(e) => {
              const selectedState = regions.find(
                (listState) => listState.id === e.target.value
              );
              const newState: AddressState = {
                id: selectedState?.id,
                name: selectedState!.label,
                shortName: selectedState!.isoCode,
              };
              formik.setFieldValue(`${typeIdSelector}Address.state`, newState);

              if (
                type === 'LOCATION' &&
                formik.values.billingAddress.state.id?.trim().length === 0
              ) {
                formik.setFieldValue(`billingAddress.state`, newState);
              }
            }}
            onBlur={formik.handleBlur}
            error={!!address.errors?.state?.id}
            placeholder="Choose State"
            label="State"
          >
            {regions.map(({ id, label }) => (
              <MenuItem key={id} value={id}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          id={`ContactForm-${typeIdSelector}-Zip-Code`}
          className="ContactForm-text-input"
          label={billingPostalCodeLabel}
          variant="outlined"
          name={`${typeIdSelector}Address.postalCode`}
          value={billingPostalCodeFormat(address.values.postalCode)}
          onChange={formik.handleChange}
          onBlur={(event) => autoFillBillingAddress(event, 'postalCode')}
          error={!!address.errors?.postalCode}
          margin="dense"
        />
      </div>

      <div className="ContactForm-flex-container padding">
        <div className="margin-bottom">
          <h4 className="inline margin-right">{typeDisplayName} Contact</h4>
          {copyMailingContactToBillingContact && (
            <button
              type="button"
              className="ContactForm-SameAsLocation"
              onClick={copyMailingContactToBillingContact}
            >
              Same as Location Contact
            </button>
          )}
        </div>
        <TextField
          id={`ContactForm-${typeIdSelector}-First-Name`}
          className="ContactForm-text-input"
          label="First Name"
          variant="outlined"
          margin="dense"
          name={`${typeIdSelector}Contact.firstName`}
          value={contact?.values?.firstName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            getIn(contact.touched, 'firstName') &&
            !!getIn(contact.errors, 'firstName')
          }
        />
        <TextField
          id={`ContactForm-${typeIdSelector}-Last-Name`}
          className="ContactForm-text-input"
          label="Last Name"
          variant="outlined"
          margin="dense"
          name={`${typeIdSelector}Contact.lastName`}
          value={contact?.values?.lastName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            getIn(contact.touched, 'lastName') &&
            !!getIn(contact.errors, 'lastName')
          }
        />
        <TextField
          id={`ContactForm-${typeIdSelector}-Job-Title`}
          className="ContactForm-text-input"
          label="Job Title"
          variant="outlined"
          margin="dense"
          name={`${typeIdSelector}Contact.jobTitle`}
          value={contact?.values?.jobTitle}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            getIn(contact.touched, 'jobTitle') &&
            !!getIn(contact.errors, 'jobTitle')
          }
        />
        <TextField
          id={`ContactForm-${typeIdSelector}-Email`}
          className="ContactForm-text-input"
          label="Email"
          inputMode="email"
          type="email"
          variant="outlined"
          margin="dense"
          name={`${typeIdSelector}Contact.email`}
          value={contact?.values?.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            getIn(contact.touched, 'email') && !!getIn(contact.errors, 'email')
          }
        />
        <NumberFormat
          id={`ContactForm-${typeIdSelector}-Phone`}
          customInput={TextField}
          className="ContactForm-text-input"
          label="Phone"
          inputMode="tel"
          type="tel"
          variant="outlined"
          margin="dense"
          format="(###) ###-####"
          mask="_"
          name={`${typeIdSelector}Contact.mobilePhone.number`}
          value={contact.values?.mobilePhone?.number}
          onChange={(e) => {
            const newPhone = utils.strings.cleanPhoneNumber(e.target.value);
            formik.setFieldValue(
              `${typeIdSelector}Contact.mobilePhone.number`,
              newPhone
            );
          }}
          onBlur={formik.handleBlur}
          error={
            getIn(contact.touched, 'mobilePhone.number') &&
            !!getIn(contact.errors, 'mobilePhone.number')
          }
        />
      </div>
    </div>
  );
};

export default ContactForm;
