import api from 'api';
import {
  OrganizationOverviewRequestParams,
  UpdateOrganizationOverviewRequestParams,
} from 'api/organizations';
import { Organization } from 'models';
import {
  ActionTypeFromCreators,
  createAsyncAction,
} from 'store/actions/helpers';
import users, { OrganizationUsersAction } from './users';

export type OrganizationsAction = OrganizationUsersAction;

const actions = {
  getOrganizationOverview: 'Get Organization Overview',
  updateOrganizationOverview: 'Update Organization Overview',
} as const;

/**
 * Get Organization Overview
 */
export const {
  types: getOrganizationOverviewActionTypes,
  creators: getOrganizationOverviewActionCreators,
  action: getOrganizationOverview,
} = createAsyncAction<
  typeof actions.getOrganizationOverview,
  OrganizationOverviewRequestParams,
  Organization
>(actions.getOrganizationOverview, ({ orgId }) =>
  api.organizations.getOrganizationOverview({ orgId })
);

type GetOrganizationOverviewAction = ActionTypeFromCreators<
  typeof actions.getOrganizationOverview,
  OrganizationOverviewRequestParams,
  Organization,
  typeof getOrganizationOverviewActionCreators
>;

/**
 * Update Organization
 */
export const {
  types: updateOrganizationOverviewActionTypes,
  creators: updateOrganizationOverviewActionCreators,
  action: updateOrganizationOverview,
} = createAsyncAction<
  typeof actions.updateOrganizationOverview,
  UpdateOrganizationOverviewRequestParams,
  Organization
>(actions.updateOrganizationOverview, ({ org }) =>
  api.organizations.updateOrganizationOverview({ org })
);

type UpdateOrganizationOverviewAction = ActionTypeFromCreators<
  typeof actions.updateOrganizationOverview,
  UpdateOrganizationOverviewRequestParams,
  Organization,
  typeof updateOrganizationOverviewActionCreators
>;

/**
 * Export Consolidation
 */
export type OrganizationOverviewAction =
  | GetOrganizationOverviewAction
  | UpdateOrganizationOverviewAction;

export default {
  users,
};
