import {
  Index,
  TableProps as VirtualizedTableProps,
  TableCellProps as VirtualizedTableCellProps,
  TableRowProps as VirtualizedTableRowProps,
  ColumnProps as VirtualizedColumnProps,
} from 'react-virtualized';
import { NumberFormatProps } from 'react-number-format';
import { SelectionState } from 'common/types';

type CellContent = React.ReactNode | ColumnContentFunction;
export interface CellProps extends VirtualizedTableCellProps {
  content?: CellContent;
  value?: ColumnValueFunction;
}

export interface ChecklistColumnContentProps<T> {
  item: T;
}

interface Column
  extends SupportedVirtualizedColumnProps,
    SupportedNumberFormatProps {
  heading: React.ReactNode;
  content?: CellContent;
  value?: ColumnValueFunction;

  width?: number;
  sort?: string;
  reverse?: boolean;

  numberFormat?: boolean | NumberFormatProps['format'];
}

type ColumnContentFunction = (
  data: VirtualizedTableCellProps
) => React.ReactElement;
type ColumnValueFunction = (
  data: VirtualizedTableCellProps
) => boolean | number | string;

type OptionalVirtualizedTableProps = Partial<
  Pick<
    VirtualizedTableProps,
    | 'headerHeight'
    | 'rowHeight'
    | 'rowClassName'
    | 'rowStyle'
    | 'onRowClick'
    | 'height'
    | 'width'
  >
>;

export interface OuterSelection<T> {
  outerSelectionState?: SelectionState<T>;
  outerlUpdateSelectionState?:
    | ((newSelection: Partial<SelectionState<T>>) => void)
    | undefined;
}

export interface RowProps extends VirtualizedTableRowProps {
  rowKey: string;
}

export type SupportedNumberFormatProps = Pick<
  NumberFormatProps,
  | 'decimalScale'
  | 'fixedDecimalScale'
  | 'isNumericString'
  | 'thousandSeparator'
  | 'decimalSeparator'
  | 'allowLeadingZeros'
>;
export const supportedNumberFormatPropNames: (keyof SupportedNumberFormatProps)[] =
  [
    'decimalScale',
    'fixedDecimalScale',
    'isNumericString',
    'thousandSeparator',
    'decimalSeparator',
    'allowLeadingZeros',
  ];

type SupportedVirtualizedColumnProps = Partial<
  Omit<VirtualizedColumnProps, 'width'>
>;

export interface TableProps<T> extends OptionalVirtualizedTableProps {
  collection?: Array<T> | any;
  items?: T[];
  columns: Column[];
  selectionState?: SelectionState<T>;
  updateSelectionState?: (newSelection: Partial<SelectionState<T>>) => void;
  selectable?: boolean;
  defaultSelectedIndex?: number;
  selectedRowClassName?: string | ((info: Index) => string);
  selectedRowStyle?:
    | React.CSSProperties
    | ((info: Index) => React.CSSProperties);
  hideHeader?: boolean;
  rowCount?: number;
  loadingPlaceholder?: React.ReactNode;
  showLoadingSkeletons?: boolean;
  skeletonRows?: number;
  totalRowCount?: number;
  fetchMoreRows?: () => any;
  isInfiniteLoading?: boolean;
}
