import useSession, { setTokens } from 'api/session';
import React from 'react';
import { Route, useLocation, useNavigate } from 'react-router-dom';

const Authenticate: React.FC = ({ children }) => {
  const queryParam = new URLSearchParams(useLocation().search);
  const refreshToken = queryParam.get('refreshToken');
  if (refreshToken) {
    setTokens('', refreshToken, '');
  }

  const navigate = useNavigate();
  const { isLoading, isError } = useSession();

  if (isError) {
    navigate('/login');
  }

  if (isLoading || isError) {
    return null;
  }

  return <Route>{children}</Route>;
};

export default Authenticate;
