import { AuthenticationResponse } from 'models';
import api from 'api';
import { AuthenticateRequestParams } from 'api/authentication';

import { createAsyncAction, ActionTypeFromCreators } from '../helpers';

const actions = {
  authenticate: 'Authenticate',
  refreshToken: 'Refresh Token',
  logout: 'Logout',
} as const;

export const {
  types: authenticateActionTypes,
  creators: authenticateActionCreators,
  action: authenticate,
} = createAsyncAction<
  typeof actions.authenticate,
  AuthenticateRequestParams,
  AuthenticationResponse
>(actions.authenticate, api.authentication.authenticate);

type AuthenticateAction = ActionTypeFromCreators<
  typeof actions.authenticate,
  AuthenticateRequestParams,
  AuthenticationResponse,
  typeof authenticateActionCreators
>;

export const {
  types: refreshTokenActionTypes,
  creators: refreshTokenActionCreators,
  action: refreshToken,
} = createAsyncAction<
  typeof actions.refreshToken,
  void,
  AuthenticationResponse
>(actions.refreshToken, api.authentication.refreshToken);

type RefreshTokenAction = ActionTypeFromCreators<
  typeof actions.refreshToken,
  void,
  AuthenticationResponse,
  typeof refreshTokenActionCreators
>;

export const {
  types: logoutActionTypes,
  creators: logoutActionCreators,
  action: logout,
} = createAsyncAction<typeof actions.logout>(
  actions.logout,
  api.authentication.logout
);

type LogoutAction = ActionTypeFromCreators<
  typeof actions.logout,
  void,
  void,
  typeof logoutActionCreators
>;

export type AuthenticationAction =
  | AuthenticateAction
  | RefreshTokenAction
  | LogoutAction;

export type AuthenticationActionTypes =
  | typeof authenticateActionTypes
  | typeof refreshTokenActionTypes
  | typeof logoutActionTypes;
