import { PluginItem } from 'models/plugins';
import {
  assignPluginActionTypes,
  getPluginsActionTypes,
  OrganizationPluginsActionTypes,
  unassignPluginActionTypes,
} from 'store/actions/organizations/plugins';
import { OrganizationPluginOptionsActionTypes } from 'store/actions/organizations/plugins/options';
import { ReconVelocityPluginActionTypes } from 'store/actions/organizations/plugins/reconvelocity';
import { VelocityEngagePluginActionTypes } from 'store/actions/organizations/plugins/velocityengage';
import { FetchedData } from 'store/reducers/types';
import organizationPluginOptionsReducer, {
  initialState as pluginOptionsInitialState,
  OrganizationPluginOptionsState,
} from './options';
import reconvelocityReducer, {
  initialState as reconVelocityInitialState,
  ReconVelocityPluginState,
} from './reconvelocity';
import velocityengageReducer, {
  initialState as velocityEngageInitialState,
  VelocityEngagePluginState,
} from './velocityengage';

interface OrganizationPluginsState
  extends FetchedData<PluginItem[] | undefined> {
  options: OrganizationPluginOptionsState;
  reconvelocity: ReconVelocityPluginState;
  velocityengage: VelocityEngagePluginState;
}

export const initialState: OrganizationPluginsState = {
  loading: false,
  data: undefined,
  error: undefined,
  options: pluginOptionsInitialState,
  reconvelocity: reconVelocityInitialState,
  velocityengage: velocityEngageInitialState,
};

function organizationPluginsReducer(
  state = initialState,
  action: OrganizationPluginsActionTypes
): OrganizationPluginsState {
  switch (action.type) {
    case getPluginsActionTypes.pending: {
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    }
    case getPluginsActionTypes.fulfilled: {
      const { data = [] } = action.payload ?? {};
      return {
        ...state,
        data,
        loading: false,
        error: undefined,
      };
    }
    case getPluginsActionTypes.rejected: {
      const { error } = action.payload ?? {};
      return {
        ...state,
        error,
        loading: false,
      };
    }

    case assignPluginActionTypes.pending: {
      return {
        ...state,
        loading: true,
      };
    }
    case assignPluginActionTypes.fulfilled: {
      const { data } = action.payload ?? {};
      if (!data) return state;

      const { data: existingData = [] } = state;

      return {
        ...state,
        loading: false,
        data: [...existingData, data],
        error: undefined,
      };
    }
    case assignPluginActionTypes.rejected: {
      const { error } = action.payload ?? {};
      return {
        ...state,
        loading: false,
        error,
      };
    }

    case unassignPluginActionTypes.pending: {
      return {
        ...state,
        loading: true,
      };
    }
    case unassignPluginActionTypes.fulfilled: {
      const { params } = action.payload ?? {};
      const { pluginId } = params ?? {};

      return {
        ...state,
        loading: false,
        data: state.data?.filter(({ id }) => id === pluginId),
        error: undefined,
      };
    }
    case unassignPluginActionTypes.rejected: {
      const { error } = action.payload ?? {};
      return {
        ...state,
        loading: false,
        error,
      };
    }

    default: {
      return {
        ...state,
        options: organizationPluginOptionsReducer(
          state.options,
          action as OrganizationPluginOptionsActionTypes
        ),
        reconvelocity: reconvelocityReducer(
          state.reconvelocity,
          action as ReconVelocityPluginActionTypes
        ),
        velocityengage: velocityengageReducer(
          state.velocityengage,
          action as VelocityEngagePluginActionTypes
        ),
      };
    }
  }
}

export default organizationPluginsReducer;
