export enum PluginItemCategory {
  DATA_PROVIDER = 'DATA_PROVIDER',
  ENHANCER = 'ENHANCER',
  MODULE = 'MODULE',
  UNKNOWN = 'UNKNOWN',
}

export enum PluginItemSubCategory {
  CRM = 'CRM',
  DMS = 'DMS',
  IMS = 'IMS',
  INVENTORY = 'INVENTORY',
  NOT_SET = 'NOT_SET',
  RECALLS = 'RECALLS',
  REPORT = 'REPORT',
}

export interface PluginSubsetting {
  dataType: string;
  value: any;
}

export interface PluginItem {
  enabled: boolean;
  category: PluginItemCategory;
  title: string;
  id?: string;
  settings: { [key: string]: PluginSubsetting };
  pluginName: string;
  subCategory: PluginItemSubCategory;
  description: string;
  iconUrl: string;
  links: Record<string, string> | null;
}

export interface PluginItemMatchCriterion {
  columnName: string;
  regexPattern: string;
  businessLine?: string;
}

export interface DataProviderPluginItem extends PluginItem {
  dealerId?: string;
  dealerCode?: string;
  matchCriteria?: PluginItemMatchCriterion[];
  notes?: string;
  ingestFileReceivedDate?: string;
  ingestFileVehicleCount?: number;
}

export interface DataProviderDmsPluginItem extends DataProviderPluginItem {
  options?: {
    enableInventory?: boolean;
    enableSales?: boolean;
    enableRepairOrders?: boolean;
  };
}
